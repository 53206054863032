import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import{ useDispatch } from 'react-redux';

import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';
import { updateUser } from '../../../../services/usersService';

import useUserById from '../../../../hooks/useUserById';

import { StyledTab } from '../../../../components/StyledTab';
import { StyledTabs } from '../../../../components/StyledTabs';
import { TabPanel } from '../../../../components/TabPanel';

import { Formik, Form, validateYupSchema, yupToFormErrors } from 'formik';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

import { stationUserUrls } from '../../routing';

import { ROLES_KEYS } from '../../../../constants/roles';

import { StationUserForm } from '../../../../Forms/StationUserForm';
import { EducationForm } from '../../../../Forms/EducationForm';
import { StationUserPermissionsForm } from '../../../../Forms/StationUserPermissionsForm';
import { CoursesTable } from '../../../../components/CoursesTable';
import { ExperienceTable } from '../../../../components/ExperienceTable';

import schema from './schema';

const useStyles = makeStyles(styles);

const EditStationUser = (props) => {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(0);
    const [courses, setCourses] = useState([]);
    const [experiences, setExperiences] = useState([]);
    const dispatch = useDispatch();
    const { user_id } = useParams();
    const { user } = useUserById(user_id);
    const {
        backendResponse: updateUserResponse,
        executeService: updateUserService,
    } = useBackendMessageResponse(updateUser);
    const [userProfileData, setUserProfileData] = useState({
        // StationUser
        firstname: '',
        lastname: '',
        document_type: '',
        document: '',
        genre: '',
        nationality: '',
        country_id: '',
        department_id: '',
        city_id: '',
        born_date: new Date(),
        born_city_id: '',
        military_type: '',
        military_number: '',
        address: '',
        position_id: '',
        rank_id: '',
        email: '',
        phone: '',

        // Education
        education_level_id: '',
    });

    const [settings, setSettings] = useState({
        st: {
            read: false,
            write: false,
        },
        mt: {
            read: false,
            write: false,
        },
        gr: {
            read: false,
            write: false,
        },
        events: {
            read: false,
            write: false,
        },
        user: {
            read: false,
            write: false,
        },
    });

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    }

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    const handleOnCoursesChange = (newCourses) => {
        setCourses(newCourses);
    }

    const handleOnExperiencesChange = (newExperiences) => {
        setExperiences(newExperiences);
    }

    const handleUserProfileSubmit = (values) => {
        setUserProfileData(values);
        setSelectedTab(1);
    }

    const handlePermissionsSubmit = async (values) => {
        setSettings(values);

        const stationUserData = {
            ...userProfileData,
            id: user_id,
            role: ROLES_KEYS.station_user,
            firefighter_courses: JSON.stringify(courses),
            firefighter_info: JSON.stringify(experiences),
            settings: JSON.stringify(values),
        };

        await updateUserService(user_id, stationUserData);
    }

    useEffect(() => {
        if (updateUserResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: updateUserResponse.message,
            severity: 'success',
        }));

        props.history.push(stationUserUrls.Listado);
    }, [dispatch, updateUserResponse.message, props.history]);

    useEffect(() => {
        if (updateUserResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: updateUserResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, updateUserResponse.error])

    useEffect(() => {
        if (user === null) {
            return;
        }

        setUserProfileData({
            // StationUser
            firstname: user.firstname,
            lastname: user.lastname,
            document_type: user.document_type,
            document: user.document,
            genre: user.genre,
            nationality: user.nationality,
            country_id: user.country_id,
            department_id: user.department.id,
            city_id: user.city.id,
            born_date: new Date(user.born_date),
            born_city_id: user.born_city_id,
            military_type: user.military_type,
            military_number: user.military_number,
            address: user.address,
            position_id: user.position_id,
            rank_id: user.rank_id,
            email: user.email,
            phone: user.phone,

            // Education
            education_level_id: user.education_level_id,
        });

        // Se tiene que convertir en objeto Date para que los componentes de las tablas de experiencias y cursos
        // puedan operar correctamente y no tengan que preocuparse por el mapeo.
        if (user.firefighter_courses !== null) {
            const userCourses = JSON.parse(user.firefighter_courses);
            const userCoursesWithDatesMapped = userCourses.map(course => {
                return {
                    ...course,
                    date: new Date(course.date),
                };
            });
            setCourses(userCoursesWithDatesMapped);
        }
        if (user.firefighter_info !== null) {
            const userExperience = JSON.parse(user.firefighter_info);
            const userExperienceWithDatesMapped = userExperience.map(experience => {
                return {
                    ...experience,
                    start_date: new Date(experience.start_date),
                    retirement_date: new Date(experience.retirement_date),
                };
            });
            setExperiences(userExperienceWithDatesMapped);
        }
        if (user.settings !== null) {
            const userSettings = JSON.parse(user.settings);
            setSettings(userSettings);
        }
    }, [user]);

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <StyledTabs value={selectedTab} onChange={handleTabChange} aria-label="add-station-user-lists-tabs" variant="scrollable">
                <StyledTab label="Información del usuario" />
                <StyledTab label="Permisos" disabled={selectedTab !== 1} />
            </StyledTabs>
            <div className={classes.panelsContainer}>
                <TabPanel value={selectedTab} index={0}>
                    <Formik
                        enableReinitialize
                        validate={async (values) => {
                            try {
                                await validateYupSchema(values, schema, false, { 
                                    document_type: values.document_type,
                                    id: parseInt(user_id),
                                });
                            } catch(err) {
                                return yupToFormErrors(err);
                            }

                            return {};
                        }}
                        initialValues={userProfileData}
                        onSubmit={handleUserProfileSubmit}
                    >
                        {formik => (
                            <Form>
                                <Grid container direction="row" justify="center" alignItems="flex-start" spacing={2}>
                                    <Grid item md={6} xs={12} style={{borderRight: '2px solid #e5e5e5'}}>
                                        <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                                            <Grid item className={classes.columnWidth}>
                                                <StationUserForm {...formik} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                                            <Grid item className={classes.columnWidth}>
                                                <Typography component="div">
                                                    <Box fontWeight="fontWeightBold" fontSize={18} className={classes.stationFormTitle}>
                                                        Academia
                                                    </Box>
                                                </Typography>
                                                <EducationForm {...formik} />
                                            </Grid>
                                            <Grid item className={classes.columnWidth}>
                                                <CoursesTable courses={courses} onCoursesChange={handleOnCoursesChange} />
                                            </Grid>
                                            <Grid item className={classes.columnWidth}>
                                                <ExperienceTable experiences={experiences} onExperiencesChange={handleOnExperiencesChange} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <Grid item>
                                        <Button
                                            className={classes.saveProfileButton}
                                            color="primary"
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            disableElevation
                                        >
                                            Siguiente
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    <Formik
                        enableReinitialize
                        initialValues={settings}
                        onSubmit={handlePermissionsSubmit}
                        component={StationUserPermissionsForm}
                    />
                </TabPanel>
            </div>
        </div>
    );
}

export default EditStationUser;