import { useState, useEffect } from 'react';
import { getExtinguisherByID } from '../services/extinguishersService';

const useExtinguisherById = (extinguisher_id) => {
    const [extinguisher, setExtinguisher] = useState(null);

    useEffect(() => {
        async function fetchExtinguisher() {
            try {
                const { data } = await getExtinguisherByID(extinguisher_id);
                setExtinguisher(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchExtinguisher();
    }, [extinguisher_id]);

    return { extinguisher };
}

export default useExtinguisherById;