import React from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

import { Field, ErrorMessage } from 'formik';

import useVehicles from '../../../../../../../hooks/MT/useVehicles';
import useEquipments from '../../../../../../../hooks/MT/useEquipments';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const DamageReportCostsForm = (props) => {
    const classes = useStyles();

    // El 1000 es porque necesitamos traernos todos los vehículos y todos los equipos.
    // Entonces para reutilizar los mismos servicios y el mismo endpoint, se solicita una sola página
    // con mil registros. En caso de ser más, el 1000 debe incrementarse.
    const vehicles = useVehicles(1, 1000);
    const equipments = useEquipments(1, 1000);

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    };

    const handleTypeButtonClicked = (value) => () => {
        props.setFieldValue('type', value);
    }

    return (
        <Grid container direction="column" justify="flex-start" alignItems="center" spacing={2}>
            <Grid item className={classes.fullWidth}>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Field 
                            name="business_name" 
                            type="text" 
                            as={TextField} 
                            variant="outlined" 
                            label="Razón social"
                            className={classes.formControl} 
                            error={hasError('business_name')}
                            helperText={<ErrorMessage name="business_name" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field 
                            name="iva" 
                            type="text" 
                            variant="outlined" 
                            as={CurrencyTextField}
                            label="IVA"
                            className={classes.formControl} 
                            error={hasError('iva')}
                            helperText={<ErrorMessage name="iva" />}
                            onChange={(event, value) => props.setFieldValue('iva', value)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.fullWidth}>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Field 
                            name="facturing_res" 
                            type="text" 
                            as={TextField} 
                            variant="outlined" 
                            label="Resolución de facturación"
                            className={classes.formControl} 
                            error={hasError('facturing_res')}
                            helperText={<ErrorMessage name="facturing_res" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field 
                            name="costs" 
                            type="text" 
                            as={CurrencyTextField} 
                            variant="outlined" 
                            label="Costos totales"
                            className={classes.formControl} 
                            error={hasError('costs')}
                            helperText={<ErrorMessage name="costs" />}
                            onChange={(event, value) => props.setFieldValue('costs', value)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.fullWidth}>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Field 
                            name="receipt_number" 
                            type="number" 
                            as={TextField} 
                            variant="outlined" 
                            label="Número de factura"
                            className={classes.formControl} 
                            error={hasError('receipt_number')}
                            helperText={<ErrorMessage name="receipt_number" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ButtonGroup style={{height: '50px'}} fullWidth color="primary" aria-label="type-button-group">
                            <Button onClick={handleTypeButtonClicked("Equipo")} variant={props.values.type === "Equipo" ? "contained": "outlined"}>Equipo</Button>
                            <Button onClick={handleTypeButtonClicked("Máquina")} variant={props.values.type === "Máquina" ? "contained": "outlined"}>Máquina</Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.fullWidth}>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Field 
                            name="ivaless_cost" 
                            type="text" 
                            as={CurrencyTextField} 
                            variant="outlined" 
                            label="Valor sin IVA"
                            className={classes.formControl} 
                            error={hasError('ivaless_cost')}
                            helperText={<ErrorMessage name="ivaless_cost" />}
                            onChange={(event, value) => props.setFieldValue('ivaless_cost', value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {props.values.type === "Equipo" ? (
                            <FormControl className={classes.formControl} variant="outlined" error={hasError('equipment_id')}>
                                <InputLabel>Código de equipo</InputLabel>
                                <Field 
                                    name="equipment_id" 
                                    type="text"
                                    as={Select} 
                                    label="Código de equipo"
                                    className={classes.formControl} 
                                >
                                    {equipments.map((equipment, key) => (
                                        <MenuItem key={`equipment-key-${key}`} value={equipment.id}>{equipment.code}</MenuItem>
                                    ))}
                                </Field>
                                <FormHelperText>
                                    <ErrorMessage name="equipment_id" />
                                </FormHelperText>
                            </FormControl>
                        ) : (
                            <FormControl className={classes.formControl} variant="outlined" error={hasError('vehicle_id')}>
                                <InputLabel>Código de vehículo</InputLabel>
                                <Field 
                                    name="vehicle_id" 
                                    type="text"
                                    as={Select} 
                                    label="Código de vehículo"
                                    className={classes.formControl} 
                                >
                                    {vehicles.map((vehicle, key) => (
                                        <MenuItem key={`vehicle-key-${key}`} value={vehicle.id}>{vehicle.code}</MenuItem>
                                    ))}
                                </Field>
                                <FormHelperText>
                                    <ErrorMessage name="vehicle_id" />
                                </FormHelperText>
                            </FormControl>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default DamageReportCostsForm;