import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedTab, selectConfigurationLists } from '../../../../store/slices/configurationListsSlice';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

import { StyledTab } from '../../../../components/StyledTab';
import { StyledTabs } from '../../../../components/StyledTabs';
import { TabPanel } from '../../../../components/TabPanel';

import { 
    ListaBarrios,
    ListaDepartamentos,
    ListaEmergencias,
    ListaMunicipios,
} from './Listas';

const useStyles = makeStyles(styles);

const ListadosConfiguracion = (props) => {
    const dispatch = useDispatch();
    const configurationLists = useSelector(selectConfigurationLists);
    const classes = useStyles();

    const handleTabChange = (event, newValue) => {
        dispatch(setSelectedTab({ selectedTab: newValue }));
    }

    return (
        <div>
            <StyledTabs value={configurationLists.selectedTab} onChange={handleTabChange} aria-label="configuration-lists-tabs" variant="scrollable">
                <StyledTab label="Emergencias"/>
                <StyledTab label="Departamentos"/>
                <StyledTab label="Municipios"/>
                <StyledTab label="Barrios"/>
            </StyledTabs>
            <div className={classes.panelsContainer}>
                <TabPanel value={configurationLists.selectedTab} index={0}>
                    <ListaEmergencias {...props} />
                </TabPanel>
                <TabPanel value={configurationLists.selectedTab} index={1}>
                    <ListaDepartamentos {...props} />
                </TabPanel>
                <TabPanel value={configurationLists.selectedTab} index={2}>
                    <ListaMunicipios {...props} />
                </TabPanel>
                <TabPanel value={configurationLists.selectedTab} index={3}>
                    <ListaBarrios {...props} />
                </TabPanel>
            </div>
        </div>
    );
}

export default ListadosConfiguracion;