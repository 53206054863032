import validate from 'validate.js';
import isAfter from 'date-fns/isAfter';
import isEqual from 'date-fns/isEqual';

validate.validators.isAfterDate = function(value, options, key, attributes) {
    const compareWithKey = options['compareWith'];
    const dateToCompare = attributes[compareWithKey];
    const dateThatShouldBeAfter = value;
    if (isAfter(dateThatShouldBeAfter, dateToCompare)) {
        return null;
    } else if (options['checkEquality'] && isEqual(dateThatShouldBeAfter, dateToCompare)) {
        return null;
    }
    return options['message'] || `is not after ${compareWithKey}`;
}