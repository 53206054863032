import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MuiPhoneNumber from 'material-ui-phone-number';

import { UploadAvatar } from '../../../../../../components/UploadAvatar';

import { ID_TYPES } from '../../../../../../constants/id_types';

import { Form, Field, ErrorMessage } from 'formik';

import useDepartments from '../../../../../../hooks/useDepartments';
import useCities from '../../../../../../hooks/useCities';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const AdminProfileForm = (props) => {
    const classes = useStyles();
    const departments = useDepartments();
    const cities = useCities();

    const { blockedEmail, blockedForm } = props;

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    };

    return (
        <Form>
            <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
                <Grid item className={classes.avatarContainer}>
                  <div className={classes.ratioContainer}>
                    <div className={classes.ratioItem}>
                      <Field 
                          name="image_url"
                          as={UploadAvatar}
                          imageSrc={props.values.image_url}
                          noImageText={'Cargar avatar'}
                          onChange={(image_url) => props.setFieldValue('image_url', image_url)}
                          disabled={blockedForm}
                          ratioText={'Ratio 1:1 (500x500)'}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item className={classes.row}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                        <Grid item xs={12} md={6}>
                            <Field 
                                name="firstname" 
                                type="text" 
                                as={TextField} 
                                variant="outlined" 
                                label="Nombres"
                                className={classes.formControl} 
                                error={hasError('firstname')}
                                helperText={<ErrorMessage name="firstname" />}
                                disabled={blockedForm}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field 
                                name="lastname" 
                                type="text" 
                                as={TextField} 
                                variant="outlined" 
                                label="Apellidos"
                                className={classes.formControl} 
                                error={hasError('lastname')}
                                helperText={<ErrorMessage name="lastname" />}
                                disabled={blockedForm}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.row}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                        <Grid item xs={12} md={6}>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                                <Grid item xs={3}>
                                    <FormControl 
                                        variant="outlined" 
                                        className={classes.formControl} 
                                        error={hasError("document_type")}
                                        disabled={blockedForm}
                                    >
                                        <InputLabel id="id-type-station-user-label">ID</InputLabel>
                                        <Field
                                            name="document_type"
                                            as={Select}
                                            labelId="id-type-station-user-label"
                                            id="id-type-station-user-select"
                                            label="ID"
                                        >
                                        {ID_TYPES.map((id_type, index) => (
                                            <MenuItem value={id_type} key={`id-type-station-user-key-${index}`}>{id_type}</MenuItem>
                                        ))}
                                        </Field>
                                        <FormHelperText>
                                            <ErrorMessage name="document_type" />
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={9}>
                                    <Field
                                        name="document"
                                        as={TextField}
                                        className={classes.formControl}
                                        variant="outlined"
                                        label="Identificación"
                                        type="text"
                                        error={hasError("document")}
                                        helperText={<ErrorMessage name="document" />}
                                        disabled={blockedForm}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                name="phone"
                                as={MuiPhoneNumber}
                                className={classes.formControl}
                                variant="outlined"
                                label="Número de teléfono"
                                type="text"
                                error={hasError("phone")}
                                helperText={<ErrorMessage name="phone" />}
                                onChange={(value) => props.setFieldValue('phone', value)}
                                disabled={blockedForm}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.row}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                        <Grid item xs={12} md={6}>
                            <Field
                                name="email"
                                as={TextField}
                                className={classes.formControl}
                                variant="outlined"
                                label="Correo electrónico"
                                type="text"
                                error={hasError("email")}
                                helperText={<ErrorMessage name="email" />}
                                disabled={blockedEmail || blockedForm}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl 
                                variant="outlined" 
                                className={classes.formControl} 
                                error={hasError("department_id")}
                                disabled={blockedForm}
                            >
                                <InputLabel id="department-risk-user-label">Departamento</InputLabel>
                                <Field
                                    name="department_id"
                                    as={Select}
                                    labelId="department-risk-user-label"
                                    id="department-risk-user-select"
                                    label="Departamento"
                                >
                                {departments.map((department, index) => (
                                    <MenuItem value={department.id} key={`department-risk-user-key-${index}`}>{department.name}</MenuItem>
                                ))}
                                </Field>
                                <FormHelperText>
                                    <ErrorMessage name="department_id" />
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.row}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                        <Grid item xs={12} md={6}>
                            <FormControl 
                                variant="outlined" 
                                className={classes.formControl} 
                                error={hasError('city_id')}
                                disabled={blockedForm}
                            >
                                <InputLabel id="city-risk-user-label">Municipio</InputLabel>
                                <Field
                                    name="city_id"
                                    as={Select}
                                    labelId="city-risk-user-label"
                                    id="city-risk-user-select"
                                    label="Municipio"
                                >
                                {cities.filter(city => city.department_id === props.values.department_id || city.name === "Otro").map((city, index) => (
                                    <MenuItem value={city.id} key={`city-station-user-key-${index}`}>{city.name}</MenuItem>
                                ))}
                                </Field>
                                <FormHelperText>
                                    <ErrorMessage name="city_id" />
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.row}>
                    <Button
                        className={classes.saveButton}
                        color="primary"
                        size="large"
                        type="submit"
                        variant="contained"
                        disableElevation
                    >
                        {blockedForm ? 'Editar' : 'Guardar'}
                    </Button>
                </Grid>
            </Grid>
        </Form>
    );
}

AdminProfileForm.defaultProps = {
    blockedEmail: false,
    blockedForm: false,
};

export default AdminProfileForm;