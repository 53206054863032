import { useState, useEffect } from 'react';
import { getAllExtinguishers } from '../services/extinguishersService';

const useAllExtinguishers = () => {
    const [extinguishers, setExtinguishers] = useState([]);

    useEffect(() => {
        async function fetchAllExtinguishers() {
            try {
                const { data } = await getAllExtinguishers();
                setExtinguishers(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchAllExtinguishers();
    }, []);

    return { extinguishers };
}

export default useAllExtinguishers;