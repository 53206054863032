export default theme => ({
    inspectionDataContainer: {
        marginTop: '20px',
    },
    inspectionQuantity: {
        color: '#0199AE',
    },
    divider: {
        marginTop: '15px',
    },
});