import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { Form, Field, ErrorMessage } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const ChartInspectionForm = (props) => {
    const classes = useStyles();

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Form>
                <Grid container direction="column" spacing={2} justify="flex-start" alignItems="flex-start">
                    <Grid item className={classes.row}>
                        <Grid container direction="row" spacing={2} justify="flex-start" alignItems="flex-start">
                            <Grid item xs={12} md={5}>
                                <Field 
                                    name="start_date" 
                                    as={KeyboardDatePicker} 
                                    label="Fecha de Inicio"
                                    className={classes.formControl} 
                                    inputVariant="outlined"
                                    variant="inline"
                                    InputAdornmentProps={{ position: "end" }}
                                    format="dd/MM/yyyy"
                                    onChange={(date) => props.setFieldValue("start_date", date)}
                                    error={hasError('start_date')}
                                    helperText={<ErrorMessage name="start_date" />}
                                    autoOk
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Field 
                                    name="end_date" 
                                    as={KeyboardDatePicker} 
                                    label="Fecha de Cierre"
                                    className={classes.formControl} 
                                    inputVariant="outlined"
                                    variant="inline"
                                    InputAdornmentProps={{ position: "end" }}
                                    format="dd/MM/yyyy"
                                    onChange={(date) => props.setFieldValue("end_date", date)}
                                    error={hasError('end_date')}
                                    helperText={<ErrorMessage name="end_date" />}
                                    autoOk
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button
                                    className={classes.submitButton}
                                    color="primary"
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    disableElevation
                                >
                                    Consultar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Form>
        </MuiPickersUtilsProvider>
    );
}

export default ChartInspectionForm;