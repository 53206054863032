import { report_types, report_modules } from '../../../../../../constants/MT/reports';

const COSTS_TYPES = report_types[report_modules.costs];

export const requireVehicle = (selectedType) => {
    const vehicleRequiredTypes = [
        COSTS_TYPES.general_maintenance_vehicle,
        COSTS_TYPES.general_damage_report_vehicle,
        COSTS_TYPES.maintenance_by_vehicle,
        COSTS_TYPES.damage_report_by_vehicle,
    ];
    return vehicleRequiredTypes.includes(COSTS_TYPES[selectedType]);
}

export const requireEquipment = (selectedType) => {
    const equipmentRequiredTypes = [
        COSTS_TYPES.general_maintenance_equipment,
        COSTS_TYPES.general_damage_report_equipment,
        COSTS_TYPES.maintenance_by_equipment,
        COSTS_TYPES.damage_report_by_equipment,
    ];
    return equipmentRequiredTypes.includes(COSTS_TYPES[selectedType]);
}