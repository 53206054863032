import React from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import useUsers from '../../../../../hooks/useUsers';

import { Form, Field, ErrorMessage } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const RecepcionForm = (props) => {
    const classes = useStyles();
    const { readMode } = props;

    const { users, findUserById } = useUsers(1, 1000);

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    };

    return (
        <div className={classes.root}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Form>
                    <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                        <Grid item className={classes.row}>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="reception_date" 
                                        as={KeyboardDatePicker} 
                                        label="Fecha de recepción"
                                        className={classes.formControl} 
                                        inputVariant="outlined"
                                        variant="inline"
                                        InputAdornmentProps={{ position: "end" }}
                                        format="dd/MM/yyyy"
                                        onChange={(date) => props.setFieldValue("reception_date", date)}
                                        error={hasError('reception_date')}
                                        helperText={<ErrorMessage name="reception_date" />}
                                        disableFuture
                                        autoOk
                                        disabled={readMode}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl disabled={readMode} className={classes.formControl} variant="outlined" error={hasError('sender_id')}>
                                        <InputLabel>Nombre del remitente</InputLabel>
                                        <Field 
                                            name="sender_id" 
                                            type="text"
                                            as={Select} 
                                            label="Nombre del remitente"
                                            className={classes.formControl} 
                                        >
                                            {users.map((user, key) => (
                                                <MenuItem key={`sender-key-${key}`} value={user.id}>{`${user.firstname} ${user.lastname}`}</MenuItem>
                                            ))}
                                        </Field>
                                        <FormHelperText>
                                            <ErrorMessage name="sender_id" />
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.row}>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="reception_time" 
                                        as={KeyboardTimePicker} 
                                        ampm={false}
                                        label="Hora de recepción" 
                                        className={classes.formControl} 
                                        inputVariant="outlined"
                                        variant="inline"
                                        InputAdornmentProps={{ position: "end" }}
                                        keyboardIcon={<AccessTimeIcon />}
                                        onChange={(date) => props.setFieldValue("reception_time", date)}
                                        error={hasError('reception_time')}
                                        helperText={<ErrorMessage name="reception_time" />}
                                        autoOk
                                        disabled={readMode}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="sender_document" 
                                        type="text" 
                                        as={TextField} 
                                        variant="outlined" 
                                        label="Identificación del remitente"
                                        className={classes.formControl} 
                                        error={hasError('sender_document')}
                                        helperText={<ErrorMessage name="sender_document" />}
                                        value={(() => {
                                            const user = props.values.sender_id !== '' ? findUserById(props.values.sender_id) : null;
                                            if (user === undefined || user === null) {
                                                return '';
                                            }
                                            return `${user.document_type} - ${user.document}`;
                                        })()}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.row}>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="sheet_number" 
                                        type="number" 
                                        as={TextField} 
                                        variant="outlined" 
                                        label="Folio"
                                        className={classes.formControl} 
                                        error={hasError('sheet_number')}
                                        helperText={<ErrorMessage name="sheet_number" />}
                                        disabled={readMode}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="remission_date" 
                                        as={KeyboardDatePicker} 
                                        label="Fecha de remisión"
                                        className={classes.formControl} 
                                        inputVariant="outlined"
                                        variant="inline"
                                        InputAdornmentProps={{ position: "end" }}
                                        format="dd/MM/yyyy"
                                        onChange={(date) => props.setFieldValue("remission_date", date)}
                                        error={hasError('remission_date')}
                                        helperText={<ErrorMessage name="remission_date" />}
                                        disableFuture
                                        autoOk
                                        disabled={readMode}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.row}>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="annex_number" 
                                        type="number" 
                                        as={TextField} 
                                        variant="outlined" 
                                        label="Número de anexos"
                                        className={classes.formControl} 
                                        error={hasError('annex_number')}
                                        helperText={<ErrorMessage name="annex_number" />}
                                        disabled={readMode}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl disabled={readMode} className={classes.formControl} variant="outlined" error={hasError('receiver_id')}>
                                        <InputLabel>Nombre de quien recepciona</InputLabel>
                                        <Field 
                                            name="receiver_id" 
                                            type="text"
                                            as={Select} 
                                            label="Nombre de quien recepciona"
                                            className={classes.formControl} 
                                        >
                                            {users.map((user, key) => (
                                                <MenuItem key={`receiver-key-${key}`} value={user.id}>{`${user.firstname} ${user.lastname}`}</MenuItem>
                                            ))}
                                        </Field>
                                        <FormHelperText>
                                            <ErrorMessage name="receiver_id" />
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.row}>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="receiver_document" 
                                        type="text" 
                                        as={TextField} 
                                        variant="outlined" 
                                        label="Identificación de quien recepciona"
                                        className={classes.formControl} 
                                        error={hasError('receiver_document')}
                                        helperText={<ErrorMessage name="receiver_document" />}
                                        value={(() => {
                                            const user = props.values.receiver_id !== '' ? findUserById(props.values.receiver_id) : null;
                                            if (user === undefined || user === null) {
                                                return '';
                                            }
                                            return `${user.document_type} - ${user.document}`;
                                        })()}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.row}>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                                <Grid item xs={12}>
                                    <Field 
                                        name="reception_observations" 
                                        type="text" 
                                        as={TextField} 
                                        variant="outlined" 
                                        label="Observaciones adicionales"
                                        className={classes.formControl} 
                                        error={hasError('reception_observations')}
                                        helperText={<ErrorMessage name="reception_observations" />}
                                        multiline
                                        rows={6}
                                        disabled={readMode}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Button
                        className={classes.saveButton}
                        color="primary"
                        size="large"
                        type="submit"
                        variant="contained"
                        disableElevation
                        disabled={readMode}
                    >
                        Guardar
                    </Button>
                </Form>
            </MuiPickersUtilsProvider>
        </div>
    );
}

RecepcionForm.defaultProps = {
    readMode: false,
};

export default RecepcionForm;