import { useEffect, useState } from 'react';
import { getAllNeighborhoods } from '../services/neighborhoodsService';

const useAllNeighborhoods = () => {
    const [neighborhoods, setNeighborhoods] = useState([]);

    useEffect(() => {
        async function fetchNeighborhoods() {
            try {
                const { data } = await getAllNeighborhoods();
                const otroNeighborhoodIndex = data.findIndex(neighborhood => neighborhood.name === "Otro");
                if (otroNeighborhoodIndex !== -1) {
                    const otroNeighborhood = data[otroNeighborhoodIndex];
                    data.splice(otroNeighborhoodIndex, 1);
                    data.unshift(otroNeighborhood);
                }
                setNeighborhoods(data);
            } catch(e) {
                const{ response } = e;
                console.log(response);
            }
        }
        fetchNeighborhoods();
    }, []);

    return neighborhoods;
};

export default useAllNeighborhoods;