import httpClient from '../../http';
import qs from 'qs';

export const downloadGRReport = async (reportData) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        responseType: 'blob',
    };
    const serializedReportData = qs.stringify(reportData);
    return await httpClient.post('/reports/gr/inspections', serializedReportData, config);
}