export default (theme) => ({
    root: {
        marginLeft: "20px",
    },
    formContainer: {
        marginTop: theme.spacing(5),
    },
    form: {
        width: '40%',
    },
    row: {
        width: '100%',
    },
    formControl: {
        width: '90%',
    },
    changePasswordButton: {
        marginTop: theme.spacing(2),
        width: '160px',
        height: '45px',
    },
    backdrop: {
        zIndex: 2,
        color: '#fff',
    },
    errorMessage: {
        position: 'absolute',
        bottom: '-1.5em',
    },
});