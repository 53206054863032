import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { 
    selectNeighborhoods, 
    fetchNeighborhoodsRequest, 
} from '../../../../../../store/slices/neighborhoodsSlice';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import useBackendMessageResponse from '../../../../../../hooks/useBackendMessageResponse';
import { deleteNeighborhood } from '../../../../../../services/neighborhoodsService';
import { setGlobalAlert } from '../../../../../../store/slices/alertsSlice';

import { SearchField } from '../../../../../../components/SearchField';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import { configurationUrls } from '../../../../routing';

const useStyles = makeStyles(styles);

const ListaBarrios = (props) => {
    const dispatch = useDispatch();
    const neighborhoods = useSelector(selectNeighborhoods);
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const {
        backendResponse: deleteNeighborhoodResponse,
        executeService: deleteNeighborhoodService,
    } = useBackendMessageResponse(deleteNeighborhood);
    const [neighborhoodClickedData, setNeighborhoodClickedData] = useState({
        openDialog: false,
        neighborhood: null,
    });

    const onClickAddNeighborhood = () => {
        const { history } = props;
        history.push(configurationUrls.AddNeighborhood);
    }

    const onClickEditNeighborhood = (neighborhood) => () => {
        const { history } = props;
        history.push(configurationUrls.EditNeighborhood.replace(':neighborhood_id', neighborhood.id));
    }

    const onClickDeleteNeighborhood = (neighborhood) => () => {
        setNeighborhoodClickedData({
            openDialog: true,
            neighborhood,
        });
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onGetSearchTerm = (term) => {
        setSearchTerm(term);
        setPage(0);
    }

    const handleNeighborgoodDelete = async () => {
        const neighborhoodId = neighborhoodClickedData.neighborhood.id;
        await deleteNeighborhoodService(neighborhoodId);
        setNeighborhoodClickedData({
            openDialog: false,
            neighborhood: null,
        });
        dispatch(fetchNeighborhoodsRequest({
            page: page + 1,
            page_limit: rowsPerPage,
            filters: {
                searchTerm: searchTerm !== '' ? searchTerm : undefined,
            },
        }));
    }

    const handleCloseDeleteDialog = () => {
        setNeighborhoodClickedData({
            openDialog: false,
            neighborhood: null,
        });
    }

    useEffect(() => {
        if (deleteNeighborhoodResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: deleteNeighborhoodResponse.message,
            severity: 'success',
        }));
    }, [dispatch, deleteNeighborhoodResponse.message]);

    useEffect(() => {
        if (deleteNeighborhoodResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: deleteNeighborhoodResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, deleteNeighborhoodResponse.error])

    useEffect(() => {
        dispatch(fetchNeighborhoodsRequest({
            page: page + 1,
            page_limit: rowsPerPage,
            filters: {
                searchTerm: searchTerm !== '' ? searchTerm : undefined,
            },
        }));
    }, [dispatch, page, rowsPerPage, searchTerm]);

    return (
        <div className={classes.root}>
            <Dialog
                open={neighborhoodClickedData.openDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-neighborhood-delete-title"
                aria-describedby="alert-dialog-neighborhood-delete-description"
            >
                <DialogTitle id="alert-dialog-neighborhood-delete-title">{"Confirmación"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-neighborhood-delete-description">
                        ¿Estás seguro de querer continuar con la eliminación del registro seleccionado?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleNeighborgoodDelete} color="primary" autoFocus>
                        Continuar
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.fullWidth} spacing={2}>
                <Grid item md={6} xs={12} className={classes.searchInput}>
                    <SearchField onClickSearch={onGetSearchTerm} />
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" justify="space-between" className={classes.fullWidth}>
                <Grid item>
                    <Typography component={"span"}>
                        <Box fontWeight="fontWeightBold" fontSize={25}>Barrios</Box>
                    </Typography>
                </Grid>
                <Grid item>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        className={classes.addNeighborhoodButton} 
                        disableElevation
                        onClick={onClickAddNeighborhood}
                    >
                        Añadir barrio 
                    </Button>
                </Grid>
            </Grid>
            <Paper elevation={0}>
                <TableContainer>
                    <Table className={classes.table} aria-label="activities-table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Barrio</TableCell>
                                <TableCell align="left">Municipio</TableCell>
                                <TableCell align="right">Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {neighborhoods.neighborhoods.map((neighborhood, key) => (
                                <TableRow key={`neighborhood-list-key-${key}`}>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {neighborhood.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {neighborhood.city.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Grid container direction="row" alignItems="center" justify="flex-end" spacing={1}>
                                            <Grid item>
                                                <IconButton aria-label="edit" className={classes.iconButton} onClick={onClickEditNeighborhood(neighborhood)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <IconButton aria-label="delete" className={classes.iconButton} onClick={onClickDeleteNeighborhood(neighborhood)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={neighborhoods.total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage="Filas por página:"
                        labelDisplayedRows={() => {
                            return `Página: ${page + 1} de ${Math.ceil(neighborhoods.total / rowsPerPage)}`;
                        }}
                    />
                </TableContainer>
            </Paper>
        </div>
    );
}

export default ListaBarrios;