import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const SearchField = props => {
    const [value, setValue] = useState('');
    const classes = useStyles();

    const handleChange = (event) => {
      const updatedValue = event.target.value;
      setValue(updatedValue);
      if (updatedValue === '') {
        // This is done because in case the value is empty (i.e. '')
        // then we proceed to delete the filter applied through this component.
        const { onClickSearch } = props;
        onClickSearch(updatedValue);
      }
    }

    const submitSearchTerm = (event) => {
        event.preventDefault();
        const { onClickSearch } = props;
        onClickSearch(value);
    }

    return (
        <Paper component="form" className={classes.searchField} elevation={0} onSubmit={submitSearchTerm}>
            <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item xs={1}>
                    <IconButton className={classes.iconButton} aria-label="menu">
                        <SearchIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={8}>
                    <InputBase
                        className={classes.input}
                        placeholder="Escribe lo que necesitas buscar..."
                        inputProps={{ 'aria-label': 'search users' }}
                        onChange={handleChange}
                        value={value}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button type="submit" variant="contained" color="primary" className={classes.searchButton} disableElevation>
                        Buscar
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default SearchField;