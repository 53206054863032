export default theme => ({
    root: {
        width: '100%',
    },
    row: {
        width: '60%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    fullWidth: {
        width: '100%',
    },
    card: {
        height: '250px',
    },
    title: {
        marginTop: '15px',
    },
});