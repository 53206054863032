import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

import { NavLink } from "react-router-dom";

import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import Person from "@material-ui/icons/Person";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Poppers from "@material-ui/core/Popper";

import logo from '../../assets/logos/newLogoHor.png';

import clsx from 'clsx';

const useStyles = makeStyles(styles);

const Sidebar = (props) => {
    const classes = useStyles();
    const [openProfile, setOpenProfile] = React.useState(null);

    const { routes, dropdownActions } = props;

    const handleClickProfile = event => {
        if (openProfile && openProfile.contains(event.target)) {
            setOpenProfile(null);
        } else {
            setOpenProfile(event.currentTarget);
        }
    };
    const handleCloseProfile = () => {
        setOpenProfile(null);
    };

    const activeRoute = (routeName) => {
        return window.location.href.indexOf(routeName) > -1 ? true : false;
    }

    var links = (
        <List className={classes.list}>
            {routes.map((item, key) => {
                return (
                    <NavLink
                        to={item.path}
                        activeClassName="active"
                        key={key}
                        className={classes.item}
                    >
                        <ListItem button className={clsx(classes.itemLink, { [classes.activeItem]: activeRoute(item.path)})}>
                            <ListItemIcon>
                                {activeRoute(item.path) 
                                    ? <img src={item.activeIcon} className={classes.itemIcon} alt="sidebar-icon-active" />
                                    : <img src={item.icon} className={classes.itemIcon} alt="sidebar-icon" />
                                }
                            </ListItemIcon>
                            <ListItemText primary={item.title} className={clsx(classes.itemText, { [classes.activeElement]: activeRoute(item.path)})} />
                        </ListItem>
                    </NavLink>
                );
            })}
        </List>
    );

    var dropdownRoutes = (
        <div>
            <div className={classes.manager}>
                <Button
                    aria-owns={openProfile ? "profile-menu-list-grow" : null}
                    aria-haspopup="true"
                    onClick={handleClickProfile}
                    className={classes.buttonLink}
                >
                    <Person className={classes.icons} />
                    <p className={classes.linkText}>Perfil</p>
                </Button>
                <Poppers
                    open={Boolean(openProfile)}
                    anchorEl={openProfile}
                    transition
                    disablePortal
                    className={
                        clsx({ [classes.popperClose]: !openProfile }) +
                        " " +
                        classes.popperNav
                    }
                >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        id="profile-menu-list-grow"
                        style={{
                            transformOrigin:
                            placement === "bottom" ? "center top" : "center bottom"
                        }}
                    >
                    <Paper>
                        <ClickAwayListener onClickAway={handleCloseProfile}>
                        <MenuList role="menu">
                            {dropdownActions.map((dropdownAction, key) => (
                                <MenuItem
                                    onClick={dropdownAction.onClick}
                                    className={classes.dropdownItem}
                                    key={`profile-item-menu-key-${key}`}
                                >
                                    {dropdownAction.title}
                                </MenuItem>
                            ))}
                        </MenuList>
                        </ClickAwayListener>
                    </Paper>
                    </Grow>
                )}
                </Poppers>
            </div>
            <Divider variant="middle" className={classes.divider} />
        </div>
    );

    var brand = (
        <div className={classes.logo}>
            <div className={classes.logoImage}>
                <img src={logo} alt="logo" className={classes.img} />
            </div>
        </div>
    );

    return (
        <div>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    open={props.open}
                    anchor="right"
                    onClose={props.handleDrawerToggle}
                    classes={{
                        paper: clsx(classes.drawerPaper)
                    }}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>
                        {dropdownRoutes}
                        {links}
                    </div>
                    <div className={classes.background}></div>
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    variant="permanent"
                    open
                    anchor="left"
                    classes={{
                        paper: clsx(classes.drawerPaper)
                    }}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>{links}</div>
                    <div className={classes.background}></div>
                </Drawer>
            </Hidden>
        </div>
    );
};

export default Sidebar;