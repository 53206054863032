import httpClient from '../http';
import qs from 'qs';

export const getEmergencies = async (page, page_limit, filters) => {
    const filterParams = {
        page,
        page_limit,
        ...filters,
    };
    const queryParams = qs.stringify(filterParams);
    return await httpClient.get(`/emergencies?${queryParams}`);
}

export const getAllEmergencies = async () => {
    return await httpClient.get(`/emergencies/all`);
}

export const getEmergencyById = async (emergency_id) => {
    return await httpClient.get(`/emergencies/${emergency_id}`);
}

export const deleteEmergency = async (emergency_id) => {
    return await httpClient.delete(`/emergencies/${emergency_id}`);
}

export const createEmergency = async (emergency) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedEmergency = qs.stringify(emergency);
    return await httpClient.post('/emergencies', serializedEmergency, config);
}

export const updateEmergency = async (emergency_id, emergency) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedEmergency = qs.stringify(emergency);
    return await httpClient.put(`/emergencies/${emergency_id}`, serializedEmergency, config);
}