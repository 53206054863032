import httpClient from '../http';
import qs from 'qs';

export const createCertificateFormat = async (certificateFormat) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedCertificateFormat = qs.stringify(certificateFormat);
    return await httpClient.post('/cert_fields', serializedCertificateFormat, config);
}

export const getLatestCertificateFormat = async () => {
    return await httpClient.get('/cert_fields/latest');
}