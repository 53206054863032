import { useState, useEffect } from 'react';
import { getDamageReportById } from '../../services/MT/damageReportsService';

const useDamageReportById = (report_id) => {
    const [damageReport, setDamageReport] = useState(null);

    useEffect(() => {
        async function fetchDamageReport() {
            try {
                const { data } = await getDamageReportById(report_id);
                setDamageReport(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchDamageReport();
    }, [report_id]);

    return { damageReport };
}

export default useDamageReportById;