export default ({
    code: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
        length: {
            maximum: 4,
            tooLong: 'Éste campo sólo permite un máximo de %{count} caracteres.',
        },
    },
    level: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    },
    name: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    },
});