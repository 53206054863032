export default theme => ({
    fullWidth: {
        width: '100%',
    },
    formControl: {
        width: '100%',
    },
    saveExperienceButton: {
        marginTop: '20px',
        width: '170px',
    },
});