import { useState, useEffect } from 'react';
import { getAllDeviationTypes } from '../services/deviationTypesService';

const useAllDeviationTypes = () => {
    const [deviationTypes, setDeviationTypes] = useState([]);

    useEffect(() => {
        async function fetchAllDeviationTypes() {
            try {
                const { data } = await getAllDeviationTypes();
                setDeviationTypes(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchAllDeviationTypes();
    }, []);

    return { deviationTypes };
}

export default useAllDeviationTypes;