import httpClient from '../../http';
import qs from 'qs';

export const getInspections = async (page, page_limit, filters) => {
    const filterParams = {
        page,
        page_limit,
        ...filters
    };
    const queryParams = qs.stringify(filterParams);
    return await httpClient.get(`/gr/inspections?${queryParams}`);
}

export const getAllInspections = async (filters) => {
    const queryParams = qs.stringify(filters);
    return await httpClient.get(`/gr/inspections/all?${queryParams}`);
}

export const getInspectionById = async (inspection_id) => {
    return await httpClient.get(`/gr/inspections/${inspection_id}`);
}

export const createInspection = async (inspection) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedInspection = qs.stringify(inspection);
    return await httpClient.post('/gr/inspections', serializedInspection, config);
}

export const editInspection = async (inspection_id, inspection) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedInspection = qs.stringify(inspection);
    return await httpClient.put(`/gr/inspections/${inspection_id}`, serializedInspection, config);
}

export const deleteInspection = async (inspection_id) => {
    return await httpClient.delete(`/gr/inspections/${inspection_id}`);
}