import { useState, useEffect } from 'react';
import { getAreas } from '../services/areasService';

const useAreasByMinwageAndLevel = (minwage_id, level_id) => {
    const [areas, setAreas] = useState([]);

    useEffect(() => {
        async function fetchAreas() {
            try {
                // El 6 viene porque cada nivel tiene asignado únicamente 6 áreas.
                const { data } = await getAreas(1, 6, {
                    minwage_id,
                    level_id,
                });
                setAreas(data.results);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchAreas();
    }, [minwage_id, level_id]);

    return { areas };
}

export default useAreasByMinwageAndLevel;