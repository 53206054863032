import { createSlice } from '@reduxjs/toolkit';

export const citiesSlice = createSlice({
    name: 'cities',
    initialState: {
        isLoading: false,
        cities: [],
        total: null,
        pages: null,
        page: 1,
        page_limit: 10,
        error: {
            code: null,
            message: null,
        },
    },
    reducers: {
        fetchCitiesRequest: state => {
            state.isLoading = true;
        },
        fetchCitiesSuccess: (state, action) => {
            state.isLoading = false;
            state.cities = action.payload.cities;
            state.total = action.payload.total;
            state.pages = action.payload.pages;
            state.page = action.payload.page;
            state.page_limit = action.payload.page_limit;
            state.error = {
                code: null,
                message: null,
            };
        },
        fetchCitiesFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
    },
});

export const {
    fetchCitiesRequest,
    fetchCitiesSuccess,
    fetchCitiesFailure,
} = citiesSlice.actions;

export const selectCities = state => state.cities;

export default citiesSlice.reducer;