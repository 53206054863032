import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentUser, currentUserRequest } from '../../../../../store/slices/currentUserSlice';

import useBackendMessageResponse from '../../../../../hooks/useBackendMessageResponse';
import { updateCurrentUser } from '../../../../../services/currentUserService';
import { setGlobalAlert } from '../../../../../store/slices/alertsSlice';

import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { RiskAgentProfileForm } from './RiskAgentProfileForm';

import schema from './schema';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const RiskAgentProfile = (props) => {
    const classes = useStyles();
    const { user: currentUser } = useSelector(selectCurrentUser);
    const [readMode, setReadMode] = useState(true);
    const dispatch = useDispatch();
    const {
        backendResponse: updateCurrentUserResponse,
        executeService: updateCurrentUserService,
    } = useBackendMessageResponse(updateCurrentUser);
    const [profileData, setProfileData] = useState({
        image_url: '',
        firstname: '',
        lastname: '',
        document_type: '',
        document: '',
        phone: '',
        email: '',
        risk_user_type: '',
        department_id: '',
        city_id: '',
    });

    const handleSubmit = async (updatedProfileData) => {
        setProfileData(updatedProfileData);
        const totalProfileData = {
            id: currentUser.id,
            role: currentUser.role,
            ...updatedProfileData,
        };
        await updateCurrentUserService(totalProfileData);
        dispatch(currentUserRequest());
        setReadMode(true);
    }

    useEffect(() => {
        if (currentUser === null || currentUser === undefined) {
            return;
        }

        setProfileData({
            image_url: currentUser.image_url ?? '',
            firstname: currentUser.firstname,
            lastname: currentUser.lastname,
            document_type: currentUser.document_type,
            document: currentUser.document,
            phone: currentUser.phone,
            email: currentUser.email,
            risk_user_type: currentUser.risk_user_type,
            department_id: currentUser.department_id,
            city_id: currentUser.city_id,
        });
    }, [currentUser]);

    useEffect(() => {
        if (updateCurrentUserResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: updateCurrentUserResponse.message,
            severity: 'success',
        }));
    }, [dispatch, updateCurrentUserResponse.message, currentUser]);

    useEffect(() => {
        if (updateCurrentUserResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: updateCurrentUserResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, updateCurrentUserResponse.error, currentUser])

    return (
        <div className={classes.root}>
            <Formik
                enableReinitialize
                validate={async (values) => {
                    if (readMode) {
                        return {};
                    }

                    try {
                        await validateYupSchema(values, schema, false, { /* context */ });
                    } catch(err) {
                        return yupToFormErrors(err);
                    }

                    return {};
                }}
                initialValues={profileData}
                onSubmit={async (values) => {
                    if (readMode) {
                        setReadMode(false);
                        return;
                    }
                    await handleSubmit(values);
                }}
            >
                {(formikProps) => (
                    <RiskAgentProfileForm {...formikProps} blockedEmail blockedUserType blockedForm={readMode} />
                )}
            </Formik>
        </div>
    );
}

export default RiskAgentProfile;