import httpClient from '../http';
import qs from 'qs';

export const getVehicles = async (page, page_limit, filters) => {
    const filterParams = {
        page,
        page_limit,
        ...filters
    };
    const queryParams = qs.stringify(filterParams);
    return await httpClient.get(`/mt/vehicles?${queryParams}`);
}

export const deleteVehicle = async (vehicle_id) => {
    return await httpClient.delete(`/mt/vehicles/${vehicle_id}`);
}

export const createVehicle = async (vehicle) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedVehicle = qs.stringify(vehicle);
    return await httpClient.post(`/mt/vehicles`, serializedVehicle, config);
}

export const getVehicleById = async (vehicle_id) => {
    return await httpClient.get(`/mt/vehicles/${vehicle_id}`);
}

export const updateVehicle = async (vehicle_id, vehicle) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedVehicle = qs.stringify(vehicle);
    return await httpClient.put(`/mt/vehicles/${vehicle_id}`, serializedVehicle, config);
}