import httpClient from '../http';
import qs from 'qs';

export const getallDepartments = async () => {
    return await httpClient.get('/departments/all');
}

export const getDepartments = async (page, page_limit, filters) => {
    const filterParams = {
        page,
        page_limit,
        ...filters,
    };
    const queryParams = qs.stringify(filterParams);
    return await httpClient.get(`/departments?${queryParams}`);
}

export const getDepartmentByID = async (department_id) => {
    return await httpClient.get(`/departments/${department_id}`);
}

export const createDepartment = async (department) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedDepartment = qs.stringify(department);
    return await httpClient.post('/departments', serializedDepartment, config);
}

export const updateDepartment = async (department_id, department) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedDepartment = qs.stringify(department);
    return await httpClient.put(`/departments/${department_id}`, serializedDepartment, config);
}

export const deleteDepartment = async (department_id) => {
    return await httpClient.delete(`/departments/${department_id}`);
}