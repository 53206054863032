import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';
import { getEventsRequest, selectEvents } from '../../../../store/slices/eventsSlice';
import { createEvent, deleteEvent } from '../../../../services/eventsService';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Form, Field, ErrorMessage, Formik } from 'formik';

import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import LocaleEs from '@fullcalendar/core/locales/es';

import { SearchField } from '../../../../components/SearchField';

import { EventsUrls } from '../../routing';

import format from 'date-fns/format';
import { enUS } from 'date-fns/esm/locale'

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import './fullcalendar.css';

import { AllowOnlyIf } from '../../../../components/AllowOnlyIf';
import { PERMISSION_KEYS } from '../../../../constants/permissions';

const useStyles = makeStyles(styles);

const EventsList = (props) => {
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = useState('');
    const dispatch = useDispatch();
    const events = useSelector(selectEvents);
    const [eventClickedData, setEventClickedData] = useState({
        eventApi: null,
        openDialog: false,
        openDialogAddEvent: false,
        deleteConfirmation: false,
    });
    const [eventDialogContent, setEventDialogContent] = useState({
        title: '',
        date: new Date(),
        date_end: new Date(),
        description: '',
    });

    const [eventDialogNewEvent, setEventDialogNewEvent] = useState({
        name: '',
        date: null,
        date_end: null,
        motif: '',
    });

    const {
        backendResponse: deleteEventResponse,
        executeService: deleteEventService,
    } = useBackendMessageResponse(deleteEvent);

    const {
        backendResponse: createEventResponse,
        executeService: createEventService,
    } = useBackendMessageResponse(createEvent);

    const handleSubmit = async () => {
        if(eventDialogNewEvent.date >= eventDialogNewEvent.date_end) {
            dispatch(setGlobalAlert({
                show: true,
                duration: 5000,
                message: "El rango de fechas no es válido",
                severity: 'error',
            }));
            return;
        }

        await createEventService(eventDialogNewEvent);
    }

    const onGetSearchTerm = (term) => {
        setSearchTerm(term);
    }

    const onAddNewEvent = () => {
        const { history } = props;
        history.push(EventsUrls.AddEvent);
    }

    useEffect(() => {
        if (createEventResponse.message === null) {
            return;
        }

        if (eventClickedData.openDialogAddEvent) {
            dispatch(setGlobalAlert({
                show: true,
                duration: 5000,
                message: createEventResponse.message,
                severity: 'success',
            }));

            setEventClickedData((clickedData) => ({
                ...clickedData,
                openDialogAddEvent: false,
            }));
            createEventResponse.message = null;
        }

        props.history.push(EventsUrls.Listado);
    }, [dispatch, createEventResponse.message, eventClickedData.openDialogAddEvent, props.history]);

    // Ésta es la función que se usaba inicialmente para paginar los eventos de acuerdo al rango de fecha del calendario.
    // El problema con ella es que se ejecuta tras cada render. Por lo que al abrir el dialog y al cerrar, vuelve a hacer las llamadas
    // a la api. De igual forma al actualizarse el estado de Redux, vuelve a ejecutarse en caso de hacer un dispatch. 
    // Ocasionando un cíclo de renders infinito.
    // Quizás existen formas de combinarla con Redux, pero la solución que está aplicada ahora
    // es la de traerse todos los eventos y vaciarlos sobre el calendario.
    /*
    const handleDatechange = async (info, successCallback, failureCallback) => {
        //dispatch(getEventsRequest({
        //  fromDate: info.startStr,
        //  toDate: info.endStr,
        //   filters: {},
        // }));
        const filters = {};
        const { data } = await getAllEvents(info.startStr, info.endStr, filters);
        const eventsMapped = data.map((event) => ({
            id: event.id,
            title: event.name,
            start: new Date(event.date),
            allDay: true,
            description: event.motif,
            extendedProps: {
                motif: event.motif,
            },
        }));
        successCallback(eventsMapped);
    }
    */

    const handleEventClick = (info) => {
        setEventClickedData({
            eventApi: info.event,
            openDialog: true,
            openDialogAddEvent: false,
            deleteConfirmation: false,
        });
        setEventDialogContent({
            title: info.event.title,
            date: info.event.start,
            date_end: info.event.end,
            description: info.event.extendedProps.description,
        });
    }

    const renderEventContent = (info) => {
        return (
            <>
                <Typography color="textSecondary" variant="body2" style={{color: 'white', padding: '1px', paddingLeft: '10px'}}>
                    {info.event.title}
                </Typography>
            </>
        );
    }

    const handleDialogClose = () => {
        if (eventClickedData.deleteConfirmation) {
            setEventClickedData((clickedData) => ({
                ...clickedData,
                deleteConfirmation: false,
            }));
            return;
        }

        if (eventClickedData.openDialogAddEvent) {
            setEventClickedData((clickedData) => ({
                ...clickedData,
                openDialogAddEvent: false,
            }));
            return;
        }

        setEventClickedData({
            eventApi: null,
            openDialog: false,
            deleteConfirmation: false,
        });
    }

    const onDeleteEvent = () => {
        setEventClickedData((clickedData) => ({
            ...clickedData,
            deleteConfirmation: true,
        }));
    }

    const handleEventDelete = async () => {
        try {
            const eventId = eventClickedData.eventApi._def.publicId;
            await deleteEventService(eventId);
            dispatch(getEventsRequest({
                filters: {
                    searchTerm: searchTerm !== '' ? searchTerm : undefined,
                },
            }));
            setEventClickedData({
                eventApi: null,
                openDialog: false,
                deleteConfirmation: false,
            });
        } catch (e) {
            const { response } = e;
            console.log(response);
        }
    }

    const onDateClick = (info) => {
        setEventClickedData({
            eventApi: info.event,
            openDialogAddEvent: true,
        });

        setEventDialogNewEvent(newEvent =>({
            ...newEvent,
            date: new Date(info.date),
        }))
    }

    const onEditEvent = () => {
        const { history } = props;
        const eventId = eventClickedData.eventApi._def.publicId;
        history.push(EventsUrls.EditEvent.replace(':event_id', eventId));
    }

    const parseEvents = () => {
        const eventsMapped = events.events.map((event) => ({
            id: event.id,
            title: event.name,
            start: new Date(event.date),
            end: new Date(event.date_end),
            description: event.motif,
            display: '#96C2C9',
            extendedProps: {
                motif: event.motif,
            },
        }));
        return eventsMapped;
    }

    useEffect(() => {
        if (deleteEventResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: deleteEventResponse.message,
            severity: 'success',
        }));
    }, [dispatch, deleteEventResponse.message]);

    useEffect(() => {
        if (deleteEventResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: deleteEventResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, deleteEventResponse.error])

    useEffect(() => {
        dispatch(getEventsRequest({
            filters: {
                searchTerm: searchTerm !== '' ? searchTerm : undefined,
            },
        }));

        setEventDialogNewEvent(newEvent =>({
            ...newEvent,
            name: '', 
            motif: '',
            date: null,
            date_end: null,
        }))
    }, [dispatch, searchTerm, eventClickedData]);

    const handleChange = (e) => {
        setEventDialogNewEvent((newEvent) =>({
            ...newEvent,
            [e.target.name]: e.target.value
         }));
      }

    return (
        <div className={classes.root}>
            <Dialog
                open={eventClickedData.openDialog}
                onClose={handleDialogClose}
                aria-labelledby="enable-disable-dialog-title"
                aria-describedby="enable-disable-dialog-description"
            >
                <DialogContent>
                    <Grid container direction="column" justify="center" alignItems="fexls-start" spacing={1}>
                        <Grid item style={{width: '100%'}}>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Grid item>
                                    <Typography gutterBottom variant="h6">
                                        {eventDialogContent.title}
                                    </Typography>
                                    <Typography color="textSecondary" variant="body2">
                                        {`Fecha Inicio: ${format(eventDialogContent.date, 'dd/MM/yyyy H:mma', {locale: enUS})}`}
                                    </Typography>
                                    <Typography color="textSecondary" variant="body2">
                                        {`Fecha Fin: ${eventDialogContent.date_end 
                                            ? format(eventDialogContent.date_end, 'dd/MM/yyyy H:mma', {locale: enUS})
                                            : '' }
                                        `}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" alignItems="center" justify="flex-end">
                                        <Grid item>
                                            <IconButton aria-label="edit" className={classes.iconButton} onClick={onEditEvent}>
                                                <EditIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <IconButton aria-label="delete" className={classes.iconButton} onClick={onDeleteEvent}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item style={{width: '100%'}}>
                            <Divider />
                        </Grid>
                        <Grid item style={{marginBottom: '20px', minWidth: '400px'}}>
                            <Typography color="textSecondary">
                                {eventDialogContent.description}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog
                open={eventClickedData.openDialogAddEvent}
                onClose={handleDialogClose}
                aria-labelledby="enable-disable-dialog-title"
                aria-describedby="enable-disable-dialog-description"
            >
                <DialogContent>
                    <Grid container direction="column" justify="center" alignItems="fexls-start" spacing={1}>
                        <Grid item style={{width: '100%'}}>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Grid item>
                                    <Typography gutterBottom variant="h6">
                                        Añadir evento
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" alignItems="center" justify="flex-end">
                                        <Grid item>
                                            <IconButton aria-label="close" className={classes.iconButton} onClick={handleDialogClose}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item style={{width: '100%'}}>
                                    <Divider />
                                </Grid>
                                <Grid item style={{marginTop: '20px', minWidth: '400px'}}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Formik
                                            initialValues={eventDialogNewEvent}
                                            onSubmit= {handleSubmit}
                                        >
                                            <Form>
                                                <Grid container direction="column" justify="center" alignItems="flex-start" spacing={2}>
                                                    <Grid item className={classes.row}>
                                                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                                            <Grid item xs={12} md={12}>
                                                                <Field 
                                                                    name="name" 
                                                                    type="text" 
                                                                    as={TextField} 
                                                                    variant="outlined" 
                                                                    label="Evento"
                                                                    value={eventDialogNewEvent.name}
                                                                    onChange = {handleChange}
                                                                    className={classes.formControl}
                                                                    helperText={<ErrorMessage name="name" />}
                                                                    required
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <Field 
                                                                    name="date" 
                                                                    as={KeyboardDateTimePicker} 
                                                                    label="Fecha Inicio"
                                                                    className={classes.formControl} 
                                                                    inputVariant="outlined"
                                                                    variant="inline"
                                                                    InputAdornmentProps={{ position: "end" }}
                                                                    format="dd/MM/yyyy, hh:mm a"
                                                                    onChange={(date) => {
                                                                        setEventDialogNewEvent(newEvent => ({
                                                                            ...newEvent,
                                                                            date: date
                                                                        }))}
                                                                    }
                                                                    helperText={<ErrorMessage name="date" />}
                                                                    value={eventDialogNewEvent.date}
                                                                    required
                                                                    autoOk
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <Field 
                                                                    name="date_end"
                                                                    as={KeyboardDateTimePicker} 
                                                                    label="Fecha Fin"
                                                                    className={classes.formControl}
                                                                    inputVariant="outlined"
                                                                    variant="inline"
                                                                    InputAdornmentProps={{ position: "end" }}
                                                                    format="dd/MM/yyyy, hh:mm a"
                                                                    minDate={eventDialogNewEvent.date}
                                                                    onChange={(date_end) => {
                                                                        setEventDialogNewEvent(newEvent => ({
                                                                            ...newEvent,
                                                                            date_end: date_end
                                                                        }))}
                                                                    }
                                                                    required
                                                                    value={eventDialogNewEvent.date_end}
                                                                    helperText={<ErrorMessage name="date_end" />}
                                                                    autoOk
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item className={classes.row} xs={12} md={12} style={{width: '100%'}}>
                                                        <Field 
                                                            name="motif" 
                                                            type="text" 
                                                            as={TextField} 
                                                            variant="outlined" 
                                                            label="Motivo"
                                                            className={classes.formControl}
                                                            multiline={true}
                                                            value={eventDialogNewEvent.motif}
                                                            onChange = {handleChange}
                                                            style={{width: '100%'}}
                                                            rows={5}
                                                            required
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Button
                                                    className={classes.saveButton}
                                                    color="primary"
                                                    size="large"
                                                    variant="contained"
                                                    type="submit"
                                                    disableElevation
                                                    style={{marginTop: '20px'}}
                                                >
                                                    Guardar
                                                </Button>
                                            </Form>
                                        </Formik>
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog
                open={eventClickedData.deleteConfirmation}
                onClose={handleDialogClose}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">{"Confirmación"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        ¿Estás seguro de querer continuar con la eliminación del evento?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleEventDelete} color="primary" autoFocus>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.fullWidth}>
                <Grid item xs={6} className={classes.searchInput}>
                    <SearchField onClickSearch={onGetSearchTerm} />
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" justify="space-between" className={classes.fullWidth}>
                <Grid item>
                    <Typography component={"span"}>
                        <Box fontWeight="fontWeightBold" fontSize={25}>Eventos</Box>
                    </Typography>
                </Grid>
                <AllowOnlyIf permission={PERMISSION_KEYS.EVENTS_WRITE}>
                    <Grid item>
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            className={classes.addEventButton} 
                            disableElevation
                            onClick={onAddNewEvent}
                        >
                            Añadir evento
                        </Button>
                    </Grid>
                </AllowOnlyIf>
            </Grid>
            <Paper elevation={0} className={classes.paper}>
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    initialView='dayGridMonth'
                    headerToolbar={{
                        left: 'today prevYear,prev,next,nextYear',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                     }}
                     eventBackgroundColor= '#96C2C9'
                     selectable={true}
                     dateClick={onDateClick}
                     locale={LocaleEs}
                     weekends={true}
                     events={parseEvents()}
                     eventClick={handleEventClick}
                     editable={true}
                     eventContent={renderEventContent}
                     height={620}
                />
            </Paper>
        </div>
    );
}

export default EventsList;