import routesUrls from '../../routes/urls';

import { 
    TabsGestionRiesgo,
    AddInspection,
    EditInspection,
    DetailsInspection,
} from './Pages';

export const GestionRiesgoUrls = {
    Tablero: `${routesUrls.GestionRiesgo}/tablero`,
    AddInspection: `${routesUrls.GestionRiesgo}/agregar-inspeccion`,
    EditInspection: `${routesUrls.GestionRiesgo}/editar-inspeccion/:inspection_id`,
    DetailsInspection: `${routesUrls.GestionRiesgo}/detalles-inspeccion/:inspection_id`,
};

const GestionRiesgoRouting = [{
    path: GestionRiesgoUrls.Tablero,
    component: TabsGestionRiesgo,
}, {
    path: GestionRiesgoUrls.AddInspection,
    component: AddInspection,
}, {
    path: GestionRiesgoUrls.EditInspection,
    component: EditInspection,
}, {
    path: GestionRiesgoUrls.DetailsInspection,
    component: DetailsInspection,
}];

export default GestionRiesgoRouting;