export default (theme) => ({
    root: {
        height: '100%',
    },
    grid: {
        height: '100%'
    },
    title: {
        color: '#FFFFFF',
        marginBottom: theme.spacing(1),
    },
    gridForm: {
        width: '260px',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    button: {
        marginTop: theme.spacing(4),
        borderRadius: 50,
        width: '190px',
        height: '50px',
        color: '#FFFFFF' // Text Color
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    authErrorText: {
        marginTop: theme.spacing(2),
    },
    authNotificationText: {
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: 10,
        color: '#fff',
    },
});