import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const VariableDocumentationDialog = (props) => {
    const classes = useStyles();
    const { onClose, open, variables } = props;

    return (
        <Dialog open={open} onClose={onClose} className={classes.root} classes={{ paper: classes.dialogPaper }} scroll="paper" maxWidth="lg">
            <DialogTitle>{`Variables`}</DialogTitle>
            <DialogContent>
                <Paper elevation={0}>
                    <TableContainer>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Nombre</TableCell>
                                    <TableCell align="left">Descripción</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.entries(variables).map(([key, value], index) => (
                                    <TableRow key={`variable-index-${index}`}>
                                        <TableCell align="left">
                                            <Typography component="span">
                                                <Box fontWeight="fontWeightBold" fontSize={15}>
                                                    {`{{ ${key} }}`}
                                                </Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography component="span">
                                                <Box fontWeight="fontWeightLight">
                                                    {value}
                                                </Box>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </DialogContent>
        </Dialog>
    );
}

VariableDocumentationDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    variables: PropTypes.object.isRequired,
};

export default VariableDocumentationDialog;