import * as Yup from 'yup';

import { getEquipments } from '../../../../../../services/equipmentsService';
// import debounce from 'lodash/debounce';

export default Yup.object({
    code: Yup.string()
        .max(15, 'Éste campo sólo permite 15 caracteres.')
        .required('Éste campo es requerido.')
        .test(
            'equipment-code-in-use',
            'El código ya se encuentra registrado.', 
            async function (code_num, yup) {
                // A ésta función no se le pone el debounce porque por alguna razón
                // si se hace click sobre el campo, y luego le quitamos el focus, despliega el error.
                // Pero eso sólo sucede la primera vez que se hace, si se le vuelve a poner el focus y a quitar ya no sucede.
                // Por qué sucede esto? ¯\_(ツ)_/¯
                const currentEquipmentId = this.options.context.id === undefined ? null : this.options.context.id;
                const { data: { results }} = await getEquipments(1, 1, { code: code_num, exact: true });
                return results.length !== 0 && currentEquipmentId !== results[0].id ? false : true;
            }),
    name: Yup.string()
        .max(100, 'Sólo se permite máximo 100 caracteres.')
        .required('Éste campo es requerido.'),
    model: Yup.string()
        .max(60, 'Sólo se permite máximo 60 caracteres.')
        .required('Éste campo es requerido.'),
    class: Yup.string()
        .max(60, 'Sólo se permite máximo 60 caracteres.')
        .required('Éste campo es requerido.'),
    entry_date: Yup.date()
        .required('Éste campo es requerido.'),
    engine_power: Yup.string()
        .max(60, 'Sólo se permite máximo 60 caracteres.')
        .required('Éste campo es requerido.'),
    provider: Yup.string()
        .max(100, 'Sólo se permite máximo 100 caracteres.')
        .required('Éste campo es requerido.'),
    type: Yup.string()
        .required('Éste campo es requerido.'),
    entry_state: Yup.string()
        .required('Éste campo es requerido.'),
    engine: Yup.string()
        .max(60, 'Sólo se permite máximo 60 caracteres.')
        .required('Éste campo es requerido.'),
    price: Yup.string()
        .max(16, 'Sólo se permite máximo 60 dígitos.')
        .required('Éste campo es requerido.'),
    fuel: Yup.string()
        .max(60, 'Sólo se permite máximo 60 caracteres.')
        .required('Éste campo es requerido.'),
    owner_name: Yup.string()
        .max(100, 'Sólo se permite máximo 100 caracteres.')
        .required('Éste campo es requerido.'),
    version: Yup.string()
        .max(20, 'Sólo se permite máximo 20 caracteres.')
        .required('Éste campo es requerido.'),
    brand: Yup.string()
        .max(60, 'Sólo se permite máximo 60 caracteres.')
        .required('Éste campo es requerido.'),
    observations: Yup.string()
        .max(500, 'Sólo se permite máximo 500 caracteres.')
        .required('Éste campo es requerido.'),
});