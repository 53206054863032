import httpClient from '../http';
import qs from 'qs';

export const createEvent = async (event) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedEvent = qs.stringify(event);
    return await httpClient.post('/events', serializedEvent, config);
}

export const updateEvent = async (event_id, event) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedEvent = qs.stringify(event);
    return await httpClient.put(`/events/${event_id}`, serializedEvent, config);
}

export const deleteEvent = async (event_id) => {
    return await httpClient.delete(`/events/${event_id}`);
}

export const getEventById = async (event_id) => {
    return await httpClient.get(`/events/${event_id}`);
}

export const getAllEvents = async (filters) => {
    const filterParams = {
        ...filters
    };
    const queryParams = qs.stringify(filterParams);
    return await httpClient.get(`/events/all?${queryParams}`);
}