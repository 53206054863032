export default {
  document: {
    presence: { allowEmpty: false, message: "Éste campo es requerido." },
    length: {
      maximum: 15,
      tooLong: 'El numero de documento debe tener maximo %{count} caracteres',
      minimum: 4,
      tooShort: 'El numero de documento debe tener mínimo %{count} caracteres',
    },
  },

  cert_number: {
    presence: { allowEmpty: false, message: "Éste campo es requerido." },
    length: {
      minimum: 4,
      tooShort: 'El numero del certificado debe tener mínimo %{count} caracteres',
    },
  },
};
