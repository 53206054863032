import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isLoading: false,
        token: null,
        message: null,
        error: {
            code: null,
            message: null,
        },
    },
    reducers: {
        // Login
        authRequest: state => { 
            state.isLoading = true;
            state.error.code = null;
            state.error.message = null;
            state.token = null;
            state.message = null;
        },
        authSuccess: (state, action) => {
            state.isLoading = false;
            state.token = action.payload.token;
            state.message = action.payload.message;
            state.error.code = null;
            state.error.message = null;
        },
        authFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload.error;
            state.message = null;
            state.token = null;
        },
        authResetState: (state) => {
            state.isLoading = false;
            state.message = null;
            state.token = null;
            state.error = {
                code: null,
                message: null
            };
        },
        // Password recovery
        authPasswordRecoveryRequest: state => {
            state.isLoading = true;
            state.token = null;
            state.message = null;
            state.error = {
                code: null,
                message: null,
            };
        },
        authPasswordRecoverySuccess: (state, action) => {
            state.isLoading = false;
            state.message = action.payload.message;
            state.token = null;
            state.error = {
                code: null,
                message: null,
            };
        },
        authPasswordRecoveryFailure: (state, action) => {
            state.isLoading = false;
            state.message = null;
            state.token = null;
            state.error = action.payload.error;
        },
        // Check recovery token
        checkRecoveryTokenRequest: (state) => {
            state.isLoading = true;
            state.token = null;
            state.message = null;
            state.error = {
                code: null,
                message: null,
            };
        },
        checkRecoveryTokenSuccess: (state, action) => {
            state.isLoading = false;
            state.token = null;
            state.message = action.payload.message;
            state.error = {
                code: null,
                message: null,
            };
        },
        checkRecoveryTokenFailure: (state, action) => {
            state.isLoading = false;
            state.token = null;
            state.message = null;
            state.error = action.payload.error;
        },
        // Change password 
        changePasswordRequest: (state) => {
            state.isLoading = true;
            state.token = null;
            state.message = null;
            state.error = {
                code: null,
                message: null,
            };
        },
        changePasswordSuccess: (state, action) => {
            state.isLoading = false;
            state.token = null;
            state.message = action.payload.message;
            state.error = {
                code: null,
                message: null,
            };
        },
        changePasswordFailure: (state, action) => {
            state.isLoading = false;
            state.token = null;
            state.message = null;
            state.error = action.payload.error;
        },
        // Log out
        signOutRequest: (state) => {
            state.isLoading = true;
            state.token = null;
            state.message = null;
            state.error = {
                code: null,
                message: null,
            };
        },
        signOutSuccess: (state, action) => {
            state.isLoading = false;
            state.token = null;
            state.message = null;
            state.error = {
                code: null,
                message: null,
            };
        },
        signOutFailure: (state, action) => {
            state.isLoading = false;
            state.token = null;
            state.message = null;
            state.error = action.payload.error;
        },
        // Check ReliefCorps
        checkReliefCorpRequest: (state) => {
            state.isLoading = true;
            state.message = null;
            state.error = {
                code: null,
                message: null,
            };
        },
        checkReliefCorpSuccess: (state, action) => {
            state.isLoading = false;
            state.message = action.payload.message;
            state.error = {
                code: null,
                message: null,
            };
        },
        checkReliefCorpFailure: (state, action) => {
            state.isLoading = false;
            state.message = null;
            state.error = action.payload.error;
        },
    }
});

export const { 
    authRequest, 
    authSuccess, 
    authFailure, 
    authResetState,

    authPasswordRecoveryRequest,
    authPasswordRecoverySuccess,
    authPasswordRecoveryFailure,

    checkRecoveryTokenRequest,
    checkRecoveryTokenSuccess,
    checkRecoveryTokenFailure,

    changePasswordRequest,
    changePasswordSuccess,
    changePasswordFailure,

    signOutRequest,
    signOutSuccess,
    signOutFailure,

    checkReliefCorpRequest,
    checkReliefCorpSuccess,
    checkReliefCorpFailure,
} = authSlice.actions;

export const selectAuth = state => state.auth;

export default authSlice.reducer;