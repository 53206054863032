import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectMaintenances, getMaintenancesRequest } from '../../../../../../store/slices/maintenancesSlice';
import { setGlobalAlert } from '../../../../../../store/slices/alertsSlice';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Can, Only } from '../../../../../../components/Can';
import { ROLES_KEYS } from '../../../../../../constants/roles';

import { FilterButton } from '../../../../../../components/FilterButton';
import { Formik } from 'formik';
import { MaintenancesFilterForm } from './MaintenancesFilterForm';

import useBackendMessageResponse from '../../../../../../hooks/useBackendMessageResponse';
import { deleteMaintenance } from '../../../../../../services/MT/maintenancesService';

import format from 'date-fns/format';

import { SearchField } from '../../../../../../components/SearchField';

import { MotorUrls } from '../../../../routing';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';
import { AllowOnlyIf } from '../../../../../../components/AllowOnlyIf';
import { PERMISSION_KEYS } from '../../../../../../constants/permissions';

const useStyles = makeStyles(styles);

const ListaMantenimiento = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const maintenances = useSelector(selectMaintenances);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const {
        backendResponse: deleteMaintenanceResponse,
        executeService: deleteMaintenanceService,
    } = useBackendMessageResponse(deleteMaintenance);
    const [maintenanceClickedData, setMaintenanceClickedData] = useState({
        openDialog: false,
        maintenance: null,
    });
    const [maintenanceFilterForm, setMaintenanceFilterForm] = useState({
        report: '',
        date: '',
        workshop: '',
        in_charge: '',
        phone: '',
        sheet: '',

        // Éste campo aparecerá únicamente para aquellos usuarios
        // que no sean ni estación ni usuarios de una estación.
        station_name: '',
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onGetSearchTerm = (term) => {
        setSearchTerm(term);
        setPage(0);
    }

    const onClickAddMaintenance = () => {
        const { history } = props;
        history.push(MotorUrls.AddMaintenance);
    }

    const onClickEditMaintenance = (maintenance) => () => {
        const { history } = props;
        history.push(MotorUrls.EditMaintenance.replace(':maintenance_id', maintenance.id));
    }

    const onClickDeleteMaintenance = (maintenance) => () => {
        setMaintenanceClickedData({
            openDialog: true,
            maintenance,
        });
    }

    const handleCloseDeleteDialog = () => {
        setMaintenanceClickedData({
            openDialog: false,
            maintenance: null,
        });
    }

    const cleanFilter = useCallback(() => {
        const cleanFilteredData = maintenanceFilterForm;
        Object.keys(cleanFilteredData).forEach((key) => {
            if (cleanFilteredData[key] === '') {
                delete cleanFilteredData[key];
            }
        });
        return cleanFilteredData;
    }, [maintenanceFilterForm]);


    const handleMaintenanceDelete = async () => {
        try {
            const maintenanceId = maintenanceClickedData.maintenance.id;
            await deleteMaintenanceService(maintenanceId);
            setMaintenanceClickedData({
                openDialog: false,
                maintenance: null,
            });
            dispatch(getMaintenancesRequest({
                page: page + 1,
                page_limit: rowsPerPage,
                filters: {
                    searchTerm: searchTerm !== '' ? searchTerm : undefined,
                    ...cleanFilter(),
                },
            }));
        } catch (e) {
            const { response } = e;
            console.log(response);
        }
    }

    useEffect(() => {
        dispatch(getMaintenancesRequest({
            page: page + 1,
            page_limit: rowsPerPage,
            filters: {
                searchTerm: searchTerm !== '' ? searchTerm : undefined,
                ...cleanFilter(),
            },
        }));
    }, [dispatch, page, rowsPerPage, searchTerm, cleanFilter]);

    useEffect(() => {
        if (deleteMaintenanceResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: deleteMaintenanceResponse.message,
            severity: 'success',
        }));
    }, [dispatch, deleteMaintenanceResponse.message]);

    useEffect(() => {
        if (deleteMaintenanceResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: deleteMaintenanceResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, deleteMaintenanceResponse.error])

    return (
        <div className={classes.root}>
            <Dialog
                open={maintenanceClickedData.openDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-maintenance-delete-title"
                aria-describedby="alert-dialog-maintenance-delete-description"
            >
                <DialogTitle id="alert-dialog-maintenance-delete-title">{"Confirmación"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-maintenance-delete-description">
                        ¿Estás seguro de querer continuar con la eliminación del registro seleccionado?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleMaintenanceDelete} color="primary" autoFocus>
                        Continuar
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.fullWidth} spacing={2}>
                <Grid item md={6} xs={12} className={classes.searchInput}>
                    <SearchField onClickSearch={onGetSearchTerm} />
                </Grid>
                <Grid item>
                    <FilterButton>
                        {filterActions => (
                            <div className={classes.filterForm}>
                                <Formik 
                                    enableReinitialize
                                    component={MaintenancesFilterForm}
                                    initialValues={maintenanceFilterForm}
                                    onSubmit={(values) => { 
                                        const submitter = window.event.submitter.name;
                                        setPage(0);
                                        if (submitter === "clean") {
                                            // Acá se tiene que vaciar el estado de esta forma en lugar de:
                                            // action.resetForm(); ya que FilterButton usa un Popper internamente y se está usando
                                            // filterFormData como valores iniciales. Por lo que al montar y desmontar
                                            // a través del Popper se van a tomar los valores del estado.
                                            setMaintenanceFilterForm({
                                                report: '',
                                                date: '',
                                                workshop: '',
                                                in_charge: '',
                                                phone: '',
                                                sheet: '',
                                            });
                                            filterActions.hideFilterForm();
                                            return;
                                        }
                                        setMaintenanceFilterForm(values);
                                        filterActions.hideFilterForm();
                                    }}
                                />
                            </div>
                        )}
                    </FilterButton>
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" justify="space-between" className={classes.fullWidth}>
                <Grid item>
                    <Typography component={"span"}>
                        <Box fontWeight="fontWeightBold" fontSize={25}>Mantenimientos</Box>
                    </Typography>
                </Grid>
                <Grid item>
                    <AllowOnlyIf permission={PERMISSION_KEYS.MT_WRITE}>
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            className={classes.addMaintenanceButton} 
                            disableElevation
                            onClick={onClickAddMaintenance}
                        >
                            Añadir mantenimiento 
                        </Button>
                    </AllowOnlyIf>
                </Grid>
            </Grid>
            <Paper elevation={0}>
                <TableContainer>
                    <Table className={classes.table} aria-label="activities-table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Reporte</TableCell>
                                <TableCell align="left">Fecha</TableCell>
                                <TableCell align="left">Taller</TableCell>
                                <TableCell align="left">Responsable</TableCell>
                                <TableCell align="left">Teléfono</TableCell>
                                <TableCell align="left">Folio</TableCell>
                                <Can>
                                    <Only roles={[ROLES_KEYS.admin, ROLES_KEYS.risk_agent]}>
                                        <TableCell align="left">Estación</TableCell>
                                    </Only>
                                </Can>
                                <AllowOnlyIf permission={PERMISSION_KEYS.MT_WRITE}>
                                    <TableCell align="right">Acciones</TableCell>
                                </AllowOnlyIf>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {maintenances.maintenances.map((maintenance, key) => (
                                <TableRow key={`list-maintenance-item-key-${key}`}>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {maintenance.report}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {format(new Date(maintenance.date), "dd/MM/yyyy")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {maintenance.workshop}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {maintenance.in_charge.firstname + " " + maintenance.in_charge.lastname}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {maintenance.phone}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {maintenance.sheet}
                                        </Typography>
                                    </TableCell>
                                    <Can>
                                        <Only roles={[ROLES_KEYS.admin, ROLES_KEYS.risk_agent]}>
                                            <TableCell align="left">
                                                <Typography component={"span"} className={classes.tableCellText}>
                                                    {`${maintenance.station_name}`}
                                                </Typography>
                                            </TableCell>
                                        </Only>
                                    </Can>
                                    <AllowOnlyIf permission={PERMISSION_KEYS.MT_WRITE}>
                                        <TableCell align="right">
                                            <Grid container direction="row" alignItems="center" justify="flex-end" spacing={1}>
                                                <Grid item>
                                                    <IconButton aria-label="edit" className={classes.iconButton} onClick={onClickEditMaintenance(maintenance)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton aria-label="delete" className={classes.iconButton} onClick={onClickDeleteMaintenance(maintenance)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </AllowOnlyIf>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={maintenances.total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage="Filas por página:"
                        labelDisplayedRows={() => {
                            return `Página: ${page + 1} de ${Math.ceil(maintenances.total / rowsPerPage)}`;
                        }}
                    />
                </TableContainer>
            </Paper>
        </div>
    );
};

export default ListaMantenimiento;