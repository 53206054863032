import { call, put, takeLatest } from 'redux-saga/effects';

import { 
    getCurrentUser,
} from '../services/currentUserService';

import { 
    currentUserRequest, 
    currentUserSuccess, 
    currentUserFailure,
} from '../store/slices/currentUserSlice';

function* getCurrentUserEffect(action) {
    try {
        const { data } = yield call(getCurrentUser);
        yield put(currentUserSuccess({
            user: data,
        }));
    } catch(e) {
        const { response } = e;
        const error = {
            code: response.status,
            message: response.data.message,
        };
        yield put(currentUserFailure({ error }));
    }
}

function* currentUserEffects() {
    yield takeLatest(currentUserRequest.type, getCurrentUserEffect);
}

export default currentUserEffects;