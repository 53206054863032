import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';

import { authPasswordRecoveryRequest, authResetState } from '../../store/slices/authSlice';

import validate from 'validate.js';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

import schema from './schema';

const useStyles = makeStyles(styles);

const PasswordRecovery = (props) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const authState = useSelector(selectAuth);

    const { history } = props;

    const [formState, setFormState] = useState({
        isValid: false,
        isSubmitted: false,
        values: {
            email: '',
        },
        touched: {
            email: false,
        },
        errors: {
            email: [],
        },
    });

    useEffect(() => {
        dispatch(authResetState());
    }, [dispatch]);

    useEffect(() => {
        const errors = validate(formState.values, schema);

        setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [formState.values]);

    const hasError = field =>
        (formState.isSubmitted || formState.touched[field]) && formState.errors[field] ? true : false;

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!formState.isSubmitted) {
            setFormState(formState => ({
                ...formState,
                isSubmitted: true,
            }));
        }
        if (!formState.isValid) {
            return;
        }
        dispatch(authPasswordRecoveryRequest({ email: formState.values.email.toLocaleLowerCase() }));
    };

    const handleChange = event => {
        event.persist();

        setFormState(formState => ({
            ...formState,
            values: {
                email: event.target.value,
            },
            touched: {
                email: true,
            },
        }));
    };

    const handleOnCancel = () => {
        history.goBack();
    };

    return (
        <div className={classes.root}>
            <Grid container className={classes.grid}>
                <Grid item xs={12}>
                    <Typography className={classes.title}>
                        <Box fontWeight="fontWeightBold">
                            Restablecer contraseña
                        </Box>
                    </Typography>
                </Grid>
                <Grid item xs={12} direction="column" justify="center" alignItems="center" className={classes.gridForm}>
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            className={classes.textField}
                            label="Ingresar correo electrónico"
                            error={hasError('email')}
                            helperText={
                                hasError('email') ? formState.errors.email[0] : null
                            }
                            name="email"
                            type="text"
                            onChange={handleChange}
                            value={formState.values.email}
                        />
                        { formState.isSubmitted && authState.error.message !== null && (
                            <Typography color="error" className={classes.authNotificationText}>
                                <Box fontWeight="fontWeightBold">
                                    { authState.error.message }
                                </Box>
                            </Typography>
                        )}
                        { formState.isSubmitted && authState.message !== null && (
                            <Typography color="primary" className={clsx(classes.authNotificationText, classes.whiteText)}>
                                <Box fontWeight="fontWeightBold">
                                    { authState.message }
                                </Box>
                            </Typography>
                        )}
                        <Grid 
                            container 
                            direction="row"
                            justify="space-around"
                            alignItems="center"
                        >
                            <Grid item>
                                <Button
                                    fullWidth
                                    className={classes.button}
                                    color="primary"
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    disabled={authState.isLoading}
                                >
                                    ENVIAR
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    className={classes.button}
                                    size="large"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleOnCancel}
                                >
                                    CANCELAR
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </div>
    );
}

export default PasswordRecovery;