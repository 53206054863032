import { createSlice } from '@reduxjs/toolkit';

export const extinguishersSlice = createSlice({
    name: 'extinguishers',
    initialState: {
        isLoading: false,
        extinguishers: [],
        total: null,
        pages: null,
        page: 1,
        page_limit: 10,
        error: {
            code: null,
            message: null,
        },
    },
    reducers: {
        getExtinguishersRequest: (state) => {
            state.isLoading = true;
        },
        getExtinguishersSuccess: (state, action) => {
            state.isLoading = false;
            state.error = {
                code: null,
                message: null,
            };
            state.extinguishers = action.payload.extinguishers;
            state.total = action.payload.total;
            state.pages = action.payload.pages;
            state.page = action.payload.page;
            state.page_limit = action.payload.page_limit;
        },
        getExtinguishersFailure: (state, action)  => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
    },
});

export const { 
    getExtinguishersRequest,
    getExtinguishersSuccess,
    getExtinguishersFailure,
} = extinguishersSlice.actions;

export const selectExtinguishers = (state) => state.extinguishers;

export default extinguishersSlice.reducer;