import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedTab, selectMotorLists } from '../../../../store/slices/motorListsSlice';

import { StyledTab } from '../../../../components/StyledTab';
import { StyledTabs } from '../../../../components/StyledTabs';
import { TabPanel } from '../../../../components/TabPanel';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

import { 
    ListaVehiculos,
    ListaMantenimiento,
    ListaHerramientas,
    ListaDisponibilidad,
    ListaDamageReport,
    Informes,
} from './Listas';

const useStyles = makeStyles(styles);

const ListadoMotor = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const motorLists = useSelector(selectMotorLists);

    const handleTabChange = (event, newValue) => {
        dispatch(setSelectedTab({ selectedTab: newValue }));
    }

    return (
        <div>
            <StyledTabs value={motorLists.selectedTab} onChange={handleTabChange} aria-label="motor-lists-tabs" variant="scrollable">
                <StyledTab label="Vehículos"/>
                <StyledTab label="Equipos y Herramientas"/>
                <StyledTab label="Disponibilidad"/>
                <StyledTab label="Reporte de Daños"/>
                <StyledTab label="Mantenimientos"/>
                <StyledTab label="Informes"/>
            </StyledTabs>
            <div className={classes.panelsContainer}>
                <TabPanel value={motorLists.selectedTab} index={0}>
                    <ListaVehiculos {...props} />
                </TabPanel>
                <TabPanel value={motorLists.selectedTab} index={1}>
                    <ListaHerramientas {...props} />
                </TabPanel>
                <TabPanel value={motorLists.selectedTab} index={2}>
                    <ListaDisponibilidad {...props} />
                </TabPanel>
                <TabPanel value={motorLists.selectedTab} index={3}>
                    <ListaDamageReport {...props} />
                </TabPanel>
                <TabPanel value={motorLists.selectedTab} index={4}>
                    <ListaMantenimiento {...props} />
                </TabPanel>
                <TabPanel value={motorLists.selectedTab} index={5}>
                    <Informes {...props} />
                </TabPanel>
            </div>
        </div>
    );
};

export default ListadoMotor;