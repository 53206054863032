import { report_types, report_modules } from '../../../../../../constants/MT/reports';

const DETAILS_TYPES = report_types[report_modules.details];

export const requireVehicle = (selectedType) => {
    const vehicleRequiredTypes = [
        DETAILS_TYPES.vehicle_details,
    ];
    return vehicleRequiredTypes.includes(DETAILS_TYPES[selectedType]);
}

export const requireEquipment = (selectedType) => {
    const equipmentRequiredTypes = [
        DETAILS_TYPES.equipment_details,
    ];
    return equipmentRequiredTypes.includes(DETAILS_TYPES[selectedType]);
}