import { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../store/slices/currentUserSlice';

const AllowOnlyIf = (props) => {
    const { user: currentUser }= useSelector(selectCurrentUser);

    const renderChildren = useCallback(() => {
        if (currentUser === null) {
            return null;
        }

        const hasPermission = currentUser.permissions.includes(props.permission);

        if (typeof props.children === 'function') {
            return props.children(hasPermission);
        }

        return hasPermission
            ? props.children
            : null; 
    }, [currentUser, props]);

    return renderChildren();
}

export default AllowOnlyIf;