import httpClient from '../../http';
import qs from 'qs';

export const getEmergencies = async (page, page_limit, filters) => {
    const filterParams = {
        page,
        page_limit,
        ...filters,
    };
    const queryParams = qs.stringify(filterParams);
    return await httpClient.get(`/st/emergencies?${queryParams}`);
}

export const getAllEmergencies = async (filters) => {
    const queryParams = qs.stringify(filters);
    return await httpClient.get(`/st/emergencies/all?${queryParams}`);
}

export const getAllByEmergencies = async (filters) => {
    const queryParams = qs.stringify(filters);
    return await httpClient.get(`/st/emergencies/by_emegency?${queryParams}`);
}

export const getStEmergencyById = async (emergency_id) => {
    return await httpClient.get(`/st/emergencies/${emergency_id}`);
}

export const createStEmergency = async (emergency) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedEmergency = qs.stringify(emergency);
    return await httpClient.post('/st/emergencies', serializedEmergency, config);
}

export const editStEmergency = async (emergency_id, emergency) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedEmergency = qs.stringify(emergency);
    return await httpClient.put(`/st/emergencies/${emergency_id}`, serializedEmergency, config);
}

export const deleteStEmergency = async (emergency_id) => {
    return await httpClient.delete(`/st/emergencies/${emergency_id}`);
}