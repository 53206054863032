export default theme => ({
    root: {
        width: '100%',
    },
    addReportButton: {
        width: '180px',
        height: '55px',
        color: '#FFF',
    },
    fullWidth: {
        marginTop: theme.spacing(2),
        width: '100%',
    },
    table: {
        marginTop: theme.spacing(1),
    },
    tableCellText: {
        color: '#5C5C5C',
    },
    iconButton: {
        color: '#96C2C9',
    },
    searchInput: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    filterForm: {
        width: '200px',
        padding: '10px',
    },
});