import validate from 'validate.js';

validate.validators.isGreaterThanField = function(value, options, key, attributes) {
    const currentField = parseFloat(value);
    const fieldToCompareTo = parseFloat(attributes[options.field])

    if(isNaN(currentField) || isNaN(fieldToCompareTo)) {
        return undefined;
    }

    return currentField > fieldToCompareTo ? null : options.message;
}