import React from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { Form, Field, ErrorMessage } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const ValidarCertificadoForm = (props) => {
  const classes = useStyles();

  const hasError = (field) => {
      return props.errors[field] && props.touched[field] ? true : false;
  };

  return (
    <Form>
      <Grid container direction="column" justify="center" alignItems="flex-start" spacing={2}>
        <Grid item className={classes.row}>
          <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
            <Grid item xs={12} md={6}>
              <Field 
                  name="cert_number" 
                  type="text" 
                  as={TextField} 
                  variant="outlined" 
                  label="Número de certificado"
                  className={classes.formControl} 
                  error={hasError('cert_number')}
                  helperText={<ErrorMessage name="cert_number" />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                  className={classes.saveButton}
                  color="primary"
                  size="large"
                  type="submit"
                  variant="contained"
                  disableElevation
              >
                Validar 
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}

export default ValidarCertificadoForm;