import * as Yup from 'yup';

export const SelectExtintorSchema = Yup.object().shape({
    extinguisher: Yup.object().shape({
        id: Yup.number()
            .required('Éste campo es requerido.'),
        name: Yup.string()
            .required('Éste campo es requerido.')
    }),
});

export default Yup.object().shape({
    extinguishers_comments: Yup.string()
        .max(500, 'Máximo 500 caracteres.')
        .required('Éste campo es requerido.'),
});