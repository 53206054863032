import { createSlice } from '@reduxjs/toolkit';

export const neighborhoodsSlice = createSlice({
    name: 'neighborhoods',
    initialState: {
        isLoading: false,
        neighborhoods: [],
        total: null,
        pages: null,
        page: 1,
        page_limit: 10,
        error: {
            code: null,
            message: null,
        },
    },
    reducers: {
        fetchNeighborhoodsRequest: state => {
            state.isLoading = true;
        },
        fetchNeighborhoodsSuccess: (state, action) => {
            state.isLoading = false;
            state.neighborhoods = action.payload.neighborhoods;
            state.total = action.payload.total;
            state.pages = action.payload.pages;
            state.page = action.payload.page;
            state.page_limit = action.payload.page_limit;
            state.error = {
                code: null,
                message: null,
            };
        },
        fetchNeighborhoodsFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
    },
});

export const {
    fetchNeighborhoodsRequest,
    fetchNeighborhoodsSuccess,
    fetchNeighborhoodsFailure,
} = neighborhoodsSlice.actions;

export const selectNeighborhoods = state => state.neighborhoods;

export default neighborhoodsSlice.reducer;