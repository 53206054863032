export default theme => ({
    root: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
    },
    formSection: {
        width: '100%',
        marginTop: '30px',
    },
    selectInput: {
        fontWeight: 'bold',
        fontSize: '27px',
    },
});