export default theme => ({
    saveButton: {
        marginTop: '40px',
        width: '170px',
    },
    formControl: {
        width: '100%',
    },
    row: {
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
});