import httpClient from '../http';
import qs from 'qs';

export const getNeighborhoods = async (page, page_limit, filters) => {
    const filterParams = {
        page,
        page_limit,
        ...filters
    };
    const queryParams = qs.stringify(filterParams);
    return await httpClient.get(`/neighborhoods?${queryParams}`);
}

export const getAllNeighborhoods = async () => {
    return await httpClient.get(`/neighborhoods/all`);
}

export const getNeighborhoodById = async (neighborhood_id) => {
    return await httpClient.get(`/neighborhoods/${neighborhood_id}`);
}

export const deleteNeighborhood = async (neighborhood_id) => {
    return await httpClient.delete(`/neighborhoods/${neighborhood_id}`);
}

export const createNeighborhood = async (neighborhood) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedNeighborhood = qs.stringify(neighborhood);
    return await httpClient.post('/neighborhoods', serializedNeighborhood, config);
}

export const updateNeighborhood = async (neighborhood_id, neighborhood) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedNeighborhood = qs.stringify(neighborhood);
    return await httpClient.put(`/neighborhoods/${neighborhood_id}`, serializedNeighborhood, config);
}