import { useEffect, useState } from 'react';
import { getAllCountries } from '../services/countriesService';

const useCountries = () => {
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        async function fetchAllCountries() {
            try {
                const { data } = await getAllCountries();
                const otroCountryIndex = data.findIndex(country => country.name === "Otro");
                if (otroCountryIndex !== -1) {
                    const otroCountry = data[otroCountryIndex];
                    data.splice(otroCountryIndex, 1);
                    data.unshift(otroCountry);
                }
                setCountries(data);
            } catch(e) {
                const{ response } = e;
                console.log(response);
            }
        }
        fetchAllCountries();
    }, []);

    return countries;
};

export default useCountries;