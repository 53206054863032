import { useEffect, useState } from 'react';
import { getEmergencies } from '../services/emergenciesService';

const useEmergencies = (page, page_limit, filters) => {
    const [emergencies, setEmergencies] = useState([]);

    useEffect(() => {
        async function fetchEmergencies() {
            try {
                const { data: { results } } = await getEmergencies(page, page_limit, filters);
                setEmergencies(results);
            } catch(e) {
                const{ response } = e;
                console.log(response);
            }
        }
        fetchEmergencies();
    }, [page, page_limit, filters]);

    return emergencies;
};

export default useEmergencies;