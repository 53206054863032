import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

// Éstas rutas tienen que importante obligatoriamente acá arriba para
// que el objeto esté inicializado al momento de usarse en las rutas internas.
import routesUrls from './urls';

import { 
    RouteWithLayout,
    ProtectedRoute,
} from '../components';

import { 
    Auth as AuthLayout,
    Main as MainLayout,
 } from '../layouts';

 import { 
     // Auth
     SignIn as SignInView,
     ReliefCorps as ReliefCorpsView,
     PasswordRecovery as PasswordRecoveryView,
     ChangePassword as ChangePasswordView,
     SignInPostConfig as SignInPostConfigView,
     // Main
     Usuarios as UsuariosView,
     GestionRiesgo as GestionRiesgoView,
     DatosGlobales as DatosGlobalesView,
     Motor as MotorView,
     Siniestralidad as SiniestralidadView,
     Configuracion as ConfiguracionView,
     Perfil as PerfilView,
     UsuariosEstacion as UsuariosEstacionView,
     Eventos as EventosView,
     UsuariosRiesgo as UsuariosRiesgoView,
 } from '../views';

 import UsuariosIcon from '../assets/sidebar/icons/usuarios.svg';
 import UsuariosActiveIcon from '../assets/sidebar/icons/usuarios_active.svg';
 import SiniestralidadIcon from '../assets/sidebar/icons/siniestralidad.svg';
 import SiniestralidadActiveIcon from '../assets/sidebar/icons/siniestralidad_active.svg';
 import GestionDeRiesgoIcon from '../assets/sidebar/icons/gestion-de-riesgo.svg';
 import GestionDeRiesgoActiveIcon from '../assets/sidebar/icons/gestion-de-riesgo_active.svg';
 import DatosGlobalesIcon from '../assets/sidebar/icons/datos-globales.svg';
 import DatosGlobalesActiveIcon from '../assets/sidebar/icons/datos-globales_active.svg';
 import MotorIcon from '../assets/sidebar/icons/motor.svg';
 import MotorActiveIcon from '../assets/sidebar/icons/motor_active.svg';
 import ConfiguracionIcon from '../assets/sidebar/icons/configuracion.svg';
 import ConfiguracionActiveIcon from '../assets/sidebar/icons/configuracion_active.svg';
 import EventosIcon from '../assets/sidebar/icons/events.svg';
 import EventosActiveIcon from '../assets/sidebar/icons/events_active.svg';

 import { ROLES_KEYS } from '../constants/roles';
 import Modules from '../constants/modules';

 export const modulesRoutesPath = [{
     shortName: Modules.ST,
     layout: MainLayout,
     component: SiniestralidadView,
     path: routesUrls.Siniestralidad,
     title: 'Siniestralidad',
     icon: SiniestralidadIcon,
     activeIcon: SiniestralidadActiveIcon,
     allowedRoles: [ROLES_KEYS.admin, ROLES_KEYS.station, ROLES_KEYS.station_user, ROLES_KEYS.risk_agent],
 }, {
     shortName: Modules.MT,
     layout: MainLayout,
     component: MotorView,
     path: routesUrls.Motor,
     title: 'Motor',
     icon: MotorIcon,
     activeIcon: MotorActiveIcon,
     allowedRoles: [ROLES_KEYS.admin, ROLES_KEYS.station, ROLES_KEYS.station_user],
 }, {
     shortName: Modules.GR,
     layout: MainLayout,
     component: GestionRiesgoView,
     path: routesUrls.GestionRiesgo,
     title: 'Gestión de Riesgo',
     icon: GestionDeRiesgoIcon,
     activeIcon: GestionDeRiesgoActiveIcon,
     allowedRoles: [ROLES_KEYS.admin, ROLES_KEYS.station, ROLES_KEYS.station_user, ROLES_KEYS.risk_agent],
 }];

 export const mainRoutesPath = [{
     layout: MainLayout,
     component: UsuariosView,
     path: routesUrls.AdminUsuarios,
     title: 'Usuarios',
     icon: UsuariosIcon,
     activeIcon: UsuariosActiveIcon,
     allowedRoles: [ROLES_KEYS.admin],
 }, {
     layout: MainLayout,
     component: UsuariosEstacionView,
     path: routesUrls.EstacionUsuarios,
     title: 'Usuarios',
     icon: UsuariosIcon,
     activeIcon: UsuariosActiveIcon,
     allowedRoles: [ROLES_KEYS.station, ROLES_KEYS.station_user],
 }, {
     layout: MainLayout,
     component: UsuariosRiesgoView,
     path: routesUrls.RiesgoUsuarios,
     title: 'Usuarios',
     icon: UsuariosIcon,
     activeIcon: UsuariosActiveIcon,
     allowedRoles: [ROLES_KEYS.risk_agent],
 }, {
     layout: MainLayout,
     component: DatosGlobalesView,
     path: routesUrls.DatosGlobales,
     title: 'Datos Globales',
     icon: DatosGlobalesIcon,
     activeIcon: DatosGlobalesActiveIcon,
     allowedRoles: [ROLES_KEYS.admin, ROLES_KEYS.station, ROLES_KEYS.station_user],
 }, {
     layout: MainLayout,
     component: ConfiguracionView,
     path: routesUrls.Configuracion,
     title: 'Configuración',
     icon: ConfiguracionIcon,
     activeIcon: ConfiguracionActiveIcon,
     allowedRoles: [ROLES_KEYS.admin],
 }, {
     layout: MainLayout,
     component: EventosView,
     path: routesUrls.Eventos,
     title: 'Eventos',
     icon: EventosIcon,
     activeIcon: EventosActiveIcon,
     allowedRoles: [ROLES_KEYS.station, ROLES_KEYS.station_user],
 }];

 export const dropdownRoutes = [{
     layout: MainLayout,
     component: PerfilView,
     path: routesUrls.MyProfile,
     title: 'Perfil',
     allowedRoles: [ROLES_KEYS.admin, ROLES_KEYS.station, ROLES_KEYS.station_user],
 }];

 export const authRoutes = [{
     layout: AuthLayout,
     component: SignInView,
     path: routesUrls.SignIn,
 }, {
    layout: AuthLayout,
    component: ReliefCorpsView,
    path: routesUrls.ReliefCorps,
 }, {
     layout: AuthLayout,
     component: PasswordRecoveryView,
     path: routesUrls.PasswordRecovery,
 }, {
     layout: AuthLayout,
     component: ChangePasswordView,
     path: routesUrls.AuthChangePassword,
 }];

const Routes = () => {
    return (
        <Switch>
            <Redirect
                exact
                from="/"
                to={routesUrls.SignIn}
            />
            <Route 
                exact
                path={routesUrls.SignInPostConfig} 
                render={(props) => (
                    <ProtectedRoute redirectUrl={routesUrls.SignIn} {...props}>
                        <SignInPostConfigView {...props} />
                    </ProtectedRoute>
                )}
            />
            {authRoutes.map((item, key) => (
                <RouteWithLayout
                    key={`auth-${key}`}
                    layout={item.layout}
                    component={item.component}
                    path={item.path}
                    exact
                    isProtected={false}
                />
            ))}
            {mainRoutesPath.map((item, key) => {
                return (
                    <RouteWithLayout
                        layout={item.layout}
                        component={item.component}
                        path={item.path}
                        key={`main-${key}`}
                        isProtected={true}
                    />
                );
            })}
            {modulesRoutesPath.map((item, key) => {
                return (
                    <RouteWithLayout
                        layout={item.layout}
                        component={item.component}
                        path={item.path}
                        key={`main-${key}`}
                        isProtected={true}
                    />
                );
            })}
            {dropdownRoutes.map((item, key) => {
                return (
                    <RouteWithLayout
                        layout={item.layout}
                        component={item.component}
                        path={item.path}
                        key={`dropdown-${key}`}
                        isProtected={true}
                    />
                );
            })}
        </Switch>
    );
};

export default Routes;