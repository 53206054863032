import React, { useEffect } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import { ROLES_KEYS } from '../../constants/roles';

import routesUrls from '../../routes/urls';

import { useDispatch, useSelector } from 'react-redux';
import { currentUserRequest, selectCurrentUser } from '../../store/slices/currentUserSlice';

const useStyles = makeStyles(styles);

const SignInPostConfig = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { user: currentUser } = useSelector(selectCurrentUser);
    const { history } = props;

    useEffect(() => {
        dispatch(currentUserRequest());
    }, [dispatch]);

    useEffect(() => {
        if (currentUser === null) {
            return;
        }

        if (currentUser.id === null) {
             return;
        }

        switch(currentUser.role) {
            case ROLES_KEYS.admin:
                history.push(routesUrls.AdminUsuarios);
                return;
            case ROLES_KEYS.station:
                history.push(routesUrls.EstacionUsuarios);
                return;
            case ROLES_KEYS.risk_agent:
                history.push(routesUrls.RiesgoUsuarios);
                return;
            default:
                // Cambiar esto a una ruta respectiva...
                history.push(routesUrls.EstacionUsuarios);
                return;
        }
    }, [currentUser, history]);

    return (
        <div>
            <Backdrop className={classes.backdrop} open={true}>
                <Grid container justify="center" alignItems="center" direction="column">
                    <Grid item>
                        <CircularProgress color="inherit" />
                    </Grid>
                    <Grid item>
                        <Typography component="div">
                            <Box fontWeight="fontWeightBold" m={1}>
                                Iniciando sesión...
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
            </Backdrop>
        </div>
    );
};

export default SignInPostConfig;