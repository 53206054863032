import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPositionsRequest, selectPositions } from '../store/slices/positionsSlice';

const usePositions = () => {
    const dispatch = useDispatch();
    const positions = useSelector(selectPositions);

    useEffect(() => {
        dispatch(getPositionsRequest());
    }, [dispatch]);

    const findPositionNameById = useCallback((id) => {
        const position = positions.positions.find(position => position.id === id);
        if (position === undefined) {
            return '';
        }
        return position.name;
    }, [positions]);

    return { positions, findPositionNameById };
}

export default usePositions;