import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import useUsersByPosition from '../../../../../hooks/useUsersByPosition';

import useAllMinwages from '../../../../../hooks/useAllMinwages';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { Form, Field, ErrorMessage } from 'formik';

import { 
    requiredDateRange, 
    requiredInspection,
    requiredInspector,
    requiredMinwageYear,
    requiredRequestDate,
} from './helpers';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';
import useAvailableReportTypes from '../../../../../hooks/GR/useAvailableReportTypes';

const useStyles = makeStyles(styles);

const InspectionReports = (props) => {
    const classes = useStyles();
    const inspectors = useUsersByPosition("Inspector");
    const { minWages }= useAllMinwages();
    const reportTypes = useAvailableReportTypes();

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Form>
                <Grid container direction="column" spacing={2} justify="flex-start" alignItems="flex-start">
                    <Grid item className={classes.row}>
                        <Grid container direction="row" spacing={2} justify="flex-start" alignItems="flex-start">
                            <Grid item xs={12} md={6}>
                                <FormControl className={classes.formControl} variant="outlined" error={hasError('type')}>
                                    <InputLabel>Tipo de reporte</InputLabel>
                                    <Field 
                                        name="type" 
                                        type="text"
                                        as={Select}
                                        label="Tipo de reporte"
                                        className={classes.formControl} 
                                    >
                                        {Object.entries(reportTypes).map(([report_type, report_name], key) => (
                                            <MenuItem key={`report-type-key-${key}`} value={report_type}>{report_name}</MenuItem>
                                        ))}
                                    </Field>
                                    <FormHelperText>
                                        <ErrorMessage name="type" />
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            { requiredInspection(props.values.type) && (
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="inspection_number" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Número de Inspección"
                                    className={classes.formControl} 
                                    error={hasError('inspection_number')}
                                    helperText={<ErrorMessage name="inspection_number" />}
                                    inputProps={{
                                        style: {
                                            textTransform: 'uppercase',
                                        },
                                    }}
                                />
                            </Grid>
                            )}
                            { requiredInspector(props.values.type) && (
                            <Grid item xs={12} md={6}>
                                <FormControl className={classes.formControl} variant="outlined" error={hasError('inspector_id')}>
                                    <InputLabel>Inspector</InputLabel>
                                    <Field 
                                        name="inspector_id" 
                                        type="text"
                                        as={Select} 
                                        label="Inspector"
                                        className={classes.formControl} 
                                    >
                                        {inspectors.map((item, key) => (
                                            <MenuItem key={`inspector-key-${key}`} value={item.id}>{`${item.document_type}-${item.document} ${item.firstname} ${item.lastname}`}</MenuItem>
                                        ))}
                                    </Field>
                                    <FormHelperText>
                                        <ErrorMessage name="inspector_id" />
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            )}
                            { requiredMinwageYear(props.values.type) && (
                            <Grid item xs={12} md={6}>
                                <FormControl className={classes.formControl} variant="outlined" error={hasError('year')}>
                                    <InputLabel>Año</InputLabel>
                                    <Field 
                                        name="year" 
                                        type="text"
                                        as={Select} 
                                        label="Año"
                                        className={classes.formControl} 
                                    >
                                        {minWages.map((item, key) => (
                                            <MenuItem key={`year-key-${key}`} value={item.year}>{`${item.year}`}</MenuItem>
                                        ))}
                                    </Field>
                                    <FormHelperText>
                                        <ErrorMessage name="year" />
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            )}
                            {requiredRequestDate(props.values.type) && (
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="request_date" 
                                    as={KeyboardDatePicker} 
                                    label="Fecha"
                                    className={classes.formControl} 
                                    inputVariant="outlined"
                                    variant="inline"
                                    InputAdornmentProps={{ position: "end" }}
                                    format="dd/MM/yyyy"
                                    onChange={(date) => props.setFieldValue("request_date", date)}
                                    error={hasError('request_date')}
                                    helperText={<ErrorMessage name="request_date" />}
                                    autoOk
                                />
                            </Grid>
                            )}
                        </Grid>
                    </Grid>
                    { requiredDateRange(props.values.type) && (
                    <Grid item className={classes.row}>
                        <Grid container direction="row" spacing={2} justify="flex-start" alignItems="flex-start">
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="start_date" 
                                    as={KeyboardDatePicker} 
                                    label="Fecha de Inicio"
                                    className={classes.formControl} 
                                    inputVariant="outlined"
                                    variant="inline"
                                    InputAdornmentProps={{ position: "end" }}
                                    format="dd/MM/yyyy"
                                    onChange={(date) => props.setFieldValue("start_date", date)}
                                    error={hasError('start_date')}
                                    helperText={<ErrorMessage name="start_date" />}
                                    autoOk
                                    disableFuture
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="end_date" 
                                    as={KeyboardDatePicker} 
                                    label="Fecha de Cierre"
                                    className={classes.formControl} 
                                    inputVariant="outlined"
                                    variant="inline"
                                    InputAdornmentProps={{ position: "end" }}
                                    format="dd/MM/yyyy"
                                    onChange={(date) => props.setFieldValue("end_date", date)}
                                    error={hasError('end_date')}
                                    helperText={<ErrorMessage name="end_date" />}
                                    autoOk
                                    disableFuture
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    )}
                    <Grid item>
                        <Button
                            className={classes.downloadButton}
                            color="primary"
                            size="large"
                            type="submit"
                            variant="contained"
                            disableElevation
                        >
                            Consultar
                        </Button>
                    </Grid>
                </Grid>
            </Form>
        </MuiPickersUtilsProvider>
    );
}

export default InspectionReports;