import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../../store/slices/alertsSlice';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

import useUsers from '../../../../../hooks/useUsers';
import useRanks from '../../../../../hooks/useRanks';
import usePositions from '../../../../../hooks/usePositions';

import schema from './schema';

import { Formik, Form, Field, ErrorMessage } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const UnidadesForm = (props) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const dispatch = useDispatch();
    // El 1000 es porque se necesitan listar todos los usuarios. Entonces para evitar crear 
    // un endpoint separado, se consume el mismo de los listados con una paginación muy grande.
    // En caso de que existan más usuarios, se tienen que aumentar el tamaño de la paginación o 
    // crear un endpoint separado.
    const { users, findUserById } = useUsers(1, 1000);
    const { findRankNameById } = useRanks();
    const { findPositionNameById } = usePositions();

    const { readMode } = props;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onClickDeleteUnit = (unit_index) => () => {
        const selectedUnits = props.unitiesData.unities;
        selectedUnits.splice(unit_index, 1);
        props.onChangeUnities(selectedUnits);
    }

    const hasError = (field, formik) => {
        return formik.errors[field] && formik.touched[field] ? true : false;
    };

    const onHandleNext = () => {
        if (props.unitiesData.unities.length === 0) {
            dispatch(setGlobalAlert({
                show: true,
                duration: 5000,
                message: 'Debes seleccionar al menos una unidad.',
                severity: 'error',
            }));
            return;
        }
        props.onHandleNext();
    }

    return (
        <div className={classes.root}>
            <Formik
                initialValues={{
                    unit: '',
                }}
                validationSchema={schema}
                onSubmit={(values, actions) => {
                    const unities = props.unitiesData.unities;
                    unities.push(values.unit);
                    props.onChangeUnities(unities);
                    actions.resetForm();
                }}
            >
                {formik => (
                    <Form>
                        <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
                            <Grid item className={classes.row}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                    <Grid item xs={8}>
                                        <FormControl className={classes.formControl} variant="outlined" disabled={readMode} error={hasError('unit', formik)}>
                                            <InputLabel>Unidades</InputLabel>
                                            <Field 
                                                name="unit" 
                                                type="text"
                                                as={Select} 
                                                label="Unidades"
                                                className={classes.formControl} 
                                            >
                                                {users.filter(user => !props.unitiesData.unities.includes(user.id)).map((user, key) => (
                                                    <MenuItem key={`unit-key-${key}`} value={user.id}>{`${user.firstname} ${user.lastname}`}</MenuItem>
                                                ))}
                                            </Field>
                                            <FormHelperText>
                                                <ErrorMessage name="unit" />
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Grid container direction="row" justify="center" alignItems="center">
                                            <Grid item>
                                                <Typography component={"span"}>
                                                    <Box fontWeight="fontWeightLight" fontSize={12} className={classes.addUnitText}>Añadir registro</Box>
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <IconButton aria-label="add-fire-type-icon" color="primary" className={classes.addUnitContainer} type="submit" disabled={readMode}>
                                                    <AddCircleRoundedIcon className={classes.addUnitIcon} />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.row}>
                                <Divider />
                            </Grid>
                            <Grid item className={classes.row}>
                                <Typography component={"div"}>
                                    <Box fontWeight="fontWeightBold" fontSize={16}>Detalle de unidad</Box>
                                </Typography>
                                <Paper elevation={0}>
                                    <TableContainer>
                                        <Table className={classes.table} aria-label="activities-table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Nombre</TableCell>
                                                    <TableCell align="left">Identificación</TableCell>
                                                    <TableCell align="left">Cargo</TableCell>
                                                    <TableCell align="left">Rango</TableCell>
                                                    <TableCell align="right"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {props.unitiesData.unities.map((unit, key) => {
                                                    const user = findUserById(unit);
                                                    // Ésto se coloca ya que el findUserById tiene un useEffect con una función que obtiene todos
                                                    // los usuarios de forma asíncrona. Y durante el primer render puede que la petición no haya concluído.
                                                    if (user === undefined) {
                                                        return null;
                                                    }

                                                    return (
                                                        <TableRow key={`list-units-item-key-${key}`}>
                                                            <TableCell align="left">
                                                                <Typography component={"span"} className={classes.tableCellText}>
                                                                    {`${user.firstname} ${user.lastname}`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography component={"span"} className={classes.tableCellText}>
                                                                    {`${user.document_type}-${user.document}`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography component={"span"} className={classes.tableCellText}>
                                                                    {`${findPositionNameById(user.position_id)}`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography component={"span"} className={classes.tableCellText}>
                                                                    {`${findRankNameById(user.rank_id)}`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <IconButton aria-label="delete" className={classes.iconButton} disabled={readMode} onClick={onClickDeleteUnit(key)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={props.unitiesData.unities.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            labelRowsPerPage="Filas por página:"
                                            labelDisplayedRows={() => {
                                                return `Página: ${page + 1} de ${Math.ceil(props.unitiesData.unities.length / rowsPerPage)}`;
                                            }}
                                        />
                                    </TableContainer>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Button
                            className={classes.saveButton}
                            color="primary"
                            size="large"
                            onClick={onHandleNext}
                            variant="contained"
                            disableElevation
                            disabled={readMode}
                        >
                            Siguiente
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

UnidadesForm.defaultProps = {
    readMode: false,
};

export default UnidadesForm;