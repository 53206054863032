export default ({
    name: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
        length: {
            maximum: 150,
            tooLong: 'Éste campo sólo permite un máximo de %{count} dígitos.'
        },
    },
    norm: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
        length: {
            maximum: 500,
            tooLong: 'Éste campo sólo permite un máximo de %{count} dígitos.'
        },
    }, 
    code: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
        length: {
            maximum: 4,
            tooLong: 'Éste campo sólo permite un máximo de %{count} dígitos.'
        },
    },
    deviation_type_id: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    },
});