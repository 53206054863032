import { call, put, takeLatest } from 'redux-saga/effects';

import { 
    authRequest, 
    authSuccess, 
    authFailure,

    authPasswordRecoveryRequest,
    authPasswordRecoverySuccess,
    authPasswordRecoveryFailure,

    checkRecoveryTokenRequest,
    checkRecoveryTokenSuccess,
    checkRecoveryTokenFailure,

    changePasswordRequest,
    changePasswordSuccess,
    changePasswordFailure,

    signOutRequest,
    signOutSuccess,
    signOutFailure,

    checkReliefCorpRequest,
    checkReliefCorpSuccess,
    checkReliefCorpFailure,
} from '../store/slices/authSlice';
import { 
    login, 
    passwordRecovery, 
    checkRecoveryToken,
    changePassword,
    signOutUser,
    getReliefCorp,
} from '../services/authService';

function* authenticateUser(action) {
    const { email, password } = action.payload;
    try {
        const { data: { token, message }} = yield call(login, email, password);
        yield put(authSuccess({ token, message }));
    } catch(e) {
        const { response } = e;
        const errorStatusCode = response.status;
        const errorMessage = response.data.message;
        const error = {
            code: errorStatusCode,
            message: errorMessage,
        };
        yield put(authFailure({ error }));
    }
}

function* checkReliefCorp(action) {
    const { document } = action.payload;
    try {
        const { data: { message }} = yield call(getReliefCorp, document);
        console.log(message);
        yield put(checkReliefCorpSuccess({ message }));
    } catch(e) {
        const { response } = e;
        const errorStatusCode = response.status;
        const errorMessage = response.data.message;
        const error = {
            code: errorStatusCode,
            message: errorMessage,
        };
        yield put(checkReliefCorpFailure({ error }));
    }
}

function* passwordRecoveryUser(action) {
    const { email } = action.payload;
    try {
        const { data: { message }} = yield call(passwordRecovery, email);
        yield put(authPasswordRecoverySuccess({ message }));
    } catch(e) {
        const { response } = e;
        const error = {
            code: response.status,
            message: response.data.message,
        };
        yield put(authPasswordRecoveryFailure({ error }));
    }
}

function* validateRecoveryToken(action) {
    const { token } = action.payload;
    try {
        const { data: { message }} = yield call(checkRecoveryToken, token);
        yield put(checkRecoveryTokenSuccess({ message }));
    } catch(e) {
        const { response } = e;
        const error = {
            code: response.status,
            message: response.data.message,
        };
        yield put(checkRecoveryTokenFailure({ error }));
    }
}

function* changePasswordEffect(action) {
    const { token, password } = action.payload;
    try {
        const { data: { message }} = yield call(changePassword, token, password);
        yield put(changePasswordSuccess({ message }));
    } catch(e) {
        const { response } = e;
        const error = {
            code: response.status,
            message: response.data.message,
        };
        yield put(changePasswordFailure({ error }));
    }
}

function* signOutUserEffect(action) {
    try {
        const userId = action.payload.userId;
        const { data: { message }} = yield call(signOutUser, userId);
        yield put(signOutSuccess({ message }));
    } catch(e) {
        const { response } = e;
        const error = {
            code: response.status,
            message: response.data.message,
        };
        yield put(signOutFailure({ error }));
    }
}

function* authEffects() {
    yield takeLatest(authRequest.type, authenticateUser);
    yield takeLatest(checkReliefCorpRequest.type, checkReliefCorp);
    yield takeLatest(authPasswordRecoveryRequest.type, passwordRecoveryUser);
    yield takeLatest(checkRecoveryTokenRequest.type, validateRecoveryToken);
    yield takeLatest(changePasswordRequest.type, changePasswordEffect);
    yield takeLatest(signOutRequest.type, signOutUserEffect);
}

export default authEffects;