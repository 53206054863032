import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import sagaEffectsMiddleware from '../effects';
import authEffects from '../effects/authEffects';
import currentUserEffects from '../effects/currentUserEffects';
import citiesEffects from '../effects/citiesEffects';
import departmentsEffects from '../effects/departmentsEffects';
import usersEffects from '../effects/usersEffects';
import minWagesEffects from '../effects/minWagesEffects';
import activitiesEffects from '../effects/activitiesEffects';
import deviationsEffects from '../effects/deviationsEffects';
import extinguishersEffects from '../effects/extinguishersEffects';
import emergenciesEffects from '../effects/emergenciesEffects';
import areasEffects from '../effects/areasEffects';
import neighborhoodsEffects from '../effects/neighborhoodsEffects';
import vehiclesEffects from '../effects/vehiclesEffects';
import equipmentsEffects from '../effects/equipmentsEffects';
import positionsEffects from '../effects/positionsEffects';
import ranksEffects from '../effects/ranksEffects';
import maintenancesEffects from '../effects/maintenancesEffects';
import damageReportsEffects from '../effects/damageReportsEffects';
import availabilitiesEffects from '../effects/availabilitiesEffects';
import eventsEffects from '../effects/eventsEffects';
import stEmergenciesEffects from '../effects/stEmergenciesEffects';
import grInspectionsEffects from '../effects/inspectionsEffects';

import { handleAuthTokenMiddleware } from './middlewares';

import authReducer from './slices/authSlice';
import currentUserReducer from './slices/currentUserSlice';
import citiesReducer from './slices/citiesSlice';
import departmentsReducer from './slices/departmentsSlice';
import usersReducer from './slices/usersSlice';
import httpClientReducer from './slices/httpClientSlice';
import minWagesReducer from './slices/minWagesSlice';
import areasReducer from './slices/areasSlice';
import activitiesReducer from './slices/activitiesSlice';
import deviationsReducer from './slices/deviationsSlice';
import extinguishersReducer from './slices/extinguishersSlice';
import emergenciesReducer from './slices/emergenciesSlice';
import alertsReducer from './slices/alertsSlice';
import neighborhoodsReducer from './slices/neighborhoodsSlice';
import globalDataListsReducer from './slices/globalDataListsSlice';
import configurationListsReducer from './slices/configurationListsSlice';
import motorListsReducer from './slices/motorListsSlice';
import modulesReducer from './slices/modulesSlice';
import vehiclesReducer from './slices/vehiclesSlice';
import equipmentsReducer from './slices/equipmentsSlice';
import positionsReducer from './slices/positionsSlice';
import ranksReducer from './slices/ranksSlice';
import maintenancesReducer from './slices/maintenancesSlice';
import damageReportsReducer from './slices/damageReportsSlice';
import availabilitiesReducer from './slices/availabilitiesSlice';
import eventsReducer from './slices/eventsSlice';
import stEmergenciesReducer from './slices/stEmergenciesSlice';
import grInspectionsReducer from './slices/grInspectionsSlice';
import linearProgressReducer from './slices/linearProgressSlice';

import { signOutSuccess } from './slices/authSlice';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const middleware = [...getDefaultMiddleware({
    serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
}), sagaEffectsMiddleware, handleAuthTokenMiddleware];

const persistConfig = {
  key: 'sipgerd-root',
  version: 1,
  storage,
  whitelist: ['auth'],
};

const appReducer = combineReducers({
    auth: authReducer,
    alerts: alertsReducer,
    modules: modulesReducer,
    globalDataLists: globalDataListsReducer,
    configurationLists: configurationListsReducer,
    motorLists: motorListsReducer,
    currentUser: currentUserReducer,
    departments: departmentsReducer,
    cities: citiesReducer,
    users: usersReducer,
    httpClient: httpClientReducer,
    minWages: minWagesReducer,
    activities: activitiesReducer,
    deviations: deviationsReducer,
    extinguishers: extinguishersReducer,
    emergencies: emergenciesReducer,
    areas: areasReducer,
    neighborhoods: neighborhoodsReducer,
    vehicles: vehiclesReducer,
    equipments: equipmentsReducer,
    positions: positionsReducer,
    ranks: ranksReducer,
    maintenances: maintenancesReducer,
    damageReports: damageReportsReducer,
    availabilities: availabilitiesReducer,
    events: eventsReducer,
    stEmergencies: stEmergenciesReducer,
    grInspections: grInspectionsReducer,
    linearProgress: linearProgressReducer,
});

const rootReducer = (state, action) => {
    if (action.type === signOutSuccess.type) {
        storage.removeItem(`persist:${persistConfig.key}`);
    }
    return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware,
});

export const persistor = persistStore(store);

sagaEffectsMiddleware.run(authEffects);
sagaEffectsMiddleware.run(currentUserEffects);
sagaEffectsMiddleware.run(departmentsEffects);
sagaEffectsMiddleware.run(citiesEffects);
sagaEffectsMiddleware.run(usersEffects);
sagaEffectsMiddleware.run(minWagesEffects);
sagaEffectsMiddleware.run(activitiesEffects);
sagaEffectsMiddleware.run(deviationsEffects);
sagaEffectsMiddleware.run(extinguishersEffects);
sagaEffectsMiddleware.run(emergenciesEffects);
sagaEffectsMiddleware.run(areasEffects);
sagaEffectsMiddleware.run(neighborhoodsEffects);
sagaEffectsMiddleware.run(vehiclesEffects);
sagaEffectsMiddleware.run(equipmentsEffects);
sagaEffectsMiddleware.run(positionsEffects);
sagaEffectsMiddleware.run(ranksEffects);
sagaEffectsMiddleware.run(maintenancesEffects);
sagaEffectsMiddleware.run(damageReportsEffects);
sagaEffectsMiddleware.run(availabilitiesEffects);
sagaEffectsMiddleware.run(eventsEffects);
sagaEffectsMiddleware.run(stEmergenciesEffects);
sagaEffectsMiddleware.run(grInspectionsEffects);