export const PERMISSION_KEYS = {
    ST_READ: 'ST_READ',
    ST_WRITE: 'ST_WRITE',

    MT_READ: 'MT_READ',
    MT_WRITE: 'MT_WRITE',

    GR_READ: 'GR_READ',
    GR_WRITE: 'GR_WRITE',

    USERS_READ: 'USERS_READ',
    USERS_WRITE: 'USERS_WRITE',

    EVENTS_READ: 'EVENTS_READ',
    EVENTS_WRITE: 'EVENTS_WRITE',
};