import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import validate from 'validate.js';
import schema from './schema';

import useDepartments from '../../../../hooks/useDepartments';
import useCityById from '../../../../hooks/useCityById';
import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';

import { configurationUrls } from '../../routing';

import { updateCity } from '../../../../services/cityService';

const useStyles = makeStyles(styles);

const EditCity = (props) => {
    const classes = useStyles();
    const departments = useDepartments();
    const { city_id } = useParams();
    const { city } = useCityById(city_id);
    const dispatch = useDispatch();
    const {
        backendResponse: updateCityResponse,
        executeService: updateCityService,
    } = useBackendMessageResponse(updateCity);
    const [cityForm, setCityForm] = useState({
        isValid: false,
        isSubmitted: false,
        values: {
            department_id: '',
            name: '',
        },
        touched: {
            department_id: '',
            name: false,
        }, 
        errors: {},
    });

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    const onSubmitEditCity = async (event) => {
        event.preventDefault();

        if (!cityForm.isSubmitted) {
            setCityForm(currentCityForm => ({
                ...currentCityForm,
                isSubmitted: true,
            }));
        }

        if (!cityForm.isValid) {
            return;
        }

        const city = cityForm.values;
        await updateCityService(city_id, city);
    }

    const handleChange = event => {
        event.persist();

        setCityForm(currentCityForm => ({
            ...currentCityForm,
            values: {
                ...currentCityForm.values,
                [event.target.name]: event.target.value,
            },
            touched: {
                ...currentCityForm.touched,
                [event.target.name]: true
            },
        }));
    };

    const hasError = field =>
        (cityForm.isSubmitted || cityForm.touched[field]) && cityForm.errors[field] ? true : false;

    useEffect(() => {
        const errors = validate(cityForm.values, schema);

        setCityForm(currentCityForm => ({
            ...currentCityForm,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [cityForm.values, cityForm.isSubmitted]);

    useEffect(() => {
        if(city === null) {
            return;
        }

        setCityForm(currentCityForm => ({
            ...currentCityForm,
            isValid: true,
            values: {
                name: city.name,
                department_id: city.department_id,
            },
        }));
    }, [city]);

    useEffect(() => {
        if (updateCityResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: updateCityResponse.message,
            severity: 'success',
        }));
        props.history.push(configurationUrls.Listado);
    }, [dispatch, updateCityResponse.message, props.history]);

    useEffect(() => {
        if (updateCityResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: updateCityResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, updateCityResponse.error])

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Editar municipio</Box>
            </Typography>
            <form onSubmit={onSubmitEditCity} className={classes.form}>
                <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
                    <Grid item>
                        <FormControl variant="outlined" className={classes.formControl} error={hasError('department_id')}>
                            <InputLabel id="department-id-input-label">Nombre del departamento</InputLabel>
                            <Select
                                labelId="department-id-input-label"
                                id="department-id-input"
                                value={cityForm.values.department_id}
                                onChange={handleChange}
                                label="Nombre del departamento"
                                name="department_id"
                            >
                            {departments.map((department, key) => (
                                <MenuItem key={`department-key-${key}`} value={department.id}>{department.name}</MenuItem>
                            ))}
                            </Select>
                            { hasError('department_id') && (
                                <FormHelperText>{cityForm.errors.department_id[0]}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <TextField 
                            id="name-city-input" 
                            label="Nombre del municipio" 
                            variant="outlined" 
                            className={classes.formControl} 
                            error={hasError('name')}
                            helperText={
                                hasError('name') ? cityForm.errors.name[0] : null
                            }
                            name="name"
                            value={cityForm.values.name}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Button
                    className={classes.saveButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disableElevation
                >
                    Guardar
                </Button>
            </form>
        </div>
    );
}

export default EditCity;