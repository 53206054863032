import { call, put, takeLatest } from 'redux-saga/effects';

import { 
    getVehicles,
} from '../services/vehiclesService';

import { 
    getVehiclesRequest,
    getVehiclesSuccess,
    getVehiclesFailure,
} from '../store/slices/vehiclesSlice';

function* getVehiclesEffect(action) {
    try {
        const page = action.payload.page;
        const page_limit = action.payload.page_limit;
        const filters = action.payload.filters;
        const { data } = yield call(getVehicles, page, page_limit, filters);
        yield put(getVehiclesSuccess({ 
            vehicles: data.results,
            page: data.page,
            pages: data.pages,
            total: data.total,
            page_limit,
        }));
    } catch(e) {
        const { response } = e;
        const error = {
            code: response.status,
            message: response.data.message,
        };
        yield put(getVehiclesFailure({ error }));
    }
}

function* vehiclesEffects() {
    yield takeLatest(getVehiclesRequest.type, getVehiclesEffect);
}

export default vehiclesEffects;