import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentUser } from '../../../../store/slices/currentUserSlice';
import { signOutRequest } from '../../../../store/slices/authSlice';
 
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';

import validate from 'validate.js';
import schema from './schema';

import { changePassword } from '../../../../services/usersService';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const ChangePassword = (props) => {
    const classes = useStyles();
    const { user } = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
    const [openBackdrop, setOpenBackdrop] = useState(false);

    const [changePasswordForm, setChangePasswordForm] = useState({
        isValid: false,
        isSubmitted: false,
        values: {
            current_password: '',
            new_password: '',
            confirm_new_password: '',
        },
        touched: {
            current_password: false,
            new_password: false,
            confirm_new_password: false,
        },
        errors: {
            current_password: [],
            new_password: [],
            confirm_new_password: [],
        },
        showPassword: {
            current_password: false,
            new_password: false,
            confirm_new_password: false,
        },
    });

    const [backendResponse, setBackendResponse] = useState({
        isPending: false,
        message: null,
        error: null,
    });

    const handleClickShowPassword = (field) => () => {
        const invertedShowPassword = !changePasswordForm.showPassword[field];
        setChangePasswordForm(formState => ({ 
            ...formState, 
            showPassword: {
                ...formState.showPassword,
                [field]: invertedShowPassword,
            },
        }));
    };

    const handleFieldChange = event => {
        event.persist();

        setChangePasswordForm(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]: event.target.value,
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            },
        }));
    };

    const handleChangePasswordSubmit = async (event) => {
        event.preventDefault();
        if(!changePasswordForm.isSubmitted) {
            setChangePasswordForm(formState => ({
                ...formState,
                isSubmitted: true,
            }));
        }
        if(!changePasswordForm.isValid) {
            return;
        }

        try {
            setBackendResponse(currentBackendResponse => ({
                ...currentBackendResponse,
                isPending: true,
            }));
            const old_password = changePasswordForm.values.current_password;
            const new_password = changePasswordForm.values.new_password;
            const { data } = await changePassword(user.id, old_password, new_password);
            setBackendResponse({
                message: data.message,
                error: null,
                isPending: false,
            });
            setOpenBackdrop(true);
        } catch(e) {
            const { response } = e;
            setBackendResponse({
                message: null,
                isPending: false,
                error: {
                    code: response.status,
                    message: response.data.message,
                },
            });
        }
    };

    const hasError = field =>
        (changePasswordForm.isSubmitted || changePasswordForm.touched[field]) && changePasswordForm.errors[field] ? true : false;

    useEffect(() => {
        const errors = validate(changePasswordForm.values, schema);

        setChangePasswordForm(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [changePasswordForm.values, changePasswordForm.isSubmitted]);

    useEffect(() => {
        if (!openBackdrop) {
            return;
        }

        // Si llegamos hasta acá, y openBackdrop es igual a 'true',
        // es porque el cambio de contraseña fue exitoso. Así que se muestra el backdrop
        // y en dos segundos ejecutamos el signout para ocasionar un redireccionamiento al login.
        let interval = setInterval(() => {
            dispatch(signOutRequest({ userId: user.id }));
        }, 2000);

        return () => {
            clearInterval(interval);
        };
    }, [openBackdrop, user, dispatch]);

    return (
        <div className={classes.root}>
            <Backdrop className={classes.backdrop} open={openBackdrop}>
                <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                    <Grid item>
                        <CircularProgress color="inherit" />
                    </Grid>
                    <Grid item>
                        <Typography>
                            <Box fontWeight="fontWeightBold" fontSize={18}>
                                Redireccionando...
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
            </Backdrop>
            <Typography>
                <Box fontWeight="fontWeightBold" fontSize={25}>
                    Cambio de contraseña
                </Box>
            </Typography>
            <div className={classes.formContainer}>
                <form className={classes.form} onSubmit={handleChangePasswordSubmit}>
                    <Grid container direction="column" justify="center" alignItems="flex-start" spacing={2}>
                        <Grid item className={classes.row}>
                            <Grid container direction="row" justify="flex-start" alignItems="center">
                                <Grid item md={6} xs={12}>
                                    <FormControl className={classes.formControl} error={hasError('current_password')} variant="outlined">
                                        <InputLabel htmlFor="password-label">Contraseña actual</InputLabel>
                                        <OutlinedInput
                                            id="current_password"
                                            type={changePasswordForm.showPassword["current_password"] ? 'text' : 'password'}
                                            value={changePasswordForm.values.current_password}
                                            onChange={handleFieldChange}
                                            name="current_password"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        edge="end"
                                                        onClick={handleClickShowPassword("current_password")}
                                                    >
                                                        {changePasswordForm.showPassword["current_password"] ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            labelWidth={125}
                                        />
                                        {hasError('current_password') && 
                                            <FormHelperText id="current-password-error-text">
                                                {changePasswordForm.errors.current_password[0]}
                                            </FormHelperText> 
                                        }
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.row}>
                            <Grid container direction="row" justify="flex-start" alignItems="center">
                                <Grid item md={6} xs={12}>
                                    <FormControl className={classes.formControl} error={hasError('new_password')} variant="outlined">
                                        <InputLabel htmlFor="password-label">Nueva contraseña</InputLabel>
                                        <OutlinedInput
                                            id="new_password"
                                            type={changePasswordForm.showPassword["new_password"] ? 'text' : 'password'}
                                            value={changePasswordForm.values.new_password}
                                            onChange={handleFieldChange}
                                            name="new_password"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        edge="end"
                                                        onClick={handleClickShowPassword("new_password")}
                                                    >
                                                        {changePasswordForm.showPassword["new_password"] ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            labelWidth={125}
                                        />
                                        {hasError('new_password') && 
                                            <FormHelperText id="new-password-error-text" className={classes.errorMessage}>
                                                {changePasswordForm.errors.new_password[0]}
                                            </FormHelperText> 
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <FormControl className={classes.formControl} error={hasError('confirm_new_password')} variant="outlined">
                                        <InputLabel htmlFor="password-label">Repetir contraseña</InputLabel>
                                        <OutlinedInput
                                            id="confirm_new_password"
                                            type={changePasswordForm.showPassword["confirm_new_password"] ? 'text' : 'password'}
                                            value={changePasswordForm.values.confirm_new_password}
                                            onChange={handleFieldChange}
                                            name="confirm_new_password"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        edge="end"
                                                        onClick={handleClickShowPassword("confirm_new_password")}
                                                    >
                                                        {changePasswordForm.showPassword["confirm_new_password"] ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            labelWidth={130}
                                        />
                                        {hasError('confirm_new_password') && 
                                            <FormHelperText id="confirm-new-password-error-text" className={classes.errorMessage}>
                                                {changePasswordForm.errors.confirm_new_password[0]}
                                            </FormHelperText> 
                                        }
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.row}>
                            { backendResponse.message !== null && (
                                <Typography color="primary">
                                    <Box fontWeight="fontWeightBold">
                                        { backendResponse.message }
                                    </Box>
                                </Typography>
                            )}
                            { backendResponse.error !== null && (
                                <Typography color="error">
                                    <Box fontWeight="fontWeightBold">
                                        { backendResponse.error.message }
                                    </Box>
                                </Typography>
                            )}
                        </Grid>
                        <Grid item className={classes.row}>
                            <Button
                                className={classes.changePasswordButton}
                                color="primary"
                                size="large"
                                type="submit"
                                variant="contained"
                                disableElevation
                                disable={backendResponse.isPending}
                            >
                                Cambiar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </div>
    );
}

export default ChangePassword;