import { useState, useEffect } from 'react';
import { getNeighborhoodById } from '../services/neighborhoodsService';

const useNeighborhoodByID = (neighborhood_id) => {
    const [neighborhood, setNeighborhood] = useState(null);

    useEffect(() => {
        async function fetchNeighborhood() {
            try {
                const { data } = await getNeighborhoodById(neighborhood_id);
                setNeighborhood(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchNeighborhood();
    }, [neighborhood_id]);

    return { neighborhood };
}

export default useNeighborhoodByID;