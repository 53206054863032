import React from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import MuiPhoneNumber from 'material-ui-phone-number';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import useCountries from '../../hooks/useCountries';
import useDepartments from '../../hooks/useDepartments';
import useCities from '../../hooks/useCities';
import usePositions from '../../hooks/usePositions';
import useRanks from '../../hooks/useRanks';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import { Field, ErrorMessage } from 'formik';

const useStyles = makeStyles(styles);

const ID_TYPES = ['CC', 'CE', 'PA'];
const GENRE_TYPES = ['Masculino', 'Femenino'];
const MILITARY_TYPES = ['Primera clase', 'Segunda clase'];

// Éste componente no contiene el 'Form' porque es necesario delegar eso a un componente padre
// en caso de que se quiera combinar con otros formularios en otras vistas y necesitan
// enviarse todos bajo el mismo submit. 
const StationUserForm = (props) => {
    const classes = useStyles();

    const countries = useCountries();
    const departments = useDepartments();
    const cities = useCities();
    const { positions } = usePositions();
    const { ranks } = useRanks();

    const { blockedEmail, blockedForm } = props;

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true: false;
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                <Grid item xs={12} className={classes.fullWidth}>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Field
                                name="firstname"
                                as={TextField}
                                className={classes.formControl}
                                variant="outlined"
                                label="Nombres"
                                type="text"
                                error={hasError("firstname")}
                                helperText={<ErrorMessage name="firstname" />}
                                disabled={blockedForm}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                name="lastname"
                                as={TextField}
                                className={classes.formControl}
                                variant="outlined"
                                label="Apellidos"
                                type="text"
                                error={hasError("lastname")}
                                helperText={<ErrorMessage name="lastname" />}
                                disabled={blockedForm}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.fullWidth}>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl variant="outlined" className={classes.formControl} error={hasError("document_type")} disabled={blockedForm}>
                                        <InputLabel id="id-type-station-user-label">ID</InputLabel>
                                        <Field
                                            name="document_type"
                                            as={Select}
                                            labelId="id-type-station-user-label"
                                            id="id-type-station-user-select"
                                            label="ID"
                                        >
                                        {ID_TYPES.map((id_type, index) => (
                                            <MenuItem value={id_type} key={`id-type-station-user-key-${index}`}>{id_type}</MenuItem>
                                        ))}
                                        </Field>
                                        <FormHelperText>
                                            <ErrorMessage name="document_type" />
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                    <Field
                                        name="document"
                                        as={TextField}
                                        className={classes.formControl}
                                        variant="outlined"
                                        label="Identificación"
                                        type="text"
                                        error={hasError("document")}
                                        helperText={<ErrorMessage name="document" />}
                                        disabled={blockedForm}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl variant="outlined" className={classes.formControl} error={hasError("genre")} disabled={blockedForm}>
                                <InputLabel id="genre-station-user-label">Sexo</InputLabel>
                                <Field
                                    name="genre"
                                    as={Select}
                                    labelId="genre-station-user-label"
                                    id="genre-station-user-select"
                                    label="Sexo"
                                >
                                {GENRE_TYPES.map((genre, index) => (
                                    <MenuItem value={genre} key={`genre-station-user-key-${index}`}>{genre}</MenuItem>
                                ))}
                                </Field>
                                <FormHelperText>
                                    <ErrorMessage name="genre" />
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.fullWidth}>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Field
                                name="nationality"
                                as={TextField}
                                className={classes.formControl}
                                variant="outlined"
                                label="Nacionalidad"
                                type="text"
                                error={hasError("nationality")}
                                helperText={<ErrorMessage name="nationality" />}
                                disabled={blockedForm}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl variant="outlined" className={classes.formControl} error={hasError("country_id")} disabled={blockedForm}>
                                <InputLabel id="country-station-user-label">País</InputLabel>
                                <Field
                                    name="country_id"
                                    as={Select}
                                    labelId="country-station-user-label"
                                    id="country-station-user-select"
                                    label="País"
                                >
                                {countries.map((country, index) => (
                                    <MenuItem value={country.id} key={`country-station-user-key-${index}`}>{country.name}</MenuItem>
                                ))}
                                </Field>
                                <FormHelperText>
                                    <ErrorMessage name="country_id" />
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.fullWidth}>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FormControl variant="outlined" className={classes.formControl} error={hasError("department_id")} disabled={blockedForm}>
                                <InputLabel id="department-station-user-label">Departamento</InputLabel>
                                <Field
                                    name="department_id"
                                    as={Select}
                                    labelId="department-station-user-label"
                                    id="department-station-user-select"
                                    label="Departamento"
                                >
                                {departments.map((department, index) => (
                                    <MenuItem value={department.id} key={`department-station-user-key-${index}`}>{department.name}</MenuItem>
                                ))}
                                </Field>
                                <FormHelperText>
                                    <ErrorMessage name="department_id" />
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl variant="outlined" className={classes.formControl} error={hasError('city_id')} disabled={blockedForm}>
                                <InputLabel id="city-station-user-label">Municipio</InputLabel>
                                <Field
                                    name="city_id"
                                    as={Select}
                                    labelId="city-station-user-label"
                                    id="city-station-user-select"
                                    label="Municipio"
                                >
                                {cities.filter(city => city.department_id === props.values.department_id || city.name === "Otro").map((city, index) => (
                                    <MenuItem value={city.id} key={`city-station-user-key-${index}`}>{city.name}</MenuItem>
                                ))}
                                </Field>
                                <FormHelperText>
                                    <ErrorMessage name="city_id" />
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.fullWidth}>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Field 
                                name="born_date" 
                                as={KeyboardDatePicker} 
                                label="Fecha de nacimiento"
                                className={classes.formControl} 
                                inputVariant="outlined"
                                variant="inline"
                                InputAdornmentProps={{ position: "end" }}
                                format="dd/MM/yyyy"
                                onChange={(date) => props.setFieldValue("born_date", date)}
                                error={hasError("born_date")}
                                disableFuture
                                autoOk
                                helperText={<ErrorMessage name="born_date" />}
                                disabled={blockedForm}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl variant="outlined" className={classes.formControl} error={hasError("born_city_id")} disabled={blockedForm}>
                                <InputLabel id="city-born-station-user-label">Lugar de nacimiento</InputLabel>
                                <Field
                                    name="born_city_id"
                                    as={Select}
                                    labelId="city-born-station-user-label"
                                    id="city-born-station-user-select"
                                    label="Lugar de nacimiento"
                                >
                                {cities.map((city, index) => (
                                    <MenuItem value={city.id} key={`city-born-station-user-key-${index}`}>{city.name}</MenuItem>
                                ))}
                                </Field>
                                <FormHelperText>
                                    <ErrorMessage name="born_city_id" />
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.fullWidth}>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl variant="outlined" className={classes.formControl} error={hasError("military_type")} disabled={blockedForm}>
                                        <InputLabel id="military-number-type-station-user-label">Libreta Militar</InputLabel>
                                        <Field
                                            name="military_type"
                                            as={Select}
                                            labelId="military-number-type-type-station-user-label"
                                            id="military-number-type-station-user-select"
                                            label="Libreta Militar"
                                        >
                                        {MILITARY_TYPES.map((id_type, index) => (
                                            <MenuItem value={id_type} key={`id-type-station-user-key-${index}`}>{id_type}</MenuItem>
                                        ))}
                                        </Field>
                                        <FormHelperText>
                                            <ErrorMessage name="military_type" />
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        name="military_number"
                                        as={TextField}
                                        className={classes.formControl}
                                        variant="outlined"
                                        label="Número"
                                        type="text"
                                        error={hasError("military_number")}
                                        helperText={<ErrorMessage name="military_number" />}
                                        disabled={blockedForm}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                name="address"
                                as={TextField}
                                className={classes.formControl}
                                variant="outlined"
                                label="Dirección de correspondencia"
                                type="text"
                                error={hasError("address")}
                                helperText={<ErrorMessage name="address" />}
                                disabled={blockedForm}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.fullWidth}>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FormControl variant="outlined" className={classes.formControl} error={hasError("position_id")} disabled={blockedForm}>
                                <InputLabel id="position-station-user-label">Cargo</InputLabel>
                                <Field
                                    name="position_id"
                                    as={Select}
                                    labelId="position-station-user-label"
                                    id="position-station-user-select"
                                    label="Cargo"
                                >
                                {positions.positions.map((position, index) => (
                                    <MenuItem value={position.id} key={`position-station-user-key-${index}`}>{position.name}</MenuItem>
                                ))}
                                </Field>
                                <FormHelperText>
                                    <ErrorMessage name="position_id" />
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl variant="outlined" className={classes.formControl} error={hasError("rank_id")} disabled={blockedForm}>
                                <InputLabel id="rank-station-user-label">Rango</InputLabel>
                                <Field
                                    name="rank_id"
                                    as={Select}
                                    labelId="rank-station-user-label"
                                    id="rank-station-user-select"
                                    label="Rango"
                                >
                                {ranks.ranks.map((rank, index) => (
                                    <MenuItem value={rank.id} key={`rank-station-user-key-${index}`}>{rank.name}</MenuItem>
                                ))}
                                </Field>
                                <FormHelperText>
                                    <ErrorMessage name="rank_id" />
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.fullWidth}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Field
                                name="phone"
                                as={MuiPhoneNumber}
                                className={classes.formControl}
                                variant="outlined"
                                label="Número de teléfono"
                                type="text"
                                error={hasError("phone")}
                                helperText={<ErrorMessage name="phone" />}
                                onChange={(value) => props.setFieldValue('phone', value)}
                                disabled={blockedForm}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                name="email"
                                as={TextField}
                                className={classes.formControl}
                                variant="outlined"
                                label="Correo electrónico"
                                type="text"
                                error={hasError("email")}
                                helperText={<ErrorMessage name="email" />}
                                disabled={blockedEmail || blockedForm}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    );
}

StationUserForm.defaultProps = {
    blockedEmail: false,
    blockedForm: false,
};

export default StationUserForm;