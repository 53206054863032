import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../../../store/slices/alertsSlice';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import { Formik } from 'formik';
import { CertificatesFormatForm } from '../../../Forms/Certificates/CertificatesFormatForm';

import useLatestCertificateFormat from '../../../../../../hooks/useLatestCertificateFormat';

import useBackendMessageResponse from '../../../../../../hooks/useBackendMessageResponse';
import { createCertificateFormat } from '../../../../../../services/certificatesService';

const useStyles = makeStyles(styles);

const ListaCertificados = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        backendResponse: createFormatResponse,
        executeService: createFormatService,
        resetBackendResponseState: createFormatReset,
    } = useBackendMessageResponse(createCertificateFormat);
    const [certificateFormat, setCertificateFormat] = useState({
        header_html: '',
        footer_html: '',
        body_html: '',
    });

    const latestCertificateFormat = useLatestCertificateFormat();

    useEffect(() => {
        if (createFormatResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createFormatResponse.message,
            severity: 'success',
        }));
        createFormatReset();
    }, [dispatch, createFormatResponse.message, createFormatReset]);

    useEffect(() => {
        if (createFormatResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createFormatResponse.error.message,
            severity: 'error',
        }));
        createFormatReset();
    }, [dispatch, createFormatResponse.error, createFormatReset])

    useEffect(() => {
        if (latestCertificateFormat === null) {
            return;
        }

        setCertificateFormat({
            header_html: latestCertificateFormat.header_html,
            footer_html: latestCertificateFormat.footer_html,
            body_html: latestCertificateFormat.body_html,
        });
    }, [latestCertificateFormat])

    return (
        <div className={classes.root}>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Formatos</Box>
            </Typography>
            <br />
            <Formik
                initialValues={certificateFormat}
                enableReinitialize
                component={CertificatesFormatForm}
                onSubmit={async (values) => {
                    setCertificateFormat(values);
                    await createFormatService(values);
                }}
            />
        </div>
    );
}

export default ListaCertificados;