import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import SiniestralidadRouting, { SiniestralidadUrls } from './routing';
import routesUrls from '../../routes/urls';

const Siniestralidad = props => {
    return (
        <div>
            <Switch>
                {SiniestralidadRouting.map((item, key) => (
                    <Route key={`motor-${key}`} path={item.path} exact render={routeProps => (
                        <item.component {...routeProps} {...props} />
                    )}/>
                ))}
                <Redirect exact from={routesUrls.Siniestralidad} to={SiniestralidadUrls.Tablero} />
            </Switch>
        </div>
    );
};

export default Siniestralidad;