import { createSlice } from '@reduxjs/toolkit';

export const httpClientSlice = createSlice({
    name: 'httpClient',
    initialState: {
        isReady: false,
    },
    reducers: {
        clientPrepared: state => {
            state.isReady = true;
        },
        clientUnprepared: state => {
            state.isReady = false;
        },
    }
});

export const {
    clientPrepared,
    clientUnprepared
} = httpClientSlice.actions;

export const selectHttpClient = (state) => state.httpClient;

export default httpClientSlice.reducer;