import { useEffect, useState } from 'react';
import { getallDepartments } from '../services/departmentService';

const useDepartments = () => {
    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        async function fetchAllDeparments() {
            try {
                const { data } = await getallDepartments();
                const otroDepartmentIndex = data.findIndex(department => department.name === "Otro");
                if (otroDepartmentIndex !== -1) {
                    const otroDepartment = data[otroDepartmentIndex];
                    data.splice(otroDepartmentIndex, 1);
                    data.unshift(otroDepartment);
                }
                setDepartments(data);
            } catch(e) {
                const{ response } = e;
                console.log(response);
            }
        }
        fetchAllDeparments();
    }, []);

    return departments;
};

export default useDepartments;