import React from 'react';

import { Field, ErrorMessage } from 'formik';

import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import useEducationLevels from '../../hooks/useEducationLevels';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

// Éste componente no contiene el 'Form' porque es necesario delegar eso a un componente padre
// en caso de que se quiera combinar con otros formularios en otras vistas y necesitan
// enviarse todos bajo el mismo submit. 
const EducationForm = (props) => {
    const classes = useStyles();

    const { blockedForm } = props;

    const educationLevels = useEducationLevels();

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    }

    return (
        <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
            <Grid item className={classes.fullWidth}>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item md={6} xs={12}>
                        <FormControl variant="outlined" className={classes.formControl} error={hasError('education_level_id')} disabled={blockedForm}>
                            <InputLabel id="education-level-station-user-label">Nivel de educación</InputLabel>
                            <Field
                                name="education_level_id"
                                as={Select}
                                labelId="education-level-station-user-label"
                                id="education-level-station-user-select"
                                label="Nivel de educación"
                            >
                            {educationLevels.map((education, index) => (
                                <MenuItem value={education.id} key={`education-level-station-user-key-${index}`}>{education.name}</MenuItem>
                            ))}
                            </Field>
                            <FormHelperText>
                                <ErrorMessage name="education_level_id" />
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

EducationForm.defaultProps = {
    blockedForm: false,
};

export default EducationForm;