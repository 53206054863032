import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';

import DatosIngresoErrorSchema from '../Forms/Vehicles/DatosIngresoForm/schema';
import DatosVehiculoErrorSchema from '../Forms/Vehicles/DatosVehiculoForm/schema';
import DatosImpuestoErrorSchema from '../Forms/Vehicles/DatosImpuestoForm/schema';

import useTimestamp from '../../../../hooks/useTimestamp';
import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';
import { createVehicle } from '../../../../services/vehiclesService';

import { Formik } from 'formik';

import { 
    DatosImpuestoForm,
    DatosIngresoForm,
    DatosVehiculoForm,
} from '../Forms/Vehicles';

import { MotorUrls } from '../../routing';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const AddVehicle = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { 
        backendResponse: createVehicleResponse,
        executeService: createVehicleService,
    } = useBackendMessageResponse(createVehicle);
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState(new Set());
    const timestamp = useTimestamp();
    const [datosIngreso, setDatosIngreso] = useState({
        image_url: '',
        code: `VH-${timestamp}`, 
        owner_name: '',
        class: '',
        registration_number: '',
        enter_date: new Date(),
        registration_class: '',
        provider_name: '',
        plate_number: '',
        cost: '',
        description: '',
        enter_state: '',
        owning_method: '',
    });
    const [datosVehiculo, setDatosVehiculo] = useState({
        traction: '',
        brand_id: '',
        model_id: '',
        power_id: '',
        engine_brand_id: '',
        engine_fuel: '',
        oil_tank: '',
        transmission: '',
        engine_name: '',
        chasis: '',
        color: '',
        type: '',
        doors: '',
        passengers: '',
        capacity: '',
    });

    const [datosImpuesto, setDatosImpuesto] = useState({
        tax_date: new Date(),
        tax_form_number: '',
        tax_value: '',
        soat_expedition_date: new Date(),
        soat_due_date: new Date(),
        tm_expedition_date: new Date(),
        tm_due_date: new Date(),
    });

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleStep = (step) => () => {
        if (completed.has(step)) {
            setActiveStep(step);
        }
    }

    const handleComplete = () => {
        const newCompleted = new Set(completed);
        newCompleted.add(activeStep);
        setCompleted(newCompleted);
        handleNext();
    }

    const isStepCompleted = (index) => {
        return completed.has(index);
    }

    useEffect(() => {
        if (createVehicleResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createVehicleResponse.message,
            severity: 'success',
        }));

        props.history.push(MotorUrls.Listado);
    }, [dispatch, createVehicleResponse.message, props.history]);

    useEffect(() => {
        if (createVehicleResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createVehicleResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, createVehicleResponse.error])

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Añadir vehículo</Box>
            </Typography>
            <div className={classes.formSteps}>
                <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                    <Step key="datos-ingreso">
                        <StepButton onClick={handleStep(0)} completed={isStepCompleted(0)} className={classes.stepButton}>Datos de ingreso</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <Formik
                                    validationSchema={DatosIngresoErrorSchema}
                                    initialValues={datosIngreso} 
                                    component={DatosIngresoForm} 
                                    onSubmit={(values) => {
                                        setDatosIngreso(values);
                                        handleComplete();
                                    }}
                                />
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="datos-vehiculos">
                        <StepButton onClick={handleStep(1)} completed={isStepCompleted(1)} className={classes.stepButton}>Datos de vehículo</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <Formik
                                    validationSchema={DatosVehiculoErrorSchema}
                                    component={DatosVehiculoForm}
                                    initialValues={datosVehiculo}
                                    onSubmit={(values) => {
                                        setDatosVehiculo(values);
                                        handleComplete();
                                    }}
                                />
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="datos-impuesto">
                        <StepButton onClick={handleStep(2)} completed={isStepCompleted(2)} className={classes.stepButton}>Datos de Impuesto, Seguro y Revisiones</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <Formik
                                    validationSchema={DatosImpuestoErrorSchema}
                                    component={DatosImpuestoForm}
                                    initialValues={datosImpuesto}
                                    onSubmit={async (datosImpuestoValues) => {
                                        setDatosImpuesto(datosImpuestoValues);
                                        const newVehicleData = {
                                            ...datosIngreso,
                                            ...datosVehiculo,
                                            ...datosImpuestoValues,
                                        };

                                        await createVehicleService(newVehicleData);
                                    }}
                                />
                            </div>
                        </StepContent>
                    </Step>
                </Stepper>
            </div>
        </div>
    );
};

export default AddVehicle;