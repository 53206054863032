import httpClient from '../http';
import qs from 'qs';

export const getLevels = async (page, page_limit, filters) => {
    const filterParams = {
        page,
        page_limit,
        ...filters
    };
    const queryParams = qs.stringify(filterParams);
    return await httpClient.get(`/levels?${queryParams}`);
}

export const getAllLevels = async () => {
    return await httpClient.get('/levels/all');
}

export const getLevelById = async (level_id) => {
    return await httpClient.get(`/levels/${level_id}`);
}

export const createLevel = async (level) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedLevel = qs.stringify(level);
    return await httpClient.post('/levels', serializedLevel, config);
}

export const updateLevel = async(level_id, level_data) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedLevel = qs.stringify(level_data);
    return await httpClient.put(`/levels/${level_id}`, serializedLevel, config);
}

export const deleteLevel = async (level_id) => {
    return await httpClient.delete(`/levels/${level_id}`);
}