import React from 'react';

import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import { Form, Field, ErrorMessage } from 'formik';

import { report_types, report_modules } from '../../../../../../constants/MT/reports';
import { requireEquipment, requireVehicle } from './helpers';

import useVehicles from '../../../../../../hooks/MT/useVehicles';
import useEquipments from '../../../../../../hooks/MT/useEquipments';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const TYPES = report_types[report_modules.details];

const DetailsReportForm = (props) => {
    const classes = useStyles();

    // El 1000 es porque necesitamos traernos todos los vehículos/equipos.
    // Está así mientras se crea un endpoint aparte que retorne todos los vehículos/equipos.
    const vehicles = useVehicles(1, 1000);
    const equipments = useEquipments(1, 1000);

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    };

    return (
        <Form>
            <Grid container direction="column" spacing={2} justify="flex-start" alignItems="flex-start">
                <Grid item className={classes.row}>
                    <Grid container direction="row" spacing={2} justify="flex-start" alignItems="flex-start">
                        <Grid item xs={12} md={6}>
                            <FormControl className={classes.formControl} variant="outlined" error={hasError('type')}>
                                <InputLabel>Tipo de reporte</InputLabel>
                                <Field 
                                    name="type" 
                                    type="text"
                                    as={Select} 
                                    label="Tipo de reporte"
                                    className={classes.formControl} 
                                >
                                    {Object.entries(TYPES).map(([report_type, report_name], key) => (
                                        <MenuItem key={`report-type-key-${key}`} value={report_type}>{report_name}</MenuItem>
                                    ))}
                                </Field>
                                <FormHelperText>
                                    <ErrorMessage name="type" />
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            { requireVehicle(props.values.type) && (
                                <FormControl className={classes.formControl} variant="outlined" error={hasError('vehicle_id')}>
                                    <InputLabel>Vehículo</InputLabel>
                                    <Field 
                                        name="vehicle_id" 
                                        type="text"
                                        as={Select} 
                                        label="Vehículo"
                                        className={classes.formControl} 
                                    >
                                        {vehicles.map((vehicle, key) => (
                                            <MenuItem key={`vehicle-key-${key}`} value={vehicle.id}>{vehicle.code}</MenuItem>
                                        ))}
                                    </Field>
                                    <FormHelperText>
                                        <ErrorMessage name="vehicle_id" />
                                    </FormHelperText>
                                </FormControl>
                            )}
                            { requireEquipment(props.values.type) && (
                                <FormControl className={classes.formControl} variant="outlined" error={hasError('equipment_id')}>
                                    <InputLabel>Equipo/Herramienta</InputLabel>
                                    <Field 
                                        name="equipment_id" 
                                        type="text"
                                        as={Select} 
                                        label="Equipo/Herramienta"
                                        className={classes.formControl} 
                                    >
                                        {equipments.map((equipment, key) => (
                                            <MenuItem key={`equipment-key-${key}`} value={equipment.id}>{equipment.code}</MenuItem>
                                        ))}
                                    </Field>
                                    <FormHelperText>
                                        <ErrorMessage name="equipment_id" />
                                    </FormHelperText>
                                </FormControl>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Button
                        className={classes.downloadButton}
                        color="primary"
                        size="large"
                        type="submit"
                        variant="contained"
                        disableElevation
                    >
                        Consultar
                    </Button>
                </Grid>
            </Grid>
        </Form>
    );
}

export default DetailsReportForm;