import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MuiPhoneNumber from 'material-ui-phone-number';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import useDepartments from '../../../../../hooks/useDepartments';
import useCities from '../../../../../hooks/useCities';
import useAllNeighborhoods from '../../../../../hooks/useAllNeighborhoods';

import { Form, Field, ErrorMessage } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const UbicacionForm = (props) => {
    const classes = useStyles();
    const departments = useDepartments();
    const cities = useCities();
    const neighborhoods = useAllNeighborhoods();

    const { readMode } = props;

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    };

    const hasProcedureError = (index, name) => {
        const procedures = props.errors.location_procedures;
        if (procedures === undefined || procedures[index] === undefined) {
            return false;
        }

        const touched = props.touched.location_procedures;
        if (touched === undefined || touched[index] === undefined) {
            return false;
        }

        return procedures[index][name] && touched[index][name] ? true : false;
    }

    const handleAreaButtonClicked = (value) => () => {
        props.setFieldValue('location_area', value);
    }

    const onAddProcedure = () => {
        const procedures = props.values.location_procedures;
        procedures.push({
            procedure: '',
        });
        props.setFieldValue('location_procedures', procedures);
    }

    const onDeleteProcedure = (index) => () => {
        const procedures = props.values.location_procedures;
        procedures.splice(index, 1);
        props.setFieldValue('location_procedures', procedures);
    }

    return (
        <div className={classes.root}>
            <Form>
                <Grid container direction="row" spacing={1} justify="flex-start" alignItems="flex-start" className={classes.row}>
                    <Grid item xs={12} md={6}>
                        <Grid container direction="column" spacing={1} justify="flex-start" alignItems="flex-start">
                            <Grid item className={classes.fullWidth}>
                                <FormControl disabled={readMode} className={classes.formControl} variant="outlined" error={hasError('department_id')}>
                                    <InputLabel>Departamento</InputLabel>
                                    <Field 
                                        name="department_id" 
                                        type="text"
                                        as={Select} 
                                        label="Departamento"
                                        className={classes.formControl} 
                                    >
                                        {departments.map((department, key) => (
                                            <MenuItem key={`department-key-${key}`} value={department.id}>{`${department.name}`}</MenuItem>
                                        ))}
                                    </Field>
                                    <FormHelperText>
                                        <ErrorMessage name="department_id" />
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <FormControl disabled={readMode} className={classes.formControl} variant="outlined" error={hasError('city_id')}>
                                    <InputLabel>Municipio</InputLabel>
                                    <Field 
                                        name="city_id" 
                                        type="text"
                                        as={Select} 
                                        label="Municipio"
                                        className={classes.formControl} 
                                    >
                                        {cities.filter(city => city.department_id === props.values.department_id).map((city, key) => (
                                            <MenuItem key={`city-key-${key}`} value={city.id}>{`${city.name}`}</MenuItem>
                                        ))}
                                    </Field>
                                    <FormHelperText>
                                        <ErrorMessage name="city_id" />
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <ButtonGroup disabled={readMode} style={{height: '50px'}} fullWidth color="primary" aria-label="type-button-group">
                                    <Button onClick={handleAreaButtonClicked("Rural")} variant={props.values.location_area === "Rural" ? "contained": "outlined"}>Rural</Button>
                                    <Button onClick={handleAreaButtonClicked("Urbana")} variant={props.values.location_area === "Urbana" ? "contained": "outlined"}>Urbana</Button>
                                </ButtonGroup>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <FormControl disabled={readMode} className={classes.formControl} variant="outlined" error={hasError('neighborhood_id')}>
                                    <InputLabel>Barrio</InputLabel>
                                    <Field 
                                        name="neighborhood_id" 
                                        type="text"
                                        as={Select} 
                                        label="Barrio"
                                        className={classes.formControl} 
                                    >
                                        {neighborhoods.filter(neighborhood => neighborhood.city_id === props.values.city_id).map((neighborhood, key) => (
                                            <MenuItem key={`neighborhood-key-${key}`} value={neighborhood.id}>{`${neighborhood.name}`}</MenuItem>
                                        ))}
                                    </Field>
                                    <FormHelperText>
                                        <ErrorMessage name="neighborhood_id" />
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Field 
                                    name="location_address" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Dirección"
                                    className={classes.formControl} 
                                    error={hasError('location_address')}
                                    helperText={<ErrorMessage name="location_address" />}
                                    disabled={readMode}
                                />
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Field 
                                    name="location_phone" 
                                    type="text" 
                                    as={MuiPhoneNumber} 
                                    variant="outlined" 
                                    label="Teléfono"
                                    className={classes.formControl} 
                                    error={hasError('location_phone')}
                                    helperText={<ErrorMessage name="location_phone" />}
                                    onChange={(value) => props.setFieldValue('location_phone', value)}
                                    disabled={readMode}
                                />
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Field 
                                    name="location_observations" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Observaciones"
                                    className={classes.formControl} 
                                    error={hasError('location_observations')}
                                    helperText={<ErrorMessage name="location_observations" />}
                                    multiline
                                    rows={6}
                                    disabled={readMode}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container direction="column" spacing={1} justify="flex-start" alignItems="flex-start">
                            {props.values.location_procedures.map((procedure, index) => (
                                <Grid item className={classes.fullWidth} key={`procedure-${index}`}>
                                    {props.values.location_procedures.length > 1 && (
                                        <IconButton disabled={readMode} aria-label="delete-procedure-icon" color="primary" className={classes.deleteProcedureContainer} onClick={onDeleteProcedure(index)}>
                                            <DeleteIcon className={classes.deleteProcedureIcon} />
                                        </IconButton>
                                    )}
                                    <Field 
                                        name={`location_procedures[${index}].procedure`}
                                        type="text" 
                                        as={TextField} 
                                        variant="outlined" 
                                        label={`Procedimiento ${index + 1}`}
                                        className={classes.formControl} 
                                        error={hasProcedureError(index, 'procedure')}
                                        helperText={<ErrorMessage name={`location_procedures[${index}].procedure`} />}
                                        multiline
                                        rows={6}
                                        disabled={readMode}
                                    />
                                </Grid>
                            ))}
                            {props.values.location_procedures.length < 4 && (
                                <Grid item className={classes.fullWidth}>
                                    <Grid container direction="row" justify="center" alignItems="center">
                                        <Grid item>
                                            <Typography component={"span"}>
                                                <Box fontWeight="fontWeightLight" fontSize={12} className={classes.addProcedureText}>Añadir procedimiento</Box>
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <IconButton disabled={readMode} aria-label="add-procedure-icon" color="primary" className={classes.addProcedureContainer} onClick={onAddProcedure}>
                                                <AddCircleRoundedIcon className={classes.addProcedureIcon} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Button
                    className={classes.saveButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disableElevation
                    disabled={readMode}
                >
                    Siguiente
                </Button>
            </Form>
        </div>
    );
}

UbicacionForm.defaultProps = {
    readMode: false,
};

export default UbicacionForm;