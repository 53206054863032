export const REPORT_TYPES = {
    emergencies_list: 'emergencies_list',
    st_list: 'st_list',
    emergencies_by_type_and_neighborhood: 'emergencies_by_type_and_neighborhood',
    emergency_attendance: 'emergency_attendance',
    firefighter_record: 'firefighter_record',
    emergency_statistics: 'emergency_statistics',
    emergencies_by_type: 'emergencies_by_type',
};

export const REPORT_TYPES_FULLNAMES = {
    [REPORT_TYPES.emergencies_list]: 'Listado de Emergencias',
    [REPORT_TYPES.st_list]: 'Resumen de Siniestralidad',
    [REPORT_TYPES.emergencies_by_type_and_neighborhood]: 'Consulta tipo de Emergencia y Barrio',
    [REPORT_TYPES.emergency_attendance]: 'Informe de atención de Emergencia',
    [REPORT_TYPES.firefighter_record]: 'Registro de Emergencias de Bombero',
    [REPORT_TYPES.emergency_statistics]: 'Informe estadístico de Emergencias',
    [REPORT_TYPES.emergencies_by_type]: 'Consulta de Emergencias por Tipo',
};