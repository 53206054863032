import { createSlice } from '@reduxjs/toolkit';

export const alertsSlice = createSlice({
    name: 'alerts',
    initialState: {
        show: false,
        message: '',
        severity: '',
        duration: 0,
    },
    reducers: {
        setGlobalAlert: (state, action) => {
            state.show = action.payload.show;
            state.message = action.payload.message;
            state.severity = action.payload.severity;
            state.duration = action.payload.duration;
        },
        hideGlobalAlert: (state) => {
            state.show = false;
        },
    },
});

export const {
    setGlobalAlert,
    hideGlobalAlert,
} = alertsSlice.actions;

export const selectAlerts = (state) => state.alerts;

export default alertsSlice.reducer;