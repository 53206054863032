export const report_modules = {
    availabilities: 'Disponibilidad',
    details: 'Hoja de vida',
    records: 'Reportes',
    costs: 'Costos',
    operators: 'Maquinistas',
};

export const report_urls = {
    [report_modules.availabilities]: '/reports/mt/availabilities',
    [report_modules.details]: '/reports/mt/details',
    [report_modules.records]: '/reports/mt/records',
    [report_modules.costs]: '/reports/mt/costs',
    [report_modules.operators]: '/reports/mt/operators',
};

export const report_types = {
    [report_modules.availabilities]: {
        vehicle_availability: 'Disponibilidad de Vehículo',
        availability_summary: 'Resumen de Disponinilidad',
        availability_summary_vehicle: 'Resumen de Disponibilidad (Vehículo)',
    },

    [report_modules.details]: {
        vehicle_details: 'Vehículo',
        equipment_details: 'Equipo/Herramienta',
    },

    [report_modules.records]: {
        damage_report_vehicle: 'Reporte de daño (Vehículo)',
        damage_report_equipment: 'Reporte de daño (Equipo)',
        maintenance_vehicle: 'Mantenimiento (Vehículo)',
        maintenance_equipment: 'Mantenimiento (Equipo)',
    },

    [report_modules.costs]: {
        total_costs: 'Costos totales',

        // Mantenimiento
        general_maintenance: 'Mantenimiento general',
        general_maintenance_equipment: 'Mantenimiento general (Equipos)',
        general_maintenance_vehicle: 'Mantenimiento general (Vehículos)',
        maintenance_by_equipment: 'Mantenimiento (Equipo)',
        maintenance_by_vehicle: 'Mantenimiento (Vehículo)',

        // Incidentes
        general_damage_report: 'Incidentes general',
        general_damage_report_equipment: 'Incidentes general (Equipo)',
        general_damage_report_vehicle: 'Incidentes general (Vehículo)',
        damage_report_by_equipment: 'Incidente (Equipo)',
        damage_report_by_vehicle: 'Incidente (Vehículo)',
    },

    [report_modules.operators]: {
        general_report: 'Reporte general',
        operator_report: 'Informe de Maquinista',
    },
};