import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';

import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';
import { createArea } from '../../../../services/areasService';

import { datosGlobalesUrls } from '../../routing';

import useAllMinwages from '../../../../hooks/useAllMinwages';
import useAllLevels from '../../../../hooks/useAllLevels';

import validate from 'validate.js';
import schema from './schema';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import currency from 'currency.js';

const useStyles = makeStyles(styles);

const AddLevel = (props) => {
    const { minWages } = useAllMinwages();
    const { levels } = useAllLevels();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { 
        backendResponse: createAreaResponse,
        executeService: createAreaService,
    } = useBackendMessageResponse(createArea);
    const [newLevelForm, setNewLevelForm] = useState({
        isSubmitted: false,
        isValid: false,
        values: {
            minwage_id: null,
            level: null,

            area_start_1: '',
            area_end_1: '',
            impact_1: '',

            area_start_2: '',
            area_end_2: '',
            impact_2: '',

            area_start_3: '',
            area_end_3: '',
            impact_3: '',
            
            area_start_4: '',
            area_end_4: '',
            impact_4: '',

            area_start_5: '',
            area_end_5: '',
            impact_5: '',

            area_start_6: '',
            area_end_6: '',
            impact_6: '',
        },
        touched: {
            minwage_id: false,
            level: false,

            area_start_1: false,
            area_end_1: false,
            impact_1: false,

            area_start_2: false,
            area_end_2: false,
            impact_2: false,

            area_start_3: false,
            area_end_3: false,
            impact_3: false,

            area_start_4: false,
            area_end_4: false,
            impact_4: false,

            area_start_5: false,
            area_end_5: false,
            impact_5: false,

            area_start_6: false,
            area_end_6: false,
            impact_6: false,
        },
        errors: {},
    });
    
    // Los values se colocan aparte porque son calculados de forma automática
    // cuando las áreas y el resto de los datos son colocados.
    // Por lo que para evitar un infinite loop se tienen que colocar aparte.
    const [computedValuesForm, setComputedValuesForm] = useState({
        value_1: '',
        value_2: '',
        value_3: '',
        value_4: '',
        value_5: '',
        value_6: '',
    });

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if(!newLevelForm.isSubmitted) {
            setNewLevelForm(currentLevelForm => ({
                ...currentLevelForm,
                isSubmitted: true,
            }));
        }

        if(!newLevelForm.isValid) {
            return;
        }

        const areasData = [{
            area_start: newLevelForm.values.area_start_1,
            area_end: newLevelForm.values.area_end_1,
            impact: newLevelForm.values.impact_1,
            level_id: newLevelForm.values.level,
            minwage_id: newLevelForm.values.minwage_id,
        }, {
            area_start: newLevelForm.values.area_start_2,
            area_end: newLevelForm.values.area_end_2,
            impact: newLevelForm.values.impact_2,
            level_id: newLevelForm.values.level,
            minwage_id: newLevelForm.values.minwage_id,
        }, {
            area_start: newLevelForm.values.area_start_3,
            area_end: newLevelForm.values.area_end_3,
            impact: newLevelForm.values.impact_3,
            level_id: newLevelForm.values.level,
            minwage_id: newLevelForm.values.minwage_id,
        }, {
            area_start: newLevelForm.values.area_start_4,
            area_end: newLevelForm.values.area_end_4,
            impact: newLevelForm.values.impact_4,
            level_id: newLevelForm.values.level,
            minwage_id: newLevelForm.values.minwage_id,
        }, {
            area_start: newLevelForm.values.area_start_5,
            area_end: newLevelForm.values.area_end_5,
            impact: newLevelForm.values.impact_5,
            level_id: newLevelForm.values.level,
            minwage_id: newLevelForm.values.minwage_id,
        }, {
            area_start: newLevelForm.values.area_start_6,
            area_end: newLevelForm.values.area_end_6,
            impact: newLevelForm.values.impact_6,
            level_id: newLevelForm.values.level,
            minwage_id: newLevelForm.values.minwage_id,
        }];
        const createAreaServiceArray = areasData.map(area => createAreaService(area));
        try {
            await Promise.all([...createAreaServiceArray]);
            dispatch(setGlobalAlert({
                show: true,
                duration: 5000,
                message: 'Áreas creadas en nivel con éxito.',
                severity: 'success',
            }));
            const { history } = props;
            history.push(datosGlobalesUrls.Listado);
        } catch(e) {
            console.log(e);
        }
    }

    const handleChange = event => {
        event.persist();

        setNewLevelForm(levelForm => ({
            ...levelForm,
            values: {
                ...levelForm.values,
                [event.target.name]: event.target.value,
            },
            touched: {
                ...levelForm.touched,
                [event.target.name]: true
            },
        }));
    };

    const formatComputedValue = (amount) => {
        return currency(amount).format();
    }

    const getAmountFromMinwageid = (id) => {
        if (minWages.length === 0 || id === null) {
            return '';
        }
        const minwage = minWages.find(item => item.id === id);
        return currency(minwage.amount).format();
    }

    const getLevelIndexFromLevelId = (id) => {
        if (levels.length === 0 || id === null) {
            return '';
        }
        const level = levels.find(item => item.id === id);
        const levelValueInPercentage = level.index_value * 100;
        return `${levelValueInPercentage}%`;
    }

    const computeLevelValue = (minwage, smlvPercentage, impact) => {
        return minwage * smlvPercentage * impact;
    }

    const hasError = field =>
        (newLevelForm.isSubmitted || newLevelForm.touched[field]) && newLevelForm.errors[field] ? true : false;

    useEffect(() => {
        const minwageId = newLevelForm.values.minwage_id;
        const level = newLevelForm.values.level;
        if (minwageId === null || level === null) {
            return;
        }

        const salaryAmount = minWages
            .find(item => item.id === minwageId)
            .amount;
        const levelIndexPercentage = levels
            .find(item => item.id === level)
            .index_value;

        if (newLevelForm.values.area_start_1 !== '' && newLevelForm.values.area_end_1 !== '' && newLevelForm.values.impact_1 !== '') {
            setComputedValuesForm(form => ({
                ...form,
                value_1: computeLevelValue(salaryAmount, levelIndexPercentage, parseFloat(newLevelForm.values.impact_1)),
            }));
        }
        if (newLevelForm.values.area_start_2 !== '' && newLevelForm.values.area_end_2 !== '' && newLevelForm.values.impact_2 !== '') {
            setComputedValuesForm(form => ({
                ...form,
                value_2: computeLevelValue(salaryAmount, levelIndexPercentage, parseFloat(newLevelForm.values.impact_2)),
            }));
        }
        if (newLevelForm.values.area_start_3 !== '' && newLevelForm.values.area_end_3 !== '' && newLevelForm.values.impact_3 !== '') {
            setComputedValuesForm(form => ({
                ...form,
                value_3: computeLevelValue(salaryAmount, levelIndexPercentage, parseFloat(newLevelForm.values.impact_3)),
            }));
        }
        if (newLevelForm.values.area_start_4 !== '' && newLevelForm.values.area_end_4 !== '' && newLevelForm.values.impact_4 !== '') {
            setComputedValuesForm(form => ({
                ...form,
                value_4: computeLevelValue(salaryAmount, levelIndexPercentage, parseFloat(newLevelForm.values.impact_4)),
            }));
        }
        if (newLevelForm.values.area_start_5 !== '' && newLevelForm.values.area_end_5 !== '' && newLevelForm.values.impact_5 !== '') {
            setComputedValuesForm(form => ({
                ...form,
                value_5: computeLevelValue(salaryAmount, levelIndexPercentage, parseFloat(newLevelForm.values.impact_5)),
            }));
        }
        if (newLevelForm.values.area_start_6 !== '' && newLevelForm.values.area_end_6 !== '' && newLevelForm.values.impact_6 !== '') {
            setComputedValuesForm(form => ({
                ...form,
                value_6: computeLevelValue(salaryAmount, levelIndexPercentage, parseFloat(newLevelForm.values.impact_6)),
            }));
        }
    }, [newLevelForm.values, minWages, levels]);

    useEffect(() => {
        const errors = validate(newLevelForm.values, schema);

        setNewLevelForm(form => ({
            ...form,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [newLevelForm.values, newLevelForm.isSubmitted]);

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Añadir nivel</Box>
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} className={classes.row}>
                    <Grid item md={3} xs={12}>
                        <FormControl variant="outlined" className={classes.formControl} error={hasError('minwage_id')}>
                            <InputLabel id="level-year-select">Año</InputLabel>
                            <Select
                                labelId="level-year-select"
                                id="level-year-select-outlined"
                                label="Año"
                                name="minwage_id"
                                value={newLevelForm.values.minwage_id}
                                onChange={handleChange}
                            >
                                {minWages.map((item, key) => (
                                    <MenuItem key={`minwage-new-level-key-${key}`} value={item.id}>
                                        {item.year}
                                    </MenuItem>
                                ))}
                            </Select>
                            {hasError('minwage_id') && 
                                <FormHelperText id="level-year-id-error-text">
                                    {newLevelForm.errors.minwage_id[0]}
                                </FormHelperText> 
                            }
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <FormControl variant="outlined" className={classes.formControl} error={hasError('level')}>
                            <InputLabel id="level-select">Nivel</InputLabel>
                            <Select
                                labelId="level-select"
                                id="level-select-outlined"
                                label="Nivel"
                                name="level"
                                value={newLevelForm.values.level}
                                onChange={handleChange}
                            >
                                {levels.map((level, key) => (
                                    <MenuItem value={level.id} key={`new-level-key-key-${key}`}>
                                        {level.level}
                                    </MenuItem>
                                ))}
                            </Select>
                            {hasError('level') && 
                                <FormHelperText id="level-id-error-text">
                                    {newLevelForm.errors.level[0]}
                                </FormHelperText> 
                            }
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} className={classes.row}>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="minwage-input" 
                            label="Salario" 
                            variant="outlined" 
                            className={classes.formControl}
                            value={getAmountFromMinwageid(newLevelForm.values.minwage_id)}
                            disabled
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="level-index-input" 
                            label="Indice de nivel" 
                            variant="outlined" 
                            className={classes.formControl}
                            value={getLevelIndexFromLevelId(newLevelForm.values.level)}
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} className={classes.row}>
                    <Grid item md={3} xs={12}>
                        <Typography component={"span"}>
                            <Box fontWeight="fontWeightBold" fontSize={16} className={classes.formControl}>Área desde (mt2)</Box>
                        </Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Typography component={"span"}>
                            <Box fontWeight="fontWeightBold" fontSize={16} className={classes.formControl}>Área hasta (mt2)</Box>
                        </Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Typography component={"span"}>
                            <Box fontWeight="fontWeightBold" fontSize={16} className={classes.formControl}>Impacto</Box>
                        </Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Typography component={"span"}>
                            <Box fontWeight="fontWeightBold" fontSize={16} className={classes.formControl}>Valor</Box>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} className={classes.row}>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-1-from-input" 
                            label="Área 1" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="area_start_1"
                            value={newLevelForm.values.area_start_1}
                            onChange={handleChange}
                            type="number"
                            error={hasError('area_start_1')}
                            helperText={
                                hasError('area_start_1') ? newLevelForm.errors.area_start_1[0] : null
                            }
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-1-to-input" 
                            label="Área 1" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="area_end_1"
                            value={newLevelForm.values.area_end_1}
                            onChange={handleChange}
                            type="number"
                            error={hasError('area_end_1')}
                            helperText={
                                hasError('area_end_1') ? newLevelForm.errors.area_end_1[0] : null
                            }
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-1-impact-input" 
                            label="Impacto 1" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="impact_1"
                            value={newLevelForm.values.impact_1}
                            onChange={handleChange}
                            type="number"
                            error={hasError('impact_1')}
                            helperText={
                                hasError('impact_1') ? newLevelForm.errors.impact_1[0] : null
                            }
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-1-value-input" 
                            label="Valor 1" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="value_1"
                            value={formatComputedValue(computedValuesForm.value_1)}
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} className={classes.row}>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-2-from-input" 
                            label="Área 2" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="area_start_2"
                            value={newLevelForm.values.area_start_2}
                            onChange={handleChange}
                            type="number"
                            error={hasError('area_start_2')}
                            helperText={
                                hasError('area_start_2') ? newLevelForm.errors.area_start_2[0] : null
                            }
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-2-to-input" 
                            label="Área 2" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="area_end_2"
                            value={newLevelForm.values.area_end_2}
                            onChange={handleChange}
                            type="number"
                            error={hasError('area_end_2')}
                            helperText={
                                hasError('area_end_2') ? newLevelForm.errors.area_end_2[0] : null
                            }
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-2-impact-input" 
                            label="Impacto 2" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="impact_2"
                            value={newLevelForm.values.impact_2}
                            onChange={handleChange}
                            type="number"
                            error={hasError('impact_2')}
                            helperText={
                                hasError('impact_2') ? newLevelForm.errors.impact_2[0] : null
                            }
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-2-value-input" 
                            label="Valor 2" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="value_2"
                            value={formatComputedValue(computedValuesForm.value_2)}
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} className={classes.row}>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-3-from-input" 
                            label="Área 3" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="area_start_3"
                            value={newLevelForm.values.area_start_3}
                            onChange={handleChange}
                            type="number"
                            error={hasError('area_start_3')}
                            helperText={
                                hasError('area_start_3') ? newLevelForm.errors.area_start_3[0] : null
                            }
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-3-to-input" 
                            label="Área 3" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="area_end_3"
                            value={newLevelForm.values.area_end_3}
                            onChange={handleChange}
                            type="number"
                            error={hasError('area_end_3')}
                            helperText={
                                hasError('area_end_3') ? newLevelForm.errors.area_end_3[0] : null
                            }
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-3-impact-input" 
                            label="Impacto 3" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="impact_3"
                            value={newLevelForm.values.impact_3}
                            onChange={handleChange}
                            type="number"
                            error={hasError('impact_3')}
                            helperText={
                                hasError('impact_3') ? newLevelForm.errors.impact_3[0] : null
                            }
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-3-value-input" 
                            label="Valor 3" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="value_3"
                            value={formatComputedValue(computedValuesForm.value_3)}
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} className={classes.row}>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-4-from-input" 
                            label="Área 4" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="area_start_4"
                            value={newLevelForm.values.area_start_4}
                            onChange={handleChange}
                            type="number"
                            error={hasError('area_start_4')}
                            helperText={
                                hasError('area_start_4') ? newLevelForm.errors.area_start_4[0] : null
                            }
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-4-to-input" 
                            label="Área 4" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="area_end_4"
                            value={newLevelForm.values.area_end_4}
                            onChange={handleChange}
                            type="number"
                            error={hasError('area_end_4')}
                            helperText={
                                hasError('area_end_4') ? newLevelForm.errors.area_end_4[0] : null
                            }
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-4-impact-input" 
                            label="Impacto 4" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="impact_4"
                            value={newLevelForm.values.impact_4}
                            onChange={handleChange}
                            type="number"
                            error={hasError('impact_4')}
                            helperText={
                                hasError('impact_4') ? newLevelForm.errors.impact_4[0] : null
                            }
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-4-value-input" 
                            label="Valor 4" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="value_4"
                            value={formatComputedValue(computedValuesForm.value_4)}
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} className={classes.row}>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-5-from-input" 
                            label="Área 5" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="area_start_5"
                            value={newLevelForm.values.area_start_5}
                            onChange={handleChange}
                            type="number"
                            error={hasError('area_start_5')}
                            helperText={
                                hasError('area_start_5') ? newLevelForm.errors.area_start_5[0] : null
                            }
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-5-to-input" 
                            label="Área 5" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="area_end_5"
                            value={newLevelForm.values.area_end_5}
                            onChange={handleChange}
                            type="number"
                            error={hasError('area_end_5')}
                            helperText={
                                hasError('area_end_5') ? newLevelForm.errors.area_end_5[0] : null
                            }
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-5-impact-input" 
                            label="Impacto 5" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="impact_5"
                            value={newLevelForm.values.impact_5}
                            onChange={handleChange}
                            type="number"
                            error={hasError('impact_5')}
                            helperText={
                                hasError('impact_5') ? newLevelForm.errors.impact_5[0] : null
                            }
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-5-value-input" 
                            label="Valor 5" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="value_5"
                            value={formatComputedValue(computedValuesForm.value_5)}
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} className={classes.row}>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-6-from-input" 
                            label="Área 6" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="area_start_6"
                            value={newLevelForm.values.area_start_6}
                            onChange={handleChange}
                            type="number"
                            error={hasError('area_start_6')}
                            helperText={
                                hasError('area_start_6') ? newLevelForm.errors.area_start_6[0] : null
                            }
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-6-to-input" 
                            label="Área 6" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="area_end_6"
                            value={newLevelForm.values.area_end_6}
                            onChange={handleChange}
                            type="number"
                            error={hasError('area_end_6')}
                            helperText={
                                hasError('area_end_6') ? newLevelForm.errors.area_end_6[0] : null
                            }
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-6-impact-input" 
                            label="Impacto 6" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="impact_6"
                            value={newLevelForm.values.impact_6}
                            onChange={handleChange}
                            type="number"
                            error={hasError('impact_6')}
                            helperText={
                                hasError('impact_6') ? newLevelForm.errors.impact_6[0] : null
                            }
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField 
                            id="area-6-value-input" 
                            label="Valor 6" 
                            variant="outlined" 
                            className={classes.formControl}
                            name="value_6"
                            value={formatComputedValue(computedValuesForm.value_6)}
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} className={classes.row}>
                    <Grid item md={3} xs={12}>
                        <Button
                            className={classes.saveButton}
                            color="primary"
                            size="large"
                            type="submit"
                            variant="contained"
                            disableElevation
                            disabled={createAreaResponse.isLoading}
                        >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
};

export default AddLevel;