import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';
import { createEvent } from '../../../../services/eventsService';

import { Formik } from 'formik';
import { DatosEventForm } from '../../Forms/DatosEventForm';
import schema from '../../Forms/DatosEventForm/schema';

import { EventsUrls } from '../../routing';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const AddEvent = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [eventData, setEventData] = useState({
        name: '',
        motif: '',
        date_end: new Date(),
        date: new Date(),
    });
    const {
        backendResponse: createEventResponse,
        executeService: createEventService,
    } = useBackendMessageResponse(createEvent);

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    const handleSubmit = async (values) => {
        setEventData(values);
        await createEventService(values);
    }

    useEffect(() => {
        if (createEventResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createEventResponse.message,
            severity: 'success',
        }));

        props.history.push(EventsUrls.Listado);
    }, [dispatch, createEventResponse.message, props.history]);

    useEffect(() => {
        if (createEventResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createEventResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, createEventResponse.error])

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Añadir Evento</Box>
            </Typography>
            <div className={classes.form}>
                <Formik
                    validationSchema={schema}
                    initialValues={eventData}
                    component={DatosEventForm}
                    onSubmit={handleSubmit}
                />
            </div>
        </div>
    );
}

export default AddEvent;