import { useState, useEffect } from 'react';
import { getEquipments } from '../../services/equipmentsService';

const useEquipments = (page, page_limit) => {
    const [equipments, setEquipments] = useState([]);

    useEffect(() => {
        async function fetchEquipments() {
            try {
                const { data: { results } } = await getEquipments(page, page_limit);
                setEquipments(results);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchEquipments();
    }, [page, page_limit]);

    return equipments;
}

export default useEquipments;