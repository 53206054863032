import httpClient from '../../http';
import qs from 'qs';

export const getAvailabilities = async (page, page_limit, filters) => {
    const filterParams = {
        page,
        page_limit,
        ...filters
    };
    const queryParams = qs.stringify(filterParams);
    return await httpClient.get(`/mt/availabilities?${queryParams}`);
}

export const getAvailabilityById = async (availability_id) => {
    return await httpClient.get(`/mt/availabilities/${availability_id}`);
}

export const deleteAvailability = async (availability_id) => {
    return await httpClient.delete(`/mt/availabilities/${availability_id}`);
}

export const createAvailability = async (availability) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedAvailability = qs.stringify(availability);
    return await httpClient.post('/mt/availabilities', serializedAvailability, config);
}

export const updateAvailability = async (availability_id, availability) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedAvailability = qs.stringify(availability);
    return await httpClient.put(`/mt/availabilities/${availability_id}`, serializedAvailability, config);
}