import { createSlice } from '@reduxjs/toolkit';

export const minWagesSlice = createSlice({
    name: 'minWages',
    initialState: {
        isLoading: false,
        minWages: [],
        total: null,
        pages: null,
        page: 1,
        page_limit: 10,
        error: {
            code: null,
            message: null,
        },
    },
    reducers: {
        getMinWagesRequest: (state) => {
            state.isLoading = true;
        },
        getMinWagesSuccess: (state, action) => {
            state.isLoading = false;
            state.error = {
                code: null,
                message: null,
            };
            state.minWages = action.payload.minWages;
            state.total = action.payload.total;
            state.pages = action.payload.pages;
            state.page = action.payload.page;
            state.page_limit = action.payload.page_limit;
        },
        getMinWagesFailure: (state, action)  => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
    },
});

export const { 
    getMinWagesRequest,
    getMinWagesSuccess,
    getMinWagesFailure,
} = minWagesSlice.actions;

export const selectMinWages = (state) => state.minWages;

export default minWagesSlice.reducer;