import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../../../store/slices/alertsSlice';
import { startLinearProgress, stopLinearProgress } from '../../../../../../store/slices/linearProgressSlice';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { Formik } from 'formik';

import { 
    AvailabilitiesReportForm,
    CostsReportForm,
    DetailsReportForm,
    OperatorsReportForm,
    RecordsReportForm,
} from '../../../Forms/Reports';

import useReportNameGenerator from '../../../../../../hooks/useReportNameGenerator';

import AvailabilitySchema from '../../../Forms/Reports/AvailabilitiesReportForm/schema';
import DetailsSchema from '../../../Forms/Reports/DetailsReportForm/schema';
import RecordsSchema from '../../../Forms/Reports/RecordsReportForm/schema';
import CostsSchema from '../../../Forms/Reports/CostsReportForm/schema';
import OperatorsSchema from '../../../Forms/Reports/OperatorsReportForm/schema';

import { report_modules, report_types } from '../../../../../../constants/MT/reports';
import { SUBMIT_MESSAGE } from '../../../../../../constants/reports';
import { downloadMTReport } from '../../../../../../services/MT/reportsService';
import FileDownload from 'js-file-download';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const Informes = (props) => {
    const classes = useStyles();
    const [selectedReport, setSelectedReport] = useState(Object.keys(report_modules)[0]);
    const dispatch = useDispatch();
    const generateName = useReportNameGenerator();

    return (
        <div className={classes.root}>
            <FormControl className={classes.formControl}>
                <Select
                    id="module-picker"
                    disableUnderline
                    label="Reporte MT"
                    value={selectedReport}
                    onChange={(event) => setSelectedReport(event.target.value)}
                    className={classes.selectInput}
                >
                    {Object.entries(report_modules).map(([module_key, module_name]) => (
                        <MenuItem value={module_key}>{module_name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className={classes.formSection}>
                {report_modules[selectedReport] === report_modules.availabilities && (
                    <Formik
                        component={AvailabilitiesReportForm}
                        initialValues={{
                            start_date: new Date(),
                            end_date: new Date(),
                            type: '',
                            vehicle_id: '',
                        }}
                        validationSchema={AvailabilitySchema}
                        onSubmit={async (values) => {
                            try {
                                dispatch(startLinearProgress());
                                dispatch(setGlobalAlert({
                                    show: true,
                                    duration: 5000,
                                    message: SUBMIT_MESSAGE,
                                    severity: 'info',
                                }));
                                const result = await downloadMTReport(selectedReport, values);
                                const reportNames = report_types[report_modules.availabilities];
                                const filename = generateName(reportNames, values.type);
                                FileDownload(result.data, filename);
                                dispatch(stopLinearProgress());
                            } catch(err) {
                                const { response } = err;
                                dispatch(setGlobalAlert({
                                    show: true,
                                    duration: 5000,
                                    message: response.data.message,
                                    severity: 'error',
                                }));
                                dispatch(stopLinearProgress());
                            }
                        }}
                    />
                )}
                {report_modules[selectedReport] === report_modules.costs && (
                    <Formik
                        component={CostsReportForm}
                        initialValues={{
                            type: Object.keys(report_types[report_modules.costs])[0],
                            start_date: new Date(),
                            end_date: new Date(),
                            vehicle_id: '',
                            equipment_id: '',
                        }}
                        validationSchema={CostsSchema}
                        onSubmit={async (values) => {
                            try {
                                dispatch(startLinearProgress());
                                dispatch(setGlobalAlert({
                                    show: true,
                                    duration: 5000,
                                    message: SUBMIT_MESSAGE,
                                    severity: 'info',
                                }));
                                const result = await downloadMTReport(selectedReport, values);
                                const reportNames = report_types[report_modules.costs];
                                const filename = generateName(reportNames, values.type);
                                FileDownload(result.data, filename);
                                dispatch(stopLinearProgress());
                            } catch(err) {
                                const { response } = err;
                                dispatch(setGlobalAlert({
                                    show: true,
                                    duration: 5000,
                                    message: response.data.message,
                                    severity: 'error',
                                }));
                                dispatch(stopLinearProgress());
                            }
                        }}
                    />
                )}
                {report_modules[selectedReport] === report_modules.details && (
                    <Formik
                        component={DetailsReportForm}
                        initialValues={{
                            type: Object.keys(report_types[report_modules.details])[0],
                            vehicle_id: '',
                            equipment_id: ''
                        }}
                        validationSchema={DetailsSchema}
                        onSubmit={async (values) => {
                            try {
                                dispatch(startLinearProgress());
                                dispatch(setGlobalAlert({
                                    show: true,
                                    duration: 5000,
                                    message: SUBMIT_MESSAGE,
                                    severity: 'info',
                                }));
                                const result = await downloadMTReport(selectedReport, values);
                                const reportNames = report_types[report_modules.details];
                                const filename = generateName(reportNames, values.type);
                                FileDownload(result.data, filename);
                                dispatch(stopLinearProgress());
                            } catch(err) {
                                const { response } = err;
                                dispatch(setGlobalAlert({
                                    show: true,
                                    duration: 5000,
                                    message: response.data.message,
                                    severity: 'error',
                                }));
                                dispatch(stopLinearProgress());
                            }
                        }}
                    />
                )}
                {report_modules[selectedReport] === report_modules.operators && (
                    <Formik
                        component={OperatorsReportForm}
                        initialValues={{
                            type: Object.keys(report_types[report_modules.operators])[0],
                            operator_id: '',
                        }}
                        validationSchema={OperatorsSchema}
                        onSubmit={async (values) => {
                            try {
                                dispatch(startLinearProgress());
                                dispatch(setGlobalAlert({
                                    show: true,
                                    duration: 5000,
                                    message: SUBMIT_MESSAGE,
                                    severity: 'info',
                                }));
                                const result = await downloadMTReport(selectedReport, values);
                                const reportNames = report_types[report_modules.operators];
                                const filename = generateName(reportNames, values.type);
                                FileDownload(result.data, filename);
                                dispatch(stopLinearProgress());
                            } catch(err) {
                                const { response } = err;
                                dispatch(setGlobalAlert({
                                    show: true,
                                    duration: 5000,
                                    message: response.data.message,
                                    severity: 'error',
                                }));
                                dispatch(stopLinearProgress());
                            }
                        }}
                    />
                )}
                {report_modules[selectedReport] === report_modules.records && (
                    <Formik
                        component={RecordsReportForm}
                        initialValues={{
                            type: Object.keys(report_types[report_modules.records])[0],
                            vehicle_id: '',
                            equipment_id: ''
                        }}
                        validationSchema={RecordsSchema}
                        onSubmit={async (values) => {
                            try {
                                dispatch(startLinearProgress());
                                dispatch(setGlobalAlert({
                                    show: true,
                                    duration: 5000,
                                    message: SUBMIT_MESSAGE,
                                    severity: 'info',
                                }));
                                const result = await downloadMTReport(selectedReport, values);
                                const reportNames = report_types[report_modules.records];
                                const filename = generateName(reportNames, values.type);
                                FileDownload(result.data, filename);
                                dispatch(stopLinearProgress());
                            } catch(err) {
                                const { response } = err;
                                dispatch(setGlobalAlert({
                                    show: true,
                                    duration: 5000,
                                    message: response.data.message,
                                    severity: 'error',
                                }));
                                dispatch(stopLinearProgress());
                            }
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default Informes;