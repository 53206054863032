import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import { datosGlobalesUrls } from '../../routing';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';

import useAllDeviationTypes from '../../../../hooks/useAllDeviationTypes';
import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';
import { createDeviation } from '../../../../services/deviationsService';

import validate from 'validate.js';
import schema from './schema';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const AddDeviation = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { deviationTypes } = useAllDeviationTypes();
    const [deviationForm, setDeviatonForm] = useState({
        isValid: false,
        isSubmitted: false,
        values: {
            name: '',
            norm: '',
            code: '',
            deviation_type_id: '',
        },
        touched: {
            name: false,
            norm: false,
            code: false,
            deviation_type_id: false,
        }, 
        errors: {},
    });
    const {
        backendResponse: createDeviationResponse,
        executeService: createDeviationService,
    } = useBackendMessageResponse(createDeviation);

    const handleChange = event => {
        event.persist();

        setDeviatonForm(currentDeviationForm => ({
            ...currentDeviationForm,
            values: {
                ...currentDeviationForm.values,
                [event.target.name]: event.target.value,
            },
            touched: {
                ...currentDeviationForm.touched,
                [event.target.name]: true
            }
        }));
    };

    const hasError = field =>
        (deviationForm.isSubmitted || deviationForm.touched[field]) && deviationForm.errors[field] ? true : false;

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    const onSubmitAddDeviation = async (event) => {
        event.preventDefault();

        if (!deviationForm.isSubmitted) {
            setDeviatonForm(currentDeviationForm => ({
                ...currentDeviationForm,
                isSubmitted: true,
            }));
        }

        if (!deviationForm.isValid) {
            return;
        }

        const deviation = deviationForm.values;
        await createDeviationService(deviation);
    }

    useEffect(() => {
        const errors = validate(deviationForm.values, schema);

        setDeviatonForm(currentDeviationForm => ({
            ...currentDeviationForm,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [deviationForm.values, deviationForm.isSubmitted]);

    useEffect(() => {
        if (createDeviationResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createDeviationResponse.message,
            severity: 'success',
        }));

        props.history.push(datosGlobalesUrls.Listado);
    }, [dispatch, createDeviationResponse.message, props.history]);

    useEffect(() => {
        if (createDeviationResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createDeviationResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, createDeviationResponse.error])

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Añadir desviación</Box>
            </Typography>
            <form onSubmit={onSubmitAddDeviation} className={classes.form}>
                <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
                    <Grid item>
                        <TextField 
                            id="code-deviation-input" 
                            label="Código" 
                            variant="outlined" 
                            className={classes.formControl} 
                            error={hasError('code')}
                            helperText={
                                hasError('code') ? deviationForm.errors.code[0] : null
                            }
                            name="code"
                            type="number"
                            value={deviationForm.values.code}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item>
                        <TextField 
                            id="deviation-name-input" 
                            label="Desviación" 
                            variant="outlined" 
                            className={classes.formControl} 
                            error={hasError('name')}
                            helperText={
                                hasError('name') ? deviationForm.errors.name[0] : null
                            }
                            name="name"
                            value={deviationForm.values.name}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item>
                        <TextField 
                            id="deviation-norm-input" 
                            label="Norma" 
                            variant="outlined" 
                            className={classes.formControl} 
                            error={hasError('norm')}
                            helperText={
                                hasError('norm') ? deviationForm.errors.norm[0] : null
                            }
                            name="norm"
                            value={deviationForm.values.norm}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item>
                        <FormControl variant="outlined" className={classes.formControl} error={hasError('deviation_type_id')}>
                            <InputLabel id="deviation-type-select">Tipo de desviación</InputLabel>
                            <Select
                                labelId="deviation-type-select"
                                id="deviation-type-select-outlined"
                                label="Tipo de desviación"
                                name="deviation_type_id"
                                onChange={handleChange}
                                value={deviationForm.values.deviation_type_id}
                            >
                                {deviationTypes.map((deviation_type, key) => (
                                    <MenuItem value={deviation_type.id} key={`deviation-type-key-${key}`}>
                                        {deviation_type.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {hasError('deviation_type_id') && 
                                <FormHelperText id="deviation-type-error-text">
                                    {deviationForm.errors.deviation_type_id[0]}
                                </FormHelperText> 
                            }
                        </FormControl>
                    </Grid>
                </Grid>
                <Button
                    className={classes.saveButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disableElevation
                >
                    Guardar
                </Button>
            </form>
        </div>
    );
}

export default AddDeviation;