import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import validate from 'validate.js';
import schema from './schema';

import useDepartments from '../../../../hooks/useDepartments';
import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';

import { configurationUrls } from '../../routing';

import { createCity } from '../../../../services/cityService';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const AddCity = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const departments = useDepartments();
    const [applyRedirect, setApplyRedirect] = useState(false);
    const {
        backendResponse: createCityResponse,
        executeService: createCityService,
        resetBackendResponseState: createCityBackendReset,
    } = useBackendMessageResponse(createCity);
    const [cityForm, setCityForm] = useState({
        isValid: false,
        isSubmitted: false,
        values: {
            department_id: '',
            name: '',
        },
        touched: {
            department_id: '',
            name: false,
        }, 
        errors: {},
    });

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    const onSubmitAddCity = (applyRedirect) => async (event) => {
        event.preventDefault();

        if (!cityForm.isSubmitted) {
            setCityForm(currentCityForm => ({
                ...currentCityForm,
                isSubmitted: true,
            }));
        }

        if (!cityForm.isValid) {
            return;
        }

        setApplyRedirect(applyRedirect);
        const city = cityForm.values;
        await createCityService(city);
    }

    const handleChange = event => {
        event.persist();

        setCityForm(currentCityForm => ({
            ...currentCityForm,
            values: {
                ...currentCityForm.values,
                [event.target.name]: event.target.value,
            },
            touched: {
                ...currentCityForm.touched,
                [event.target.name]: true
            },
        }));
    };

    const hasError = field =>
        (cityForm.isSubmitted || cityForm.touched[field]) && cityForm.errors[field] ? true : false;

    useEffect(() => {
        if (createCityResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createCityResponse.message,
            severity: 'success',
        }));

        if(applyRedirect) {
            props.history.push(configurationUrls.Listado);
        } else {
            setCityForm({
                isValid: false,
                isSubmitted: false,
                values: {
                    department_id: '',
                    name: '',
                },
                touched: {
                    department_id: '',
                    name: false,
                }, 
                errors: {},
            });
            createCityBackendReset();
        }
    }, [dispatch, createCityResponse.message, props.history, applyRedirect, createCityBackendReset]);

    useEffect(() => {
        if (createCityResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createCityResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, createCityResponse.error])

    useEffect(() => {
        const errors = validate(cityForm.values, schema);

        setCityForm(currentCityForm => ({
            ...currentCityForm,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [cityForm.values, cityForm.isSubmitted]);

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Añadir municipio</Box>
            </Typography>
            <form onSubmit={onSubmitAddCity(true)} className={classes.form}>
                <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
                    <Grid item>
                        <FormControl variant="outlined" className={classes.formControl} error={hasError('department_id')}>
                            <InputLabel id="department-id-input-label">Nombre del departamento</InputLabel>
                            <Select
                                labelId="department-id-input-label"
                                id="department-id-input"
                                value={cityForm.values.department_id}
                                onChange={handleChange}
                                label="Nombre del departamento"
                                name="department_id"
                            >
                            {departments.map((department, key) => (
                                <MenuItem key={`department-key-${key}`} value={department.id}>{department.name}</MenuItem>
                            ))}
                            </Select>
                            { hasError('department_id') && (
                                <FormHelperText>{cityForm.errors.department_id[0]}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <TextField 
                            id="name-city-input" 
                            label="Nombre del municipio" 
                            variant="outlined" 
                            className={classes.formControl} 
                            error={hasError('name')}
                            helperText={
                                hasError('name') ? cityForm.errors.name[0] : null
                            }
                            name="name"
                            value={cityForm.values.name}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                    <Grid item>
                        <Button
                            className={classes.saveButton}
                            color="primary"
                            size="large"
                            type="submit"
                            variant="contained"
                            disableElevation
                        >
                            Guardar
                        </Button>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item>
                                <Typography component={"span"}>
                                    <Box fontWeight="fontWeightLight" fontSize={12} className={classes.addAnotherCityText}>Agregar otro municipio</Box>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton aria-label="add-fire-type-icon" color="primary" className={classes.addAnotherCityIconContainer} onClick={onSubmitAddCity(false)}>
                                    <AddCircleRoundedIcon className={classes.addAnotherCityIcon} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
}

export default AddCity;