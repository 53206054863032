import httpClient from '../http';
import qs from 'qs';

export const getCurrentUser = async () => {
    return await httpClient.get('/users/current');
}

export const updateCurrentUser = async(newUserData) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const data = qs.stringify(newUserData);
    return await httpClient.put('/users', data, config);
}