import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/icons/Menu";

import { UserDropdownLinks } from '../UserDropdownLinks';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const Navbar = (props) => {
    const classes = useStyles();

    const { routes, userName, userRole, avatarUrl, dropdownActions } = props;

    const getRouteName = () => {
        var name;
        routes.map(item => {
            if (window.location.href.indexOf(item.path) !== -1) {
                name = item.title;
            }
            return null;
        });
        return name;
    }

    return (
        <AppBar className={classes.appBar}>
            <Toolbar className={classes.container}>
                <div className={classes.flex}>
                    <Button href="#" className={classes.title}>
                        {getRouteName()}
                    </Button>
                </div>
                <Hidden smDown implementation="css">
                    <UserDropdownLinks 
                        userName={userName}
                        userRole={userRole}
                        avatarUrl={avatarUrl}
                        dropdownActions={dropdownActions}
                    />
                </Hidden>
                <Hidden mdUp implementation="css">
                    <IconButton
                        aria-label="open drawer"
                        onClick={props.handleDrawerToggle}
                    >
                        <Menu />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

export default Navbar;