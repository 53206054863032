import React from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import { Can, Only } from '../../../../../../../components/Can';
import { ROLES_KEYS } from '../../../../../../../constants/roles';

import useAllEmergencies from '../../../../../../../hooks/useAllEmergencies';
import useCities from '../../../../../../../hooks/useCities';
import useAllNeighborhoods from '../../../../../../../hooks/useAllNeighborhoods';

import { Form, Field } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const EmergenciasFilterForm = (props) => {
    const classes = useStyles();

    const emergencies = useAllEmergencies();
    const cities = useCities();
    const neighborhoods = useAllNeighborhoods();

    return (
        <Form>
            <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                <Grid item className={classes.fullWidth}>
                    <Field
                        name="emergency_date"
                        as={TextField}
                        className={classes.formControl}
                        variant="outlined"
                        label="Fecha de emergencia"
                        size="small"
                    />
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <FormControl variant="outlined" className={classes.formControl} size="small">
                        <InputLabel id="emergency-type-id">Tipo de Emergencia</InputLabel>
                        <Field
                            name="emergency_id"
                            as={Select}
                            labelId="emergency-type-id"
                            id="emergency-type-id-select"
                            label="Tipo de Emergencia"
                        >
                        {emergencies.map((emergency, index) => (
                            <MenuItem value={emergency.id} key={`global-emergency-key-${index}`}>{emergency.name}</MenuItem>
                        ))}
                        </Field>
                    </FormControl>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <FormControl variant="outlined" className={classes.formControl} size="small">
                        <InputLabel id="city-label-id">Ciudad</InputLabel>
                        <Field
                            name="city_id"
                            as={Select}
                            labelId="city-label-id"
                            id="city-id-select"
                            label="Ciudad"
                        >
                        {cities.map((city, index) => (
                            <MenuItem value={city.id} key={`city-key-${index}`}>{city.name}</MenuItem>
                        ))}
                        </Field>
                    </FormControl>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <FormControl variant="outlined" className={classes.formControl} size="small">
                        <InputLabel id="neighborhood-label-id">Barrio</InputLabel>
                        <Field
                            name="neighborhood_id"
                            as={Select}
                            labelId="neighborhood-label-id"
                            id="neighborhood-id-select"
                            label="Barrio"
                        >
                        {neighborhoods.map((neighborhood, index) => (
                            <MenuItem value={neighborhood.id} key={`neighborhood-key-${index}`}>{neighborhood.name}</MenuItem>
                        ))}
                        </Field>
                    </FormControl>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <Field
                        name="reporter_name"
                        as={TextField}
                        className={classes.formControl}
                        variant="outlined"
                        label="Nombre quien reporta"
                        size="small"
                    />
                </Grid>
                <Can>
                    <Only roles={[ROLES_KEYS.admin, ROLES_KEYS.risk_agent, ROLES_KEYS.client]}>
                        <Field
                            name="station_name"
                            as={TextField}
                            className={classes.formControlStation}
                            variant="outlined"
                            label="Nombre de Estación"
                            size="small"
                        />
                    </Only>
                </Can>
                <Grid item className={classes.fullWidth}>
                    <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                        <Grid item>
                            <Button
                                name="apply"
                                variant="contained" 
                                color="primary" 
                                className={classes.filterButton} 
                                size="small" 
                                disableElevation
                                type="submit"
                            >
                                Aplicar
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button 
                                name="clean"
                                color="primary" 
                                size="small"
                                disableElevation
                                type="submit"
                            >
                                Limpiar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Form>
    );
}

export default EmergenciasFilterForm;