import React from 'react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Grid from '@material-ui/core/Grid';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';

import { Form, Field, ErrorMessage } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const EmergencyStatisticsReportForm = (props) => {
    const classes = useStyles();

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Form>
                <Grid container direction="row" spacing={1} justify="flex-start" alignItems="center">
                    <Grid item>
                        <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={props.goBack}>
                            <ArrowBackRoundedIcon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography component={"span"}>
                            <Box fontWeight="fontWeightBold" fontSize={25}>Informe estadístico de Emergencia</Box>
                        </Typography>
                    </Grid>
                </Grid>
                <br />
                <Grid container direction="column" spacing={2} justify="flex-start" alignItems="flex-start">
                    <Grid item className={classes.row}>
                        <Grid container direction="row" spacing={2} justify="flex-start" alignItems="flex-start">
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="start_date" 
                                    as={KeyboardDatePicker} 
                                    label="Fecha de Inicio"
                                    className={classes.formControl} 
                                    inputVariant="outlined"
                                    variant="inline"
                                    InputAdornmentProps={{ position: "end" }}
                                    format="dd/MM/yyyy"
                                    onChange={(date) => props.setFieldValue("start_date", date)}
                                    error={hasError('start_date')}
                                    helperText={<ErrorMessage name="start_date" />}
                                    autoOk
                                    disableFuture
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="end_date" 
                                    as={KeyboardDatePicker} 
                                    label="Fecha de Cierre"
                                    className={classes.formControl} 
                                    inputVariant="outlined"
                                    variant="inline"
                                    InputAdornmentProps={{ position: "end" }}
                                    format="dd/MM/yyyy"
                                    onChange={(date) => props.setFieldValue("end_date", date)}
                                    error={hasError('end_date')}
                                    helperText={<ErrorMessage name="end_date" />}
                                    autoOk
                                    disableFuture
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button
                            className={classes.downloadButton}
                            color="primary"
                            size="large"
                            type="submit"
                            variant="contained"
                            disableElevation
                        >
                            Consultar
                        </Button>
                    </Grid>
                </Grid>
            </Form>
        </MuiPickersUtilsProvider>
    );
}

export default EmergencyStatisticsReportForm;