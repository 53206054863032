import { createSlice } from '@reduxjs/toolkit';

export const grInspectionsSlice = createSlice({
    name: 'grInspections',
    initialState: {
        isLoading: false,
        grInspections: [],
        total: null,
        pages: null,
        page: 1,
        page_limit: 10,
        error: {
            code: null,
            message: null,
        },
    },
    reducers: {
        getGrInspectionsRequest: (state) => {
            state.isLoading = true;
        },
        getGrInspectionsSuccess: (state, action) => {
            state.isLoading = false;
            state.error = {
                code: null,
                message: null,
            };
            state.grInspections = action.payload.grInspections;
            state.total = action.payload.total;
            state.pages = action.payload.pages;
            state.page = action.payload.page;
            state.page_limit = action.payload.page_limit;
        },
        getGrInspectionsFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
    },
});

export const {
    getGrInspectionsRequest,
    getGrInspectionsSuccess,
    getGrInspectionsFailure,
} = grInspectionsSlice.actions;

export const selectGrInspections = (state) => state.grInspections;

export default grInspectionsSlice.reducer;