import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const FilterButton = (props) => {
    const [filterForm, setFilterForm] = useState(null);
    const classes = useStyles();

    const { children } = props;

    const onClickFilterButton = (event) => {
        setFilterForm(filterForm ? null : event.currentTarget);
    }

    const hideFilterForm = () => {
        setFilterForm(null);
    }

    return (
        <div className={classes.root}>
            <Button
                variant="contained"
                color="primary"
                startIcon={<FilterListOutlinedIcon />}
                endIcon={<ArrowDropDownRoundedIcon />}
                className={classes.filterButton}
                onClick={onClickFilterButton}
                disableElevation 
            >
                Filtrar
            </Button>
            <Popper open={Boolean(filterForm)} anchorEl={filterForm} transition>
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                        <Paper>
                            {typeof children === "function" 
                                ? children({
                                    hideFilterForm,
                                }) 
                                : children }
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

export default FilterButton;