import { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../store/slices/currentUserSlice';

export const Only = (props) => {
    return props.render !== undefined && props.render !== null 
        ? props.render()
        : props.children;
}

export const Otherwise = (props) => {
    return props.render !== undefined && props.render !== null 
        ? props.render()
        : props.children;
}

const Can = (props) => {
    const { user: currentUser }= useSelector(selectCurrentUser);

    const checkRole = useCallback((roles) => {
        const { role } = currentUser;
        if (roles.includes(role)) {
            return true;
        }
        return false;
    }, [currentUser]);

    const renderChildren = useCallback(() => {
        if (currentUser === null) {
            return null;
        }

        if (currentUser.role === null) {
            return null;
        }

        let onlyChildren = [];
        let otherwiseChild = [];

        if (Array.isArray(props.children)) {
            onlyChildren = props.children.filter(child => child.type === Only);
            otherwiseChild = props.children.filter(child => child.type === Otherwise);
        } else if (props.children.type === Only) {
            onlyChildren = [props.children];
        } else if (props.children.type === Otherwise) {
            otherwiseChild = [props.children];
        }

        for (const onlyTypeChild of onlyChildren) {
            if (checkRole(onlyTypeChild.props.roles)) {
                return onlyTypeChild;
            }
        }
        
        if (otherwiseChild.length === 0) {
            return null;
        }

        return otherwiseChild[0];
    }, [props.children, checkRole, currentUser]);

    return renderChildren();
}

export default Can;