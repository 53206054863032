import { useState, useEffect } from 'react';
import { getAllDeviations } from '../services/deviationsService';

const useAllDeviations = () => {
    const [deviations, setDeviations] = useState([]);

    useEffect(() => {
        async function fetchAllDeviations() {
            try {
                const { data } = await getAllDeviations();
                setDeviations(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchAllDeviations();
    }, []);

    return { deviations };
}

export default useAllDeviations;