import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import MuiPhoneNumber from 'material-ui-phone-number';

import { FORM_RISK_TYPES } from '../../../../../constants/risk_types';
import useDepartments from '../../../../../hooks/useDepartments';
import useCities from '../../../../../hooks/useCities';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import { Form, Field, ErrorMessage } from 'formik';

const ID_TYPES = ['CC', 'CE', 'PA'];

const useStyles = makeStyles(styles);

const RiskAgentForm = (props) => {
    const classes = useStyles();
    const departments = useDepartments();
    const cities = useCities();

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true: false;
    }

    return (
        <div className={classes.root}>
            <Form>
                <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item xs={12} md={6}>
                                <Field
                                    name="firstname"
                                    as={TextField}
                                    className={classes.formControl}
                                    variant="outlined"
                                    label="Nombres"
                                    type="text"
                                    error={hasError("firstname")}
                                    helperText={<ErrorMessage name="firstname" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field
                                    name="lastname"
                                    as={TextField}
                                    className={classes.formControl}
                                    variant="outlined"
                                    label="Apellidos"
                                    type="text"
                                    error={hasError("lastname")}
                                    helperText={<ErrorMessage name="lastname" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item xs={12} md={6}>
                                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                                    <Grid item xs={4}>
                                        <FormControl variant="outlined" className={classes.formControl} error={hasError("document_type")}>
                                            <InputLabel id="id-type-station-user-label">ID</InputLabel>
                                            <Field
                                                name="document_type"
                                                as={Select}
                                                labelId="id-type-station-user-label"
                                                id="id-type-station-user-select"
                                                label="ID"
                                            >
                                            {ID_TYPES.map((id_type, index) => (
                                                <MenuItem value={id_type} key={`id-type-station-user-key-${index}`}>{id_type}</MenuItem>
                                            ))}
                                            </Field>
                                            <FormHelperText>
                                                <ErrorMessage name="document_type" />
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Field
                                            name="document"
                                            as={TextField}
                                            className={classes.formControl}
                                            variant="outlined"
                                            label="Identificación"
                                            type="text"
                                            error={hasError("document")}
                                            helperText={<ErrorMessage name="document" />}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field
                                    name="phone"
                                    as={MuiPhoneNumber}
                                    className={classes.formControl}
                                    variant="outlined"
                                    label="Número de teléfono"
                                    type="text"
                                    error={hasError("phone")}
                                    helperText={<ErrorMessage name="phone" />}
                                    onChange={(value) => props.setFieldValue('phone', value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item xs={12} md={6}>
                                <Field
                                    name="email"
                                    as={TextField}
                                    className={classes.formControl}
                                    variant="outlined"
                                    label="Correo electrónico"
                                    type="text"
                                    error={hasError("email")}
                                    helperText={<ErrorMessage name="email" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Typography component="span">
                            <Box fontWeight="fontWeightBold" fontSize={16}>Tipo de ubicación</Box>
                        </Typography>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" className={classes.formControl} error={hasError("risk_user_type")}>
                                    <InputLabel id="id-user-type-label">Tipo</InputLabel>
                                    <Field
                                        name="risk_user_type"
                                        as={Select}
                                        labelId="id-user-type-label"
                                        id="id-user-type-select"
                                        label="Tipo"
                                    >
                                    {Object.entries(FORM_RISK_TYPES).map(([risk_type_key, risk_type_name], index) => (
                                        <MenuItem value={risk_type_key} key={`id-risk-type-key-${index}`}>{risk_type_name}</MenuItem>
                                    ))}
                                    </Field>
                                    <FormHelperText>
                                        <ErrorMessage name="risk_user_type" />
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item xs={12} md={6}>
                              <FormControl variant="outlined" className={classes.formControl} error={hasError("department_id")}>
                                  <InputLabel id="department-risk-user-label">Departamento</InputLabel>
                                  <Field
                                      name="department_id"
                                      as={Select}
                                      labelId="department-risk-user-label"
                                      id="department-risk-user-select"
                                      label="Departamento"
                                  >
                                  {departments.map((department, index) => (
                                      <MenuItem value={department.id} key={`department-risk-user-key-${index}`}>{department.name}</MenuItem>
                                  ))}
                                  </Field>
                                  <FormHelperText>
                                      <ErrorMessage name="department_id" />
                                  </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl variant="outlined" className={classes.formControl} error={hasError('city_id')}>
                                  <InputLabel id="city-risk-user-label">Municipio</InputLabel>
                                  <Field
                                      name="city_id"
                                      as={Select}
                                      labelId="city-risk-user-label"
                                      id="city-risk-user-select"
                                      label="Municipio"
                                  >
                                  {cities.filter(city => city.department_id === props.values.department_id || city.name === "Otro").map((city, index) => (
                                      <MenuItem value={city.id} key={`city-station-user-key-${index}`}>{city.name}</MenuItem>
                                  ))}
                                  </Field>
                                  <FormHelperText>
                                      <ErrorMessage name="city_id" />
                                  </FormHelperText>
                              </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Button
                            className={classes.saveButton}
                            color="primary"
                            size="large"
                            type="submit"
                            variant="contained"
                            disableElevation
                        >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Form>
        </div>
    );
}

export  default RiskAgentForm;