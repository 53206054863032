import * as Yup from 'yup';

export default Yup.object().shape({
    affected_person_apply: Yup.boolean()
        .required('Éste campo es requerido.'),
    affected_person: Yup.string()
        .when("affected_person_apply", (affected_person_apply, yup) => {
            return affected_person_apply 
                ? yup.required('Éste campo es requerido.')
                     .max(100, 'Máximo 100 caracteres.')
                : yup;
        }),
    affected_person_document: Yup.string()
        .when("affected_person_apply", (affected_person_apply, yup) => {
            return affected_person_apply 
                ? yup.required('Éste campo es requerido.') 
                     .max(16, 'Máximo 16 caracteres.')
                : yup;
        }),
    affected_person_address: Yup.string()
        .when("affected_person_apply", (affected_person_apply, yup) => {
            return affected_person_apply 
                ? yup.required('Éste campo es requerido.')
                     .max(100, 'Máximo 100 caracteres.')
                : yup;
        }),
    affected_person_phone: Yup.string()
        .when("affected_person_apply", (affected_person_apply, yup) => {
            return affected_person_apply 
                ? yup.required('Éste campo es requerido.') 
                     .max(13, 'Máximo 13 dígitos.')
                : yup;
        }),
    affected_person_description: Yup.string()
        .when("affected_person_apply", (affected_person_apply, yup) => {
            return affected_person_apply 
                ? yup.required('Éste campo es requerido.') 
                     .max(500, 'Máximo 500 caracteres.')
                : yup;
        }),
});