import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';

import useInspectionById from '../../../../hooks/GR/useInspectionById';
import useTimestamp from '../../../../hooks/useTimestamp';
import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';
import { editInspection } from '../../../../services/GR/inspectionService';

import { Formik } from 'formik';

import {
    DatosGeneralesForm,
    CategorizarForm,
    ContabilizarForm,
    ExtintoresForm,
    ChecklistForm,
} from '../../Forms/Inspeccion';

import DatosGeneralesSchema from '../../Forms/Inspeccion/DatosGeneralesForm/schema';
import CategorizarSchema from '../../Forms/Inspeccion/CategorizarForm/schema';
import ContabilizarSchema from '../../Forms/Inspeccion/ContabilizarForm/schema';

import useAllDeviations from '../../../../hooks/useAllDeviations';

import { GestionRiesgoUrls } from '../../routing';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const DetailsInspection = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState(new Set());
    const { deviations } = useAllDeviations();
    const {
        backendResponse: editInspectionResponse,
        executeService: editInspectionService,
    } = useBackendMessageResponse(editInspection);
    const { inspection_id } = useParams();
    const { inspection } = useInspectionById(inspection_id);
    const timestamp = useTimestamp();

    const [datosGeneralesForm, setDatosGeneralesForm] = useState({
        inspection_number: `GR-${timestamp}`,
        address: '',
        social_reason: '',
        neighborhood_id: '',
        commercial_reason: '',
        phone_number: '',
        cert_number: '',
        email: '',
        inspection_date: new Date(),
        owner_name: '',
        state: '',
        admin_name: '',
        nit: '',
    });

    const [categorizarForm, setCategorizarForm] = useState({
        width: '',
        length: '',
        activity_id: '',

        // Éste campo 'impact' aunque se envía al backend, no se hace nada con él realmente.
        // Ya que está acá, para ejecutar validaciones sobre él y asegurar que
        // se ha encontrado un impacto válido.
        impact: '',
    });

    const [contabilizarForm, setContabilizarForm] = useState({
        inspector_id: '',
        discount: '',
        donation: false,
    });

    const [extintoresForm, setExtintoresForm] = useState({
        extinguishers: [],
        extinguishers_comments: '',
    });

    const [checklistForm, setChecklistForm] = useState({
        checklist: [],
        remedied: false,
        certified: false,
    });

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleStep = (step) => () => {
        if (completed.has(step)) {
            setActiveStep(step);
        }
    }

    const handleComplete = () => {
        const newCompleted = new Set(completed);
        newCompleted.add(activeStep);
        setCompleted(newCompleted);
        handleNext();
    }

    const isStepCompleted = (index) => {
        return completed.has(index);
    }

    // Acá se recibe el formulario de Checklist porque setChecklistForm funciona de forma asíncrona
    // Entonces como es el último formulario y para asegurar que se están usando los valores actualizados
    // se usa como el evento para crear una inspección pero en lugar de usar el estado directamente,
    // se usa los valores retornados por Formik.
    const onCreateInspection = async (checklistFormData) => {
        setChecklistForm(checklistFormData);

        const inspectionData = {
            ...datosGeneralesForm,
            ...categorizarForm,
            ...contabilizarForm,
            ...extintoresForm,
            extinguishers: JSON.stringify(extintoresForm.extinguishers),
            ...checklistFormData,
            checklist: JSON.stringify(checklistFormData.checklist),
        };

        await editInspectionService(inspection_id, inspectionData);
    }

    useEffect(() => {
        if (editInspectionResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: editInspectionResponse.message,
            severity: 'success',
        }));

        props.history.push(GestionRiesgoUrls.Tablero);
    }, [dispatch, editInspectionResponse.message, props.history]);

    useEffect(() => {
        if (editInspectionResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: editInspectionResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, editInspectionResponse.error])
    
    useEffect(() => {
        if (inspection === null || deviations.length === 0) {
            return;
        }

        setDatosGeneralesForm({
            inspection_number: inspection.inspection_number,
            address: inspection.address,
            social_reason: inspection.social_reason,
            neighborhood_id: inspection.neighborhood_id,
            commercial_reason: inspection.commercial_reason,
            phone_number: inspection.phone_number,
            cert_number: inspection.cert_number,
            email: inspection.email,
            inspection_date: new Date(inspection.inspection_date),
            owner_name: inspection.owner_name,
            state: inspection.state,
            admin_name: inspection.admin_name,
            nit: inspection.nit,
        });

        setCategorizarForm({
            width: inspection.width,
            length: inspection.length,
            activity_id: inspection.activity_id,
        });

        setContabilizarForm({
            inspector_id: inspection.inspector_id,
            discount: inspection.discount,
            donation: inspection.donation,
        });

        setExtintoresForm({
            extinguishers: JSON.parse(inspection.extinguishers),
            extinguishers_comments: inspection.extinguishers_comments,
        });

        const inspectionCurrentDeviations = JSON.parse(inspection.checklist);
        const deviationsMapped = deviations.map((deviation) => {
            const existingDeviation = inspectionCurrentDeviations.find(d => d.id === deviation.id);
            return {
                id: deviation.id,
                code: deviation.code,
                name: deviation.name,
                norm: deviation.norm,
                deviation_type_id: deviation.deviation_type_id,
                checked: existingDeviation !== undefined ? existingDeviation.checked : false,
                NA: existingDeviation !== undefined ? existingDeviation.NA : false,
            };
        });

        setChecklistForm({
            checklist: deviationsMapped,
            remedied: inspection.remedied,
            certified: inspection.certified,
        });

        completed.add(0);
        completed.add(1);
        completed.add(2);
        completed.add(3);
        completed.add(4);
    
    // eslint-disable-next-line
    }, [inspection, deviations]);

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Detalles de inspección</Box>
            </Typography>
            <div className={classes.formSteps}>
                <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                    <Step key="datos-generales">
                        <StepButton onClick={handleStep(0)} completed={isStepCompleted(0)}>Datos generales</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <Formik
                                    enableReinitialize
                                    initialValues={datosGeneralesForm}
                                    validationSchema={DatosGeneralesSchema}
                                    onSubmit={(values) => {
                                        setDatosGeneralesForm(values);
                                        handleComplete();
                                    }}
                                >
                                    {(formik) => <DatosGeneralesForm {...formik} readMode={true} />}
                                </Formik>
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="categorizar">
                        <StepButton onClick={handleStep(1)} completed={isStepCompleted(1)}>Categorizar</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <Formik
                                    enableReinitialize
                                    initialValues={categorizarForm}
                                    validationSchema={CategorizarSchema}
                                    onSubmit={(values) => {
                                        setCategorizarForm(values);
                                        handleComplete();
                                    }}
                                >
                                    {(formik) => <CategorizarForm {...formik} readMode={true} />}
                                </Formik>
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="contabilizar">
                        <StepButton onClick={handleStep(2)} completed={isStepCompleted(2)}>Contabilizar</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <Formik
                                    enableReinitialize
                                    initialValues={contabilizarForm}
                                    validationSchema={ContabilizarSchema}
                                    onSubmit={(values) => {
                                        setContabilizarForm(values);
                                        handleComplete();
                                    }}
                                >
                                    {formik => (
                                        <ContabilizarForm {...formik} categorizarData={{...categorizarForm}} readMode={true} />
                                    )}
                                </Formik>
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="extintores">
                        <StepButton onClick={handleStep(3)} completed={isStepCompleted(3)}>Extintores</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <ExtintoresForm 
                                    extintoresData={extintoresForm}
                                    onChangeExtintores={(extinguishers) => {
                                        setExtintoresForm(current => ({
                                            ...current,
                                            extinguishers,
                                        }))
                                    }}
                                    onCommentChange={(extinguishers_comments) => {
                                        setExtintoresForm(current => ({
                                            ...current,
                                            extinguishers_comments,
                                        }))
                                    }}
                                    onHandleNext={() => {
                                        handleComplete();
                                    }}
                                    readMode={true}
                                />
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="checklist">
                        <StepButton onClick={handleStep(4)} completed={isStepCompleted(4)}>Checklist</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <Formik
                                    enableReinitialize
                                    initialValues={checklistForm}
                                    onSubmit={onCreateInspection}
                                >
                                    {(formik) => <ChecklistForm {...formik} readMode={true} />}
                                </Formik>
                            </div>
                        </StepContent>
                    </Step>
                </Stepper>
            </div>
        </div>
    );
}

export default DetailsInspection;