import { useState, useEffect } from 'react';
import { getVehicles } from '../../services/vehiclesService';

const useVehicles = (page, page_limit) => {
    const [vehicles, setVehicles] = useState([]);

    useEffect(() => {
        async function fetchVehicles() {
            try {
                const { data: { results } } = await getVehicles(page, page_limit);
                setVehicles(results);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchVehicles();
    }, [page, page_limit]);

    return vehicles;
}

export default useVehicles;