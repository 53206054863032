import React, { useState, useEffect } from 'react';

import { createMinWage } from '../../../../services/minWageService';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

import validate from 'validate.js';
import schema from './schema';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const AddMinWage = (props) => {
    const classes = useStyles();
    const [salaryForm, setSalaryForm] = useState({
        isValid: false,
        isSubmitted: false,
        values: {
            year: '',
            amount: '',
        },
        touched: {
            year: false,
            amount: false,
        }, 
        erros: {},
    });
    const [backendResponse, setBackendResponse] = useState({
        isLoading: false,
        error: null,
        message: null,
    });

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    const onSubmitAddSalary = async (event) => {
        event.preventDefault();
        if (!salaryForm.isSubmitted) {
            setSalaryForm(currentSalaryForm => ({
                ...currentSalaryForm,
                isSubmitted: true,
            }));
        }

        if(!salaryForm.isValid) {
            return;
        }

        try {
            const { data } = await createMinWage({...salaryForm.values});
            setBackendResponse({
                isLoading: false,
                message: data.message,
                error: null,
            });
        } catch(e) {
            const { response } = e;
            let message = '';
            if (typeof response.data.message === 'object') {
                for(var key in response.data.message) {
                    message = response.data.message[key];
                    break;
                }
            } else {
                message = response.data.message;
            }
            const error = {
                code: response.status,
                message,
            };
            setBackendResponse({
                isLoading: false,
                message: null,
                error
            });
        }
    }

    const handleChange = event => {
        event.persist();

        setSalaryForm(currentSalaryForm => ({
            ...currentSalaryForm,
            values: {
                ...currentSalaryForm.values,
                [event.target.name]: event.target.value,
            },
            touched: {
                ...currentSalaryForm.touched,
                [event.target.name]: true
            }
        }));
    };

    const handleSalaryChange = (event, value) => {
        setSalaryForm(currentSalaryForm => ({
            ...currentSalaryForm,
            values: {
                ...currentSalaryForm.values,
                amount: value !== 0 ? value : '',
            },
            touched: {
                ...currentSalaryForm.touched,
                amount: true
            },
        }));
    }

    const hasError = field =>
        (salaryForm.isSubmitted || salaryForm.touched[field]) && salaryForm.errors[field] ? true : false;

    useEffect(() => {
        const errors = validate(salaryForm.values, schema);

        setSalaryForm(currentSalaryForm => ({
            ...currentSalaryForm,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [salaryForm.values, salaryForm.isSubmitted]);

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Añadir salario</Box>
            </Typography>
            <form onSubmit={onSubmitAddSalary} className={classes.form}>
                <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
                    <Grid item>
                        <TextField 
                            id="year-min-wage-input" 
                            label="Año" 
                            variant="outlined" 
                            className={classes.formControl} 
                            error={hasError('year')}
                            helperText={
                                hasError('year') ? salaryForm.errors.year[0] : null
                            }
                            name="year"
                            type="number"
                            value={salaryForm.values.year}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item>
                        <CurrencyTextField
                            id="amount-min-wage-input" 
                            label="Salario" 
                            variant="outlined" 
                            className={classes.formControl} 
                            error={hasError('amount')}
                            helperText={
                                hasError('amount') ? salaryForm.errors.amount[0] : null
                            }
                            name="amount"
                            value={salaryForm.values.amount}
                            onChange={handleSalaryChange}
                        />
                    </Grid>
                </Grid>
                { backendResponse.message !== null && (
                    <Typography color="primary" className={classes.authNotificationText}>
                        <Box fontWeight="fontWeightBold">
                            { backendResponse.message }
                        </Box>
                    </Typography>
                )}
                { backendResponse.error !== null && (
                    <Typography color="error" className={classes.authNotificationText}>
                        <Box fontWeight="fontWeightBold">
                            { backendResponse.error.message }
                        </Box>
                    </Typography>
                )}
                <Button
                    className={classes.editButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disableElevation
                >
                    Guardar
                </Button>
            </form>
        </div>
    );
}

export default AddMinWage;