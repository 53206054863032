import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { resetListState } from '../../store/slices/motorListsSlice';

import MotorRouting, { MotorUrls } from './routing';
import routesUrls from '../../routes/urls';

const Motor = props => {
    const dispatch = useDispatch();

    // Ésto se coloca porque el listado guarda el estado del tab seleccionado para que 
    // al cambiar a cualquier de las páginas se vuelva a seleccionar el tab que estaba 
    // anteriormente. Pero no queremos que se persista al salir del módulo de Motor.
    useEffect(() => {
        return () => {
            dispatch(resetListState());
        };
    }, [dispatch]);

    return (
        <div>
            <Switch>
                {MotorRouting.map((item, key) => (
                    <Route key={`motor-${key}`} path={item.path} exact render={routeProps => (
                        <item.component {...routeProps} {...props} />
                    )}/>
                ))}
                <Redirect exact from={routesUrls.Motor} to={MotorUrls.Listado} />
            </Switch>
        </div>
    );
};

export default Motor;