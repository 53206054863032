import * as Yup from 'yup';

export default Yup.object().shape({
    report: Yup.string()
        .max(20, 'Máximo 20 carácteres.')
        .required('Éste campo es requerido.'),
    sheet: Yup.string()
        .max(20, 'Máximo 20 carácteres.')
        .required('Éste campo es requerido.'),
    date: Yup.date()
        .required('Éste campo es requerido.'),
    value: Yup.string()
        .max(16, 'Máximo 16 dígitos.')
        .required('Éste campo es requerido.'),
    workshop: Yup.string()
        .max(100, 'Máximo 100 carácteres.')
        .required('Éste campo es requerido.'),
    description: Yup.string()
        .max(500, 'Máximo 500 caracteres.')
        .required('Éste campo es requerido.'),
    in_charge_id: Yup.number()
        .required('Éste campo es requerido.'),
    type: Yup.string()
        .required('Éste campo es requerido.'),
    phone: Yup.string()
        .max(16, 'Máximo 16 dígitos.')
        .required('Éste campo es requerido.'),
    equipment_id: Yup.number()
        .when("type", (type, yup) => {
            return type && type === "Equipo" 
                ? yup.required('Éste campo es requerido.') 
                : yup;
        }),
    vehicle_id: Yup.number()
        .when("type", (type, yup) => {
            return type && type === "Máquina" 
                ? yup.required('Éste campo es requerido.')
                : yup;
        }),
});