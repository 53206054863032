import React from 'react';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

import differenceInMinutes from 'date-fns/differenceInMinutes';

import { Form, Field, ErrorMessage } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const CostosForm = (props) => {
    const classes = useStyles();
    const { readMode } = props;

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    };

    return (
        <div className={classes.root}>
            <Form>
                <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item xs={12} md={6}>
                                <FormControlLabel
                                    control={
                                        <Field
                                            name="cost_apply"
                                            as={Checkbox}
                                            checked={!props.values.cost_apply}
                                            onChange={(event) => props.setFieldValue('cost_apply', !event.target.checked)}
                                            color="primary"
                                        />
                                    }
                                    label="No aplica"
                                    disabled={readMode}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="costs_intervention_hour" 
                                    type="text" 
                                    as={CurrencyTextField} 
                                    variant="outlined" 
                                    label="Valor hora intervención"
                                    className={classes.formControl} 
                                    error={hasError('costs_intervention_hour')}
                                    helperText={<ErrorMessage name="costs_intervention_hour" />}
                                    onChange={(event, value) => props.setFieldValue('costs_intervention_hour', value)}
                                    disabled={!props.values.cost_apply || readMode}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="minute_value" 
                                    type="text" 
                                    as={CurrencyTextField} 
                                    variant="outlined" 
                                    label="Valor minuto"
                                    className={classes.formControl} 
                                    error={hasError('minute_value')}
                                    helperText={<ErrorMessage name="minute_value" />}
                                    value={props.values.costs_intervention_hour / 60}
                                    onChange={(event, value) => props.setFieldValue('minute_value', props.values.costs_intervention_hour / 60)}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="machine_quantity" 
                                    type="number" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Cantidad de máquinas"
                                    className={classes.formControl} 
                                    error={hasError('machine_quantity')}
                                    helperText={<ErrorMessage name="machine_quantity" />}
                                    value={props.datosGenerales.support_machine.length + props.datosGenerales.dispatch_machine.length}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="minutes_duration" 
                                    type="number" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Duración en minutos"
                                    className={classes.formControl} 
                                    error={hasError('minutes_duration')}
                                    helperText={<ErrorMessage name="minutes_duration" />}
                                    value={differenceInMinutes(props.datosGenerales.station_arriving_time, props.datosGenerales.dispatch_time)}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="total_cost" 
                                    type="text" 
                                    as={CurrencyTextField} 
                                    variant="outlined" 
                                    label="Costo total"
                                    className={classes.formControl} 
                                    error={hasError('total_cost')}
                                    helperText={<ErrorMessage name="total_cost" />}
                                    onChange={(event, value) => props.setFieldValue('total_cost', value)}
                                    value={differenceInMinutes(props.datosGenerales.station_arriving_time, props.datosGenerales.dispatch_time) * (props.values.costs_intervention_hour / 60)}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Button
                    className={classes.saveButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disableElevation
                    disabled={readMode}
                >
                    Siguiente
                </Button>
            </Form>
        </div>
    );
}

CostosForm.defaultProps = {
    readMode: false,
};

export default CostosForm;