export default ({
    current_password: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    },
    new_password: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
        length: {
            maximum: 60,
            tooLong: 'Máximo %{count} caracteres',
            minimum: 8,
            tooShort: 'Mínimo %{count} caracteres',
        },
        fulfillPasswordStandards: true,
    },
    confirm_new_password: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
        equality: {
            attribute: 'new_password',
            message: 'Las contraseñas no coinciden.',
        },
        length: {
            maximum: 60,
            tooLong: 'Máximo %{count} caracteres',
            minimum: 8,
            tooShort: 'Mínimo %{count} caracteres',
        },
        fulfillPasswordStandards: true,
    },
});