import httpClient from '../http';
import qs from 'qs';

export const getEquipments = async (page, page_limit, filters) => {
    const filterParams = {
        page,
        page_limit,
        ...filters
    };
    const queryParams = qs.stringify(filterParams);
    return await httpClient.get(`/mt/equipments?${queryParams}`);
}

export const getEquipmentById = async (equipment_id) => {
    return await httpClient.get(`/mt/equipments/${equipment_id}`);
}

export const deleteEquipment = async (equipment_id) => {
    return await httpClient.delete(`/mt/equipments/${equipment_id}`);
}

export const createEquipment = async (equipment) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedEquipment = qs.stringify(equipment);
    return await httpClient.post(`/mt/equipments`, serializedEquipment, config);
}

export const updateEquipment = async (equipment_id, equipment) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedEquipment = qs.stringify(equipment);
    return await httpClient.put(`/mt/equipments/${equipment_id}`, serializedEquipment, config);
}