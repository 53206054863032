import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { selectMinWages, getMinWagesRequest } from '../../../../../../store/slices/minWagesSlice';

import { deleteMinWage } from '../../../../../../services/minWageService';

import { SearchField } from '../../../../../../components/SearchField';

import { Can, Only } from '../../../../../../components/Can';

import { ROLES_KEYS } from '../../../../../../constants/roles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import TablePagination from '@material-ui/core/TablePagination';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import currency from 'currency.js';

import { datosGlobalesUrls } from '../../../../routing';

const useStyles = makeStyles(styles);

const ListaSalarioMinimo = (props) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [minWageClickedData, setMinWageClickedData] = useState({
        openDialog: false,
        minWage: null,
    });
    const minWages = useSelector(selectMinWages);
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');

    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onEditMinWage = (minWage) => () => {
        const { history } = props;
        history.push(datosGlobalesUrls.EditMinwage.replace(':minwage_id', minWage.id));
    };

    const onDeleteMinWage = (minWage) => () => {
        setMinWageClickedData({
            openDialog: true,
            minWage,
        });
    };

    const onClickAddMinWage = () => {
        const { history } = props;
        history.push(datosGlobalesUrls.AddMinwage);
    };

    const handleCloseDeleteDialog = () => {
        setMinWageClickedData({
            openDialog: false,
            minWage: null,
        });
    }

    const handleMinWageDelete = async () => {
        try {
            const minWageId = minWageClickedData.minWage.id;
            await deleteMinWage(minWageId);
            setMinWageClickedData({
                openDialog: false,
                minWage: null,
            });
            dispatch(getMinWagesRequest({
                page: page + 1,
                page_limit: rowsPerPage,
            }));
        } catch (e) {
            const { response } = e;
            console.log(response);
        }
    }

    const onGetSearchTerm = (term) => {
        setSearchTerm(term);
        setPage(0);
    }

    useEffect(() => {
        dispatch(getMinWagesRequest({
            page: page + 1,
            page_limit: rowsPerPage,
            filters: {
                searchTerm: searchTerm !== '' ? searchTerm : undefined,
            },
        }));
    }, [dispatch, page, rowsPerPage, searchTerm]);

    return (
        <div className={classes.root}>
            <Dialog
                open={minWageClickedData.openDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-minwage-delete-title"
                aria-describedby="alert-dialog-minwage-delete-description"
            >
                <DialogTitle id="alert-dialog-minwage-delete-title">{"Confirmación"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-minwage-delete-description">
                        ¿Estás seguro de querer continuar con la eliminación del registro seleccionado?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleMinWageDelete} color="primary" autoFocus>
                        Continuar
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.fullWidth} spacing={2}>
                <Grid item md={6} xs={12} className={classes.searchInput}>
                    <SearchField onClickSearch={onGetSearchTerm} />
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" justify="space-between" className={classes.fullWidth}>
                <Grid item>
                    <Typography component={"span"}>
                        <Box fontWeight="fontWeightBold" fontSize={25}>Salario mínimo</Box>
                    </Typography>
                </Grid>
                <Grid item>
                    <Can>
                        <Only roles={[ROLES_KEYS.admin]}>
                            <Button 
                                variant="contained" 
                                color="secondary" 
                                className={classes.addSalaryButton} 
                                disableElevation
                                onClick={onClickAddMinWage}
                            >
                                Añadir salario 
                            </Button>
                        </Only>
                    </Can>
                </Grid>
            </Grid>
            <Paper elevation={0}>
                <TableContainer>
                    <Table className={classes.table} aria-label="min-wages-table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Año</TableCell>
                                <TableCell align="left">Salario</TableCell>
                                <Can>
                                    <Only roles={[ROLES_KEYS.admin]}>
                                        <TableCell align="right">Acciones</TableCell>
                                    </Only>
                                </Can>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {minWages.minWages.map((item, key) => (
                                <TableRow key={`table-row-min-wage-key-${key}`}>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {item.year}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {currency(item.amount).format()}
                                        </Typography>
                                    </TableCell>
                                    <Can>
                                        <Only roles={[ROLES_KEYS.admin]}>
                                            <TableCell align="right">
                                                <Grid container direction="row" alignItems="center" justify="flex-end" spacing={1}>
                                                    <Grid item>
                                                        <IconButton aria-label="edit" className={classes.iconButton} onClick={onEditMinWage(item)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton aria-label="delete" className={classes.iconButton} onClick={onDeleteMinWage(item)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </Only>
                                    </Can>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={minWages.total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage="Filas por página:"
                        labelDisplayedRows={() => {
                            return `Página: ${page + 1} de ${Math.ceil(minWages.total / rowsPerPage)}`;
                        }}
                    />
                </TableContainer>
            </Paper>
        </div>
    );
}

export default ListaSalarioMinimo;