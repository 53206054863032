import { 
    AddAvailability,
    AddDamageReport,
    AddEquipment,
    AddMaintenance,
    AddVehicle,
    EditAvailability,
    EditDamageReport,
    EditEquipment,
    EditMaintenance,
    EditVehicle,
    ListadoMotor,
} from './Pages';

import routesUrls from '../../routes/urls';

export const MotorUrls = {
    Listado: `${routesUrls.Motor}/listados`,
    AddAvailability: `${routesUrls.Motor}/agregar-disponibilidad`,
    AddDamageReport: `${routesUrls.Motor}/agregar-reporte-daño`,
    AddEquipment: `${routesUrls.Motor}/agregar-equipos`,
    AddMaintenance: `${routesUrls.Motor}/agregar-mantenimiento`,
    AddVehicle: `${routesUrls.Motor}/agregar-vehiculo`,
    EditAvailability: `${routesUrls.Motor}/editar-disponibilidad/:availability_id`,
    EditDamageReport: `${routesUrls.Motor}/editar-report-daño/:damage_report_id`,
    EditEquipment: `${routesUrls.Motor}/editar-equipo/:equipment_id`,
    EditMaintenance: `${routesUrls.Motor}/editar-mantenimiento/:maintenance_id`,
    EditVehicle: `${routesUrls.Motor}/editar-vehiculo/:vehicle_id`,
};

const MotorRouting = [{
    path: MotorUrls.Listado,
    component: ListadoMotor,
}, {
    path: MotorUrls.AddAvailability,
    component: AddAvailability,
}, {
    path: MotorUrls.AddDamageReport,
    component: AddDamageReport,
}, {
    path: MotorUrls.AddEquipment,
    component: AddEquipment,
}, {
    path: MotorUrls.AddMaintenance,
    component: AddMaintenance,
}, {
    path: MotorUrls.AddVehicle,
    component: AddVehicle,
}, {
    path: MotorUrls.EditAvailability,
    component: EditAvailability,
}, {
    path: MotorUrls.EditDamageReport,
    component: EditDamageReport,
}, {
    path: MotorUrls.EditEquipment,
    component: EditEquipment,
}, {
    path: MotorUrls.EditMaintenance,
    component: EditMaintenance,
}, {
    path: MotorUrls.EditVehicle,
    component: EditVehicle,
}];

export default MotorRouting;