import * as Yup from 'yup';

export default Yup.object().shape({
    name: Yup.string()
        .max(100, 'Máximo 100 carácteres.')
        .required('Éste campo es requerido.'),
    motif: Yup.string()
        .max(500, 'Máximo 500 carácteres.')
        .required('Éste campo es requerido.'),
    date: Yup.date()
        .required('Éste campo es requerido.'),
});