import * as Yup from 'yup';

export default Yup.object().shape({
    firstname: Yup.string()
        .required('Éste campo es requerido.'),
    lastname: Yup.string()
        .required('Éste campo es requerido.'),
    document_type: Yup.string()
        .required('Éste campo es requerido.'),
    document: Yup.string()
        .required('Éste campo es requerido.'),
    phone: Yup.string()
        .required('Éste campo es requerido.'),
    email: Yup.string()
        .required('Éste campo es requerido.'),
    risk_user_type: Yup.string()
        .required('Éste campo es requerido.'),
    department_id: Yup.number()
        .required('Éste campo es requerido.'),
    city_id: Yup.number()
        .required('Éste campo es requerido.'),
});