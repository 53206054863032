export default theme => ({
    scanner: {
        width: '25%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    icon: {
        fontSize: 50,
    },
    sucessColor: {
        color: theme.palette.primary.main,
    },
    errorColor: {
        color: theme.palette.error.main,
    },
    certificateCheckText: {
        color: '#5C5C5C',
    },
});