import { useState, useEffect, useCallback } from 'react';
import { getAreas } from '../services/areasService';

const useAreasByYear = (year) => {
    const [areas, setAreas] = useState([]);

    useEffect(() => {
        async function fetchAreas() {
            try {
                const { data: { results } } = await getAreas(1, 100, { year });
                setAreas(results);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchAreas();
    }, [year]);

    const findAreaByGivenAreaRange = useCallback((areaM2) => {
        const area = areas.find(area => areaM2 >= area.area_start && areaM2 <= area.area_end);
        return area;
    }, [areas]);

    return { areas, findAreaByGivenAreaRange };
}

export default useAreasByYear;