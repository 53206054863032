import React, { useState, useRef } from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import ButtonBase from '@material-ui/core/ButtonBase';
import InsertPhotoOutlinedIcon from '@material-ui/icons/InsertPhotoOutlined';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';

import schema from './schema';

import { uploadImage } from '../../../../../services/imageService';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../../store/slices/alertsSlice';

import useUsers from '../../../../../hooks/useUsers';
import usePositions from '../../../../../hooks/usePositions';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const EvidenciasForm = (props) => {
    const inputFileRef = useRef();
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const dispatch = useDispatch();
    const { readMode } = props;

    const { users, findUserById } = useUsers(1, 1000);
    const { findPositionNameById } = usePositions();

    const hasError = (field, formik) => {
        return formik.errors[field] && formik.touched[field] ? true : false;
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onClickDeleteEvidence = (evidence_index) => () => {
        const currentEvidences = props.evidencesData.evidences;
        currentEvidences.splice(evidence_index, 1);
        props.onChangeEvidences(currentEvidences);
    }

    const onClickImageOpen = (image_url) => () => {
        window.open(image_url, '_blank');
    }

    const onFilesSelected = (formik) => async (event) => {
        const files = event.target.files;
        let fileServicePromises = [];
        for(let file of files) {
            const fileUploadService = uploadImage(file);
            fileServicePromises.push(fileUploadService);
        }

        try {
            const imagesUploaded = await Promise.all([...fileServicePromises]);
            const imagesUploadedMapped = imagesUploaded.map(({ data: { image } }) => {
                return image.Location;
            });
            const currentImages = formik.values.images;
            const currentImagesUpdated = [...currentImages, ...imagesUploadedMapped];
            formik.setFieldValue('images', currentImagesUpdated);
        } catch(err) {
            console.log(err);
        }
    }

    const onClickInputFile = () => {
        inputFileRef.current.click();
    }

    const onClickDeleteImage = (index, formik) => () => {
        const currentUploadedImages = formik.values.images;
        currentUploadedImages.splice(index, 1);
        formik.setFieldValue('images', currentUploadedImages);
    }

    const handleNext = () => {
        if (props.evidencesData.evidence_apply && props.evidencesData.evidences.length === 0) {
            dispatch(setGlobalAlert({
                show: true,
                duration: 5000,
                message: 'Debe cargar al menos un registro.',
                severity: 'error',
            }));
            return;
        }
        props.onHandleNext();
    }

    return (
        <div className={classes.root}>
            <Formik
                initialValues={{
                    disposal_user_id: '',
                    description: '',
                    images: [],
                }}
                onSubmit={(values, actions) => {
                    const evidences = props.evidencesData.evidences;
                    evidences.push(values);
                    props.onChangeEvidences(evidences);
                    actions.resetForm();
                }}
                validationSchema={schema}
            >
                {formik => (
                    <Form>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="evidence_apply"
                                    checked={!props.evidencesData.evidence_apply}
                                    onChange={(event) => props.onToggleApply()}
                                    color="primary"
                                />
                            }
                            label="No aplica"
                            style={{marginBottom: '5px'}}
                            disabled={readMode}
                        />
                        <Grid container className={classes.row} direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item xs={12} md={6}>
                                <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                                    <Grid item xs={12} className={classes.fullWidth}>
                                        <FormControl 
                                            className={classes.formControl} 
                                            variant="outlined" 
                                            error={hasError('disposal_user_id', formik)} 
                                            disabled={!props.evidencesData.evidence_apply || readMode}
                                        >
                                            <InputLabel>Dejadas a disposición de</InputLabel>
                                            <Field 
                                                name="disposal_user_id" 
                                                type="text"
                                                as={Select} 
                                                label="Dejadas a disposición de"
                                                className={classes.formControl} 
                                            >
                                                {users.filter(user => !props.evidencesData.evidences.find(evidence => evidence.disposal_user_id === user.id)).map((user, key) => (
                                                    <MenuItem key={`disposal-key-${key}`} value={user.id}>{`${user.firstname} ${user.lastname}`}</MenuItem>
                                                ))}
                                            </Field>
                                            <FormHelperText>
                                                <ErrorMessage name="disposal_user_id" />
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} className={classes.fullWidth}>
                                        <Field 
                                            name="disposal_user_document" 
                                            type="text" 
                                            as={TextField} 
                                            variant="outlined" 
                                            label="Identificación"
                                            className={classes.formControl} 
                                            error={hasError('disposal_user_document', formik)}
                                            helperText={<ErrorMessage name="disposal_user_document" />}
                                            value={(() => {
                                                const user = formik.values.disposal_user_id !== '' ? findUserById(formik.values.disposal_user_id) : null;
                                                if (user === null) {
                                                    return '';
                                                }
                                                return `${user.document_type} - ${user.document}`;
                                            })()}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={classes.fullWidth}>
                                        <Field 
                                            name="disposal_user_position" 
                                            type="text" 
                                            as={TextField} 
                                            variant="outlined" 
                                            label="Cargo"
                                            className={classes.formControl} 
                                            error={hasError('disposal_user_position', formik)}
                                            helperText={<ErrorMessage name="disposal_user_position" />}
                                            value={(() => {
                                                const user = formik.values.disposal_user_id !== '' ? findUserById(formik.values.disposal_user_id) : null;
                                                if (user === null) {
                                                    return '';
                                                }
                                                const position = findPositionNameById(user.position_id);
                                                return `${position}`;
                                            })()}
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="description" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Descripción de evidencia"
                                    className={classes.formControl} 
                                    error={hasError('description', formik)}
                                    helperText={<ErrorMessage name="description" />}
                                    multiline
                                    rows={6}
                                    disabled={!props.evidencesData.evidence_apply || readMode}
                                />
                            </Grid>
                        </Grid>
                        <input type="file" ref={inputFileRef} className={classes.inputFile} onChange={onFilesSelected(formik)} multiple />
                        <Grid container direction="row" spacing={2} justify="flex-start" alignItems="flex-start">
                            {formik.values.images.map((image, key) => (
                                <Grid item key={`image-key-${key}`}>
                                    <div className={classes.ratioContainer}>
                                        <div className={classes.ratioContent}>
                                            <img alt="evidence" src={image} className={classes.image} />
                                        </div>
                                        <div className={classes.deleteButtonContainer}>
                                            <IconButton key={`loaded-image`} aria-label="loaded-image" className={classes.imageDeleteButton} onClick={onClickDeleteImage(key, formik)}>
                                                <HighlightOffRoundedIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                </Grid>
                            ))}
                            <Grid item>
                                <div className={classes.ratioContainer}>
                                    <div className={classes.ratioContent}>
                                        <ButtonBase onClick={onClickInputFile} className={classes.buttonBase} disabled={!props.evidencesData.evidence_apply || readMode}>
                                            <div style={{width: '100%', height: '100%'}}>
                                                <Grid container direction="column" justify="center" alignItems="center" spacing={1} className={classes.container}>
                                                    <Grid item>
                                                        {!props.evidencesData.evidence_apply || readMode ? (
                                                            <BlockOutlinedIcon className={classes.loadImageIcon} />
                                                        ) : (
                                                            <InsertPhotoOutlinedIcon className={classes.loadImageIcon} />
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </ButtonBase>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item className={classes.row}>
                                <Paper elevation={0}>
                                    <TableContainer>
                                        <Table className={classes.table} aria-label="activities-table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">A disposición de</TableCell>
                                                    <TableCell align="left">Identificación</TableCell>
                                                    <TableCell align="left">Cargo</TableCell>
                                                    <TableCell align="left">Descripción</TableCell>
                                                    <TableCell align="left">Imágenes</TableCell>
                                                    <TableCell align="right"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {props.evidencesData.evidences.map((evidence, key) => {
                                                    const user = findUserById(evidence.disposal_user_id);
                                                    if (user === undefined) {
                                                        return null;
                                                    }
                                                    const position = findPositionNameById(user.position_id);

                                                    return (
                                                        <TableRow key={`list-evidence-item-key-${key}`}>
                                                            <TableCell align="left">
                                                                <Typography component={"span"} className={classes.tableCellText}>
                                                                    {`${user.firstname} ${user.lastname}`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography component={"span"} className={classes.tableCellText}>
                                                                    {`${user.document_type} - ${user.document}`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography component={"span"} className={classes.tableCellText}>
                                                                    {`${position}`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography component={"span"} className={classes.tableCellText}>
                                                                    {`${evidence.description}`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {evidence.images.map((image_url, key) => (
                                                                    <IconButton key={`open-image-${user.id}-${key}`} aria-label="open-image" className={classes.iconButton} onClick={onClickImageOpen(image_url)}>
                                                                        <OpenInNewRoundedIcon />
                                                                    </IconButton>
                                                                ))}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <IconButton disabled={readMode} aria-label="delete" className={classes.iconButton} onClick={onClickDeleteEvidence(key)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={props.evidencesData.evidences.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            labelRowsPerPage="Filas por página:"
                                            labelDisplayedRows={() => {
                                                return `Página: ${page + 1} de ${Math.ceil(props.evidencesData.evidences.length / rowsPerPage)}`;
                                            }}
                                        />
                                    </TableContainer>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Button
                            className={classes.saveButton}
                            style={{marginRight: '10px', width: '200px'}}
                            color="primary"
                            size="large"
                            type="submit"
                            variant="outlined"
                            disableElevation
                            disabled={!props.evidencesData.evidence_apply || readMode}
                        >
                            Añadir evidencia 
                        </Button>
                        <Button
                            className={classes.saveButton}
                            color="primary"
                            size="large"
                            variant="contained"
                            disableElevation
                            onClick={handleNext}
                            disabled={readMode}
                        >
                            Siguiente
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

EvidenciasForm.defaultProps = {
    readMode: false,
};

export default EvidenciasForm;