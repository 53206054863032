export const RISK_TYPES = {
    "global": "Global",
    "department": "Departamental",
    "city": "Municipal",
    "station": "Estación",
};

export const RISK_TYPES_KEYS = {
    "global": "global",
    "department": "department",
    "city": "city",
    "station": "station",
};

// Éste es el que se usa para el formulario de creación/edición
// de agentes de riesgo.
export const FORM_RISK_TYPES = {
    "department": "Departamental",
    "city": "Municipal",
};