import { useState, useEffect } from 'react';
import { getCityById } from '../services/cityService';

const useCityById = (city_id) => {
    const [city, setCity] = useState(null);

    useEffect(() => {
        async function fetchCity() {
            try {
                const { data } = await getCityById(city_id);
                setCity(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchCity();
    }, [city_id]);

    return { city };
}

export default useCityById;