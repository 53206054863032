import * as Yup from 'yup';

export default Yup.object().shape({
    name: Yup.string()
        .max(100, 'Máximo 100 caracteres')
        .required('Éste campo es requerido.'),
    date: Yup.date()
        .required('Éste campo es requerido.'),
    time: Yup.string()
        .max(4, 'Máximo 4 dígitos')
        .required('Éste campo es requerido.'),
    emmited_by: Yup.string()
        .max(100, 'Máximo 100 caracteres')
        .required('Éste campo es requerido.'),
    registry_number: Yup.string()
        .max(20, 'Máximo 20 dígitos')
        .required('Éste campo es requerido.'),
});