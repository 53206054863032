import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';
import { getUsersRequest, selectUsers } from '../../../../store/slices/usersSlice';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { SearchField } from '../../../../components/SearchField';
import { AntSwitch } from '../../../../components/AntSwitch';
import { FilterButton } from '../../../../components/FilterButton';

import { Formik } from 'formik';
import { ListRiskAgentsFilterForm } from './ListRiskAgentsFilterForm';

import { 
    enableUser,
    disableUser,
} from '../../../../services/usersService';
import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';

import { RISK_TYPES, RISK_TYPES_KEYS } from '../../../../constants/risk_types';
import { ROLES_KEYS } from '../../../../constants/roles';

import { riskAgentsUrls } from '../../routing';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const ListRiskAgents = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const users = useSelector(selectUsers);
    const {
        backendResponse: enableUserResponse,
        executeService: enableUserService,
        resetBackendResponseState: enableUserServiceReset,
    } = useBackendMessageResponse(enableUser);
    const {
        backendResponse: disableUserResponse,
        executeService: disableUserService,
        resetBackendResponseState: disableUserServiceReset,
    } = useBackendMessageResponse(disableUser);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [userClickedData, setUserClickedData] = useState({
        openDialog: false,
        user: null,
    });
    const [filterFormData, setFilterFormData] = useState({
        document: '',
        name: '',
        email: '',
        risk_user_type: '',
    });

    const onGetSearchTerm = (term) => {
        setSearchTerm(term);
        setPage(0);
    }

    const onAddNewUser = () => {
        const { history } = props;
        history.push(riskAgentsUrls.AddRiskAgentUser);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onEditUser = (user) => () => {
        const { history } = props;
        history.push(riskAgentsUrls.EditRiskAgentUser.replace(':user_id', user.id));
    }

    const onUserSwitchChange = (user) => () => {
        setUserClickedData({
            openDialog: true,
            user,
        });
    }

    const onEnableDisableUser = async () => {
        const { user } = userClickedData;
        if (user.disabled) {
            await enableUserService(user.id);
        } else {
            await disableUserService(user.id);
        }
        dispatch(getUsersRequest({
            page: page + 1,
            page_limit: rowsPerPage,
            filters: {
                search_term: searchTerm !== '' ? searchTerm : undefined,
                ...cleanFilter(),
            },
        }));
        setUserClickedData({
            openDialog: false,
            user: null,
        });
    };

    const handleDialogClose = () => {
        setUserClickedData({
            openDialog: false,
            user: null,
        });
    };

    const canHaveActions = (user) => {
        return user.role === ROLES_KEYS.risk_agent && user.risk_user_type !== RISK_TYPES_KEYS.global;
    }

    const cleanFilter = useCallback(() => {
        const cleanFilteredData = filterFormData;
        Object.keys(cleanFilteredData).forEach((key) => {
            if (cleanFilteredData[key] === '') {
                delete cleanFilteredData[key];
            }
        });
        return cleanFilteredData;
    }, [filterFormData]);

    useEffect(() => {
        dispatch(getUsersRequest({
            page: page + 1,
            page_limit: rowsPerPage,
            filters: {
                search_term: searchTerm !== '' ? searchTerm : undefined,
                ...cleanFilter(),
            },
        }));
    }, [page, rowsPerPage, dispatch, searchTerm, cleanFilter]);

    useEffect(() => {
        if (enableUserResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: enableUserResponse.message,
            severity: 'success',
        }));
        
        enableUserServiceReset();
    }, [dispatch, enableUserResponse.message, enableUserServiceReset]);

    useEffect(() => {
        if (enableUserResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: enableUserResponse.error.message,
            severity: 'error',
        }));

        enableUserServiceReset();
    }, [dispatch, enableUserResponse.error, enableUserServiceReset])

    useEffect(() => {
        if (disableUserResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: disableUserResponse.message,
            severity: 'success',
        }));

        disableUserServiceReset();
    }, [dispatch, disableUserResponse.message, disableUserServiceReset]);

    useEffect(() => {
        if (disableUserResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: disableUserResponse.error.message,
            severity: 'error',
        }));

        disableUserServiceReset();
    }, [dispatch, disableUserResponse.error, disableUserServiceReset])

    return (
        <div className={classes.root}>
            <Dialog
                open={userClickedData.openDialog}
                onClose={handleDialogClose}
                aria-labelledby="enable-disable-dialog-title"
                aria-describedby="enable-disable-dialog-description"
            >
                <DialogTitle id="enable-disable-dialog-title">{"Confirmación"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="enable-disable-dialog-description">
                        {`¿Estás seguro que desea ${userClickedData.user !== null && userClickedData.user.disabled ? `habilitar` : `inhabilitar`} al usuario seleccionado?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        No
                    </Button>
                    <Button onClick={onEnableDisableUser} color="primary" autoFocus>
                        Sí
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.fullWidth} spacing={2}>
                <Grid item md={6} xs={12} className={classes.searchInput}>
                    <SearchField onClickSearch={onGetSearchTerm} />
                </Grid>
                <Grid item>
                    <FilterButton>
                        {filterActions => (
                            <div className={classes.filterForm}>
                                <Formik 
                                    enableReinitialize
                                    initialValues={filterFormData}
                                    component={ListRiskAgentsFilterForm}
                                    onSubmit={(values) => {
                                        const submitter = window.event.submitter.name;
                                        setPage(0);
                                        if (submitter === "clean") {
                                            // Acá se tiene que vaciar el estado de esta forma en lugar de:
                                            // action.resetForm(); ya que FilterButton usa un Popper internamente y se está usando
                                            // filterFormData como valores iniciales. Por lo que al montar y desmontar
                                            // a través del Popper se van a tomar los valores del estado.
                                            setFilterFormData({
                                                document: '',
                                                name: '',
                                                email: '',
                                                risk_user_type: '',
                                            });
                                            filterActions.hideFilterForm();
                                            return;
                                        }
                                        setFilterFormData(values);
                                        filterActions.hideFilterForm();
                                    }}
                                />
                            </div>
                        )}
                    </FilterButton>
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" justify="space-between" className={classes.fullWidth}>
                <Grid item>
                    <Typography component={"span"}>
                        <Box fontWeight="fontWeightBold" fontSize={25}>Usuarios</Box>
                    </Typography>
                </Grid>
                <Grid item>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        className={classes.addUserButton} 
                        disableElevation
                        onClick={onAddNewUser}
                    >
                        Añadir usuarios
                    </Button>
                </Grid>
            </Grid>
            <Paper elevation={0} className={classes.fullWidth}>
                <TableContainer>
                    <Table className={classes.table} aria-label="levels-table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">N. de identificación</TableCell>                                
                                <TableCell align="left">Nombre y apellido</TableCell>                                
                                <TableCell align="left">Correo electrónico</TableCell>                                
                                <TableCell align="left">Tipo de ubicación</TableCell>                                
                                <TableCell align="right">Acciones</TableCell>                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.users.map((user, key) => (
                                <TableRow key={`risk-agent-users-key-${key}`}>
                                    <TableCell align="left">
                                        <Typography component="span" className={classes.tableCellText}>
                                            {user.document}
                                        </Typography>
                                    </TableCell>                                
                                    <TableCell align="left">
                                        <Typography component="span" className={classes.tableCellText}>
                                            {user.firstname + " " + user.lastname}
                                        </Typography>
                                    </TableCell>                                
                                    <TableCell align="left">
                                        <Typography component="span" className={classes.tableCellText}>
                                            {user.email}
                                        </Typography>
                                    </TableCell>                                
                                    <TableCell align="left">
                                        <Typography component="span" className={classes.tableCellText}>
                                            {RISK_TYPES[user.risk_user_type] ?? 'No definido'}
                                        </Typography>
                                    </TableCell>                                
                                    <TableCell align="right">
                                        {!canHaveActions(user) ? (
                                        <Typography component="span" className={classes.tableCellText}>
                                            {`Sin permisos`}
                                        </Typography>
                                        ) : (
                                        <Grid container direction="row" alignItems="center" justify="flex-end" spacing={1}>
                                            <Grid item>
                                                <IconButton aria-label="edit" className={classes.iconButton} onClick={onEditUser(user)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <AntSwitch checked={!user.disabled} name="checked" onChange={onUserSwitchChange(user)} />
                                            </Grid>
                                        </Grid>
                                        )}
                                    </TableCell>                                
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={users.total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelRowsPerPage="Filas por página:"
                    labelDisplayedRows={() => {
                        return `Página: ${page + 1} de ${Math.ceil(users.total / rowsPerPage)}`;
                    }}
                />
            </Paper>
        </div>
    );
}

export default ListRiskAgents;