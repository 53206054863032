import { useState, useEffect } from 'react';
import { getUsers } from '../services/usersService';

const useUserByPosition = (position_name) => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        async function fetchUsers() {
            try {
                // El 1000 es porque necesitamos traer a todos los operarios de la estación
                // Entonces se coloca para traer una sola página muy grande.
                // De ésta forma se reusa el mismo endpoint para obtención de usuarios.
                // En caso de que llegue a haber más, es un valor que debe aumentarse o buscar otras alternativas.
                // (i.e. crear un endpoint separado en el backend).
                const page = 1;
                const page_limit = 1000;
                const filters = {
                    position_name,
                };
                const { data: { results } } = await getUsers(page, page_limit, filters);
                setUsers(results);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchUsers();
    }, [position_name]);

    return users;
}

export default useUserByPosition;