import React, { useState, useEffect } from 'react';
import{ useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';
import { createUser } from '../../../../services/usersService';

import { Formik } from 'formik';
import { RiskAgentForm } from '../Forms/RiskAgentForm';
import RiskAgentFormSchema from '../Forms/RiskAgentForm/schema';
import { ROLES_KEYS } from '../../../../constants/roles';

import { riskAgentsUrls } from '../../routing';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const AddRiskAgent = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        backendResponse: createUserResponse,
        executeService: createUserService,
    } = useBackendMessageResponse(createUser);
    const [riskUserData, setRiskUserData] = useState({
        firstname: '',
        lastname: '',
        document_type: '',
        document: '',
        phone: '',
        email: '',
        risk_user_type: '',
        department_id: '',
        city_id: '',
    });

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    useEffect(() => {
        if (createUserResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createUserResponse.message,
            severity: 'success',
        }));

        props.history.push(riskAgentsUrls.Listado);
    }, [dispatch, createUserResponse.message, props.history]);

    useEffect(() => {
        if (createUserResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createUserResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, createUserResponse.error])

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Nuevo usuario</Box>
            </Typography>
            <div className={classes.form}>
                <Formik
                    initialValues={riskUserData}
                    component={RiskAgentForm}
                    validationSchema={RiskAgentFormSchema}
                    onSubmit={async (values) => {
                        setRiskUserData(values);
                        const riskUserData = {
                            ...values,
                            password: values.email,
                            role: ROLES_KEYS.risk_agent,
                        };
                        await createUserService(riskUserData);
                    }}
                />
            </div>
        </div>
    );
}

export default AddRiskAgent;