import React from 'react';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Form, Field, ErrorMessage } from 'formik';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

const useStyles = makeStyles(styles);

const DatosImpuestoForm = (props) => {
    const classes = useStyles();

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Form>
                <Grid container direction="column" justify="center" alignItems="flex-start" spacing={3}>
                    <Grid item>
                        <Typography component={"span"}>
                            <Box fontWeight="fontWeightBold" fontSize={18}>Datos Impuesto</Box>
                        </Typography>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="tax_date" 
                                    as={KeyboardDatePicker} 
                                    label="Fecha"
                                    className={classes.formControl} 
                                    inputVariant="outlined"
                                    variant="inline"
                                    InputAdornmentProps={{ position: "end" }}
                                    format="dd/MM/yyyy"
                                    onChange={(date) => props.setFieldValue("tax_date", date)}
                                    error={hasError('tax_date')}
                                    helperText={<ErrorMessage name="tax_date" />}
                                    autoOk
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="tax_form_number" 
                                    type="number" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Número de formulario"
                                    className={classes.formControl} 
                                    error={hasError('tax_form_number')}
                                    helperText={<ErrorMessage name="tax_form_number" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="tax_value" 
                                    type="text" 
                                    as={CurrencyTextField} 
                                    variant="outlined" 
                                    label="Valor pagado"
                                    className={classes.formControl} 
                                    error={hasError('tax_value')}
                                    helperText={<ErrorMessage name="tax_value" />}
                                    onChange={(event, value) => props.setFieldValue('tax_value', value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={clsx(classes.row, classes.topSpacing)} direction="row" justify="flex-start" alignItems="center" spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Grid container direction="column" justify="center" alignItems="flex-start" spacing={3}>
                            <Grid item>
                                <Typography component={"span"}>
                                    <Box fontWeight="fontWeightBold" fontSize={18}>Datos Soat</Box>
                                </Typography>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Field 
                                    name="soat_expedition_date" 
                                    as={KeyboardDatePicker} 
                                    label="Fecha de expedición"
                                    className={classes.formControl} 
                                    inputVariant="outlined"
                                    variant="inline"
                                    InputAdornmentProps={{ position: "end" }}
                                    format="dd/MM/yyyy"
                                    onChange={(date) => props.setFieldValue("soat_expedition_date", date)}
                                    error={hasError('soat_expedition_date')}
                                    helperText={<ErrorMessage name="soat_expedition_date" />}
                                    autoOk
                                />
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Field 
                                    name="soat_due_date" 
                                    as={KeyboardDatePicker} 
                                    label="Fecha de vencimiento"
                                    className={classes.formControl} 
                                    inputVariant="outlined"
                                    variant="inline"
                                    InputAdornmentProps={{ position: "end" }}
                                    format="dd/MM/yyyy"
                                    onChange={(date) => props.setFieldValue("soat_due_date", date)}
                                    error={hasError('soat_due_date')}
                                    helperText={<ErrorMessage name="soat_due_date" />}
                                    autoOk
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container direction="column" justify="center" alignItems="flex-start" spacing={3}>
                            <Grid item>
                                <Typography component={"span"}>
                                    <Box fontWeight="fontWeightBold" fontSize={18}>Datos tecnomecánica</Box>
                                </Typography>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Field 
                                    name="tm_expedition_date" 
                                    as={KeyboardDatePicker} 
                                    label="Fecha de expedición"
                                    className={classes.formControl} 
                                    inputVariant="outlined"
                                    variant="inline"
                                    InputAdornmentProps={{ position: "end" }}
                                    format="dd/MM/yyyy"
                                    onChange={(date) => props.setFieldValue("tm_expedition_date", date)}
                                    error={hasError('tm_expedition_date')}
                                    helperText={<ErrorMessage name="tm_expedition_date" />}
                                    autoOk
                                />
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Field 
                                    name="tm_due_date" 
                                    as={KeyboardDatePicker} 
                                    label="Fecha de vencimiento"
                                    className={classes.formControl} 
                                    inputVariant="outlined"
                                    variant="inline"
                                    InputAdornmentProps={{ position: "end" }}
                                    format="dd/MM/yyyy"
                                    onChange={(date) => props.setFieldValue("tm_due_date", date)}
                                    error={hasError('tm_due_date')}
                                    helperText={<ErrorMessage name="tm_due_date" />}
                                    autoOk
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Button
                    className={classes.saveButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disableElevation
                >
                    Guardar
                </Button>
            </Form>
        </MuiPickersUtilsProvider>
    );
};

export default DatosImpuestoForm;