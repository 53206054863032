import * as Yup from 'yup';

import { getVehicles } from '../../../../../../services/vehiclesService';
import debounce from 'lodash/debounce';

export default Yup.object({
    code: Yup.string()
        .max(15, 'Éste campo sólo permite 15 caracteres.')
        .required('Éste campo es requerido.')
        .test(
            'vehicle-code-in-use',
            'El código ya se encuentra registrado.', 
            async function (code_num) {
                // A ésta función no se le pone el debounce porque por alguna razón
                // si se hace click sobre el campo, y luego le quitamos el focus, despliega el error.
                // Pero eso sólo sucede la primera vez que se hace, si se le vuelve a poner el focus y a quitar ya no sucede.
                // Por qué sucede esto? ¯\_(ツ)_/¯
                const currentVehicleId = this.options.context.id === undefined ? null : this.options.context.id;
                const { data: { results }} = await getVehicles(1, 1, { code: code_num, exact: true });
                return results.length !== 0 && currentVehicleId !== results[0].id ? false : true;
            }),
    owner_name: Yup.string()
        .max(100, 'Éste campo sólo permite 100 caracteres.')
        .required('Éste campo es requerido.'),
    class: Yup.string()
        .max(60, 'Éste campo sólo permite 60 caracteres.')
        .required('Éste campo es requerido.'),
    registration_number: Yup.string()
        .max(20, 'Máximo 20 caracteres.')
        .required('Éste campo es requerido.'),
    enter_date: Yup.date()
        .required('Éste campo es requerido'),
    registration_class: Yup.string()
        .required('Éste campo es requerido.'),
    provider_name: Yup.string()
        .max(100, 'Éste campo sólo permite 100 caracteres.')
        .required('Éste campo es requerido.'),
    plate_number: Yup.string()
        .max(6, 'Éste campo sólo permite 6 caracteres.')
        .required('Éste campo es requerido')
        .test(
            'plate-in-use',
            'La placa ya se encuentra registrada.', 
            debounce(async function(plate, yup) {
                const currentVehicleId = this.options.context.id === undefined ? null : this.options.context.id;
                const { data: { results }} = await getVehicles(1, 1, { plate_number: plate, exact: true });
                return results.length !== 0 && currentVehicleId !== results[0].id ? false : true;
            }, 150)),
    cost: Yup.string()
        .max(16, 'Máximo 16 dígitos.')
        .required('Éste campo es requerido.'),
    description: Yup.string()
        .max(500, 'Éste campo sólo permite 500 caracteres.')
        .required('Éste campo es requerido.'),
    enter_state: Yup.string()
        .required('Éste campo es requerido.'),
    owning_method: Yup.string()
        .required('Éste campo es requerido.'),
});