import httpClient from '../../http';
import qs from 'qs';

export const getDamageReports = async (page, page_limit, filters) => {
    const filterParams = {
        page,
        page_limit,
        ...filters
    };
    const queryParams = qs.stringify(filterParams);
    return await httpClient.get(`/mt/damage_reports?${queryParams}`);
}

export const getDamageReportById = async (report_id) => {
    return await httpClient.get(`/mt/damage_reports/${report_id}`);
}

export const deleteDamageReport = async (report_id) => {
    return await httpClient.delete(`/mt/damage_reports/${report_id}`);
}

export const createDamageReport = async (report) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedDamageReport = qs.stringify(report);
    return await httpClient.post('/mt/damage_reports', serializedDamageReport, config);
}

export const updateDamageReport = async (report_id, report) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedReport = qs.stringify(report);
    return await httpClient.put(`/mt/damage_reports/${report_id}`, serializedReport, config);
}