import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import useEmergencyById from '../../../../hooks/useEmergencyById';

import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';
import { updateEmergency } from '../../../../services/emergenciesService';

import { configurationUrls } from '../../routing';

import validate from 'validate.js';
import schema from './schema';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const EditEmergency = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { emergency_id } = useParams();
    const { emergency } = useEmergencyById(emergency_id);
    const {
        backendResponse: updateEmergencyResponse,
        executeService: updateEmergencyService,
    } = useBackendMessageResponse(updateEmergency);
    const [emergencyForm, setEmergencyForm] = useState({
        isValid: false,
        isSubmitted: false,
        values: {
            code: '',
            name: '',
        },
        touched: {
            code: false,
            name: false,
        }, 
        errors: {},
    });

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    const onSubmitEditEmergency = async (event) => {
        event.preventDefault();

        if (!emergencyForm.isSubmitted) {
            setEmergencyForm(currentEmergencyForm => ({
                ...currentEmergencyForm,
                isSubmitted: true,
            }));
        }

        if (!emergencyForm.isValid) {
            return;
        }

        const emergency = emergencyForm.values;
        await updateEmergencyService(emergency_id, emergency);
    }

    const hasError = field =>
        (emergencyForm.isSubmitted || emergencyForm.touched[field]) && emergencyForm.errors[field] ? true : false;

    const handleChange = event => {
        event.persist();

        setEmergencyForm(currentEmergencyForm => ({
            ...currentEmergencyForm,
            values: {
                ...currentEmergencyForm.values,
                [event.target.name]: event.target.value,
            },
            touched: {
                ...currentEmergencyForm.touched,
                [event.target.name]: true
            }
        }));
    };

    useEffect(() => {
        const errors = validate(emergencyForm.values, schema);

        setEmergencyForm(currentEmergencyForm => ({
            ...currentEmergencyForm,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [emergencyForm.values, emergencyForm.isSubmitted]);

    useEffect(() => {
        if(emergency === null) {
            return;
        }

        setEmergencyForm(currentEmergencyForm => ({
            ...currentEmergencyForm,
            isValid: true,
            values: {
                code: emergency.code,
                name: emergency.name,
            },
        }));
    }, [emergency]);

    useEffect(() => {
        if (updateEmergencyResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            severity: 'success',
            message: updateEmergencyResponse.message,
        }));
        props.history.push(configurationUrls.Listado);
    }, [dispatch, props.history, updateEmergencyResponse.message]);

    useEffect(() => {
        if (updateEmergencyResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            severity: 'error',
            message: updateEmergencyResponse.error.message,
        }));
    }, [dispatch, updateEmergencyResponse.error]);

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Editar emergencia</Box>
            </Typography>
            <form onSubmit={onSubmitEditEmergency} className={classes.form}>
                <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
                    <Grid item>
                        <TextField 
                            id="code-emergency-input" 
                            label="Código de la emergencia" 
                            variant="outlined" 
                            className={classes.formControl} 
                            error={hasError('code')}
                            helperText={
                                hasError('code') ? emergencyForm.errors.code[0] : null
                            }
                            name="code"
                            type="number"
                            value={emergencyForm.values.code}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item>
                        <TextField 
                            id="name-emergency-input" 
                            label="Nombre de la emergencia" 
                            variant="outlined" 
                            className={classes.formControl} 
                            error={hasError('name')}
                            helperText={
                                hasError('name') ? emergencyForm.errors.name[0] : null
                            }
                            name="name"
                            value={emergencyForm.values.name}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Button
                    className={classes.saveButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disableElevation
                >
                    Guardar
                </Button>
            </form>
        </div>
    );
}

export default EditEmergency;