import validate from 'validate.js';
import { getUsers } from '../services/usersService';

validate.validators.isUniqueEmail = async function(value, options, key, attributes) {
    const filters = {
        email: value,
    };
    const { data: { results }} = await getUsers(1, 1, filters);
    if (results.length !== 0 && results[0].email === value) {
        if (options["verifySameUser"]) {
            const userId = attributes["fromId"];
            if (userId === results[0].id) {
                return null;
            }
        }
        const { message } = options;
        return message;
    }
    return null;
};