import * as Yup from 'yup';

export default Yup.object().shape({
    currently: Yup.boolean(),
    start_date: Yup.date()
        .required('Éste campo es requerido.'),
    retirement_date: Yup.date()
        .when(["start_date", "currently"], (start_date, currently, yup) => {
            if (currently) {
                return yup.required('Éste campo es requerido.');
            }
            return start_date 
                ? yup.min(start_date, "La fecha de retiro debe ser mayor a la fecha de ingreso.") 
                : yup;
        }),
    rank: Yup.number()
        .required('Éste campo es requerido.'),
    institution: Yup.string()
        .max(100, "Máximo 100 caracteres.")
        .required('Éste campo es requerido.'),
});