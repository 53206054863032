import { createSlice } from '@reduxjs/toolkit';

export const departmentsSlice = createSlice({
    name: 'departments',
    initialState: {
        isLoading: false,
        departments: [],
        total: null,
        pages: null,
        page: 1,
        page_limit: 10,
        error: {
            code: null,
            message: null,
        },
    },
    reducers: {
        fetchDepartmentsRequest: state => {
            state.isLoading = true;
        },
        fetchDepartmentsSuccess: (state, action) => {
            state.isLoading = false;
            state.departments = action.payload.departments;
            state.total = action.payload.total;
            state.pages = action.payload.pages;
            state.page = action.payload.page;
            state.page_limit = action.payload.page_limit;
            state.error = {
                code: null,
                message: null,
            };
        },
        fetchDepartmentsFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
    },
});

export const {
    fetchDepartmentsRequest,
    fetchDepartmentsSuccess,
    fetchDepartmentsFailure,
} = departmentsSlice.actions;

export const selectDepartments = state => state.departments;

export default departmentsSlice.reducer;