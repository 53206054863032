export const clientFormSchema = {
  email: {
    presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    email: {
      message: '\'%{value}\' no es una dirección válida.',
    },
    length: {
      maximum: 60,
      tooLong: "Éste campo sólo puede tener un máximo de %{count} caracteres.",
    }
  },
  firstname: {
    presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    length: {
      maximum: 100,
      tooLong: "Éste campo sólo puede tener un máximo de %{count} caracteres."
    }
  },
  lastname: {
    presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    length: {
      maximum: 100,
      tooLong: "Éste campo sólo puede tener un máximo de %{count} caracteres."
    }
  },
  document: {
    presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    length: {
      maximum: 15,
      tooLong: "Éste campo sólo puede tener un máximo de %{count} caracteres."
    }
  },
  phone: {
    presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    length: {
      maximum: 16,
      tooLong: "Éste campo sólo puede tener un máximo de %{count} caracteres."
    }
  },
  department_id: {
    presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
  },
  city_id: {
    presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
  },
};

export const stationFormSchema = {
  station_name: {
    presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    length: {
      maximum: 100,
      tooLong: "Éste campo sólo puede tener un máximo de %{count} caracteres."
    },
  },
  station_phone: {
    presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    length: {
      maximum: 100,
      tooLong: "Éste campo sólo puede tener un máximo de %{count} caracteres."
    },
  },
  station_address: {
    presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    length: {
      maximum: 100,
      tooLong: "Éste campo sólo puede tener un máximo de %{count} caracteres."
    },
  },
};

export const mtRangeDateSchema = {
  mt_end_date: {
    isAfterDate: {
      compareWith: 'mt_start_date',
      message: 'La fecha final no puede ser menor a la inicial.',
      checkEquality: true,
    },
    isTodayOrLater: {
      message: 'La fecha tiene que ser igual o superior a hoy.',
    },
  },
};

export const grRangeDateSchema = {
  gr_end_date: {
    isAfterDate: {
      compareWith: 'gr_start_date',
      message: 'La fecha final no puede ser menor a la inicial.',
      checkEquality: true,
    },
    isTodayOrLater: {
      message: 'La fecha tiene que ser igual o superior a hoy.',
    },
  },
};

export const stRangeDateSchema = {
  st_end_date: {
    isAfterDate: {
      compareWith: 'st_start_date',
      message: 'La fecha final no puede ser menor a la inicial.',
      checkEquality: true,
    },
    isTodayOrLater: {
      message: 'La fecha tiene que ser igual o superior a hoy.',
    },
  },
};

export const asyncClientFormSchema = {
  email: {
    isUniqueEmail: {
      message: 'El correo ya está siendo utilizado.',
      verifySameUser: true,
    },
  },
  document: {
    isUniqueDocument: {
      message: 'La identificación ya está siendo utilizada.',
      verifySameUser: true,
    },
  },
  // Esto es necesario para que 'verifySameUser' funcione.
  // Porque necesitamos saber si el correo pertenece al mismo usuario que se intenta editar.
  // Si es así, no hay por qué saltar un error.
  fromId: {
    type: "number",
  },
};