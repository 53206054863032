export default ({
  year: {
    presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    length: {
      is: 4,
      wrongLength: 'Tiene que tener %{count} dígitos.'
    },
  },
  amount: {
    presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
  }
});