import * as Yup from 'yup';

import { getUsers } from '../../../../../services/usersService';

export default Yup.object().shape({
    // StationUser
    firstname: Yup.string()
        .max(100, 'Máximo 100 caracteres.')
        .required('Éste campo es requerido.'),
    lastname: Yup.string()
        .max(100, 'Máximo 100 caracteres.')
        .required('Éste campo es requerido.'),
    document_type: Yup.string()
        .required('Éste campo es requerido.'),
    document: Yup.string()
        .max(16, 'Máximo 16 caracteres.')
        .required('Éste campo es requerido.')
        .test(
            'user-document-in-use',
            'La identificación ya está registrada.',
            async function (document) {
                const userId = this.options.context.id === undefined ? null : this.options.context.id;
                const document_type = this.options.context.document_type;
                const { data: { results }} = await getUsers(1, 1, { document_type, document });
                return results.length !== 0 && userId !== results[0].id ? false : true;
            },
        ),
    email: Yup.string()
        .email('Correo inválido')
        .required('Éste campo es requerido.'),
    genre: Yup.string()
        .required('Éste campo es requerido.'),
    nationality: Yup.string()
        .max(30, 'Máximo 30 caracteres.')
        .required('Éste campo es requerido.'),
    country_id: Yup.number()
        .required('Éste campo es requerido.'),
    department_id: Yup.number()
        .required('Éste campo es requerido.'),
    city_id: Yup.number()
        .required('Éste campo es requerido.'),
    born_date: Yup.date()
        .required('Éste campo es requerido.'),
    born_city_id: Yup.number()
        .required('Éste campo es requerido.'),
    military_type: Yup.string()
        .required('Éste campo es requerido.'),
    military_number: Yup.string()
        .max(15, 'Máximo 15 caracteres.')
        .required('Éste campo es requerido.'),
    address: Yup.string()
        .max(100, 'Máximo 100 caracteres.')
        .required('Éste campo es requerido.'),
    position_id: Yup.number()
        .required('Éste campo es requerido.'),
    rank_id: Yup.number()
        .required('Éste campo es requerido.'),
    phone: Yup.string()
        .max(13, 'Máximo 13 dígitos.')
        .required('Éste campo es requerido.'),
    image_url: Yup.string(),
    logo_1_url: Yup.string(),
    logo_2_url: Yup.string(),
    signature_url: Yup.string(),

    // Station
    station_name: Yup.string()
        .max(100, 'Máximo 100 caracteres.')
        .required('Éste campo es requerido.'),
    station_address: Yup.string()
        .max(100, 'Máximo 100 caracteres.')
        .required('Éste campo es requerido.'),
    station_phone: Yup.string()
        .max(13, 'Máximo 13 dígitos.')
        .required('Éste campo es requerido.'),

    // Education
    education_level_id: Yup.number()
        .required('Éste campo es requerido.'),
});