import React from 'react';
import './App.css';
import validate from 'validate.js';

import { ThemeProvider } from '@material-ui/styles';
import lightTheme from './themes/light';

import { BrowserRouter as Router } from 'react-router-dom';
import browserHistory from './history';

import { Routes } from './routes';

import './validators';

validate.options = {
  fullMessages: false,
};

validate.async.options = {
  fullMessages: false,
};

function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <Router history={browserHistory}>
        <Routes />
      </Router>
    </ThemeProvider>
  );
}

export default App;
