import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../../../store/slices/alertsSlice';
import { startLinearProgress, stopLinearProgress } from '../../../../../../store/slices/linearProgressSlice';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { STReportCard } from '../../../../../../components/STReportCard';

import { REPORT_TYPES, REPORT_TYPES_FULLNAMES } from '../../../../../../constants/ST/reports';
import { SUBMIT_MESSAGE } from '../../../../../../constants/reports';
import { downloadSTReport } from '../../../../../../services/ST/reportsService';
import FileDownload from 'js-file-download';
import useReportNameGenerator from '../../../../../../hooks/useReportNameGenerator';

import {
    StListReportForm,
    TypeNeighborhoodReportForm,
    EmergencyAttendanceReportForm,
    FirefighterRecordReportForm,
    EmergencyStatisticsReportForm,
    EmergencyTypeReportForm,
} from '../../../../Forms/Reports';

import StListReportSchema from '../../../../Forms/Reports/StListReportForm/schema';
import TypeNeighborhoodFormSchema from '../../../../Forms/Reports/TypeNeighborhoodReportForm/schema';
import EmergencyAttendanceSchema from '../../../../Forms/Reports/EmergencyAttendanceReportForm/schema';
import FirefighterRecordSchema from '../../../../Forms/Reports/FirefighterRecordReportForm/schema';
import EmergencyStatisticsSchema from '../../../../Forms/Reports/EmergencyStatisticsReportForm/schema';
import EmergencyTypeSchema from '../../../../Forms/Reports/EmergencyTypeReportForm/schema';

import { Formik } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const Consultas = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [selectedType, setSelectedType] = useState(null);
    const generateName = useReportNameGenerator();

    const goBack = () => {
        setSelectedType(null);
    }

    return (
        <div className={classes.root}>
            {selectedType === null && (
            <div className={classes.fullWidth}>
                <Typography component={"span"} className={classes.title}>
                    <Box fontWeight="fontWeightBold" fontSize={25}>Consulta general</Box>
                </Typography>
                <br />
                <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item xs={12} md={4} className={classes.card}>
                                <STReportCard 
                                    onButtonClick={() => setSelectedType(REPORT_TYPES.st_list)} 
                                    CardText={'Consulte resumen de Siniestralidad'} 
                                    ButtonText={'Siguiente'} 
                                />
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.card}>
                                <STReportCard 
                                    onButtonClick={() => setSelectedType(REPORT_TYPES.firefighter_record)} 
                                    CardText={'Consulte record de emergencias de bombero'} 
                                    ButtonText={'Siguiente'} 
                                />
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.card}>
                                <STReportCard 
                                    onButtonClick={() => setSelectedType(REPORT_TYPES.emergencies_by_type_and_neighborhood)} 
                                    CardText={'Consulte tipo de emergencia y barrio'} 
                                    ButtonText={'Siguiente'} 
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item xs={12} md={4} className={classes.card}>
                                <STReportCard 
                                    onButtonClick={() => setSelectedType(REPORT_TYPES.emergency_statistics)} 
                                    CardText={'Consulte informe estadístico de Emergencia'} 
                                    ButtonText={'Siguiente'} 
                                />
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.card}>
                                <STReportCard 
                                    onButtonClick={() => setSelectedType(REPORT_TYPES.emergency_attendance)} 
                                    CardText={'Consulte informe de emergencia por ID'} 
                                    ButtonText={'Siguiente'} 
                                />
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.card}>
                                <STReportCard 
                                    onButtonClick={async () => {
                                        try {
                                            dispatch(startLinearProgress());
                                            dispatch(setGlobalAlert({
                                                show: true,
                                                duration: 5000,
                                                message: SUBMIT_MESSAGE,
                                                severity: 'info',
                                            }));
                                            const result = await downloadSTReport({
                                                type: REPORT_TYPES.emergencies_list,
                                            });
                                            const filename = generateName(REPORT_TYPES_FULLNAMES, REPORT_TYPES.emergencies_list);
                                            FileDownload(result.data, filename);
                                            dispatch(stopLinearProgress());
                                        } catch(err) {
                                            const { response } = err;
                                            dispatch(setGlobalAlert({
                                                show: true,
                                                duration: 5000,
                                                message: response.data.message,
                                                severity: 'error',
                                            }));
                                            dispatch(stopLinearProgress());
                                        }
                                    }} 
                                    CardText={'Consulte listado de emergencias'} 
                                    ButtonText={'Descargar'} 
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item xs={12} md={4} className={classes.card}>
                                <STReportCard 
                                    onButtonClick={() => setSelectedType(REPORT_TYPES.emergencies_by_type)} 
                                    CardText={'Consulte por tipo de Emergencia'} 
                                    ButtonText={'Siguiente'} 
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            )}
            {selectedType !== null && (
                <div className={classes.fullWidth}>
                    {selectedType === REPORT_TYPES.st_list && (
                        <Formik
                            initialValues={{
                                start_date: new Date(),
                                end_date: new Date(),
                                type: REPORT_TYPES.st_list,
                            }}
                            onSubmit={async (values) => {
                                try {
                                    dispatch(startLinearProgress());
                                    dispatch(setGlobalAlert({
                                        show: true,
                                        duration: 5000,
                                        message: SUBMIT_MESSAGE,
                                        severity: 'info',
                                    }));
                                    const result = await downloadSTReport(values);
                                    const filename = generateName(REPORT_TYPES_FULLNAMES, values.type);
                                    FileDownload(result.data, filename);
                                    dispatch(stopLinearProgress());
                                } catch(err) {
                                    const { response } = err;
                                    dispatch(setGlobalAlert({
                                        show: true,
                                        duration: 5000,
                                        message: response.data.message,
                                        severity: 'error',
                                    }));
                                    dispatch(stopLinearProgress());
                                }
                            }}
                            validationSchema={StListReportSchema}
                        >
                            {(formikProps) => (
                                <StListReportForm {...formikProps} goBack={goBack} />
                            )}
                        </Formik>
                    )}
                    {selectedType === REPORT_TYPES.emergencies_by_type_and_neighborhood && (
                        <Formik
                            initialValues={{
                                start_date: new Date(),
                                end_date: new Date(),
                                type: REPORT_TYPES.emergencies_by_type_and_neighborhood,
                                emergency_id: '',
                                neighborhood_id: '',
                            }}
                            onSubmit={async (values) => {
                                try {
                                    dispatch(startLinearProgress());
                                    dispatch(setGlobalAlert({
                                        show: true,
                                        duration: 5000,
                                        message: SUBMIT_MESSAGE,
                                        severity: 'info',
                                    }));
                                    const result = await downloadSTReport(values);
                                    const filename = generateName(REPORT_TYPES_FULLNAMES, values.type);
                                    FileDownload(result.data, filename);
                                    dispatch(stopLinearProgress());
                                } catch(err) {
                                    const { response } = err;
                                    dispatch(setGlobalAlert({
                                        show: true,
                                        duration: 5000,
                                        message: response.data.message,
                                        severity: 'error',
                                    }));
                                    dispatch(stopLinearProgress());
                                }
                            }}
                            validationSchema={TypeNeighborhoodFormSchema}
                        >
                            {(formikProps) => (
                                <TypeNeighborhoodReportForm {...formikProps} goBack={goBack} />
                            )}
                        </Formik>
                    )}
                    {selectedType === REPORT_TYPES.emergency_attendance && (
                        <Formik
                            initialValues={{
                                type: REPORT_TYPES.emergency_attendance,
                                report: '',
                            }}
                            validationSchema={EmergencyAttendanceSchema}
                            onSubmit={async (values) => {
                                try {
                                    dispatch(startLinearProgress());
                                    dispatch(setGlobalAlert({
                                        show: true,
                                        duration: 5000,
                                        message: SUBMIT_MESSAGE,
                                        severity: 'info',
                                    }));
                                    const result = await downloadSTReport(values);
                                    const filename = generateName(REPORT_TYPES_FULLNAMES, values.type);
                                    FileDownload(result.data, filename);
                                    dispatch(stopLinearProgress());
                                } catch(err) {
                                    const { response } = err;
                                    dispatch(setGlobalAlert({
                                        show: true,
                                        duration: 5000,
                                        message: response.data.message,
                                        severity: 'error',
                                    }));
                                    dispatch(stopLinearProgress());
                                }
                            }}
                        >
                            {(formikProps) => (
                                <EmergencyAttendanceReportForm {...formikProps} goBack={goBack} />
                            )}
                        </Formik>
                    )}
                    {selectedType === REPORT_TYPES.firefighter_record && (
                        <Formik
                            initialValues={{
                                type: REPORT_TYPES.firefighter_record,
                                start_date: new Date(),
                                end_date: new Date(),
                                document: '',
                                document_type: '',
                            }}
                            validationSchema={FirefighterRecordSchema}
                            onSubmit={async (values) => {
                                try {
                                    dispatch(startLinearProgress());
                                    dispatch(setGlobalAlert({
                                        show: true,
                                        duration: 5000,
                                        message: SUBMIT_MESSAGE,
                                        severity: 'info',
                                    }));
                                    const result = await downloadSTReport(values);
                                    const filename = generateName(REPORT_TYPES_FULLNAMES, values.type);
                                    FileDownload(result.data, filename);
                                    dispatch(stopLinearProgress());
                                } catch(err) {
                                    const { response } = err;
                                    dispatch(setGlobalAlert({
                                        show: true,
                                        duration: 5000,
                                        message: response.data.message,
                                        severity: 'error',
                                    }));
                                    dispatch(stopLinearProgress());
                                }
                            }}
                        >
                            {(formikProps) => (
                                <FirefighterRecordReportForm {...formikProps} goBack={goBack} />
                            )}
                        </Formik>
                    )}
                    {selectedType === REPORT_TYPES.emergency_statistics && (
                        <Formik
                            initialValues={{
                                start_date: new Date(),
                                end_date: new Date(),
                                type: REPORT_TYPES.emergency_statistics,
                            }}
                            onSubmit={async (values) => {
                                try {
                                    dispatch(startLinearProgress());
                                    dispatch(setGlobalAlert({
                                        show: true,
                                        duration: 5000,
                                        message: SUBMIT_MESSAGE,
                                        severity: 'info',
                                    }));
                                    const result = await downloadSTReport(values);
                                    const filename = generateName(REPORT_TYPES_FULLNAMES, values.type);
                                    FileDownload(result.data, filename);
                                    dispatch(stopLinearProgress());
                                } catch(err) {
                                    const { response } = err;
                                    dispatch(setGlobalAlert({
                                        show: true,
                                        duration: 5000,
                                        message: response.data.message,
                                        severity: 'error',
                                    }));
                                    dispatch(stopLinearProgress());
                                }
                            }}
                            validationSchema={EmergencyStatisticsSchema}
                        >
                            {(formikProps) => (
                                <EmergencyStatisticsReportForm {...formikProps} goBack={goBack} />
                            )}
                        </Formik>
                    )}
                    {selectedType === REPORT_TYPES.emergencies_by_type && (
                        <Formik
                            initialValues={{
                                start_date: new Date(),
                                end_date: new Date(),
                                type: REPORT_TYPES.emergencies_by_type,
                                emergency_id: '',
                            }}
                            onSubmit={async (values) => {
                                try {
                                    dispatch(startLinearProgress());
                                    dispatch(setGlobalAlert({
                                        show: true,
                                        duration: 5000,
                                        message: SUBMIT_MESSAGE,
                                        severity: 'info',
                                    }));
                                    const result = await downloadSTReport(values);
                                    const filename = generateName(REPORT_TYPES_FULLNAMES, values.type);
                                    FileDownload(result.data, filename);
                                    dispatch(stopLinearProgress());
                                } catch(err) {
                                    const { response } = err;
                                    dispatch(setGlobalAlert({
                                        show: true,
                                        duration: 5000,
                                        message: response.data.message,
                                        severity: 'error',
                                    }));
                                    dispatch(stopLinearProgress());
                                }
                            }}
                            validationSchema={EmergencyTypeSchema}
                        >
                            {(formikProps) => (
                                <EmergencyTypeReportForm {...formikProps} goBack={goBack} />
                            )}
                        </Formik>
                    )}
                    {/* Acá pondríamos un layout (i.e. el footer)*/}
                </div>
            )}
        </div>
    );
}

export default Consultas;