import { useState, useEffect } from 'react';
import { getAllInspections } from '../../services/GR/inspectionService';

const useAllInspections = (filters) => {
    const [inspections, setInspections] = useState([]);

    useEffect(() => {
        async function fetchAllInspections() {
            try {
                const { data } = await getAllInspections(filters);
                setInspections(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchAllInspections();
    }, [filters]);

    return inspections;
}

export default useAllInspections;