import { call, put, takeLatest } from 'redux-saga/effects';

import { 
    getEmergencies,
} from '../services/ST/emergenciesService';

import { 
    getStEmergenciesRequest,
    getStEmergenciesSuccess,
    getStEmergenciesFailure,
} from '../store/slices/stEmergenciesSlice';

function* getStEmergenciesEffect(action) {
    try {
        const page = action.payload.page;
        const page_limit = action.payload.page_limit;
        const filters = action.payload.filters;
        const { data } = yield call(getEmergencies, page, page_limit, filters);
        yield put(getStEmergenciesSuccess({ 
            stEmergencies: data.results,
            page: data.page,
            pages: data.pages,
            total: data.total,
            page_limit,
        }));
    } catch(e) {
        const { response } = e;
        const error = {
            code: response.status,
            message: response.data.message,
        };
        yield put(getStEmergenciesFailure({ error }));
    }
}

function* stEmergenciesEffects() {
    yield takeLatest(getStEmergenciesRequest.type, getStEmergenciesEffect);
}

export default stEmergenciesEffects;