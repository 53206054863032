import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';
import { createDepartment } from '../../../../services/departmentService';

import { configurationUrls } from '../../routing';

import validate from 'validate.js';
import schema from './schema';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const AddDepartment = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [applyRedirect, setApplyRedirect] = useState(false);
    const {
        backendResponse: createDepartmentResponse,
        executeService: createDepartmentService,
        resetBackendResponseState: createDepartmentBackendReset,
    } = useBackendMessageResponse(createDepartment);
    const [departmentForm, setDepartmentForm] = useState({
        isValid: false,
        isSubmitted: false,
        values: {
            name: '',
        },
        touched: {
            name: false,
        }, 
        errors: {},
    });

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    const handleChange = event => {
        event.persist();

        setDepartmentForm(currentDepartmentForm => ({
            ...currentDepartmentForm,
            values: {
                ...currentDepartmentForm.values,
                [event.target.name]: event.target.value,
            },
            touched: {
                ...currentDepartmentForm.touched,
                [event.target.name]: true
            },
        }));
    };

    const hasError = field =>
        (departmentForm.isSubmitted || departmentForm.touched[field]) && departmentForm.errors[field] ? true : false;

    const onSubmitAddDepartment = (applyRedirect) => async (event) => {
        event.preventDefault();

        if (!departmentForm.isSubmitted) {
            setDepartmentForm(currentDepartmentForm => ({
                ...currentDepartmentForm,
                isSubmitted: true,
            }));
        }

        if (!departmentForm.isValid) {
            return;
        }

        setApplyRedirect(applyRedirect);
        const department = departmentForm.values;
        await createDepartmentService(department);
    }

    useEffect(() => {
        const errors = validate(departmentForm.values, schema);

        setDepartmentForm(currentDepartmentForm => ({
            ...currentDepartmentForm,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [departmentForm.values, departmentForm.isSubmitted]);

    useEffect(() => {
        if (createDepartmentResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createDepartmentResponse.message,
            severity: 'success',
        }));

        if(applyRedirect) {
            props.history.push(configurationUrls.Listado);
        } else {
            setDepartmentForm({
                isValid: false,
                isSubmitted: false,
                values: {
                    name: '',
                },
                touched: {
                    name: false,
                }, 
                errors: {},
            });
            createDepartmentBackendReset();
        }
    }, [dispatch, createDepartmentResponse.message, props.history, applyRedirect, createDepartmentBackendReset]);

    useEffect(() => {
        if (createDepartmentResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createDepartmentResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, createDepartmentResponse.error])

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Añadir departamento</Box>
            </Typography>
            <form onSubmit={onSubmitAddDepartment(true)} className={classes.form}>
                <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
                    <Grid item>
                        <TextField 
                            id="code-emergency-input" 
                            label="Nombre del departmento" 
                            variant="outlined" 
                            className={classes.formControl} 
                            error={hasError('name')}
                            helperText={
                                hasError('name') ? departmentForm.errors.name[0] : null
                            }
                            name="name"
                            value={departmentForm.values.name}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                    <Grid item>
                        <Button
                            className={classes.saveButton}
                            color="primary"
                            size="large"
                            type="submit"
                            variant="contained"
                            disableElevation
                        >
                            Guardar
                        </Button>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item>
                                <Typography component={"span"}>
                                    <Box fontWeight="fontWeightLight" fontSize={12} className={classes.addAnotherDepartmentText}>Agregar otro departamento</Box>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton aria-label="add-fire-type-icon" color="primary" className={classes.addAnotherDepartmentIconContainer} onClick={onSubmitAddDepartment(false)}>
                                    <AddCircleRoundedIcon className={classes.addAnotherDepartmentIcon} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
}

export default AddDepartment;