import { useCallback } from 'react';
import format from 'date-fns/format';

const useReportNameGenerator = () => {
    const generateName = useCallback((reportTypes, selectedType) => {
        const today = new Date();
        const todayFormatted = format(today, 'dd-MM-yyyy HH:mm:ss')
        const reportFullName = reportTypes[selectedType];
        return `${reportFullName} ${todayFormatted}.pdf`;
    }, []);

    return generateName;
}

export default useReportNameGenerator;