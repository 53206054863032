import httpClient from '../http';
import qs from 'qs';

export const getMinWages = async (page, page_limit, filters) => {
    const filterParams = {
        page,
        page_limit,
        ...filters,
    };
    const queryParams = qs.stringify(filterParams);
    return await httpClient.get(`/minwages?${queryParams}`);
}

export const getAllMinWages = async () => {
    return await httpClient.get('/minwages/all');
}

export const createMinWage = async (minWage) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedMinWage = qs.stringify(minWage);
    return await httpClient.post('/minwages', serializedMinWage, config);
}

export const deleteMinWage = async (minWage_id) => {
    return await httpClient.delete(`/minwages/${minWage_id}`);
}

export const getMinWageById = async (minWage_id) => {
    return await httpClient.get(`/minwages/${minWage_id}`);
}

export const updateMinWage = async (minWage_id, minWage) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedMinWage = qs.stringify(minWage);
    return await httpClient.put(`/minwages/${minWage_id}`, serializedMinWage, config);
}