import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

import useTimestamp from '../../../../hooks/useTimestamp';
import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';
import { createDamageReport } from '../../../../services/MT/damageReportsService';

import { MotorUrls } from '../../routing';

import { Formik } from 'formik';
import { DatosDamageReportForm } from '../Forms/DamageReports/DatosDamageReportForm';
import schema from '../Forms/DamageReports/DatosDamageReportForm/schema';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const AddDamageReport = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const timestamp = useTimestamp();
    const {
        backendResponse: createReportResponse,
        executeService: createReportService,
    } = useBackendMessageResponse(createDamageReport);
    const [damageReportData, setDamageReportData] = useState({
        // Ident. Form
        report: `RD-${timestamp}`,
        identification: '',
        date: new Date(),
        cause: '',
        time: new Date(),
        action: '',
        shift: '',
        novelty: '',
        operator_id: '',
        sheet: '',

        // Costs form
        business_name: '',
        iva: '',
        facturing_res: '',
        costs: '',
        receipt_number: '',
        ivaless_cost: '',
        equipment_id: '',
        vehicle_id: '',
        type: 'Equipo',
    });

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    const handleSubmit = async (values) => {
        setDamageReportData(values);
        // Se tiene que eliminar uno de los dos por dos motivos.
        // 1. Para que el motor de bases de datos no se queje.
        // 2. No tiene sentido tener ambos si se supone sólo es de un único tipo.
        if (values.type === "Equipo") {
            delete values["vehicle_id"];
        } else if (values.type === "Máquina") {
            delete values["equipment_id"];
        }
        await createReportService(values);
    }

    useEffect(() => {
        if (createReportResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createReportResponse.message,
            severity: 'success',
        }));

        props.history.push(MotorUrls.Listado);
    }, [dispatch, createReportResponse.message, props.history]);

    useEffect(() => {
        if (createReportResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createReportResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, createReportResponse.error])

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Añadir reporte de daños</Box>
            </Typography>
            <div className={classes.form}>
                <Formik
                    validationSchema={schema}
                    initialValues={damageReportData}
                    component={DatosDamageReportForm}
                    onSubmit={handleSubmit}
                />
            </div>
        </div>
    );
};

export default AddDamageReport;