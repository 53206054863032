import { useState, useEffect } from 'react';
import { getMaintenanceById } from '../../services/MT/maintenancesService';

const useMaintenanceById = (maintenance_id) => {
    const [maintenance, setMaintenance] = useState(null);

    useEffect(() => {
        async function fetchMaintenance() {
            try {
                const { data } = await getMaintenanceById(maintenance_id);
                setMaintenance(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchMaintenance();
    }, [maintenance_id]);

    return { maintenance };
}

export default useMaintenanceById;