import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { 
    selectAreas,

    getAreasRequest,
} from '../../../../../../store/slices/areasSlice';
import { setGlobalAlert } from '../../../../../../store/slices/alertsSlice';
import useBackendMessageResponse from '../../../../../../hooks/useBackendMessageResponse';

import { deleteAreasGroup } from '../../../../../../services/areasService';

import { SearchField } from '../../../../../../components/SearchField';

import { Can, Only } from '../../../../../../components/Can';

import { ROLES_KEYS } from '../../../../../../constants/roles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import currency from 'currency.js';

import { datosGlobalesUrls } from '../../../../routing';

const useStyles = makeStyles(styles);

const ListaNivel = (props) => {
    const classes = useStyles();
    const areas = useSelector(selectAreas);
    const dispatch = useDispatch();
    const {
        backendResponse: deleteAreasResponse,
        executeService: deleteAreasService,
    } = useBackendMessageResponse(deleteAreasGroup);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [areaClickedData, setAreaClickedData] = useState({
        openDialog: false,
        area: null,
    });

    const onClickAddLevel = () => {
        const { history } = props;
        history.push(datosGlobalesUrls.AddLevel);
    }

    const onEditArea = (area) => () => {
        const { history } = props;
        history.push(`${datosGlobalesUrls.EditLevel}?level_id=${area.level.id}&minwage_id=${area.minwage.id}`);
    };

    const onDeleteArea = (area) => () => {
        setAreaClickedData({
            openDialog: true,
            area,
        });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onGetSearchTerm = (term) => {
        setSearchTerm(term);
        setPage(0);
    }

    const handleCloseDeleteDialog = () => {
        setAreaClickedData({
            openDialog: false,
            level: null,
        });
    }

    const handleAreaDelete = async () => {
        try {
            const levelId = areaClickedData.area.level.id;
            const minwageId = areaClickedData.area.minwage.id;
            await deleteAreasService(minwageId, levelId);
            setAreaClickedData({
                openDialog: false,
                area: null,
            });
            dispatch(getAreasRequest({
                page: page + 1,
                page_limit: rowsPerPage,
                filters: {
                    searchTerm: searchTerm !== '' ? searchTerm : undefined,
                },
            }));
        } catch (e) {
            const { response } = e;
            console.log(response);
        }
    }

    useEffect(() => {
        if (deleteAreasResponse.message === null) {
            return;
        }
        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            severity: 'success',
            message: deleteAreasResponse.message,
        }));
    }, [dispatch, deleteAreasResponse.message]);

    useEffect(() => {
        dispatch(getAreasRequest({
            page: page + 1,
            page_limit: rowsPerPage,
            filters: {
                searchTerm: searchTerm !== '' ? searchTerm : undefined,
            },
        }));
    }, [page, rowsPerPage, dispatch, searchTerm]);

    return (
    <div className={classes.root}>
        <Dialog
            open={areaClickedData.openDialog}
            onClose={handleCloseDeleteDialog}
            aria-labelledby="alert-dialog-minwage-delete-title"
            aria-describedby="alert-dialog-minwage-delete-description"
        >
            <DialogTitle id="alert-dialog-minwage-delete-title">{"Confirmación"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-minwage-delete-description">
                    ¿Estás seguro de querer continuar con la eliminación del registro seleccionado?
                    <br /><br />
                    <Typography component={"span"}>
                        <Box fontWeight="fontWeightBold" fontSize={15}>
                            IMPORTANTE: Tanto ésta como el resto de áreas relacionadas a la seleccionada serán eliminadas.
                        </Box>
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDeleteDialog} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleAreaDelete} color="primary" autoFocus>
                    Continuar
                </Button>
            </DialogActions>
        </Dialog>
        <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.fullWidth} spacing={2}>
            <Grid item md={6} xs={12} className={classes.searchInput}>
                <SearchField onClickSearch={onGetSearchTerm} />
            </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" justify="space-between" className={classes.fullWidth}>
            <Grid item>
                <Typography component={"span"}>
                    <Box fontWeight="fontWeightBold" fontSize={25}>Nivel</Box>
                </Typography>
            </Grid>
            <Grid item>
                <Can>
                    <Only roles={[ROLES_KEYS.admin]}>
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            className={classes.addLevelButton} 
                            disableElevation
                            onClick={onClickAddLevel}
                        >
                            Añadir nivel 
                        </Button>
                    </Only>
                </Can>
            </Grid>
        </Grid>
        <Paper elevation={0}>
            <TableContainer>
                <Table className={classes.table} aria-label="levels-table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Nivel</TableCell>                                
                            <TableCell align="left">Año</TableCell>                                
                            <TableCell align="left">Salario</TableCell>                                
                            <TableCell align="left">Area desde (mt2)</TableCell>                                
                            <TableCell align="left">Area hasta (mt2)</TableCell>                                
                            <TableCell align="left">Impacto</TableCell>                                
                            <TableCell align="left">Valor</TableCell>                                
                            <Can>
                                <Only roles={[ROLES_KEYS.admin]}>
                                    <TableCell align="right">Acciones</TableCell>                                
                                </Only>
                            </Can>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {areas.areas.map((area, key) => (
                            <TableRow key={`levels-table-key-${key}`}>
                                <TableCell align="left">
                                    <Typography component="span" className={classes.tableCellText}>
                                        {area.level.level}
                                    </Typography>
                                </TableCell>                                
                                <TableCell align="left">
                                    <Typography component="span" className={classes.tableCellText}>
                                        {area.minwage.year}
                                    </Typography>
                                </TableCell>                                
                                <TableCell align="left">
                                    <Typography component="span" className={classes.tableCellText}>
                                        {currency(area.minwage.amount).format()}
                                    </Typography>
                                </TableCell>                                
                                <TableCell align="left">
                                    <Typography component="span" className={classes.tableCellText}>
                                        {area.area_start}
                                    </Typography>
                                </TableCell>                                
                                <TableCell align="left">
                                    <Typography component="span" className={classes.tableCellText}>
                                        {area.area_end}
                                    </Typography>
                                </TableCell>                                
                                <TableCell align="left">
                                    <Typography component="span" className={classes.tableCellText}>
                                        {area.impact}
                                    </Typography>
                                </TableCell>                                
                                <TableCell align="left">
                                    <Typography component="span" className={classes.tableCellText}>
                                        {parseFloat(area.impact) * parseFloat(area.level.index_value) * parseFloat(area.minwage.amount)}
                                    </Typography>
                                </TableCell>                                
                                <Can>
                                    <Only roles={[ROLES_KEYS.admin]}>
                                        <TableCell align="right">
                                            <Grid container direction="row" alignItems="center" justify="flex-end" spacing={1}>
                                                <Grid item>
                                                    <IconButton aria-label="edit" className={classes.iconButton} onClick={onEditArea(area)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton aria-label="delete" className={classes.iconButton} onClick={onDeleteArea(area)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </TableCell>                                
                                    </Only>
                                </Can>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={areas.total}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="Filas por página:"
                labelDisplayedRows={() => {
                    return `Página: ${page + 1} de ${Math.ceil(areas.total / rowsPerPage)}`;
                }}
            />
        </Paper>
    </div>
    );
}

export default ListaNivel;