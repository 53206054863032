import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Poppers from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";

const useStyles = makeStyles(styles);

const UserDropdownLinks = (props) => {

    const [openDropdown, setOpenDropdown] = useState(null);

    const { userName, userRole, avatarUrl, dropdownActions } = props;

    const classes = useStyles();

    const handleClick = (event) => {
        if (openDropdown && openDropdown.contains(event.target)) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(event.currentTarget);
        }
    }

    const handleCloseDropdown = () => {
        setOpenDropdown(null);
    }

    const handleMenuItemClicked = (callback) => () => {
        setOpenDropdown(null);
        callback();
    }

    return (
        <div>
            <ButtonBase onClick={handleClick}>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item>
                        <Avatar alt="Avatar" src={avatarUrl} className={classes.avatarLarge} />
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" justify="center" alignItems="flex-start">
                            <Grid item>
                                <Typography variant="button" display="block" className={classes.userNameText}>
                                    {userName}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption" display="block" className={classes.userRoleText}>
                                    {userRole}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Icon className={classes.dropdownArrowIconContainer}>
                            <ArrowDropDownRoundedIcon style={{ fontSize: 45 }}/>
                        </Icon>
                    </Grid>
                </Grid>
            </ButtonBase>
            <Poppers 
                open={Boolean(openDropdown)}
                anchorEl={openDropdown}
                transition
                className={classes.popper}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        id="user-dropdown"
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseDropdown}>
                            <MenuList role="menu">
                                {dropdownActions.map((item) => (
                                    <MenuItem onClick={handleMenuItemClicked(item.onClick)} className={classes.dropdownItem}>
                                        {item.title}
                                    </MenuItem>
                                ))}
                            </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Poppers>
        </div>
    );
}

export default UserDropdownLinks;