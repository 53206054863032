import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import useTimestamp from '../../../../hooks/useTimestamp';
import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';
import { createEquipment } from '../../../../services/equipmentsService';

import { 
    DatosEquipmentForm,
    DatosEquipmentErrorSchema,
} from '../Forms/Equipments';

import { Formik } from 'formik';

import { MotorUrls } from '../../routing';

const useStyles = makeStyles(styles);

const AddEquipment = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const timestamp = useTimestamp();
    const [datosEquipment, setDatosEquipment] = useState({
        image_url: '',
        code: `EQ-${timestamp}`,
        name: '',
        model: '',
        class: '',
        entry_date: new Date(),
        engine_power: '',
        provider: '',
        type: '',
        entry_state: '',
        engine: '',
        price: '',
        fuel: '',
        owner_name: '',
        version: '',
        brand: '',
        observations: '',
    });
    const { 
        backendResponse: createEquipmentResponse,
        executeService: createEquipmentService,
    } = useBackendMessageResponse(createEquipment);

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    useEffect(() => {
        if (createEquipmentResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createEquipmentResponse.message,
            severity: 'success',
        }));

        props.history.push(MotorUrls.Listado);
    }, [dispatch, createEquipmentResponse.message, props.history]);

    useEffect(() => {
        if (createEquipmentResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createEquipmentResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, createEquipmentResponse.error])

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Añadir equipo y herramienta</Box>
            </Typography>
            <div className={classes.form}>
                <Formik 
                    validationSchema={DatosEquipmentErrorSchema}
                    initialValues={datosEquipment}
                    component={DatosEquipmentForm} 
                    onSubmit={async (values) => {
                        setDatosEquipment(values);
                        await createEquipmentService(values);
                    }}
                />
            </div>
        </div>
    );
};

export default AddEquipment;