import { useState, useEffect } from 'react';
import { getDamageReports } from '../../services/MT/damageReportsService';

const useDamageReports = (page, page_limit) => {
    const [damageReports, setDamageReports] = useState([]);

    useEffect(() => {
        async function fetchDamageReports() {
            try {
                const { data: { results } } = await getDamageReports(page, page_limit);
                setDamageReports(results);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchDamageReports();
    }, [page, page_limit]);

    return damageReports;
}

export default useDamageReports;