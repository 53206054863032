import httpClient from '../http';
import qs from 'qs';

export const getExtinguishers = async (page, page_limit, filters) => {
    const filterParams = {
        page,
        page_limit,
        ...filters,
    };
    const queryParams = qs.stringify(filterParams);
    return await httpClient.get(`/extinguishers?${queryParams}`);
}

export const getAllExtinguishers = async () => {
    return await httpClient.get(`/extinguishers/all`);
}

export const getExtinguisherByID = async (extinguisher_id) => {
    return await httpClient.get(`/extinguishers/${extinguisher_id}`);
}

export const createExtinguisher = async (extinguisher) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedExtinguisher = qs.stringify(extinguisher);
    return await httpClient.post('/extinguishers', serializedExtinguisher, config);
}

export const deleteExtinguisher = async (extinguisher_id) => {
    return await httpClient.delete(`/extinguishers/${extinguisher_id}`);
}

export const updateExtinguisher = async (extinguisher_id, extinguisher) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedExtinguisher = qs.stringify(extinguisher);
    return await httpClient.put(`/extinguishers/${extinguisher_id}`, serializedExtinguisher, config);
}