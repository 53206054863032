import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

import { Formik } from 'formik';
import { DatosMaintenancesForm } from '../Forms/Maintenances/DatosMaintenancesForm';
import schema from '../Forms/Maintenances/DatosMaintenancesForm/schema';

import useTimestamp from '../../../../hooks/useTimestamp';
import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';
import { createMaintenance } from '../../../../services/MT/maintenancesService';

import { MotorUrls } from '../../routing';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const AddMaintenance = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const timestamp = useTimestamp();
    const {
        backendResponse: createMaintenanceResponse,
        executeService: createMaintenanceService,
    } = useBackendMessageResponse(createMaintenance);
    const [maintenanceData, setMaintenanceData] = useState({
        report: `MT-${timestamp}`,
        sheet: '',
        date: new Date(),
        value: '',
        workshop: '',
        description: '',
        in_charge_id: '',
        type: 'Equipo',
        phone: '',
        equipment_id: '',
        vehicle_id: '',
    });

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    const handleSubmit = async (values) => {
        setMaintenanceData(values);
        // Se tiene que eliminar uno de los dos por dos motivos.
        // 1. Para que el motor de bases de datos no se queje.
        // 2. No tiene sentido tener ambos si se supone sólo es de un único tipo.
        if (values.type === "Equipo") {
            delete values["vehicle_id"];
        } else if (values.type === "Máquina") {
            delete values["equipment_id"];
        }
        await createMaintenanceService(values);
    }

    useEffect(() => {
        if (createMaintenanceResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createMaintenanceResponse.message,
            severity: 'success',
        }));

        props.history.push(MotorUrls.Listado);
    }, [dispatch, createMaintenanceResponse.message, props.history]);

    useEffect(() => {
        if (createMaintenanceResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createMaintenanceResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, createMaintenanceResponse.error])

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Añadir mantenimiento</Box>
            </Typography>
            <div className={classes.form}>
                <Formik
                    validationSchema={schema}
                    initialValues={maintenanceData}
                    component={DatosMaintenancesForm}
                    onSubmit={handleSubmit}
                />
            </div>
        </div>
    );
};

export default AddMaintenance;