import { createSlice } from '@reduxjs/toolkit';

export const ranksSlice = createSlice({
    name: 'ranks',
    initialState: {
        isLoading: false,
        ranks: [],
        error: {
            code: null,
            message: null,
        },
    },
    reducers: {
        getRanksRequest: (state) => {
            state.isLoading = true;
        },
        getRanksSuccess: (state, action) => {
            state.isLoading = false;
            state.error = {
                code: null,
                message: null,
            };
            state.ranks = action.payload.ranks;
        },
        getRanksFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
    },
});

export const {
    getRanksRequest,
    getRanksSuccess,
    getRanksFailure,
} = ranksSlice.actions;

export const selectRanks = (state) => state.ranks;

export default ranksSlice.reducer;