import React, { useEffect, useState } from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import useUsersByPosition from '../../../../../hooks/useUsersByPosition';
import useAreasByYear from '../../../../../hooks/useAreasByYear';

import { Form, Field, ErrorMessage } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const ContabilizarForm = (props) => {
    const classes = useStyles();
    const inspectors = useUsersByPosition("Inspector");
    const [area, setArea] = useState(null);
    const [levelValue, setLevelValue] = useState('');
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const { findAreaByGivenAreaRange } = useAreasByYear(currentYear);
    const { categorizarData, readMode } = props;

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    };

    useEffect(() => {
        const areaM2 = categorizarData.length * categorizarData.width;
        const areaByRange = findAreaByGivenAreaRange(areaM2);
        if (areaByRange) {
            setArea(areaByRange);
        }
    }, [categorizarData.length, categorizarData.width, findAreaByGivenAreaRange]);

    useEffect(() => {
        if (area === null) {
            return;
        }

        const tableValue = parseFloat(area.impact) * parseFloat(area.level.index_value) * parseFloat(area.minwage.amount);
        if (isNaN(tableValue)) {
            return;
        }

        setLevelValue(tableValue);
    }, [area]);

    return (
        <div className={classes.root}>
            <Form>
                <Grid container direction="column" spacing={2} justify="flex-start" alignItems="flex-start">
                    <Grid item className={classes.row}>
                        <Grid container direction="row" spacing={1} justify="flex-start" alignItems="flex-start">
                            <Grid item xs={12} md={6}>
                                <FormControl disabled={readMode} className={classes.formControl} variant="outlined" error={hasError('inspector_id')}>
                                    <InputLabel>Inspector</InputLabel>
                                    <Field 
                                        name="inspector_id" 
                                        type="text"
                                        as={Select} 
                                        label="Inspector"
                                        className={classes.formControl} 
                                    >
                                        {inspectors.map((item, key) => (
                                            <MenuItem key={`inspector-key-${key}`} value={item.id}>{`${item.document_type}-${item.document} ${item.firstname} ${item.lastname}`}</MenuItem>
                                        ))}
                                    </Field>
                                    <FormHelperText>
                                        <ErrorMessage name="inspector_id" />
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="discount" 
                                    type="number" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="% de Descuento"
                                    className={classes.formControl} 
                                    error={hasError('discount')}
                                    helperText={<ErrorMessage name="discount" />}
                                    disabled={readMode}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" spacing={1} justify="flex-start" alignItems="flex-start">
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="table_value" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Valor tabla"
                                    className={classes.formControl} 
                                    error={hasError('table_value')}
                                    helperText={<ErrorMessage name="table_value" />}
                                    value={levelValue}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="total_value" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Total"
                                    className={classes.formControl} 
                                    error={hasError('total_value')}
                                    helperText={<ErrorMessage name="total_value" />}
                                    value={(() => {
                                        const discount_value = levelValue * props.values.discount / 100;
                                        if (isNaN(discount_value)) {
                                            return '';
                                        }
                                        const total_value = levelValue - discount_value;
                                        if (isNaN(total_value)) {
                                            return '';
                                        }
                                        props.values.total_value = total_value;
                                        return total_value;
                                    })()}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" spacing={1} justify="flex-start" alignItems="flex-start">
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="discount_value" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Descuento"
                                    className={classes.formControl} 
                                    error={hasError('discount_value')}
                                    helperText={<ErrorMessage name="discount_value" />}
                                    value={(() => {
                                        const discount_value = levelValue * props.values.discount / 100;
                                        if (isNaN(discount_value)) {
                                            return '';
                                        }
                                        props.values.discount_value = discount_value;
                                        return discount_value;
                                    })()}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{paddingTop: '12px', paddingLeft: '10px'}}>
                                <FormControlLabel
                                    control={
                                        <Field
                                            name="donation"
                                            as={Checkbox}
                                            checked={props.values.currently}
                                            onChange={(event) => props.setFieldValue("donation", event.target.checked)}
                                            color="primary"
                                        />
                                    }
                                    label="Donaciones"
                                    disabled={readMode}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Button
                    className={classes.saveButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disableElevation
                    disabled={readMode}
                >
                    Siguiente
                </Button>
            </Form>
        </div>
    );
}

ContabilizarForm.defaultProps = {
    readMode: false,
};

export default ContabilizarForm;