export default theme => ({
    inputFile: {
        display: 'none',
    },
    buttonBase: {
        width: '100%',
        height: '100%',
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        borderRadius: 3,
    },
    loadImageText: {
        color: '#96C2C9',
    },
    loadImageIcon: {
        fontSize: 80,
        color: '#96C2C9',
    },
    container: {
        backgroundColor: '#E7F6FC',
        width: '100%',
        height: '100%',
        borderRadius: 3,
        borderStyle: 'dashed',
        borderColor: '#96C2C9',
    },
});