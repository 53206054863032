import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

import { Formik } from 'formik';
import { DatosAvailabilityForm } from '../Forms/Availabilities/DatosAvailabilityForm';
import schema from '../Forms/Availabilities/DatosAvailabilityForm/schema';

import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';
import { updateAvailability } from '../../../../services/MT/availabilitiesService';

import useAvailabilityById from '../../../../hooks/MT/useAvailabilityById';

import { MotorUrls } from '../../routing';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const EditAvailability = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { availability_id } = useParams();
    const { availability } = useAvailabilityById(availability_id);
    const {
        backendResponse: updateAvailabilityResponse,
        executeService: updateAvailabilityService,
    } = useBackendMessageResponse(updateAvailability);
    const [availabilityForm, setAvailabilityForm] = useState({
        report: '',
        damage_report_id: '',
        novelties: '',
        vehicle_id: '',
        maintenance_id: '',
        date: new Date(),
        note: '',
        location: '',
        time: new Date(),
        emergency: '',
    });

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    const handleSubmit = async (values) => {
        setAvailabilityForm(values);

        await updateAvailabilityService(availability_id, values);
    }

    useEffect(() => {
        if (updateAvailabilityResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: updateAvailabilityResponse.message,
            severity: 'success',
        }));

        props.history.push(MotorUrls.Listado);
    }, [dispatch, updateAvailabilityResponse.message, props.history]);

    useEffect(() => {
        if (updateAvailabilityResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: updateAvailabilityResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, updateAvailabilityResponse.error])

    useEffect(() => {
        if (availability === null) {
            return;
        }

        setAvailabilityForm({
            report: availability.report,
            damage_report_id: availability.damage_report_id,
            novelties: availability.novelties,
            vehicle_id: availability.vehicle_id,
            maintenance_id: availability.maintenance_id,
            date: new Date(availability.date),
            note: availability.note,
            location: availability.location,
            time: new Date(availability.time),
            emergency: availability.emergency,
        });
    }, [availability]);

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Editar disponibilidad</Box>
            </Typography>
            <div className={classes.form}>
                <Formik
                    enableReinitialize
                    validationSchema={schema}
                    initialValues={availabilityForm}
                    component={DatosAvailabilityForm}
                    onSubmit={handleSubmit}
                />
            </div>
        </div>
    );
};

export default EditAvailability;