import { useState, useEffect } from 'react';
import { getVehicleEngines } from '../../services/MT/vehicleEnginesService';

const useVehicleEngines = () => {
    const [engines, setEngines] = useState([]);

    useEffect(() => {
        async function fetchVehicleEngines() {
            try {
                const { data } = await getVehicleEngines();
                setEngines(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchVehicleEngines();
    }, []);

    return { engines };
}

export default useVehicleEngines;