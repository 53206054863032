export default theme => ({
    saveButton: {
        marginTop: '20px',
        width: '170px',
    },
    formControl: {
        width: '100%',
    },
    row: {
        width: '60%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    fullWidth: {
        width: '103%',
    },
    topSpacing: {
        marginTop: '30px',
    },
});