import { call, put, takeLatest } from 'redux-saga/effects';

import { 
    getMinWages,
} from '../services/minWageService';

import { 
    getMinWagesRequest,
    getMinWagesSuccess,
    getMinWagesFailure,
} from '../store/slices/minWagesSlice';

function* getMinWagesEffect(action) {
    try {
        const page = action.payload.page;
        const page_limit = action.payload.page_limit;
        const filters = action.payload.filters;
        const { data } = yield call(getMinWages, page, page_limit, filters);
        yield put(getMinWagesSuccess({ 
            minWages: data.results,
            page: data.page,
            pages: data.pages,
            total: data.total,
            page_limit,
        }));
    } catch(e) {
        const { response } = e;
        const error = {
            code: response.status,
            message: response.data.message,
        };
        yield put(getMinWagesFailure({ error }));
    }
}

function* minWagesEffects() {
    yield takeLatest(getMinWagesRequest.type, getMinWagesEffect);
}

export default minWagesEffects;