import routesUrls from '../../routes/urls';

import { 
    TabsSiniestralidad,
    AddEmergency,
    EditEmergency,
    DetailsEmergency,
} from './Pages';

export const SiniestralidadUrls = {
    Tablero: `${routesUrls.Siniestralidad}/tablero`,
    AddEmergency: `${routesUrls.Siniestralidad}/agregar-emergencia`,
    EditEmergency: `${routesUrls.Siniestralidad}/editar-emergencia/:emergency_id`,
    DetailsEmergency: `${routesUrls.Siniestralidad}/detalles-emergencia/:emergency_id`,
};

const SiniestralidadRouting = [{
    path: SiniestralidadUrls.Tablero,
    component: TabsSiniestralidad,
}, {
    path: SiniestralidadUrls.AddEmergency,
    component: AddEmergency,
}, {
    path: SiniestralidadUrls.EditEmergency,
    component: EditEmergency,
}, {
    path: SiniestralidadUrls.DetailsEmergency,
    component: DetailsEmergency,
}];

export default SiniestralidadRouting;