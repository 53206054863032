import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const STReportCard = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container direction="column" justify="center" alignItems="center" spacing={2} className={classes.container}>
                <Grid item style={{height: '75%'}} className={classes.flexContainer}>
                    <Typography align="center" component="span" className={classes.cardText}>
                        <Box fontWeight="fontWeightLight" fontSize={20}>
                            {props.CardText}
                        </Box>
                    </Typography>
                </Grid>
                <Grid item style={{height: '25%'}} className={classes.flexContainer}>
                    <Button
                        className={classes.button}
                        color="primary"
                        size="large"
                        variant="contained"
                        disableElevation
                        onClick={props.onButtonClick}
                    >
                        {props.ButtonText}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}

STReportCard.propTypes = {
    onButtonClick: PropTypes.func.isRequired,
    CardText: PropTypes.string.isRequired,
    ButtonText: PropTypes.string.isRequired,
};

export default STReportCard;