import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { Form, Field, ErrorMessage } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import { UploadImage } from '../../../../../../components/UploadImage';

const useStyles = makeStyles(styles);

const TYPES = ['Equipo', 'Herramienta'];
const ENTER_STATES = ['Nuevo', 'Usado', 'Remanufacturado'];

const DatosEquipmentForm = (props) => {
    const classes = useStyles();

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Form>
                <Grid container direction="column" justify="center" alignItems="flex-start" spacing={3}>
                    <Grid item className={classes.row}>
                        <div className={classes.ratioContainer}>
                            <div className={classes.ratioItem}>
                                <Field
                                    name="image_url"
                                    as={UploadImage}
                                    onChange={(image_url) => props.setFieldValue('image_url', image_url)}
                                    imageSrc={props.values.image_url}
                                    ratioMessage={`Ratio 16:9 (1280x720)`}
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="code" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Código"
                                    className={classes.formControl} 
                                    error={hasError('code')}
                                    helperText={<ErrorMessage name="code" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="name" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Nombre"
                                    className={classes.formControl} 
                                    error={hasError('name')}
                                    helperText={<ErrorMessage name="name" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="model" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Modelo"
                                    className={classes.formControl} 
                                    error={hasError('model')}
                                    helperText={<ErrorMessage name="model" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="class" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Clase"
                                    className={classes.formControl} 
                                    error={hasError('class')}
                                    helperText={<ErrorMessage name="class" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="entry_date" 
                                    as={KeyboardDatePicker} 
                                    label="Fecha de Ingreso"
                                    className={classes.formControl} 
                                    inputVariant="outlined"
                                    variant="inline"
                                    InputAdornmentProps={{ position: "end" }}
                                    format="dd/MM/yyyy"
                                    onChange={(date) => props.setFieldValue("entry_date", date)}
                                    error={hasError('entry_date')}
                                    helperText={<ErrorMessage name="entry_date" />}
                                    autoOk
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="engine_power" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Cilindraje"
                                    className={classes.formControl} 
                                    error={hasError('engine_power')}
                                    helperText={<ErrorMessage name="engine_power" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="provider" 
                                    type="text"
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Proveedor"
                                    className={classes.formControl} 
                                    error={hasError('provider')}
                                    helperText={<ErrorMessage name="provider" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl className={classes.formControl} variant="outlined" error={hasError('type')}>
                                    <InputLabel>Tipo</InputLabel>
                                    <Field 
                                        name="type" 
                                        type="text"
                                        as={Select} 
                                        label="Tipo"
                                        className={classes.formControl} 
                                    >
                                        {TYPES.map((item, key) => (
                                            <MenuItem key={`type-state-key-${key}`} value={item}>{item}</MenuItem>
                                        ))}
                                    </Field>
                                    <FormHelperText>
                                        <ErrorMessage name="type" />
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                            <Grid item xs={12} md={6}>
                                <FormControl className={classes.formControl} variant="outlined" error={hasError('entry_state')}>
                                    <InputLabel>Estado de ingreso</InputLabel>
                                    <Field 
                                        name="entry_state" 
                                        type="text"
                                        as={Select} 
                                        label="Estado de ingreso"
                                        className={classes.formControl} 
                                    >
                                        {ENTER_STATES.map((item, key) => (
                                            <MenuItem key={`entry-state-key-${key}`} value={item}>{item}</MenuItem>
                                        ))}
                                    </Field>
                                    <FormHelperText>
                                        <ErrorMessage name="entry_state" />
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="engine" 
                                    type="text"
                                    as={TextField}
                                    variant="outlined" 
                                    label="Motor"
                                    className={classes.formControl} 
                                    error={hasError('engine')}
                                    helperText={<ErrorMessage name="engine" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="price" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Precio"
                                    className={classes.formControl} 
                                    error={hasError('price')}
                                    helperText={<ErrorMessage name="price" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="fuel" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Combustible"
                                    className={classes.formControl} 
                                    error={hasError('fuel')}
                                    helperText={<ErrorMessage name="fuel" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="owner_name" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Propietario"
                                    className={classes.formControl} 
                                    error={hasError('owner_name')}
                                    helperText={<ErrorMessage name="owner_name" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="version" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Version"
                                    className={classes.formControl} 
                                    error={hasError('version')}
                                    helperText={<ErrorMessage name="version" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="brand" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Marca"
                                    className={classes.formControl} 
                                    error={hasError('brand')}
                                    helperText={<ErrorMessage name="brand" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="observations" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Observaciones"
                                    className={classes.formControl} 
                                    error={hasError('observations')}
                                    helperText={<ErrorMessage name="observations" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Button
                    className={classes.saveButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disableElevation
                >
                    Guardar
                </Button>
            </Form>
        </MuiPickersUtilsProvider>
    );
};

export default DatosEquipmentForm;