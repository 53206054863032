import { call, put, takeLatest } from 'redux-saga/effects';
import {
    fetchDepartmentsRequest,
    fetchDepartmentsSuccess,
    fetchDepartmentsFailure,
} from '../store/slices/departmentsSlice';
import {
    getDepartments,
} from '../services/departmentService';

function* getDepartmentsEffect(action) {
    try {
        const { page, page_limit, filters } = action.payload;
        const { data } = yield call(getDepartments, page, page_limit, filters);
        yield put(fetchDepartmentsSuccess({ 
            departments: data.results,
            page: data.page,
            pages: data.pages,
            total: data.total,
            page_limit,
        }));
    } catch(e) {
        const { response } = e;
        const error = {
            code: response.status,
            message: response.data.message,
        };
        yield put(fetchDepartmentsFailure({ error }));
    }
}

function* departmentsEffects() {
    yield takeLatest(fetchDepartmentsRequest.type, getDepartmentsEffect);
}

export default departmentsEffects;