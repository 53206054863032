import { createSlice } from '@reduxjs/toolkit';

export const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState: {
        isLoading: false,
        user: null,
        message: null,
        error: {
            code: null,
            message: null,
        },
    },
    reducers: {
        currentUserRequest: state => {
            state.isLoading = true;
        },
        currentUserSuccess: (state, action) => {
            state.isLoading = false;
            state.user = action.payload.user;
            state.error = {
                code: null,
                message: null,
            };
        },
        currentUserFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
        resetCurrentUser: (state) => {
            state.isLoading = false;
            state.user = null;
            state.message = null;
            state.error = {
                code: null,
                message: null,
            };
        },
    },
});

export const {
    // Get Current User
    currentUserRequest,
    currentUserSuccess,
    currentUserFailure,

    // resetCurrentUser
    resetCurrentUser,
} = currentUserSlice.actions;

export const selectCurrentUser = state => state.currentUser;

export default currentUserSlice.reducer;