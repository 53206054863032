import { useState, useEffect } from 'react';
import { getActivityById } from '../services/activitiesService';

const useActivityById = (activity_id) => {
    const [activity, setActivity] = useState(null);

    useEffect(() => {
        async function fetchActivity() {
            try {
                const { data } = await getActivityById(activity_id);
                setActivity(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchActivity();
    }, [activity_id]);

    return { activity };
}

export default useActivityById;