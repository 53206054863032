import { call, put, takeLatest } from 'redux-saga/effects';

import { 
    getPositions,
} from '../services/positionsService';

import { 
    getPositionsRequest,
    getPositionsSuccess,
    getPositionsFailure,
} from '../store/slices/positionsSlice';

function* getPositionsEffect(action) {
    try {
        const { data } = yield call(getPositions);
        yield put(getPositionsSuccess({ 
            positions: data,
        }));
    } catch(e) {
        const { response } = e;
        const error = {
            code: response.status,
            message: response.data.message,
        };
        yield put(getPositionsFailure({ error }));
    }
}

function* positionsEffects() {
    yield takeLatest(getPositionsRequest.type, getPositionsEffect);
}

export default positionsEffects;