export default theme => ({
  row: {
      width: '40%',
      [theme.breakpoints.down('sm')]: {
          width: '100%',
      },
  },
  saveButton: {
    width: '170px',
  },
  formControl: {
    width: '100%',
    '& p': {
      position: 'absolute',
      marginTop: '3.5rem'
    }
  },
});