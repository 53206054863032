import { useState, useEffect } from 'react';
import { getStEmergencyById } from '../../services/ST/emergenciesService';

const useStEmergencyById = (emergency_id) => {
    const [stEmergency, setStEmergency] = useState(null);

    useEffect(() => {
        async function fetchStEmergency() {
            try {
                const { data } = await getStEmergencyById(emergency_id);
                setStEmergency(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchStEmergency();
    }, [emergency_id]);

    return { stEmergency };
}

export default useStEmergencyById;