// Se exportan para determinar en el main el tamaño del resto del contenido.
export const drawerWidth = 260;
export const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
};

const boxShadow = {
  boxShadow:
    "0 10px 15px -12px rgba(" +
    "0, 0, 0 " +
    ", 0.42), 0 4px 25px 0px rgba(" +
    "0, 0, 0 " +
    ", 0.12), 0 8px 10px -5px rgba(" +
    "0, 0, 0 " +
    ", 0.2)"
};

export default (theme) => ({
    drawerPaper: {
        border: "none",
        position: "fixed",
        top: "0",
        bottom: "0",
        left: "0",
        zIndex: "1",
        ...boxShadow,
        width: drawerWidth,
        [theme.breakpoints.up("md")]: {
            width: drawerWidth,
            position: "fixed",
            height: "100%"
        },
        [theme.breakpoints.down("sm")]: {
            width: drawerWidth,
            position: "fixed",
            display: "block",
            top: "0",
            ...boxShadow,
            height: "100vh",
            right: "0",
            left: "auto",
            zIndex: "1032",
            visibility: "visible",
            overflowY: "visible",
            borderTop: "none",
            textAlign: "left",
            paddingRight: "0px",
            paddingLeft: "0",
            transform: `translate3d(${drawerWidth}px, 0, 0)`,
            ...transition
        }
    },
    sidebarWrapper: {
        position: "relative",
        height: "calc(100vh - 75px)",
        [theme.breakpoints.down("sm")]: {
            height: "calc(82vh - 75px)",
        },
        overflow: "auto",
        overflowX: 'hidden',
        width: "260px",
        zIndex: "4",
        overflowScrolling: "touch"
    },
    list: {
        marginTop: "20px",
        paddingLeft: "0",
        paddingTop: "0",
        paddingBottom: "0",
        marginBottom: "0",
        listStyle: "none",
        position: "unset"
    },
    item: {
        position: "relative",
        display: "block",
        textDecoration: "none",
        "&:hover,&:focus,&:visited,&": {
            color: "#FFFFFF", 
        },
        paddingLeft: '10px',
    },
    itemLink: {
        transition: "all 300ms linear",
        marginTop: '10px',
        padding: "10px 15px",
        backgroundColor: "transparent",
        borderRadius: '15px 0 0 15px',
    },
    itemIcon: {
        width: "28px",
        height: "28px",
    },
    itemText: {
        margin: "0",
        lineHeight: "30px",
        fontSize: "14px",
        color: "#FFFFFF", 
    },
    activeItem: {
        backgroundColor: "#FFF",
        "&:hover": {
            backgroundColor: "#FFF",
        },
    },
    activeElement: {
        color: theme.palette.primary.main,
    },
    background: {
        position: "absolute",
        zIndex: "1",
        height: "100%",
        width: "100%",
        display: "block",
        top: "0",
        left: "0",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        "&:after": {
            position: "absolute",
            zIndex: "3",
            width: "100%",
            height: "100%",
            content: '""',
            display: "block",
            background: theme.palette.primary.main,
            opacity: ".8"
        }
    },
    // LOGO
    logo: {
        position: "relative",
        padding: "15px 15px",
        zIndex: "4",
        "&:after": {
            content: '""',
            position: "absolute",
            bottom: "0",
            height: "1px",
            right: "15px",
            width: "calc(100% - 30px)",
            backgroundColor: "rgba(245, 245, 245, 0.3)"
        }
    },
    logoImage: {
        width: "30px",
        display: "inline-block",
        height: "150px",
        marginLeft: "10px",
        marginRight: "15px"
    },
    img: {
        width: "215px",
        top: "22px",
        position: "absolute",
        verticalAlign: "middle",
        border: "0",
    },

    manager: {
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        },
        display: "inline-block"
    },
    buttonLink: {
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            margin: "10px 15px 0",
            paddingLeft: '23px',
            width: '100%',
            "& svg": {
                width: "24px",
                height: "30px",
                marginRight: "15px",
                marginLeft: "-15px"
            },
            "& .fab,& .fas,& .far,& .fal,& .material-icons": {
                fontSize: "24px",
                lineHeight: "30px",
                width: "24px",
                height: "30px",
                marginRight: "15px",
                marginLeft: "-15px"
            },
            "& > span": {
                justifyContent: "flex-start",
                width: "100%"
            },
            color: 'white',
        },
    },
    linkText: {
        zIndex: "4",
        fontWeight: "300",
        lineHeight: "1.5em",
        fontSize: "14px",
        margin: "0px",
    },
    divider: {
        backgroundColor: "rgba(245, 245, 245, 0.3)",
        width: '90%',
        marginTop: '10px',
    },

    popperClose: {
        pointerEvents: "none"
    },
    popperNav: {
        [theme.breakpoints.down("sm")]: {
            position: "static !important",
            left: "unset !important",
            top: "unset !important",
            transform: "none !important",
            willChange: "unset !important",
            "& > div": {
                boxShadow: "none !important",
                marginLeft: "0rem",
                marginRight: "0rem",
                transition: "none !important",
                marginTop: "0px !important",
                marginBottom: "0px !important",
                padding: "0px !important",
                backgroundColor: "transparent !important",
                "& ul li": {
                    color: 'white !important',
                    margin: "10px 15px 0!important",
                    padding: "10px 15px !important",
                    "&:hover": {
                        backgroundColor: "hsla(0,0%,78%,.2)",
                        boxShadow: "none"
                    }
                }
            }
        }
    },

    dropdownItem: {
        fontSize: "13px",
        padding: "10px 20px",
        margin: "0 5px",
        borderRadius: "2px",
        WebkitTransition: "all 150ms linear",
        MozTransition: "all 150ms linear",
        OTransition: "all 150ms linear",
        MsTransition: "all 150ms linear",
        transition: "all 150ms linear",
        display: "block",
        clear: "both",
        fontWeight: "400",
        lineHeight: "1.42857143",
        color: 'grey',
        whiteSpace: "nowrap",
        height: "unset",
        minHeight: "unset",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
        },
    }
});