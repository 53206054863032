import * as Yup from 'yup';

export default Yup.object().shape({
    type: Yup.string()
        .required('Éste campo es requerido.'),
    start_date: Yup.date()
        .required('Éste campo es requerido.'),
    end_date: Yup.date()
        .when('start_date', (start_date, yup) => {
            return start_date && yup.min(start_date, "Fecha final no puede ser menor que fecha inicial.");
        })
        .required('Éste campo es requerido.'),
});