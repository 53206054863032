import * as Yup from 'yup';

export default Yup.object().shape({
    name: Yup.string()
        .max(100, 'Máximo 100 caracteres.')
        .required('Éste campo es requerido.'),
    document: Yup.string()
        .max(16, 'Máximo 16 caracteres.')
        .required('Éste campo es requerido.')
        .test(
            'document-already-loaded',
            'La identificación ya se encuentra cargada.',
            function (document) {
                const first_aids = this.options.context.first_aids;
                const first_aid_document_found = first_aids.find(first_aid => first_aid.document === document);
                return first_aid_document_found === undefined;
            },
        ),
    types: Yup.string()
        .max(100, 'Máximo 100 caracteres.')
        .required('Éste campo es requerido.'),
    observations: Yup.string()
        .max(500, 'Máximo 500 caracteres.')
        .required('Éste campo es requerido.'),
});