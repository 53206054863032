import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';
import { createEmergency } from '../../../../services/emergenciesService';

import { configurationUrls } from '../../routing';

import validate from 'validate.js';
import schema from './schema';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const AddEmergency = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        backendResponse: createEmergencyResponse,
        executeService: createEmergencyService,
        resetBackendResponseState: createEmergencyBackendReset,
    } = useBackendMessageResponse(createEmergency);
    const [applyRedirect, setApplyRedirect] = useState(false);
    const [emergencyForm, setEmergencyForm] = useState({
        isValid: false,
        isSubmitted: false,
        values: {
            code: '',
            name: '',
        },
        touched: {
            code: false,
            name: false,
        }, 
        errors: {},
    });

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    const onSubmitAddEmergency = (applyRedirect) => async (event) => {
        event.preventDefault();

        if (!emergencyForm.isSubmitted) {
            setEmergencyForm(currentEmergencyForm => ({
                ...currentEmergencyForm,
                isSubmitted: true,
            }));
        }

        if (!emergencyForm.isValid) {
            return;
        }

        setApplyRedirect(applyRedirect);
        const emergency = emergencyForm.values;
        await createEmergencyService(emergency);
    }

    const handleChange = event => {
        event.persist();

        setEmergencyForm(currentEmergencyForm => ({
            ...currentEmergencyForm,
            values: {
                ...currentEmergencyForm.values,
                [event.target.name]: event.target.value,
            },
            touched: {
                ...currentEmergencyForm.touched,
                [event.target.name]: true
            }
        }));
    };

    const hasError = field =>
        (emergencyForm.isSubmitted || emergencyForm.touched[field]) && emergencyForm.errors[field] ? true : false;

    useEffect(() => {
        const errors = validate(emergencyForm.values, schema);

        setEmergencyForm(currentEmergencyForm => ({
            ...currentEmergencyForm,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [emergencyForm.values, emergencyForm.isSubmitted]);

    useEffect(() => {
        if (createEmergencyResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createEmergencyResponse.message,
            severity: 'success',
        }));

        if(applyRedirect) {
            props.history.push(configurationUrls.Listado);
        } else {
            setEmergencyForm({
                isValid: false,
                isSubmitted: false,
                values: {
                    code: '',
                    name: '',
                },
                touched: {
                    code: false,
                    name: false,
                }, 
                errors: {},
            });
            createEmergencyBackendReset();
        }
    }, [dispatch, createEmergencyResponse.message, props.history, applyRedirect, createEmergencyBackendReset]);

    useEffect(() => {
        if (createEmergencyResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createEmergencyResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, createEmergencyResponse.error])

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Añadir emergencia</Box>
            </Typography>
            <form onSubmit={onSubmitAddEmergency(true)} className={classes.form}>
                <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
                    <Grid item>
                        <TextField 
                            id="code-emergency-input" 
                            label="Código de la emergencia" 
                            variant="outlined" 
                            className={classes.formControl} 
                            error={hasError('code')}
                            helperText={
                                hasError('code') ? emergencyForm.errors.code[0] : null
                            }
                            name="code"
                            type="number"
                            value={emergencyForm.values.code}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item>
                        <TextField 
                            id="name-emergency-input" 
                            label="Nombre de la emergencia" 
                            variant="outlined" 
                            className={classes.formControl} 
                            error={hasError('name')}
                            helperText={
                                hasError('name') ? emergencyForm.errors.name[0] : null
                            }
                            name="name"
                            value={emergencyForm.values.name}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                    <Grid item>
                        <Button
                            className={classes.saveButton}
                            color="primary"
                            size="large"
                            type="submit"
                            variant="contained"
                            disableElevation
                        >
                            Guardar
                        </Button>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item>
                                <Typography component={"span"}>
                                    <Box fontWeight="fontWeightLight" fontSize={12} className={classes.addAnotherEmergencyText}>Agregar otra emergencia</Box>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton aria-label="add-fire-type-icon" color="primary" className={classes.addAnotherEmergencyIconContainer} onClick={onSubmitAddEmergency(false)}>
                                    <AddCircleRoundedIcon className={classes.addAnotherEmergencyIcon} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
}

export default AddEmergency;