import { useState, useEffect } from 'react';
import { getMinWageById } from '../services/minWageService';

const useMinWageById = (minwage_id) => {
    const [minWage, setMinWage] = useState(null);

    useEffect(() => {
        async function fetchMinWage() {
            try {
                const { data } = await getMinWageById(minwage_id);
                setMinWage(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchMinWage();
    }, [minwage_id]);

    return { minWage };
}

export default useMinWageById;