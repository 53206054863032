import { useState, useEffect } from 'react';
import { getAllLevels } from '../services/levelsService';

const useAllLevels = () => {
    const [levels, setLevels] = useState([]);

    useEffect(() => {
        async function fetchAllLevels() {
            try {
                const { data } = await getAllLevels();
                setLevels(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchAllLevels();
    }, []);

    return { levels };
}

export default useAllLevels;