import React from 'react';

import { Form, Field, ErrorMessage } from 'formik';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import useRanks from '../../hooks/useRanks';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const ExperienceForm = (props) => {
    const classes = useStyles();

    const { ranks } = useRanks();

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    }

    return (
        <Form>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                    <Grid item className={classes.fullWidth}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="start_date" 
                                    as={KeyboardDatePicker} 
                                    label="Fecha de ingreso"
                                    className={classes.formControl} 
                                    inputVariant="outlined"
                                    variant="inline"
                                    InputAdornmentProps={{ position: "end" }}
                                    format="dd/MM/yyyy"
                                    error={hasError('start_date')}
                                    autoOk
                                    helperText={<ErrorMessage name="start_date" />}
                                    onChange={(date) => props.setFieldValue('start_date', date)}
                                    disableFuture
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="retirement_date" 
                                    as={KeyboardDatePicker} 
                                    label="Fecha de retiro"
                                    className={classes.formControl} 
                                    inputVariant="outlined"
                                    variant="inline"
                                    InputAdornmentProps={{ position: "end" }}
                                    format="dd/MM/yyyy"
                                    error={hasError('retirement_date')}
                                    helperText={<ErrorMessage name="retirement_date" />}
                                    onChange={(date) => props.setFieldValue('retirement_date', date)}
                                    autoOk
                                    disabled={props.values.currently}
                                    disableFuture
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.fullWidth}>
                        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControlLabel
                                    control={
                                        <Field
                                            name="currently"
                                            as={Checkbox}
                                            checked={props.values.currently}
                                            onChange={(event) => props.setFieldValue("currently", event.target.checked)}
                                            color="primary"
                                        />
                                    }
                                    label="Actualmente"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.fullWidth}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" className={classes.formControl} error={hasError('rank')}>
                                    <InputLabel id="rank-station-user-label">Rango</InputLabel>
                                    <Field
                                        name="rank"
                                        as={Select}
                                        labelId="rank-station-user-label"
                                        id="rank-station-user-select"
                                        label="Rango"
                                    >
                                    {ranks.ranks.map((rank, index) => (
                                        <MenuItem value={rank.id} key={`rank-station-user-key-${index}`}>{rank.name}</MenuItem>
                                    ))}
                                    </Field>
                                    <FormHelperText>
                                        <ErrorMessage name="rank" />
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field
                                    name="institution"
                                    as={TextField}
                                    className={classes.formControl}
                                    variant="outlined"
                                    label="Institución"
                                    type="text"
                                    error={hasError('institution')}
                                    helperText={<ErrorMessage name="institution" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Button
                    className={classes.saveExperienceButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disableElevation
                >
                    Agregar
                </Button>
            </MuiPickersUtilsProvider>
        </Form>
    );
}

export default ExperienceForm;