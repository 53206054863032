import React, { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

import useAllActivities from '../../../../../hooks/useAllActivities';
import useAreasByYear from '../../../../../hooks/useAreasByYear';

import { Form, Field, ErrorMessage } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const CategorizarForm = (props) => {
    const classes = useStyles();
    const { activities, findActivityById } = useAllActivities();
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const { findAreaByGivenAreaRange } = useAreasByYear(currentYear);
    const { readMode } = props;

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    };

    useEffect(() => {
        const areaM2 = props.values.length * props.values.width;
        if (isNaN(areaM2)) {
            props.setFieldValue('impact', '');
            props.setFieldTouched('impact', true);
            return;
        }
        const area_level = findAreaByGivenAreaRange(areaM2);
        if (!area_level) {
            props.setFieldValue('impact', 'Ningún área encontrada.');
            props.setFieldTouched('impact', true);
            return;
        }
        props.setFieldValue('impact', area_level.impact);
    
    // eslint-disable-next-line
    }, [props.values.length, props.values.width, findAreaByGivenAreaRange]);

    return (
        <div className={classes.root}>
            <Form>
                <Grid container direction="column" spacing={1} justify="flex-start" alignItems="flex-start">
                    <Grid item className={classes.row}>
                        <Grid container direction="row" spacing={1} justify="flex-start" alignItems="flex-start">
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="width" 
                                    type="number" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Ancho (m)"
                                    className={classes.formControl} 
                                    error={hasError('width')}
                                    helperText={<ErrorMessage name="width" />}
                                    disabled={readMode}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl disabled={readMode} className={classes.formControl} variant="outlined" error={hasError('activity_id')}>
                                    <InputLabel>Actividad comercial</InputLabel>
                                    <Field 
                                        name="activity_id" 
                                        type="text"
                                        as={Select} 
                                        label="Actividad comercial"
                                        className={classes.formControl} 
                                    >
                                        {activities.map((item, key) => (
                                            <MenuItem key={`activity-key-${key}`} value={item.id}>{`${item.name}`}</MenuItem>
                                        ))}
                                    </Field>
                                    <FormHelperText>
                                        <ErrorMessage name="activity_id" />
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" spacing={1} justify="flex-start" alignItems="flex-start">
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="length" 
                                    type="number" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Largo (m)"
                                    className={classes.formControl} 
                                    error={hasError('length')}
                                    helperText={<ErrorMessage name="length" />}
                                    disabled={readMode}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" spacing={1} justify="flex-start" alignItems="flex-start">
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="impact" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Impacto"
                                    className={classes.formControl} 
                                    error={hasError('impact')}
                                    helperText={<ErrorMessage name="impact" />}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="level" 
                                    type="number" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Nivel"
                                    className={classes.formControl} 
                                    error={hasError('level')}
                                    helperText={<ErrorMessage name="level" />}
                                    disabled
                                    value={(() => {
                                        const activity = findActivityById(props.values.activity_id);
                                        if (!activity) {
                                            return '';
                                        }
                                        return activity.level;
                                    })()}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" spacing={1} justify="flex-start" alignItems="flex-start">
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="area" 
                                    type="number" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Área (m2)"
                                    className={classes.formControl} 
                                    error={hasError('area')}
                                    helperText={<ErrorMessage name="area" />}
                                    disabled
                                    value={(() => {
                                        const area2 = props.values.width * props.values.length;
                                        if (isNaN(area2)) {
                                            return '';
                                        }
                                        return area2;
                                    })()}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Button
                    className={classes.saveButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disableElevation
                    disabled={readMode}
                >
                    Siguiente
                </Button>
            </Form>
        </div>
    );
}

CategorizarForm.defaultProps = {
    readMode: false,
};

export default CategorizarForm;