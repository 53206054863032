import { useState, useEffect } from 'react';
import { getAllEmergencies } from '../services/emergenciesService';

const useAllEmergencies = () => {
    const [emergencies, setEmergencies] = useState([]);

    useEffect(() => {
        async function fetchAllEmergencies() {
            try {
                const { data } = await getAllEmergencies();
                setEmergencies(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchAllEmergencies();
    }, []);

    return emergencies;
}

export default useAllEmergencies;