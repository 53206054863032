import * as Yup from 'yup';

import { 
    requiredDateRange, 
    requiredInspection,
    requiredInspector,
    requiredMinwageYear,
    requiredRequestDate,
 } from './helpers';

export default Yup.object().shape({
    type: Yup.string()
        .required('Éste campo es requerido.'),
    start_date: Yup.date()
        .when('type', (type, yup) => {
            return requiredDateRange(type) 
                ? yup.required('Éste campo es requerido.')
                : yup;
        }),
    end_date: Yup.date()
        .when('type', (type, yup) => {
            return requiredDateRange(type) 
                ? yup.required('Éste campo es requerido.') 
                     .when('start_date', (start_date, yup) => {
                        return start_date && yup.min(start_date, "Fecha final no puede ser menor que fecha inicial.");
                     })
                : yup;
        }),
    inspection_number: Yup.string()
        .when('type', (type, yup) => {
            return requiredInspection(type)
                ? yup.required('Éste campo es requerido.')
                : yup;
        }),
    inspector_id: Yup.number()
        .when('type', (type, yup) => {
            return requiredInspector(type)
                ? yup.required('Éste campo es requerido.')
                : yup;
        }),
    year: Yup.string()
        .when('type', (type, yup) => {
            return requiredMinwageYear(type)
                ? yup.required('Éste campo es requerido.')
                : yup;
        }),
    request_date: Yup.date()
        .when('type', (type, yup) => {
            return requiredRequestDate(type) 
                ? yup.required('Éste campo es requerido.')
                : yup;
        }),
});