import { useState, useEffect } from 'react';
import { getUserById } from '../services/usersService';

const useUserById = (user_id) => {
    const [user, setUser] = useState(null);

    // La razón por la cual se repite el código en ambas funciones
    // es para evitar hacer a refetchUser una dependencia del useEffect
    // y así evitar un render loop infinito.

    useEffect(() => {
        async function fetchUser() {
            try {
                const { data } = await getUserById(user_id);
                setUser(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchUser();
    }, [user_id]);

    async function refetchUser() {
        try {
            const { data } = await getUserById(user_id);
            setUser(data);
        } catch(e) {
            const { response } = e;
            console.log(response);
        }
    }

    return { refetchUser, user };
}

export default useUserById;