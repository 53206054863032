import React from 'react';

// import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles(styles);

const ModulePicker = (props) => {
    const { 
        icon, 
        moduleTitle ,
        checked,
        activationDate,
        deactivationDate,
        onChangeActivationDate,
        onChangeDeactivationDate,
        onChangeChecked,
        backgroundImage,
        activationDateError,
        activationDateHelperText,
        deactivationDateError,
        deactivationDateHelperText,
        activationDateDisabled,
        deactivationDateDisabled,
    } = props;

    const classes = useStyles();

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={classes.root}>
                <div className={classes.paper}>
                    <div className={classes.item}>
                        <Checkbox checked={checked} onChange={onChangeChecked} className={classes.checkbox} />
                        <img
                            src={backgroundImage}
                            alt="background"
                            className={classes.backgroundImage}
                        />
                        <div className={classes.gradient}></div>
                        <div className={classes.content}>
                            <img src={icon} alt="Module Icon" className={classes.moduleIcon} />
                            <Typography component={"div"} className={classes.moduleTitle}>
                                <Box fontWeight="fontWeightBold" fontSize={30}>
                                    {moduleTitle}
                                </Box>
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.item}>
                        <div className={classes.content}>
                            <KeyboardDatePicker
                                disabled={!checked || activationDateDisabled}
                                variant="inline"
                                inputVariant="outlined"
                                margin="normal"
                                id={`activation-date-${moduleTitle}`}
                                label="Fecha de activación"
                                format="dd/MM/yyyy"
                                value={activationDate}
                                onChange={onChangeActivationDate}
                                InputAdornmentProps={{ position: "start" }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                disablePast
                                error={activationDateError}
                                helperText={activationDateHelperText}
                                autoOk
                            />
                            <KeyboardDatePicker
                                disabled={!checked || deactivationDateDisabled}
                                inputVariant="outlined"
                                margin="normal"
                                variant="inline"
                                id={`deactivation-date-${moduleTitle}`}
                                label="Fecha de finalización"
                                format="dd/MM/yyyy"
                                value={deactivationDate}
                                onChange={onChangeDeactivationDate}
                                InputAdornmentProps={{ position: "start" }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                disablePast
                                error={deactivationDateError}
                                helperText={deactivationDateHelperText}
                                autoOk
                            />
                        </div>
                    </div>
                </div>
            </div>
        </MuiPickersUtilsProvider>
    );
}

ModulePicker.defaultProps = {
  activationDateDisabled: false,
  deactivationDateDisabled: false,
};

export default ModulePicker;