import { createSlice } from '@reduxjs/toolkit';

export const linearProgressSlice = createSlice({
    name: 'linearProgress',
    initialState: {
        variant: 'determinate',
        value: 0,
    },
    reducers: {
        startLinearProgress: (state) => {
            state.variant = 'query';
        },
        stopLinearProgress: (state) => {
            state.variant = 'determinate';
            state.value = 0;
        },
    },
});

export const {
    startLinearProgress,
    stopLinearProgress,
} = linearProgressSlice.actions;

export const selectLinearProgress = (state) => state.linearProgress;

export default linearProgressSlice.reducer;