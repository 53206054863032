import * as Yup from 'yup';

export default Yup.object({
        traction: Yup.string()
            .required('Éste campo es requerido.'),
        brand_id: Yup.number()
            .required('Éste campo es requerido.'),
        model_id: Yup.number()
            .required('Éste campo es requerido.'),
        power_id: Yup.number()
            .required('Éste campo es requerido.'),
        engine_brand_id: Yup.number()
            .required('Éste campo es requerido.'),
        engine_fuel: Yup.string()
            .required('Éste campo es requerido.'),
        oil_tank: Yup.string()
            .max(60, 'Máximo 60 carácteres.')
            .required('Éste campo es requerido.'),
        transmission: Yup.string()
            .required('Éste campo es requerido.'),
        engine_name: Yup.string()
            .max(60, "Éste campo sólo permite máximo 60 dígitos.")
            .required('Éste campo es requerido.'),
        chasis: Yup.string()
            .max(60, "Éste campo sólo permite máximo 60 dígitos.")
            .required('Éste campo es requerido.'),
        color: Yup.string()
            .max(60, "Éste campo sólo permite máximo 60 dígitos.")
            .required('Éste campo es requerido.'),
        type: Yup.string()
            .max(20, "Éste campo sólo permite máximo 20 dígitos.")
            .required('Éste campo es requerido.'),
        doors: Yup.string()
            .max(60, "Éste campo sólo permite máximo 60 dígitos.")
            .required('Éste campo es requerido.'),
        passengers: Yup.string()
            .max(2, "Éste campo sólo permite máximo 2 dígitos.")
            .required('Éste campo es requerido.'),
        capacity: Yup.string()
            .max(6, "Éste campo sólo permite máximo 6 dígitos.")
            .required('Éste campo es requerido.'),
});