import * as Yup from 'yup';

export default Yup.object().shape({
    report: Yup.string()
        .max(10, 'Máximo 10 carácteres.')
        .required('Éste campo es requerido.'),
    damage_report_id: Yup.number()
        .required('Éste campo es requerido.'),
    novelties: Yup.string()
        .max(500, 'Máximo 500 carácteres.')
        .required('Éste campo es requerido.'),
    vehicle_id: Yup.number()
        .required('Éste campo es requerido.'),
    maintenance_id: Yup.number()
        .required('Éste campo es requerido.'),
    date: Yup.date()
        .required('Éste campo es requerido.'),
    note: Yup.string()
        .max(500, 'Máximo 500 carácteres.')
        .required('Éste campo es requerido.'),
    location: Yup.string()
        .max(60, 'Máximo 60 carácteres.')
        .required('Éste campo es requerido.'),
    time: Yup.date()
        .required('Éste campo es requerido.'),
    emergency: Yup.string()
        .max(10, 'Máximo 10 dígitos.')
        .required('Éste campo es requerido.'),
});