export const CERT_BODY_VARIABLES = {
    "station_details.name": "Nombre de la estación",
    "station_details.address": "Dirección de la estación",
    "station_details.phone": "Teléfono de la estación",
    "station_logo_url": "Url logo de la estación",
    "admin_name": "Nombre del administrador registrado en la inspección",
    "owner_name": "Nombre de dueño registrado en la inspección",
    "address": "Dirección de la inspección",
    "social_reason": "Razón social cargada en la inspección",
    "state": "Estado de la inspección",
    "nit": "NIT de la inspección",
    "cert_emission_date": "Fecha de emisión de certificado",
    "station.firstname": "Nombre del usuario principal de la estación",
    "station.lastname": "Apellido del usuario principal de la estación",
    "station.phone": "Teléfono del usuario principal de la estación",
    "station.email": "Correo eléctronico del usuario principal de la estación",
    "cert_number": "Número de certificado cargado en la inspección",
    "inspection_number": "Número de inspección cargado en la inspección (autogenerado)",
    "cert_expiration_date": "Fecha de inspección del certificado",
    "qr_code_url": "Enlace a imagen de código QR generado",
    "discount": "Porcentaje de descuento",
    "discount_value": "Valor del descuento",
    "total_value": "Total a pagar",
};