import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import GestionRiesgoRouting, { GestionRiesgoUrls } from './routing';
import routesUrls from '../../routes/urls';

const GestionRiesgo = props => {
    return (
        <div>
            <Switch>
                {GestionRiesgoRouting.map((item, key) => (
                    <Route key={`gestion-riesgo-${key}`} path={item.path} exact render={routeProps => (
                        <item.component {...routeProps} {...props} />
                    )}/>
                ))}
                <Redirect exact from={routesUrls.GestionRiesgo} to={GestionRiesgoUrls.Tablero} />
            </Switch>
        </div>
    );
};

export default GestionRiesgo;