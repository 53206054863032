import * as Yup from 'yup';

export default Yup.object().shape({
    inspector_id: Yup.number()
        .required('Éste campo es requerido.'),
    discount: Yup.string()
        .max(10, 'Máximo 10 dígitos.')
        .required('Éste campo es requerido.'),
    donation: Yup.boolean(),
    total_value: Yup.string(),
    discount_value: Yup.string(),
});