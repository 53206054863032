import { createSlice } from '@reduxjs/toolkit';

export const globalDataLists = createSlice({
    name: 'globalDataLists',
    initialState: {
        selectedTab: 0,
    },
    reducers: {
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload.selectedTab;
        },
        resetListState: (state) => {
            state.selectedTab = 0;
        },
    },
});

export const {
    setSelectedTab,
    resetListState,
} = globalDataLists.actions;

export const selectGlobalDataLists = (state) => state.globalDataLists;

export default globalDataLists.reducer;