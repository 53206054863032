import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStEmergenciesRequest, selectStEmergencies } from '../../../../../../store/slices/stEmergenciesSlice';
import { setGlobalAlert } from '../../../../../../store/slices/alertsSlice';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Can, Only } from '../../../../../../components/Can';
import { ROLES_KEYS } from '../../../../../../constants/roles';

import useBackendMessageResponse from '../../../../../../hooks/useBackendMessageResponse';
import { deleteStEmergency } from '../../../../../../services/ST/emergenciesService';

import format from 'date-fns/format';

import { SearchField } from '../../../../../../components/SearchField';
import { FilterButton } from '../../../../../../components/FilterButton';
import { EmergenciasFilterForm } from './EmergenciasFilterForm';

import { SiniestralidadUrls } from '../../../../routing';

import { Formik } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';
import { AllowOnlyIf } from '../../../../../../components/AllowOnlyIf';
import { PERMISSION_KEYS } from '../../../../../../constants/permissions';

const useStyles = makeStyles(styles);

const Emergencias = (props) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const dispatch = useDispatch();
    const stEmergencies = useSelector(selectStEmergencies);
    const {
        backendResponse: deleteStEmergencyResponse,
        executeService: deleteStEmergencyService,
    } = useBackendMessageResponse(deleteStEmergency);
    const [stEmergencyClickedData, setStEmergencyClickedData] = useState({
        openDialog: false,
        stEmergency: null,
    });
    const [emergenciasFilterForm, setEmergenciasFilterForm] = useState({
        emergency_date: '',
        global_emergency_id: '',
        city_id: '',
        neighborhood_id: '',
        reporter_name: '',

        // Éste campo aparecerá únicamente para aquellos usuarios
        // que no sean ni estación ni usuarios de una estación.
        station_name: '',
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onGetSearchTerm = (term) => {
        setSearchTerm(term);
        setPage(0);
    }

    const onAddNewEmergency = () => {
        const { history } = props;
        history.push(SiniestralidadUrls.AddEmergency);
    }

    const onClickEditEmergency = (emergency) => () => {
        const { history } = props;
        history.push(SiniestralidadUrls.EditEmergency.replace(':emergency_id', emergency.id));
    }

    const onClickDetailsEmergency = (emergency) => () => {
        const { history } = props;
        history.push(SiniestralidadUrls.DetailsEmergency.replace(':emergency_id', emergency.id));
    }

    const onClickDeleteEmergency = (stEmergency) => () => {
        setStEmergencyClickedData({
            openDialog: true,
            stEmergency,
        });
    }

    const handleCloseDeleteDialog = () => {
        setStEmergencyClickedData({
            openDialog: false,
            stEmergency: null,
        });
    }

    const handleStEmergencyDelete = async () => {
        try {
            const stEmergencyId = stEmergencyClickedData.stEmergency.id;
            await deleteStEmergencyService(stEmergencyId);
            setStEmergencyClickedData({
                openDialog: false,
                stEmergency: null,
            });
            dispatch(getStEmergenciesRequest({
                page: page + 1,
                page_limit: rowsPerPage,
                filters: {
                    searchTerm: searchTerm !== '' ? searchTerm : undefined,
                    ...cleanFilter(),
                },
            }));
        } catch (e) {
            const { response } = e;
            console.log(response);
        }
    }

    const cleanFilter = useCallback(() => {
        const cleanFilteredData = emergenciasFilterForm;
        Object.keys(cleanFilteredData).forEach((key) => {
            if (cleanFilteredData[key] === '') {
                delete cleanFilteredData[key];
            }
        });
        return cleanFilteredData;
    }, [emergenciasFilterForm]);

    useEffect(() => {
        dispatch(getStEmergenciesRequest({
            page: page + 1,
            page_limit: rowsPerPage,
            filters: {
                searchTerm: searchTerm !== '' ? searchTerm : undefined,
                ...cleanFilter(),
            },
        }));
    }, [page, rowsPerPage, dispatch, searchTerm, cleanFilter]);

    useEffect(() => {
        if (deleteStEmergencyResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: deleteStEmergencyResponse.message,
            severity: 'success',
        }));
    }, [dispatch, deleteStEmergencyResponse.message]);

    useEffect(() => {
        if (deleteStEmergencyResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: deleteStEmergencyResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, deleteStEmergencyResponse.error])

    return (
        <div className={classes.root}>
            <Dialog
                open={stEmergencyClickedData.openDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-vehicle-delete-title"
                aria-describedby="alert-dialog-vehicle-delete-description"
            >
                <DialogTitle id="alert-dialog-vehicle-delete-title">{"Confirmación"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-vehicle-delete-description">
                        ¿Estás seguro de querer continuar con la eliminación del registro seleccionado?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleStEmergencyDelete} color="primary" autoFocus>
                        Continuar
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.fullWidth} spacing={2}>
                <Grid item md={6} xs={12} className={classes.searchInput}>
                    <SearchField onClickSearch={onGetSearchTerm} />
                </Grid>
                <Grid item>
                    <FilterButton>
                        {filterActions => (
                            <div class={classes.filterForm}>
                                <Formik
                                    enableReinitialize
                                    initialValues={emergenciasFilterForm}
                                    component={EmergenciasFilterForm}
                                    onSubmit={(values) => {
                                        const submitter = window.event.submitter.name;
                                        setPage(0);
                                        if (submitter === "clean") {
                                            setEmergenciasFilterForm({
                                                emergency_date: '',
                                                global_emergency_id: '',
                                                city_id: '',
                                                neighborhood_id: '',
                                                reporter_name: '',
                                            });
                                            filterActions.hideFilterForm();
                                            return;
                                        }
                                        setEmergenciasFilterForm(values);
                                        filterActions.hideFilterForm();
                                    }}
                                />
                            </div>
                        )}
                    </FilterButton>
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" justify="space-between" className={classes.fullWidth}>
                <Grid item>
                    <Typography component={"span"}>
                        <Box fontWeight="fontWeightBold" fontSize={25}>Emergencias</Box>
                    </Typography>
                </Grid>
                <AllowOnlyIf permission={PERMISSION_KEYS.ST_WRITE}>
                    <Grid item>
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            className={classes.addEmergencyButton} 
                            disableElevation
                            onClick={onAddNewEmergency}
                        >
                            Añadir emergencia 
                        </Button>
                    </Grid>
                </AllowOnlyIf>
            </Grid>
            <Paper elevation={0}>
                <TableContainer>
                    <Table className={classes.table} aria-label="activities-table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Fecha de emergencia</TableCell>
                                <TableCell align="left">Tipo de emergencia</TableCell>
                                <TableCell align="left">Ciudad</TableCell>
                                <TableCell align="left">Barrio</TableCell>
                                <TableCell align="left">Nombre de quien reporta</TableCell>
                                <Can>
                                    <Only roles={[ROLES_KEYS.admin, ROLES_KEYS.risk_agent]}>
                                        <TableCell align="left">Estación</TableCell>
                                    </Only>
                                </Can>
                                <TableCell align="right">Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stEmergencies.stEmergencies.map((stEmergency, key) => (
                                <TableRow key={`list-st-emergency-item-key-${key}`}>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {format(new Date(stEmergency.report_date), 'dd/MM/yyyy')}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {`${stEmergency.emergency.code} - ${stEmergency.emergency.name}`}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {`${stEmergency.city}`}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {`${stEmergency.neighborhood}`}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {`${stEmergency.reporter_name}`}
                                        </Typography>
                                    </TableCell>
                                    <Can>
                                        <Only roles={[ROLES_KEYS.admin, ROLES_KEYS.risk_agent]}>
                                            <TableCell align="left">
                                                <Typography component={"span"} className={classes.tableCellText}>
                                                    {`${stEmergency.station_name}`}
                                                </Typography>
                                            </TableCell>
                                        </Only>
                                    </Can>
                                    <AllowOnlyIf permission={PERMISSION_KEYS.ST_WRITE}>
                                        {(hasPermission) => {
                                            return hasPermission ? (
                                                <TableCell align="right">
                                                    <Grid container direction="row" alignItems="center" justify="flex-end" spacing={1}>
                                                        <Grid item>
                                                            <IconButton aria-label="edit" className={classes.iconButton} onClick={onClickEditEmergency(stEmergency)}>
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Grid>
                                                        <Grid item>
                                                            <IconButton aria-label="delete" className={classes.iconButton} onClick={onClickDeleteEmergency(stEmergency)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            ) : (
                                                <TableCell align="right">
                                                    <IconButton aria-label="edit" className={classes.iconButton} onClick={onClickDetailsEmergency(stEmergency)}>
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                </TableCell>
                                            )
                                        }}
                                    </AllowOnlyIf>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={stEmergencies.total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage="Filas por página:"
                        labelDisplayedRows={() => {
                            return `Página: ${page + 1} de ${Math.ceil(stEmergencies.total / rowsPerPage)}`;
                        }}
                    />
                </TableContainer>
            </Paper>
        </div>
    );
}

export default Emergencias;