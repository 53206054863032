import { useState, useEffect } from 'react';
import { getEmergencyById } from '../services/emergenciesService';

const useEmergencyById = (emergency_id) => {
    const [emergency, setEmergency] = useState(null);

    useEffect(() => {
        async function fetchEmergency() {
            try {
                const { data } = await getEmergencyById(emergency_id);
                setEmergency(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchEmergency();
    }, [emergency_id]);

    return { emergency };
}

export default useEmergencyById;