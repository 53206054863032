export default (theme) => ({
    root: {
        width: '100%',
    },
    filterButton: {
        backgroundColor: '#5C5C5C',
        height: '45px',
        width: '100%',
        "&:hover": {
            backgroundColor: '#3D3D3D',
        },
    },
});