import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';

const lightTheme = createMuiTheme({
    palette,
    typography,
});

export default lightTheme;