import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';

import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';
import { editActivity } from '../../../../services/activitiesService';
import useActivityById from '../../../../hooks/useActivityById';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import validate from 'validate.js';
import schema from './schema';

import Levels from '../../../../constants/levels';

const useStyles = makeStyles(styles);

const EditActivity = (props) => {
    const classes = useStyles();
    const { activity_id } = useParams();
    const { activity } = useActivityById(activity_id);
    const {
        backendResponse: editActivityResponse,
        executeService: editActivityService,
    } = useBackendMessageResponse(editActivity);
    const [activityForm, setActivityForm] = useState({
        isValid: false,
        isSubmitted: false,
        values: {
            code: '',
            level: '',
            name: '',
        },
        touched: {
            code: '',
            level: '',
            name: '',
        }, 
        errors: {},
    });

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    const onSubmitEditActivity = async (event) => {
        event.preventDefault();

        if(!activityForm.isSubmitted) {
            setActivityForm(currentActivityForm => ({
                ...currentActivityForm,
                isSubmitted: true,
            }));
        }

        if(!activityForm.isValid) {
            return;
        }

        const activityData = activityForm.values;
        await editActivityService(activity_id, activityData);
    }

    const handleChange = event => {
        event.persist();

        setActivityForm(currentActivityForm => ({
            ...currentActivityForm,
            values: {
                ...currentActivityForm.values,
                [event.target.name]: event.target.value,
            },
            touched: {
                ...currentActivityForm.touched,
                [event.target.name]: true
            },
        }));
    };

    const hasError = field =>
        (activityForm.isSubmitted || activityForm.touched[field]) && activityForm.errors[field] ? true : false;

    useEffect(() => {
        const errors = validate(activityForm.values, schema);

        setActivityForm(currentActivityForm => ({
            ...currentActivityForm,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [activityForm.values, activityForm.isSubmitted]);

    useEffect(() => {
        if (activity === null) {
            return;
        }

        setActivityForm(currentActivityForm => ({
            ...currentActivityForm,
            isValid: true,
            values: {
                code: activity.code,
                level: activity.level,
                name: activity.name,
            },
        }))
    }, [activity])

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Editar actividad</Box>
            </Typography>
            <form onSubmit={onSubmitEditActivity} className={classes.form}>
                <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1} className={classes.formContainer}>
                    <Grid item className={classes.formItem}>
                        <TextField 
                            id="activity-code-input" 
                            label="Código" 
                            variant="outlined" 
                            className={classes.formControl} 
                            name="code"
                            onChange={handleChange}
                            value={activityForm.values.code}
                            error={hasError('code')}
                            helperText={
                                hasError('code') ? activityForm.errors.code[0] : null
                            }
                        />
                    </Grid>
                    <Grid item className={classes.formItem}>
                        <FormControl variant="outlined" className={classes.formControl} error={hasError('level')}>
                            <InputLabel id="level-select">Nivel</InputLabel>
                            <Select
                                labelId="level-select"
                                id="level-select-outlined"
                                label="Nivel"
                                name="level"
                                onChange={handleChange}
                                value={activityForm.values.level}
                            >
                                {Object.keys(Levels).map((key) => (
                                    <MenuItem value={key} key={`new-level-key-key-${key}`}>
                                        {key}
                                    </MenuItem>
                                ))}
                            </Select>
                            {hasError('level') && 
                                <FormHelperText id="level-error-text">
                                    {activityForm.errors.level[0]}
                                </FormHelperText> 
                            }
                        </FormControl>
                    </Grid>
                    <Grid item className={classes.formItem}>
                        <TextField 
                            id="acitivty-name-input" 
                            label="Actividad" 
                            variant="outlined" 
                            className={classes.formControl} 
                            name="name"
                            onChange={handleChange}
                            value={activityForm.values.name}
                            error={hasError('name')}
                            helperText={
                                hasError('name') ? activityForm.errors.name[0] : null
                            }
                        />
                    </Grid>
                </Grid>
                { editActivityResponse.message !== null && (
                    <Typography color="primary" className={classes.authNotificationText}>
                        <Box fontWeight="fontWeightBold">
                            { editActivityResponse.message }
                        </Box>
                    </Typography>
                )}
                { editActivityResponse.error !== null && (
                    <Typography color="error" className={classes.authNotificationText}>
                        <Box fontWeight="fontWeightBold">
                            { editActivityResponse.error.message }
                        </Box>
                    </Typography>
                )}
                <Button
                    className={classes.saveButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disableElevation
                    disabled={editActivityResponse.isLoading}
                >
                    Guardar
                </Button>
            </form>
        </div>
    );
}

export default EditActivity;