export default theme => ({
    saveButton: {
        marginTop: '20px',
        width: '170px',
    },
    addExtinguisherButton: {
        width: '100%',
        height: '55px',
    },
    formControl: {
        width: '100%',
        '& .Mui-disabled input, & .Mui-disabled div[class*="MuiSelect-"], & .Mui-disabled textarea': {
            color: "rgba(0,0,0,0.6)"
        },
    },
    row: {
        width: '60%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    fullWidth: {
        width: '103%',
    },
    topSpacing: {
        marginTop: '30px',
    },
    iconButton: {
        color: '#96C2C9',
    },
    iconSize: {
        fontSize: '30px',
    },
    extinguishersAcumulator: {
        backgroundColor: '#E7F6FC',
        borderRadius: '5px',
    },
    extinguisherCounter: {
        color: '#0199AE',
    },
});