import { useState, useEffect } from 'react';
import { getVehicleModels } from '../../services/MT/vehicleModelsService';

const useVehicleModels = () => {
    const [models, setModels] = useState([]);

    useEffect(() => {
        async function fetchVehicleModels() {
            try {
                const { data } = await getVehicleModels();
                setModels(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchVehicleModels();
    }, []);

    return { models };
}

export default useVehicleModels;