import { ROLES_KEYS } from '../roles';

export const ALL_REPORT_TYPES = {
    inspections_month_year: 'Inspección mes/año',
    companies_with_deviations: 'Empresas con Desviaciones',
    companies_without_deviations: 'Empresas sin Desviaciones',
    deviations_report: 'Reporte de Desviaciones',
    certified_companies: 'Compañias certificadas',
    non_certified_companies: 'Compañías no certificadas',
    inspector_summary: 'Resumen de Inspector',
    general_table_year: 'Tabla General por Año',
    general_report_inspectors: 'Informe General (Inspectores)',
    detailed_report: 'Informe Detallado',
    inspector_report: 'Informe por Inspector',
    schedule_day: 'Programación del Día',
};

export const RISK_AGENT_REPORT_TYPES = {
    companies_with_deviations: 'Empresas con Desviaciones',
    companies_without_deviations: 'Empresas sin Desviaciones',
    certified_companies: 'Compañias certificadas',
    non_certified_companies: 'Compañías no certificadas',
};

export const getAvailableReportTypes = (role) => {
  if (role === ROLES_KEYS.risk_agent) {
    return RISK_AGENT_REPORT_TYPES;
  }
  return ALL_REPORT_TYPES;
}