import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';

import useTimestamp from '../../../../hooks/useTimestamp';
import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';
import { editStEmergency } from '../../../../services/ST/emergenciesService';

import useStEmergencyById from '../../../../hooks/ST/useStEmergencyById';

import { SiniestralidadUrls } from '../../routing';

import DatosGeneralesSchema from '../../Forms/Emergencias/DatosGeneralesForm/schema';
import UbicacionSchema from '../../Forms/Emergencias/UbicacionForm/schema';
import AfectadoSchema from '../../Forms/Emergencias/AfectadoForm/schema';
import CostosSchema from '../../Forms/Emergencias/CostosForm/schema';
import ReceptionSchema from '../../Forms/Emergencias/RecepcionForm/schema';

import { Formik } from 'formik';
import { 
    DatosGeneralesForm,
    UnidadesForm,
    UbicacionForm,
    AfectadoForm,
    PrimerosAuxiliosForm,
    TestigosForm,
    EvidenciasForm,
    CostosForm,
    RecepcionForm,
} from '../../Forms/Emergencias';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const DetailsEmergency = (props) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState(new Set());
    const timestamp = useTimestamp();
    const dispatch = useDispatch();
    const { emergency_id } = useParams();
    const { stEmergency } = useStEmergencyById(emergency_id);
    const {
        backendResponse: editStEmergencyResponse,
        executeService: editStEmergencyService,
    } = useBackendMessageResponse(editStEmergency);

    const [datosGeneralesForm, setDatosGeneralesForm] = useState({
        report: `ST-${timestamp}`,
        reporter_name: '',
        report_date: new Date(),
        reporter_phone: '',
        report_time: new Date(),
        dispatch_machine: [],
        dispatch_date: new Date(),
        support_machine: [],
        dispatch_time: new Date(),
        report_address: '',
        arriving_time: new Date(),
        report_neighborhood_id: '',
        control_time: new Date(),
        emergency_id: '',
        station_arriving_time: new Date(),
    });

    const [unidadesForm, setUnidadesForm] = useState({
        unities: [],
    });

    const [ubicacionForm, setUbicationForm] = useState({
        department_id: '',
        city_id: '',
        neighborhood_id: '',
        location_phone: '',
        location_area: 'Rural',
        location_address: '',
        location_observations: '',
        location_procedures: [{
            procedure: '',
        }],
    });

    const [afectadoForm, setAfectadoForm] = useState({
        affected_person_apply: true,
        affected_person: '',
        affected_person_document: '',
        affected_person_address: '',
        affected_person_phone: '',
        affected_person_description: '',
    });

    const [primerosAuxiliosForm, setPrimerosAuxiliosForm] = useState({
        first_aid_apply: true,
        first_aids: [],
    });

    const [testigosForm, setTestigosForm] = useState({
        witness_apply: true,
        witnesses: [],
    });

    const [evidenciasForm, setEvidenciasForm] = useState({
        evidence_apply: true,
        evidences: [],
    });

    const [costosForm, setCostosForm] = useState({
        cost_apply: true,
        costs_intervention_hour: '',
    });

    const [recepcionForm, setRecepcionForm] = useState({
        reception_date: new Date(),
        sender_id: '',
        reception_time: new Date(),
        sheet_number: '',
        remission_date: new Date(),
        annex_number: '',
        receiver_id: '',
        reception_observations: '',
    });

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleStep = (step) => () => {
        if (completed.has(step)) {
            setActiveStep(step);
        }
    }

    const handleComplete = () => {
        const newCompleted = new Set(completed);
        newCompleted.add(activeStep);
        setCompleted(newCompleted);
        handleNext();
    }

    const isStepCompleted = (index) => {
        return completed.has(index);
    }

    // Acá se recibe el formulario de reception porque setReception funciona de forma asíncrona
    // Entonces como es el último formulario y para asegurar que se están usando los valores actualizados
    // se usa como el evento para crear una emergencia pero en lugar de usar el estado directamente,
    // se usa los valores retornados por Formik. 
    const onCreateEmergency = async (receptionForm) => {
        setRecepcionForm(receptionForm);

        const emergencyData = {
            ...datosGeneralesForm,
            dispatch_machine: JSON.stringify(datosGeneralesForm.dispatch_machine),
            support_machine: JSON.stringify(datosGeneralesForm.support_machine),
            unities: JSON.stringify(unidadesForm.unities),
            ...ubicacionForm,
            location_procedures: JSON.stringify(ubicacionForm.location_procedures),
            affected_person_info: JSON.stringify(afectadoForm),
            first_aids_info: JSON.stringify(primerosAuxiliosForm),
            witnesses_info: JSON.stringify(testigosForm),
            evidences_info: JSON.stringify(evidenciasForm),
            costs_info: JSON.stringify(costosForm),
            ...receptionForm,
        };

        await editStEmergencyService(emergency_id, emergencyData);
    }

    useEffect(() => {
        if (editStEmergencyResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: editStEmergencyResponse.message,
            severity: 'success',
        }));

        props.history.push(SiniestralidadUrls.Tablero);
    }, [dispatch, editStEmergencyResponse.message, props.history]);

    useEffect(() => {
        if (editStEmergencyResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: editStEmergencyResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, editStEmergencyResponse.error])

    useEffect(() => {
        if (stEmergency === null) {
            return;
        }

        setDatosGeneralesForm({
            report: stEmergency.report,
            reporter_name: stEmergency.reporter_name,
            report_date: new Date(stEmergency.report_date),
            reporter_phone: stEmergency.reporter_phone,
            report_time: new Date(stEmergency.report_time),
            dispatch_machine: stEmergency.dispatch_machine !== null ? JSON.parse(stEmergency.dispatch_machine) : [],
            dispatch_date: new Date(stEmergency.dispatch_date),
            support_machine: stEmergency.support_machine !== null ? JSON.parse(stEmergency.support_machine) : [],
            dispatch_time: new Date(stEmergency.dispatch_time),
            report_address: stEmergency.report_address,
            arriving_time: new Date(stEmergency.arriving_time),
            report_neighborhood_id: stEmergency.report_neighborhood_id,
            control_time: new Date(stEmergency.control_time),
            emergency_id: stEmergency.emergency_id,
            station_arriving_time: new Date(stEmergency.station_arriving_time),
        });

        setUnidadesForm({
            unities: JSON.parse(stEmergency.unities),
        });

        setUbicationForm({
            department_id: stEmergency.department_id,
            city_id: stEmergency.city_id,
            neighborhood_id: stEmergency.neighborhood_id,
            location_phone: stEmergency.location_phone,
            location_area: stEmergency.location_area,
            location_address: stEmergency.location_address,
            location_observations: stEmergency.location_observations,
            location_procedures: JSON.parse(stEmergency.location_procedures),
        });

        const affected_info = JSON.parse(stEmergency.affected_person_info);
        setAfectadoForm({
            affected_person_apply: affected_info.affected_person_apply,
            affected_person: affected_info.affected_person,
            affected_person_document: affected_info.affected_person_document,
            affected_person_address: affected_info.affected_person_address,
            affected_person_phone: affected_info.affected_person_phone,
            affected_person_description: affected_info.affected_person_description,
        });

        const first_aids_info = JSON.parse(stEmergency.first_aids_info);
        setPrimerosAuxiliosForm({
            first_aid_apply: first_aids_info.first_aid_apply,
            first_aids: first_aids_info.first_aids,
        });

        const witnesses_info = JSON.parse(stEmergency.witnesses_info);
        setTestigosForm({
            witness_apply: witnesses_info.witness_apply,
            witnesses: witnesses_info.witnesses,
        });

        const evidences_info = JSON.parse(stEmergency.evidences_info);
        setEvidenciasForm({
            evidence_apply: evidences_info.evidence_apply,
            evidences: evidences_info.evidences,
        });

        const costs_info = JSON.parse(stEmergency.costs_info);
        setCostosForm({
            cost_apply: costs_info.cost_apply,
            costs_intervention_hour: costs_info.costs_intervention_hour,
        });

        setRecepcionForm({
            reception_date: new Date(stEmergency.reception_date),
            sender_id: stEmergency.sender_id,
            reception_time: new Date(stEmergency.reception_time),
            sheet_number: stEmergency.sheet_number,
            remission_date: new Date(stEmergency.remission_date),
            annex_number: stEmergency.annex_number,
            receiver_id: stEmergency.receiver_id,
            reception_observations: stEmergency.reception_observations,
        });

        completed.add(0);
        completed.add(1);
        completed.add(2);
        completed.add(3);
        completed.add(4);
        completed.add(5);
        completed.add(6);
        completed.add(7);
        completed.add(8);
    
    // eslint-disable-next-line
    }, [stEmergency]);

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Detalles de emergencia</Box>
            </Typography>
            <div className={classes.formSteps}>
                <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                    <Step key="datos-generales">
                        <StepButton onClick={handleStep(0)} completed={isStepCompleted(0)}>Datos generales</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <Formik 
                                    enableReinitialize
                                    initialValues={datosGeneralesForm}
                                    validationSchema={DatosGeneralesSchema}
                                    onSubmit={(values) => {
                                        setDatosGeneralesForm(values);
                                        handleComplete();
                                    }}
                                >
                                    {(formik) => <DatosGeneralesForm {...formik} readMode={true} />}
                                </Formik>
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="unidades">
                        <StepButton onClick={handleStep(1)} completed={isStepCompleted(1)}>Unidades</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <UnidadesForm
                                    unitiesData={unidadesForm} 
                                    onChangeUnities={(unities) => setUnidadesForm(current => ({ ...current, unities}))} 
                                    onHandleNext={handleComplete}
                                    readMode={true}
                                />
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="ubicacion">
                        <StepButton onClick={handleStep(2)} completed={isStepCompleted(2)}>Ubicación</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <Formik 
                                    initialValues={ubicacionForm}
                                    validationSchema={UbicacionSchema}
                                    onSubmit={(values) => {
                                        setUbicationForm(values);
                                        handleComplete();
                                    }}
                                >
                                    {(formik) => <UbicacionForm {...formik} readMode={true} />}
                                </Formik>
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="afectado">
                        <StepButton onClick={handleStep(3)} completed={isStepCompleted(3)}>Afectado</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <Formik 
                                    initialValues={afectadoForm}
                                    validationSchema={AfectadoSchema}
                                    onSubmit={(values) => {
                                        setAfectadoForm(values);
                                        handleComplete();
                                    }}
                                >
                                    {(formik) => <AfectadoForm {...formik} readMode={true} />}
                                </Formik>
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="primeros-auxilios">
                        <StepButton onClick={handleStep(4)} completed={isStepCompleted(4)}>Primeros Auxilios</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <PrimerosAuxiliosForm 
                                    firstAidsData={primerosAuxiliosForm} 
                                    onChangeFirstAids={(first_aids) => setPrimerosAuxiliosForm(current => ({ ...current, first_aids }))} 
                                    onToggleApply={() => setPrimerosAuxiliosForm(current => ({...current, first_aid_apply: !primerosAuxiliosForm.first_aid_apply}))}
                                    onHandleNext={handleComplete}
                                    readMode={true}
                                />
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="testigos">
                        <StepButton onClick={handleStep(5)} completed={isStepCompleted(5)}>Testigos</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <TestigosForm
                                    witnessesData={testigosForm} 
                                    onChangeWitnesses={(witnesses) => setTestigosForm(current => ({ ...current, witnesses }))} 
                                    onToggleApply={() => setTestigosForm(current => ({...current, witness_apply: !testigosForm.witness_apply}))}
                                    onHandleNext={handleComplete}
                                    readMode={true}
                                />
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="evidencias">
                        <StepButton onClick={handleStep(6)} completed={isStepCompleted(6)}>Evidencias</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <EvidenciasForm
                                    evidencesData={evidenciasForm} 
                                    onChangeEvidences={(evidences) => setEvidenciasForm(current => ({ ...current, evidences }))} 
                                    onToggleApply={() => setEvidenciasForm(current => ({...current, evidence_apply: !evidenciasForm.evidence_apply}))}
                                    onHandleNext={handleComplete}
                                    readMode={true}
                                />
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="costos">
                        <StepButton onClick={handleStep(7)} completed={isStepCompleted(7)}>Costos</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <Formik
                                    initialValues={costosForm}
                                    onSubmit={(values) => {
                                        setCostosForm(values);
                                        handleComplete();
                                    }}
                                    validationSchema={CostosSchema}
                                >
                                    {formik => (
                                        <CostosForm {...formik} datosGenerales={{...datosGeneralesForm}} readMode={true} />
                                    )}
                                </Formik>
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="recepcion">
                        <StepButton onClick={handleStep(8)} completed={isStepCompleted(8)}>Recepción</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <Formik 
                                    initialValues={recepcionForm}
                                    onSubmit={onCreateEmergency}
                                    validationSchema={ReceptionSchema}
                                >
                                    {(formik) => <RecepcionForm {...formik} readMode={true} />}
                                </Formik>
                            </div>
                        </StepContent>
                    </Step>
                </Stepper>
            </div>
        </div>
    );
}

export default DetailsEmergency;