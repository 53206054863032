import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import MuiPhoneNumber from 'material-ui-phone-number';

import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import { adminUsersUrls } from '../../routing';

import validate from 'validate.js';
import { 
    clientFormSchema, 
    stationFormSchema,
    mtRangeDateSchema,
    grRangeDateSchema,
    stRangeDateSchema,
    asyncClientFormSchema,
} from './schemas';

import { StyledTab } from '../../../../components/StyledTab';
import { StyledTabs } from '../../../../components/StyledTabs';
import { TabPanel } from '../../../../components/TabPanel';
import { ModulePicker } from '../../../../components/ModulePicker';

import { createUser } from '../../../../services/usersService';

import MotorIcon from '../../../../assets/sidebar/icons/motor.svg';
import SiniestralidadIcon from '../../../../assets/sidebar/icons/siniestralidad.svg';
import GestionDeRiesgoIcon from '../../../../assets/sidebar/icons/gestion-de-riesgo.svg';

import useDepartments from '../../../../hooks/useDepartments';
import useCities from '../../../../hooks/useCities';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const user_types = {
    station: 'Estación',
    risk_agent: 'Agente de riesgo',
};

const AddUser = (props) => {
    const departments = useDepartments();
    const cities = useCities();
    const dispatch = useDispatch();
    const { 
        backendResponse: createUserResponse,
        executeService: createUserService,
    } = useBackendMessageResponse(createUser);

    const [userType, setUserType] = useState('station');
    const [selectedTab, setSelectedTab] = useState(0);

    const [clientForm, setClientForm] = useState({
        isValid: false,
        isSubmitted: false,
        showPassword: false,
        values: {
            firstname: '',
            lastname: '',
            document: '',
            phone: '',
            email: '',
            password: '',
            city_id: '',
            department_id: '',
        },
        touched: {
            firstname: false,
            lastname: false,
            document: false,
            phone: false,
            email: false,
            city_id: false,
            department_id: false,
        }, 
        errors: {
            firstname: [],
            lastname: [],
            document: [],
            phone: [],
            email: [],
        },
    });

    const [stationForm, setStationForm] = useState({
        isValid: false,
        values: {
            station_name: '',
            station_phone: '',
            station_address: '',
        },
        touched: {
            station_name: false,
            station_phone: false,
            station_address: false,
        },
        errors: {
            station_name: [],
            station_phone: [],
            station_address: [],
        },
    });

    const [modulesForm, setModulesForm] = useState({
        MT: {
            isSelected: false,
            isValid: true,
            values: {
                mt_start_date: new Date(),
                mt_end_date: new Date(),
            },
            errors: {},
        },
        ST: {
            isSelected: false,
            isValid: true,
            values: {
                st_start_date: new Date(),
                st_end_date: new Date(),
            },
            errors: {},
        },
        GR: {
            isSelected: false,
            isValid: true,
            values: {
                gr_start_date: new Date(),
                gr_end_date: new Date(),
            },
            errors: {},
        },
    });

    const changeModuleSelected = (moduleName) => (event) => {
        setModulesForm(currentModulesForm => ({
            ...currentModulesForm,
            [moduleName]: {
                ...currentModulesForm[moduleName],
                isSelected: event.target.checked,
            },
        }));
    };

    const changeFieldDate = (moduleName, moduleField) => (date) => {
        setModulesForm(currentModulesForm => ({
            ...currentModulesForm,
            [moduleName]: {
                ...currentModulesForm[moduleName],
                values: {
                    ...currentModulesForm[moduleName].values,
                    [moduleField]: date,
                },
            },
        }));
    };

    const classes = useStyles(); 
    const { history } = props;

    const handleGoBackClick = () => {
        switch(selectedTab) {
            case 0:
                history.goBack();
                break;
            case 1:
                setSelectedTab(0);
                break;
            default:
                break;
        }
    }

    const handleClickShowPassword = () => {
        setClientForm(currentFormState => ({
            ...currentFormState,
            showPassword: !currentFormState.showPassword,
        }));
    };

    const onChangeUserType = event => {
        setUserType(event.target.value);
    }

    const handleClientFormChange = event => {
        event.persist();

        setClientForm(clientFormState => ({
            ...clientFormState,
            values: {
                ...clientFormState.values,
                [event.target.name]: event.target.value,
            },
            touched: {
                ...clientFormState.touched,
                [event.target.name]: true
            }
        }));
    };

    const handlePhoneChangeClient = (phoneKey) => (phoneValue) => {
        setClientForm(clientFormState => ({
            ...clientFormState,
            values: {
                ...clientFormState.values,
                [phoneKey]: phoneValue,
            },
            touched: {
                ...clientFormState.touched,
                [phoneKey]: true
            },
        }));
    }

    const handlePhoneChangeStation = (phoneKey) => (phoneValue) => {
        setStationForm(stationFormState => ({
            ...stationFormState,
            values: {
                ...stationFormState.values,
                [phoneKey]: phoneValue,
            },
            touched: {
                ...stationFormState.touched,
                [phoneKey]: true
            },
        }));
    }

    const handleStationFormChange = event => {
        event.persist();

        setStationForm(stationFormState => ({
            ...stationFormState,
            values: {
                ...stationFormState.values,
                [event.target.name]: event.target.value,
            },
            touched: {
                ...stationFormState.touched,
                [event.target.name]: true
            },
        }));
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleNextTab = async (event) => {
        if (!clientForm.isSubmitted) {
            setClientForm(currentClientForm => ({
                ...currentClientForm,
                isSubmitted: true,
            }));
        }
        if (!clientForm.isValid) {
            return;
        }
        if(user_types[userType] === user_types['station'] && !stationForm.isValid) {
            return;
        }

        // Ésto tiene que colocarse exclusivamente acá antes de hacer la petición
        // o cambiar de tab, ya que en este punto los formularios son correctos.
        // Por lo que al validar si el correo o la identificación existen, se puede reemplazar
        // con más facilidad el estado de errores.
        try {
            const userUniqueValues = {
                email: clientForm.values.email,
                document: clientForm.values.document,
            };
            await validate.async(userUniqueValues, asyncClientFormSchema);
        } catch(errors) {
            setClientForm(currentClientForm => ({
                ...currentClientForm,
                isValid: false,
                errors: errors,
            }));
            return;
        }

        if(user_types[userType] === user_types['station']) {
            setSelectedTab(1);
            return;
        }

        // Acá sólo agregamos el clientForm porque al llegar acá
        // significa que está seleccionado el agente de riesgo
        // por ende sólo necesita los datos básicos (nombres, apellidos, etc)
        // y no los módulos GR, MT, ST.
        let user = {
            ...clientForm.values,
            role: userType,
            // Todos los agentes de riesgos que se creen desde el administrador
            // pueden auditar a nivel nacional. Y eso se define en el backend como 'global'.
            // Es por ello que colocamos acá 'global' directamente.
            risk_user_type: 'global',
        };

        await createUserService(user);
    };

    const handleSaveUser = async (event) => {
        // Al llegar acá significa que está seleccionado el usuario de tipo Cliente, es por eso
        // que podemos asignar ambos formularios sin más. Las validaciones ya se hicieron en el tab de información de usuario.
        let user = {
            ...clientForm.values,
            ...stationForm.values,
            role: userType,
        };

        let anyInvalidDate = false;
        Object.keys(modulesForm).forEach(key => {
            if (modulesForm[key].isSelected && !modulesForm[key].isValid) {
                anyInvalidDate = true;
            }
        });
        if (anyInvalidDate) {
            return;
        }

        Object.keys(modulesForm).forEach((key) => {
            if (modulesForm[key].isSelected) {
                user = {
                    ...user,
                    ...modulesForm[key].values,
                };
            }
        });

        await createUserService(user);
    };

    const clientFormHasError = field =>
        (clientForm.isSubmitted || clientForm.touched[field]) && clientForm.errors[field] ? true : false;

    const stationFormHasError = field =>
        (clientForm.isSubmitted || stationForm.touched[field]) && stationForm.errors[field] ? true : false;

    useEffect(() => {
        const errors = validate(modulesForm.MT.values, mtRangeDateSchema);

        setModulesForm(currentModulesForm => ({
            ...currentModulesForm,
            MT: {
                ...currentModulesForm['MT'],
                errors: errors || {},
                isValid: errors ? false : true,
            },
        }));
    }, [modulesForm.MT.values]);

    useEffect(() => {
        const errors = validate(modulesForm.ST.values, stRangeDateSchema);

        setModulesForm(currentModulesForm => ({
            ...currentModulesForm,
            ST: {
                ...currentModulesForm['ST'],
                errors: errors || {},
                isValid: errors ? false : true,
            },
        }));
    }, [modulesForm.ST.values]);

    useEffect(() => {
        const errors = validate(modulesForm.GR.values, grRangeDateSchema);

        setModulesForm(currentModulesForm => ({
            ...currentModulesForm,
            GR: {
                ...currentModulesForm['GR'],
                errors: errors || {},
                isValid: errors ? false : true,
            },
        }));
    }, [modulesForm.GR.values]);

    useEffect(() => {
        const errors = validate(stationForm.values, stationFormSchema);

        setStationForm(currentStationForm => ({
            ...currentStationForm,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [stationForm.values]);

    useEffect(() => {
        const errors = validate(clientForm.values, clientFormSchema);

        setClientForm(currentClientForm => ({
            ...currentClientForm,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [clientForm.values, clientForm.isSubmitted]);

    useEffect(() => {
        if (createUserResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createUserResponse.message,
            severity: 'success',
        }));

        props.history.push(adminUsersUrls.Listado);
    }, [dispatch, createUserResponse.message, props.history]);

    useEffect(() => {
        if (createUserResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createUserResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, createUserResponse.error])

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <StyledTabs value={selectedTab} onChange={handleTabChange} aria-label="add-user-tabs">
                <StyledTab label="Información del usuario" disabled={selectedTab !== 0} />
                { user_types[userType] === user_types['station'] && (
                    <StyledTab label="Tipo de producto a asignar" disabled={selectedTab !== 1} />
                )}
            </StyledTabs>
            <TabPanel value={selectedTab} index={0}>
                <Typography className={classes.pageTitle}>
                    <Box fontWeight="fontWeightBold" fontSize={25}>
                        Nuevo usuario
                    </Box>
                </Typography>
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item md={6} xs={12}>
                        <FormControl variant="outlined" className={classes.formControlUserType}>
                            <InputLabel id="user-type">Tipo de usuario</InputLabel>
                            <Select
                                labelId="user-type"
                                id="user-type-select"
                                label="Tipo de usuario"
                                value={userType}
                                onChange={onChangeUserType}
                            >
                                {Object.keys(user_types).map(key => (
                                    <MenuItem value={key} key={`user-type-${key}`}>{user_types[key]}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="flex-start" className={classes.formsContainer}>
                    <Grid item md={5} xs={12}>
                        {(user_types[userType] === user_types['station'] || user_types[userType] === user_types['risk_agent']) && (
                            <form>
                                <Grid container direction="column" justify="center" alignItems="flex-start" spacing={2}>
                                    <Grid item className={classes.row}>
                                        <Grid container direction="row" justify="center" alignItems="center">
                                            <Grid item md={6} xs={12}>
                                                <TextField 
                                                    id="firstname" 
                                                    label="Nombres" 
                                                    variant="outlined" 
                                                    className={classes.formControl} 
                                                    name="firstname"
                                                    value={clientForm.values.firstname}
                                                    onChange={handleClientFormChange}
                                                    error={clientFormHasError('firstname')}
                                                    helperText={
                                                        clientFormHasError('firstname') ? clientForm.errors.firstname[0] : null
                                                    }
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <TextField 
                                                    id="lastname" 
                                                    label="Apellidos" 
                                                    variant="outlined" 
                                                    className={classes.formControl} 
                                                    name="lastname"
                                                    value={clientForm.values.lastname}
                                                    onChange={handleClientFormChange}
                                                    error={clientFormHasError('lastname')}
                                                    helperText={
                                                        clientFormHasError('lastname') ? clientForm.errors.lastname[0] : null
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.row}>
                                        <Grid container direction="row" justify="center" alignItems="center">
                                            <Grid item md={6} xs={12}>
                                                <TextField 
                                                    id="document" 
                                                    label="Identificación" 
                                                    variant="outlined" 
                                                    className={classes.formControl} 
                                                    name="document"
                                                    value={clientForm.values.document}
                                                    onChange={handleClientFormChange}
                                                    error={clientFormHasError('document')}
                                                    helperText={
                                                        clientFormHasError('document') ? clientForm.errors.document[0] : null
                                                    }
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <MuiPhoneNumber 
                                                    id="phone" 
                                                    label="Celular" 
                                                    variant="outlined" 
                                                    className={classes.formControl} 
                                                    name="phone"
                                                    value={clientForm.values.phone}
                                                    onChange={handlePhoneChangeClient('phone')}
                                                    error={clientFormHasError('phone')}
                                                    helperText={
                                                        clientFormHasError('phone') ? clientForm.errors.phone[0] : null
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.row}>
                                        <Grid container direction="row" justify="center" alignItems="center">
                                            <Grid item md={6} xs={12}>
                                                <TextField 
                                                    id="email" 
                                                    label="Correo electrónico" 
                                                    variant="outlined" 
                                                    className={classes.formControl} 
                                                    name="email"
                                                    value={clientForm.values.email}
                                                    onChange={handleClientFormChange}
                                                    error={clientFormHasError('email')}
                                                    helperText={
                                                        clientFormHasError('email') ? clientForm.errors.email[0] : null
                                                    }
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <FormControl className={classes.formControl} error={clientFormHasError('password')} variant="outlined">
                                                    <InputLabel htmlFor="password-label">Contraseña temporal</InputLabel>
                                                    <OutlinedInput
                                                        id="password"
                                                        type={clientForm.showPassword ? 'text' : 'password'}
                                                        value={clientForm.values.password}
                                                        onChange={handleClientFormChange}
                                                        name="password"
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    edge="end"
                                                                >
                                                                    {clientForm.showPassword ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        labelWidth={150}
                                                    />
                                                    {clientFormHasError('password') && 
                                                        <FormHelperText id="password-error-text">
                                                            {clientForm.errors.password[0]}
                                                        </FormHelperText> 
                                                    }
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.row}>
                                        <Grid container direction="row" justify="flex-start" alignItems="center">
                                            <Grid item md={6} xs={12}>
                                                <FormControl error={clientFormHasError('department_id')} variant="outlined" className={classes.formControl}>
                                                    <InputLabel id="department">Departamento</InputLabel>
                                                    <Select
                                                        labelId="department"
                                                        id="department-select"
                                                        label="Departamento"
                                                        name="department_id"
                                                        value={clientForm.values.department_id}
                                                        onChange={handleClientFormChange}
                                                    >
                                                        <MenuItem value="">
                                                            <em>Ninguno</em>
                                                        </MenuItem>
                                                        {departments.map((department, key) => (
                                                            <MenuItem value={department.id} key={`department-${key}`}>{department.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    {clientFormHasError('department_id') && 
                                                        <FormHelperText id="department-id-error-text">
                                                            {clientForm.errors.department_id[0]}
                                                        </FormHelperText> 
                                                    }
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <FormControl error={clientFormHasError('city_id')} variant="outlined" className={classes.formControl}>
                                                    <InputLabel id="city">Municipio</InputLabel>
                                                    <Select
                                                        labelId="city"
                                                        id="city-select"
                                                        label="Municipio"
                                                        name="city_id"
                                                        value={clientForm.values.city_id}
                                                        onChange={handleClientFormChange}
                                                    >
                                                        <MenuItem value="">
                                                            <em>Ninguno</em>
                                                        </MenuItem>
                                                        {cities
                                                            .filter((city) => city.department_id === clientForm.values.department_id)
                                                            .map((city, key) => (
                                                                <MenuItem value={city.id} key={`city-${key}`}>{city.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    {clientFormHasError('city_id') && 
                                                        <FormHelperText id="city-id-error-text">
                                                            {clientForm.errors.city_id[0]}
                                                        </FormHelperText> 
                                                    }
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.row}>
                                        <Grid container direction="row" justify="flex-start" alignItems="center">
                                            <Grid item md={6} xs={12}>
                                                <Button 
                                                    variant="contained" 
                                                    color="primary" 
                                                    disableElevation 
                                                    className={classes.button}
                                                    onClick={handleNextTab}
                                                >
                                                    { user_types[userType] === user_types['risk_agent'] ? 'Guardar' : 'Siguiente' }
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Grid>
                    <Grid item md={1}>
                        {user_types[userType] === user_types['station'] && (
                            <Divider orientation="vertical" flexItem className={classes.divider}/>
                        )}
                    </Grid>
                    <Grid item md={6} xs={12}>
                        {user_types[userType] === user_types['station'] && (
                            <form>
                                <Grid container direction="column" justify="center" alignItems="flex-start" spacing={2}>
                                    <Grid item className={classes.row}>
                                        <Grid container direction="row" justify="center" alignItems="center">
                                            <Grid item md={6} xs={12}>
                                                <TextField 
                                                    id="station_name" 
                                                    label="Nombre estación" 
                                                    variant="outlined" 
                                                    className={classes.formControl} 
                                                    name="station_name"
                                                    value={stationForm.values.station_name}
                                                    onChange={handleStationFormChange}
                                                    error={stationFormHasError('station_name')}
                                                    helperText={
                                                        stationFormHasError('station_name') ? stationForm.errors.station_name[0] : null
                                                    }
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <TextField 
                                                    id="station_address" 
                                                    label="Dirección de la estación" 
                                                    variant="outlined" 
                                                    className={classes.formControl} 
                                                    name="station_address"
                                                    value={stationForm.values.station_address}
                                                    onChange={handleStationFormChange}
                                                    error={stationFormHasError('station_address')}
                                                    helperText={
                                                        stationFormHasError('station_address') ? stationForm.errors.station_address[0] : null
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.row}>
                                        <Grid container direction="row" justify="flex-start" alignItems="center">
                                            <Grid item md={6} xs={12}>
                                                <MuiPhoneNumber
                                                    id="station_phone" 
                                                    label="Teléfono de la estación" 
                                                    variant="outlined" 
                                                    className={classes.formControl} 
                                                    name="station_phone"
                                                    value={stationForm.values.station_phone}
                                                    onChange={handlePhoneChangeStation('station_phone')}
                                                    error={stationFormHasError('station_phone')}
                                                    helperText={
                                                        stationFormHasError('station_phone') ? stationForm.errors.station_phone[0] : null
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3} className={classes.modulesContainer}>
                    <Grid item>
                        <ModulePicker 
                            icon={MotorIcon} 
                            moduleTitle="MT" 
                            checked={modulesForm["MT"].isSelected} 
                            onChangeChecked={changeModuleSelected("MT")}
                            activationDate={modulesForm["MT"].values.mt_start_date}
                            onChangeActivationDate={changeFieldDate("MT", "mt_start_date")}
                            deactivationDate={modulesForm["MT"].values.mt_end_date}
                            onChangeDeactivationDate={changeFieldDate("MT", "mt_end_date")}
                            backgroundImage="https://images.unsplash.com/photo-1531964714722-c71126c62568?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2135&q=80"
                            activationDateError={modulesForm["MT"].errors['mt_start_date'] ? true : false}
                            deactivationDateError={modulesForm["MT"].errors['mt_end_date'] ? true : false}
                            activationDateHelperText={modulesForm["MT"].errors['mt_start_date'] ? modulesForm["MT"].errors['mt_start_date'][0] : null}
                            deactivationDateHelperText={modulesForm["MT"].errors['mt_end_date'] ? modulesForm["MT"].errors['mt_end_date'][0] : null}
                        />
                    </Grid>
                    <Grid item>
                        <ModulePicker 
                            icon={SiniestralidadIcon} 
                            moduleTitle="ST" 
                            checked={modulesForm["ST"].isSelected} 
                            onChangeChecked={changeModuleSelected("ST")}
                            activationDate={modulesForm["ST"].values.st_start_date}
                            onChangeActivationDate={changeFieldDate("ST", "st_start_date")}
                            deactivationDate={modulesForm["ST"].values.st_end_date}
                            onChangeDeactivationDate={changeFieldDate("ST", "st_end_date")}
                            backgroundImage="https://images.unsplash.com/photo-1425421669292-0c3da3b8f529?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1349&q=80"
                            activationDateError={modulesForm["ST"].errors['st_start_date'] ? true : false}
                            deactivationDateError={modulesForm["ST"].errors['st_end_date'] ? true : false}
                            activationDateHelperText={modulesForm["ST"].errors['st_start_date'] ? modulesForm["ST"].errors['st_start_date'][0] : null}
                            deactivationDateHelperText={modulesForm["ST"].errors['st_end_date'] ? modulesForm["ST"].errors['st_end_date'][0] : null}
                        />
                    </Grid>
                    <Grid item>
                        <ModulePicker 
                            icon={GestionDeRiesgoIcon} 
                            moduleTitle="GR" 
                            checked={modulesForm["GR"].isSelected} 
                            onChangeChecked={changeModuleSelected("GR")}
                            activationDate={modulesForm["GR"].values.gr_start_date}
                            onChangeActivationDate={changeFieldDate("GR", "gr_start_date")}
                            deactivationDate={modulesForm["GR"].values.gr_end_date}
                            onChangeDeactivationDate={changeFieldDate("GR", "gr_end_date")}
                            backgroundImage="https://images.unsplash.com/photo-1551911560-1c4914e2c887?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1335&q=80"
                            activationDateError={modulesForm["GR"].errors['gr_start_date'] ? true : false}
                            deactivationDateError={modulesForm["GR"].errors['gr_end_date'] ? true : false}
                            activationDateHelperText={modulesForm["GR"].errors['gr_start_date'] ? modulesForm["GR"].errors['gr_start_date'][0] : null}
                            deactivationDateHelperText={modulesForm["GR"].errors['gr_end_date'] ? modulesForm["GR"].errors['gr_end_date'][0] : null}
                        />
                    </Grid>
                </Grid>
                <Button 
                    variant="contained" 
                    color="primary" 
                    disableElevation 
                    className={classes.modulesSaveButton}
                    onClick={handleSaveUser}
                    disabled={createUserResponse.isLoading}
                >
                    Guardar
                </Button>
            </TabPanel>
        </div>
    );
}

export default AddUser;