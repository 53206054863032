import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

import useEquipmentById from '../../../../hooks/MT/useEquipmentById';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';
import { updateEquipment } from '../../../../services/equipmentsService';

import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { 
    DatosEquipmentForm,
    DatosEquipmentErrorSchema,
} from '../Forms/Equipments';

import { MotorUrls } from '../../routing';

const useStyles = makeStyles(styles);

const EditEquipment = (props) => {
    const classes = useStyles();
    const { equipment_id } = useParams();
    const { equipment } = useEquipmentById(equipment_id);
    const { 
        backendResponse: updateEquipmentResponse,
        executeService: updateEquipmentService,
    } = useBackendMessageResponse(updateEquipment);
    const dispatch = useDispatch();
    const [datosEquipment, setDatosEquipment] = useState({
        image_url: '',
        code: '',
        name: '',
        model: '',
        class: '',
        entry_date: new Date(),
        engine_power: '',
        provider: '',
        type: '',
        entry_state: '',
        engine: '',
        price: '',
        fuel: '',
        owner_name: '',
        version: '',
        brand: '',
        observations: '',
    });

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    useEffect(() => {
        if (equipment === null) {
            return;
        }

        setDatosEquipment({
            image_url: equipment.image_url,
            code: equipment.code,
            name: equipment.name,
            model: equipment.model,
            class: equipment.class,
            entry_date: new Date(equipment.entry_date),
            engine_power: equipment.engine_power,
            provider: equipment.provider,
            type: equipment.type,
            entry_state: equipment.entry_state,
            engine: equipment.engine,
            price: equipment.price,
            fuel: equipment.fuel,
            owner_name: equipment.owner_name,
            version: equipment.version,
            brand: equipment.brand,
            observations: equipment.observations,
        });
    }, [equipment]);

    useEffect(() => {
        if (updateEquipmentResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: updateEquipmentResponse.message,
            severity: 'success',
        }));

        props.history.push(MotorUrls.Listado);
    }, [dispatch, updateEquipmentResponse.message, props.history]);

    useEffect(() => {
        if (updateEquipmentResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: updateEquipmentResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, updateEquipmentResponse.error])

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Editar equipo y herramienta</Box>
            </Typography>
            <div className={classes.form}>
                <Formik 
                    enableReinitialize
                    validate={async (values) => {
                        try {
                            await validateYupSchema(values, DatosEquipmentErrorSchema, false, {id: parseInt(equipment_id)});
                        } catch(err) {
                            return yupToFormErrors(err);
                        }

                        return {};
                    }}
                    initialValues={datosEquipment}
                    component={DatosEquipmentForm} 
                    onSubmit={async (values) => {
                        setDatosEquipment(values);
                        await updateEquipmentService(equipment_id, values);
                    }}
                />
            </div>
        </div>
    );
};

export default EditEquipment;