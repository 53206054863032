export default ({
    minwage_id: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    },
    level: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    },

    area_start_1: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    },
    area_end_1: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
        isGreaterThanField: {
            field: 'area_start_1',
            message: 'Tiene que ser mayor que Área 1 (desde).',
        },
    },
    impact_1: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    },

    area_start_2: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    },
    area_end_2: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
        isGreaterThanField: {
            field: 'area_start_2',
            message: 'Tiene que ser mayor que Área 2 (desde).',
        },
    },
    impact_2: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    },

    area_start_3: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    },
    area_end_3: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
        isGreaterThanField: {
            field: 'area_start_3',
            message: 'Tiene que ser mayor que Área 3 (desde).',
        },
    },
    impact_3: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    },
    
    area_start_4: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    },
    area_end_4: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
        isGreaterThanField: {
            field: 'area_start_4',
            message: 'Tiene que ser mayor que Área 4 (desde).',
        },
    },
    impact_4: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    },

    area_start_5: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    },
    area_end_5: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
        isGreaterThanField: {
            field: 'area_start_5',
            message: 'Tiene que ser mayor que Área 5 (desde).',
        },
    },
    impact_5: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    },

    area_start_6: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    },
    area_end_6: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
        isGreaterThanField: {
            field: 'area_start_6',
            message: 'Tiene que ser mayor que Área 6 (desde).',
        },
    },
    impact_6: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    },
});