export default theme => ({
    root: {
        padding: '25px',
    },
    goBackIcon: {
        marginLeft: '-15px',
    },
    form: {
        width: '100%',
    },
    formControl: {
        marginTop: '15px',
        width: '260px',
    },
    editButton: {
        width: '150px',
        marginTop: theme.spacing(5),
    },
    authNotificationText: {
        marginTop: '25px',
    },
});