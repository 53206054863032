import React from 'react';

import { AdminProfile } from './AdminProfile';
import { StationProfile } from './StationProfile';
import { RiskAgentProfile } from './RiskAgentProfile';

import { Can, Only, Otherwise } from '../../../../components/Can';
import { ROLES_KEYS } from '../../../../constants/roles';

const UserProfile = (props) => {
    return (
        <Can>
            <Only roles={[ROLES_KEYS.admin]} render={() => <AdminProfile {...props} />} />
            <Only roles={[ROLES_KEYS.station, ROLES_KEYS.station_user]} render={() => <StationProfile {...props} />} />
            <Only roles={[ROLES_KEYS.risk_agent]} render={() => <RiskAgentProfile {...props} />} />
            <Otherwise render={() => <AdminProfile {...props} />} />
        </Can>
    );
}

export default UserProfile;