export default theme => ({
    saveButton: {
        width: '170px',
    },
    formControl: {
        width: '100%',
        '& .Mui-disabled input, & .Mui-disabled div[class*="MuiSelect-"], & .Mui-disabled textarea': {
            color: "rgba(0,0,0,0.6)"
        },
    },
    row: {
        width: '60%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    fullWidth: {
        width: '103%',
    },
    topSpacing: {
        marginTop: '30px',
    },
    deviationMessageContainer: {
        backgroundColor: '#E7F6FC',
        borderRadius: '5px',
        padding: '10px',
    },
    noDeviationIcon: {
        color: '#96C2C9',
        fontSize: '40px',
    },
    noDeviationText: {
        color: '#5C5C5C',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        width: '170px',
        height: '40px',
        marginTop: '40px',
    },
    buttonProgress: {
        position: 'absolute',
        top: '25%',
        left: '40%',
    },
});