import React, { useRef } from 'react';

import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import InsertPhotoOutlinedIcon from '@material-ui/icons/InsertPhotoOutlined';

import {
    uploadImage,
} from '../../services/imageService';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const UploadSignature = (props) => {
    const classes = useStyles();
    const inputFileRef = useRef();
    const { onChange, imageSrc, disabled } = props;

    const onClickInputFile = () => {
        if (!disabled) {
            inputFileRef.current.click();
        }
    }

    const onFileChange = async (event) => {
        event.preventDefault();
        try {
            const image = event.target.files[0];
            const { data } = await uploadImage(image);
            const image_url = data.image.Location;
            onChange(image_url);
        } catch(e) {
            console.log(e);
        }
    }

    return (
        <>
            <input type="file" accept="image/x-png,image/jpeg" ref={inputFileRef} className={classes.inputFile} onChange={onFileChange} />
            <ButtonBase onClick={onClickInputFile} className={classes.buttonBase}>
                {imageSrc !== '' && imageSrc !== null ? (
                    <img alt="vehicle" src={imageSrc} className={classes.image} />
                ) : (
                    <div style={{width: '100%', height: '100%'}}>
                        <Grid container direction="column" justify="center" alignItems="center" spacing={1} className={classes.container}>
                            <Grid item>
                                <InsertPhotoOutlinedIcon className={classes.loadImageIcon} />
                            </Grid>
                            <Grid item>
                                <Typography component="div" className={classes.loadImageText}>
                                    <Box fontWeight="fontWeightBold" fontSize={20}>Cargar firma</Box>
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                )}
            </ButtonBase>
        </>
    );
};

UploadSignature.defaultProps = {
    disabled: false,
};

export default UploadSignature;