import { createSlice } from '@reduxjs/toolkit';

export const eventsSlice = createSlice({
    name: 'events',
    initialState: {
        isLoading: false,
        events: [],
        error: {
            code: null,
            message: null,
        },
    },
    reducers: {
        getEventsRequest: (state) => {
            state.isLoading = true;
        },
        getEventsSuccess: (state, action) => {
            state.isLoading = false;
            state.error = {
                code: null,
                message: null,
            };
            state.events = action.payload.events;
        },
        getEventsFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
    },
});

export const {
    getEventsRequest,
    getEventsSuccess,
    getEventsFailure,
} = eventsSlice.actions;

export const selectEvents = (state) => state.events;

export default eventsSlice.reducer;