import { useState, useEffect } from 'react';
import { getDepartmentByID } from '../services/departmentService';

const useDepartmentById = (department_id) => {
    const [department, setDepartment] = useState(null);

    useEffect(() => {
        async function fetchDepartment() {
            try {
                const { data } = await getDepartmentByID(department_id);
                setDepartment(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchDepartment();
    }, [department_id]);

    return { department };
}

export default useDepartmentById;