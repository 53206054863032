export default ({
  email: {
    presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    email: {
      message: '\'%{value}\' no es una dirección válida.',
    },
    length: {
      maximum: 60
    }
  }
});