import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import { Bar } from 'react-chartjs-2';

import { ChartInspectionForm } from '../../../../Forms/Graficos/ChartInspectionForm';
import ChartInspectionSchema from '../../../../Forms/Graficos/ChartInspectionForm/schema';

import useAllInspections from '../../../../../../hooks/GR/useAllInspections';

import { Formik } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const Graficos = (props) => {
    const classes = useStyles();
    const [inspectionsDateRange, setInspectionsDateRange] = useState({
        start_date: new Date(),
        end_date: new Date(),
    });
    const inspections = useAllInspections(inspectionsDateRange);

    let months = {}
    inspections.forEach(inspection => {
        let date = new Date(inspection.inspection_date)
        let key = date.toLocaleString('es-CO', { month: 'long' }).toUpperCase() + '-' + date.getFullYear()
        if(months[key] === undefined){
            months[key] = 1 
        }else{
            months[key] += 1
        }

    })

    const data = {
        labels: Object.keys(months),
        datasets: [{
            label: '# de Inspecciones',
            data: Object.values(months),
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
        }],
    };

    const options = {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                },
            }],
        },
    };

    return (
        <div className={classes.root}>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Gráficos</Box>
            </Typography>
            <br />
            <Formik
                initialValues={inspectionsDateRange}
                component={ChartInspectionForm}
                validationSchema={ChartInspectionSchema}
                onSubmit={(values) => {
                    setInspectionsDateRange(values);
                }}
            />
            <Divider className={classes.divider} />
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={classes.inspectionDataContainer}>
                <Grid item xs={12} md={6}>
                    <Bar data={data} options={options} />
                </Grid>
            </Grid>
        </div>
    );
}

export default Graficos;