import { useState, useEffect } from 'react';
import { getAllMinWages } from '../services/minWageService';

const useAllMinwages = () => {
    const [minWages, setMinWages] = useState([]);

    useEffect(() => {
        async function fetchAllMinwages() {
            try {
                const { data } = await getAllMinWages();
                setMinWages(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchAllMinwages();
    }, []);

    return { minWages };
}

export default useAllMinwages;