import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { selectCurrentUser, currentUserRequest } from '../../../../../store/slices/currentUserSlice';
import { setGlobalAlert } from '../../../../../store/slices/alertsSlice';
import useBackendMessageResponse from '../../../../../hooks/useBackendMessageResponse';
import { updateCurrentUser } from '../../../../../services/currentUserService';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import { Formik, Form, Field, yupToFormErrors, validateYupSchema } from 'formik';
import { StationUserForm } from '../../../../../Forms/StationUserForm';
import { StationForm } from '../../../../../Forms/StationForm';
import { EducationForm } from '../../../../../Forms/EducationForm';
// import { CoursesForm } from './Forms/CoursesForm';
// import { ExperienceForm } from './Forms/ExperienceForm';

import { CoursesTable } from '../../../../../components/CoursesTable';
import { ExperienceTable } from '../../../../../components/ExperienceTable';
import { UploadAvatar } from '../../../../../components/UploadAvatar';
import { UploadSignature } from '../../../../../components/UploadSignature';

import { ROLES_KEYS } from '../../../../../constants/roles';

import schema from './schema';

const useStyles = makeStyles(styles);

const StationProfile = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        backendResponse: updateCurrentUserResponse,
        executeService: updateCurrentUserService,
    } = useBackendMessageResponse(updateCurrentUser);
    const [blockedProfile, setBlockedProfile] = useState(true);
    const { user: currentUser } = useSelector(selectCurrentUser);
    const [courses, setCourses] = useState([]);
    const [experiences, setExperiences] = useState([]);
    const [profileData, setProfileData] = useState({
        // StationUser
        firstname: '',
        lastname: '',
        document_type: '',
        document: '',
        genre: '',
        nationality: '',
        country_id: '',
        department_id: '',
        city_id: '',
        born_date: new Date(),
        born_city_id: '',
        military_type: '',
        military_number: '',
        address: '',
        position_id: '',
        rank_id: '',
        email: '',
        phone: '',
        image_url: '',
        logo_1_url: '',
        logo_2_url: '',
        signature_url: '',

        // Station
        station_name: '',
        station_address: '',
        station_phone: '',

        // Education
        education_level_id: '',
    });

    const handleOnCoursesChange = (newCourses) => {
        setCourses(newCourses);
    }

    const handleOnExperiencesChange = (newExperiences) => {
        setExperiences(newExperiences);
    }

    const handleSubmit = async (updatedProfileData) => {
        setProfileData(updatedProfileData);
        const totalProfileData = {
            id: currentUser.id,
            role: currentUser.role,
            ...updatedProfileData,
            firefighter_info: JSON.stringify(experiences),
            firefighter_courses: JSON.stringify(courses),
        };
        await updateCurrentUserService(totalProfileData);
        dispatch(currentUserRequest());
        setBlockedProfile(true);
    }

    useEffect(() => {
        setProfileData({
            // StationUser
            firstname: currentUser.firstname,
            lastname: currentUser.lastname,
            document_type: currentUser.document_type,
            document: currentUser.document,
            genre: currentUser.genre,
            nationality: currentUser.nationality,
            country_id: currentUser.country_id,
            department_id: currentUser.department.id,
            city_id: currentUser.city.id,
            born_date: new Date(currentUser.born_date),
            born_city_id: currentUser.born_city_id,
            military_type: currentUser.military_type,
            military_number: currentUser.military_number,
            address: currentUser.address,
            position_id: currentUser.position_id,
            rank_id: currentUser.rank_id,
            email: currentUser.email,
            phone: currentUser.phone,
            image_url: currentUser.image_url,
            logo_1_url: currentUser.logo_1_url,
            logo_2_url: currentUser.logo_2_url,
            signature_url: currentUser.signature_url,

            // Station
            station_name: currentUser.station_details !== undefined ? currentUser.station_details.name : '',
            station_address: currentUser.station_details !== undefined ? currentUser.station_details.address : '',
            station_phone: currentUser.station_details !== undefined  ? currentUser.station_details.phone : '',

            // Education
            education_level_id: currentUser.education_level_id,
        });

        // Se tiene que convertir en objeto Date para que los componentes de las tablas de experiencias y cursos
        // puedan operar correctamente y no tengan que preocuparse por el mapeo.
        if (currentUser.firefighter_courses !== null) {
            const userCourses = JSON.parse(currentUser.firefighter_courses);
            const userCoursesWithDatesMapped = userCourses.map(course => {
                return {
                    ...course,
                    date: new Date(course.date),
                };
            });
            setCourses(userCoursesWithDatesMapped);
        }
        if (currentUser.firefighter_info !== null) {
            const userExperience = JSON.parse(currentUser.firefighter_info);
            const userExperienceWithDatesMapped = userExperience.map(experience => {
                return {
                    ...experience,
                    start_date: new Date(experience.start_date),
                    retirement_date: new Date(experience.retirement_date),
                };
            });
            setExperiences(userExperienceWithDatesMapped);
        }
    }, [currentUser]);

    useEffect(() => {
        if (updateCurrentUserResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: updateCurrentUserResponse.message,
            severity: 'success',
        }));
    }, [dispatch, updateCurrentUserResponse.message, currentUser]);

    useEffect(() => {
        if (updateCurrentUserResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: updateCurrentUserResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, updateCurrentUserResponse.error, currentUser])

    return (
        <div className={classes.root}>
            <Formik
                validate={async (values) => {
                    if (blockedProfile) {
                        return {};
                    }

                    try {
                        await validateYupSchema(values, schema, false, { 
                            document_type: values.document_type,
                            id: parseInt(currentUser.id),
                        });
                    } catch(err) {
                        return yupToFormErrors(err);
                    }

                    return {};
                }}
                enableReinitialize
                initialValues={profileData}
                onSubmit={(values) => {
                    if (blockedProfile) {
                        setBlockedProfile(false);
                        return;
                    }
                    handleSubmit(values);
                }}
            >
                {formik => (
                    <Form>
                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2} className={classes.avatarRootContainer}>
                            <Grid item className={classes.avatarContainer}>
                                <div className={classes.ratioContainer}>
                                  <div className={classes.ratioItem}>
                                      <Field 
                                          name="image_url"
                                          as={UploadAvatar}
                                          imageSrc={formik.values.image_url}
                                          noImageText={'Cargar avatar'}
                                          onChange={(image_url) => formik.setFieldValue('image_url', image_url)}
                                          disabled={blockedProfile}
                                          ratioText={'Ratio 1:1 (500x500)'}
                                      />
                                  </div>
                                </div>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                                    <Grid item className={classes.avatarContainer}>
                                      <div className={classes.ratioContainer}>
                                        <div className={classes.ratioItem}>
                                          <Field 
                                              name="logo_1_url"
                                              as={UploadAvatar}
                                              imageSrc={formik.values.logo_1_url}
                                              noImageText={'Cargar logo 1'}
                                              onChange={(image_url) => formik.setFieldValue('logo_1_url', image_url)}
                                              disabled={blockedProfile || currentUser.role === ROLES_KEYS.station_user}
                                              ratioText={'Ratio 1:1 (500x500)'}
                                          />
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item className={classes.avatarContainer}>
                                      <div className={classes.ratioContainer}>
                                        <div className={classes.ratioItem}>
                                          <Field 
                                              name="logo_2_url"
                                              as={UploadAvatar}
                                              imageSrc={formik.values.logo_2_url}
                                              noImageText={'Cargar logo 2'}
                                              onChange={(image_url) => formik.setFieldValue('logo_2_url', image_url)}
                                              disabled={blockedProfile || currentUser.role === ROLES_KEYS.station_user}
                                              ratioText={'Ratio 1:1 (500x500)'}
                                          />
                                        </div>
                                      </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justify="center" alignItems="flex-start" spacing={2}>
                            <Grid item md={6} xs={12} style={{borderRight: '2px solid #e5e5e5'}}>
                                <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                                    <Grid item className={classes.columnWidth}>
                                        <StationUserForm {...formik} blockedEmail blockedForm={blockedProfile} />
                                    </Grid>
                                    <Grid item className={classes.columnWidth}>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold" fontSize={18} className={classes.stationFormTitle}>
                                                Información de la estación
                                            </Box>
                                        </Typography>
                                        <StationForm {...formik} 
                                            blockedForm={blockedProfile || currentUser.role === ROLES_KEYS.station_user} 
                                        />
                                    </Grid>
                                    <Grid item className={classes.columnWidth}>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                            <Grid item md={6} xs={12} style={{height: '160px'}}>
                                                <Field 
                                                    name="signature_url"
                                                    as={UploadSignature}
                                                    imageSrc={formik.values.signature_url}
                                                    onChange={(image_url) => formik.setFieldValue('signature_url', image_url)}
                                                    disabled={blockedProfile}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                                    <Grid item className={classes.columnWidth}>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold" fontSize={18} className={classes.stationFormTitle}>
                                                Academia
                                            </Box>
                                        </Typography>
                                        <EducationForm {...formik} blockedForm={blockedProfile} />
                                    </Grid>
                                    <Grid item className={classes.columnWidth}>
                                        <CoursesTable courses={courses} onCoursesChange={handleOnCoursesChange} disabledAlter={blockedProfile} />
                                    </Grid>
                                    <Grid item className={classes.columnWidth}>
                                        <ExperienceTable experiences={experiences} onExperiencesChange={handleOnExperiencesChange} disabledAlter={blockedProfile} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item>
                                <Button
                                    className={classes.saveProfileButton}
                                    color="primary"
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    disableElevation
                                >
                                    { blockedProfile ? 'Editar' : 'Guardar' }
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default StationProfile;