import { createSlice } from '@reduxjs/toolkit';

export const equipmentsSlice = createSlice({
    name: 'equipments',
    initialState: {
        isLoading: false,
        equipments: [],
        total: null,
        pages: null,
        page: 1,
        page_limit: 10,
        error: {
            code: null,
            message: null,
        },
    },
    reducers: {
        getEquipmentsRequest: (state) => {
            state.isLoading = true;
        },
        getEquipmentsSuccess: (state, action) => {
            state.isLoading = false;
            state.error = {
                code: null,
                message: null,
            };
            state.equipments = action.payload.equipments;
            state.total = action.payload.total;
            state.pages = action.payload.pages;
            state.page = action.payload.page;
            state.page_limit = action.payload.page_limit;
        },
        getEquipmentsFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
    },
});

export const {
    getEquipmentsRequest,
    getEquipmentsSuccess,
    getEquipmentsFailure,
} = equipmentsSlice.actions;

export const selectEquipments = (state) => state.equipments;

export default equipmentsSlice.reducer;