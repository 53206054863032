import { call, put, takeLatest } from 'redux-saga/effects';

import { 
    getAvailabilities,
} from '../services/MT/availabilitiesService';

import { 
    getAvailabilitiesRequest,
    getAvailabilitiesSuccess,
    getAvailabilitiesFailure,
} from '../store/slices/availabilitiesSlice';

function* getAvailabilitiesEffect(action) {
    try {
        const page = action.payload.page;
        const page_limit = action.payload.page_limit;
        const filters = action.payload.filters;
        const { data } = yield call(getAvailabilities, page, page_limit, filters);
        yield put(getAvailabilitiesSuccess({ 
            availabilities: data.results,
            page: data.page,
            pages: data.pages,
            total: data.total,
            page_limit,
        }));
    } catch(e) {
        const { response } = e;
        const error = {
            code: response.status,
            message: response.data.message,
        };
        yield put(getAvailabilitiesFailure({ error }));
    }
}

function* availabilitiesEffects() {
    yield takeLatest(getAvailabilitiesRequest.type, getAvailabilitiesEffect);
}

export default availabilitiesEffects;