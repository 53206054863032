import { call, put, takeLatest } from 'redux-saga/effects';
import {
    fetchCitiesRequest,
    fetchCitiesSuccess,
    fetchCitiesFailure,
} from '../store/slices/citiesSlice';
import {
    getCities,
} from '../services/cityService';

function* getCitiesEffect(action) {
    try {
        const { page, page_limit, filters } = action.payload;
        const { data } = yield call(getCities, page, page_limit, filters);
        console.log(data);
        yield put(fetchCitiesSuccess({ 
            cities: data.results,
            page: data.page,
            pages: data.pages,
            total: data.total,
            page_limit,
        }));
    } catch(e) {
        const { response } = e;
        const error = {
            code: response.status,
            message: response.data.message,
        };
        yield put(fetchCitiesFailure({ error }));
    }
}

function* citiesEffects() {
    yield takeLatest(fetchCitiesRequest.type, getCitiesEffect);
}

export default citiesEffects;