import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { FormHelperText } from '@material-ui/core';

import { 
    useVehicleBrands,
    useVehicleEngines,
    useVehiclePowers,
    useVehicleModels,
} from '../../../../../../hooks/MT';

import { Form, Field, ErrorMessage } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const TRACTIONS = ['FWD', 'RWD', 'AWD', '4WD', '4X4'];
const TRANSMISSIONS = ['Sincrónica', 'Automática'];
const FUELS = ['Gasolina', 'Diesel o gasoil', 'Licuado de petróleo', 'Natural', 'Bio-diésel', 'Etanol', 'Electricidad', 'Hidrógeno'];

/*
const FormikMUISelect = ({ field, form: { touched, errors }, children, ...props}) => {
    const hasError = () => {
        return errors[field.name] && touched[field.name] ? true : false;
    };

    return (
        <FormControl {...props} error={hasError()}>
            <InputLabel>{props.label}</InputLabel>
            <Field 
                {...field}
                {...props}
                as={Select}
            >
                {children}
            </Field>
            <FormHelperText>
                <ErrorMessage name={field.name} />
            </FormHelperText>
        </FormControl>
    );
}
*/

const DatosVehiculoForm = (props) => {
    const classes = useStyles();
    const { brands } = useVehicleBrands();
    const { engines } = useVehicleEngines();
    const { models } = useVehicleModels();
    const { powers } = useVehiclePowers();

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    };

    return (
        <Form>
            <Grid container direction="column" justify="center" alignItems="flex-start" spacing={3}>
                <Grid item className={classes.row}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FormControl className={classes.formControl} variant="outlined" error={hasError('traction')}>
                                <InputLabel>Tracción</InputLabel>
                                <Field 
                                    name="traction" 
                                    type="text"
                                    as={Select} 
                                    label="Tracción"
                                    className={classes.formControl} 
                                >
                                    {TRACTIONS.map((traction, key) => (
                                        <MenuItem key={`traction-vehicle-key-${key}`} value={traction}>{traction}</MenuItem>
                                    ))}
                                </Field>
                                <FormHelperText>
                                    <ErrorMessage name="traction" />
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className={classes.formControl} variant="outlined" error={hasError('brand_id')}>
                                <InputLabel>Marca</InputLabel>
                                <Field 
                                    name="brand_id" 
                                    type="text"
                                    as={Select} 
                                    label="Marca"
                                    className={classes.formControl} 
                                >
                                    {brands.map((brand, key) => (
                                        <MenuItem key={`brand-vehicle-key-${key}`} value={brand.id}>{brand.name}</MenuItem>
                                    ))}
                                </Field>
                                <FormHelperText>
                                    <ErrorMessage name="brand_id" />
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.row}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Field 
                                name="engine_name" 
                                type="text" 
                                as={TextField} 
                                variant="outlined" 
                                label="Motor"
                                className={classes.formControl} 
                                error={hasError('engine_name')}
                                helperText={<ErrorMessage name="engine_name" />}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className={classes.formControl} variant="outlined" error={hasError('model_id')}>
                                <InputLabel>Modelo</InputLabel>
                                <Field 
                                    name="model_id" 
                                    type="text"
                                    as={Select} 
                                    label="Modelo"
                                    className={classes.formControl} 
                                >
                                    {models.filter(model => model.brand.id === props.values.brand_id).map((model, key) => (
                                        <MenuItem key={`model-vehicle-key-${key}`} value={model.id}>{model.name}</MenuItem>
                                    ))}
                                </Field>
                                <FormHelperText>
                                    <ErrorMessage name="model_id" />
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.row}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Field 
                                name="chasis" 
                                type="text" 
                                as={TextField} 
                                variant="outlined" 
                                label="Chasis"
                                className={classes.formControl} 
                                error={hasError('chasis')}
                                helperText={<ErrorMessage name="chasis" />}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className={classes.formControl} variant="outlined" error={hasError('power_id')}>
                                <InputLabel>Cilindraje</InputLabel>
                                <Field 
                                    name="power_id" 
                                    type="text"
                                    as={Select} 
                                    label="Cilindraje"
                                    className={classes.formControl} 
                                >
                                    {powers.map((power, key) => (
                                        <MenuItem key={`power-vehicle-key-${key}`} value={power.id}>{power.name}</MenuItem>
                                    ))}
                                </Field>
                                <FormHelperText>
                                    <ErrorMessage name="power_id" />
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.row}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Field 
                                name="color" 
                                type="text" 
                                as={TextField} 
                                variant="outlined" 
                                label="Color"
                                className={classes.formControl} 
                                error={hasError('color')}
                                helperText={<ErrorMessage name="color" />}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className={classes.formControl} variant="outlined" error={hasError('engine_brand_id')}>
                                <InputLabel>Marca motor</InputLabel>
                                <Field 
                                    name="engine_brand_id" 
                                    type="text"
                                    as={Select} 
                                    label="Marca motor"
                                    className={classes.formControl} 
                                >
                                    {engines.map((engine, key) => (
                                        <MenuItem key={`engines-vehicle-key-${key}`} value={engine.id}>{engine.name}</MenuItem>
                                    ))}
                                </Field>
                                <FormHelperText>
                                    <ErrorMessage name="engine_brand_id" />
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.row}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Field 
                                name="type" 
                                type="text" 
                                as={TextField} 
                                variant="outlined" 
                                label="Tipo"
                                className={classes.formControl} 
                                error={hasError('type')}
                                helperText={<ErrorMessage name="type" />}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className={classes.formControl} variant="outlined" error={hasError('engine_fuel')}>
                                <InputLabel>Combustible motor</InputLabel>
                                <Field 
                                    name="engine_fuel" 
                                    type="text"
                                    as={Select} 
                                    label="Combustible motor"
                                    className={classes.formControl} 
                                >
                                    {FUELS.map((fuel, key) => (
                                        <MenuItem key={`fuel-vehicle-key-${key}`} value={fuel}>{fuel}</MenuItem>
                                    ))}
                                </Field>
                                <FormHelperText>
                                    <ErrorMessage name="engine_fuel" />
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.row}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Field 
                                name="doors" 
                                type="text" 
                                as={TextField} 
                                variant="outlined" 
                                label="Puertas"
                                className={classes.formControl} 
                                error={hasError('doors')}
                                helperText={<ErrorMessage name="doors" />}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field 
                                name="oil_tank" 
                                type="text" 
                                as={TextField} 
                                variant="outlined" 
                                label="Tanque combustible"
                                className={classes.formControl} 
                                error={hasError('oil_tank')}
                                helperText={<ErrorMessage name="oil_tank" />}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.row}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Field 
                                name="passengers" 
                                type="number" 
                                as={TextField} 
                                variant="outlined" 
                                label="Pasajeros"
                                className={classes.formControl} 
                                error={hasError('passengers')}
                                helperText={<ErrorMessage name="passengers" />}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className={classes.formControl} variant="outlined" error={hasError('transmission')}>
                                <InputLabel>Caja transmisión</InputLabel>
                                <Field 
                                    name="transmission" 
                                    type="text"
                                    as={Select} 
                                    label="Caja transmisión"
                                    className={classes.formControl} 
                                >
                                    {TRANSMISSIONS.map((transmission, key) => (
                                        <MenuItem key={`transmission-vehicle-key-${key}`} value={transmission}>{transmission}</MenuItem>
                                    ))}
                                </Field>
                                <FormHelperText>
                                    <ErrorMessage name="transmission" />
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.row}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Field 
                                name="capacity" 
                                type="number" 
                                as={TextField} 
                                variant="outlined" 
                                label="Capacidad"
                                className={classes.formControl} 
                                error={hasError('capacity')}
                                helperText={<ErrorMessage name="capacity" />}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Button
                className={classes.nextButton}
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                disableElevation
            >
                Siguiente
            </Button>
        </Form>
    );
};

export default DatosVehiculoForm;