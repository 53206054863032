import { call, put, takeLatest } from 'redux-saga/effects';

import { 
    getDeviations,
} from '../services/deviationsService';

import { 
    getDeviationsRequest,
    getDeviationsSuccess,
    getDeviationsFailure,
} from '../store/slices/deviationsSlice';

function* getDeviationsEffect(action) {
    try {
        const page = action.payload.page;
        const page_limit = action.payload.page_limit;
        const filters = action.payload.filters;
        const { data } = yield call(getDeviations, page, page_limit, filters);
        yield put(getDeviationsSuccess({ 
            deviations: data.results,
            page: data.page,
            pages: data.pages,
            total: data.total,
            page_limit,
        }));
    } catch(e) {
        const { response } = e;
        const error = {
            code: response.status,
            message: response.data.message,
        };
        yield put(getDeviationsFailure({ error }));
    }
}

function* deviationsEffects() {
    yield takeLatest(getDeviationsRequest.type, getDeviationsEffect);
}

export default deviationsEffects;