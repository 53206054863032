import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ProtectedRoute } from '../ProtectedRoute';

import routesUrls from '../../routes/urls';

const RouteWithLayout = props => {
  const { isProtected, layout: Layout, component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={routeProps => {
        return isProtected ? (
          <ProtectedRoute redirectUrl={routesUrls.SignIn} {...routeProps}>
            <Layout {...routeProps}>
              <Component {...routeProps} />
            </Layout>
          </ProtectedRoute>
        ) : (
          <Layout {...routeProps}>
            <Component {...routeProps} />
          </Layout>
        )
      }}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
};

export default RouteWithLayout;