import routesUrls from '../../routes/urls';

import { 
    AddActivity,
    AddDeviation,
    AddExtinguisher,
    AddLevel,
    AddMinWage,
    EditCertificate,
    ListadosDatosGlobales,
    EditMinWage,
    EditLevel,
    EditActivity,
    EditDeviation,
    EditExtinguisher,
} from './Pages';

export const datosGlobalesUrls = {
    Listado: `${routesUrls.DatosGlobales}/listados`,
    AddMinwage: `${routesUrls.DatosGlobales}/agregar-salario-minimo`,
    AddActivity: `${routesUrls.DatosGlobales}/agregar-actividad`,
    AddDeviarion: `${routesUrls.DatosGlobales}/agregar-desviacion`,
    AddExtinguisher: `${routesUrls.DatosGlobales}/agregar-extintor`,
    AddLevel: `${routesUrls.DatosGlobales}/agregar-nivel`,
    EditCertificate: `${routesUrls.DatosGlobales}/editar-certificado`,
    EditMinwage: `${routesUrls.DatosGlobales}/editar-salario-minimo/:minwage_id`,
    EditLevel: `${routesUrls.DatosGlobales}/editar-nivel`,
    EditActivity: `${routesUrls.DatosGlobales}/editar-actividad/:activity_id`,
    EditDeviation: `${routesUrls.DatosGlobales}/editar-desviacion/:deviation_id`,
    EditExtinguisher: `${routesUrls.DatosGlobales}/editar-extintor/:extinguisher_id`,
};

const datosGlobalesRouting = [{
    component: ListadosDatosGlobales,
    path: datosGlobalesUrls.Listado,
}, {
    component: AddMinWage,
    path: datosGlobalesUrls.AddMinwage,
}, {
    component: AddActivity,
    path: datosGlobalesUrls.AddActivity,
}, {
    component: AddDeviation,
    path: datosGlobalesUrls.AddDeviarion,
}, {
    component: AddExtinguisher,
    path: datosGlobalesUrls.AddExtinguisher,
}, {
    component: AddLevel,
    path: datosGlobalesUrls.AddLevel,
}, {
    component: EditCertificate,
    path: datosGlobalesUrls.EditCertificate,
}, {
    component: EditMinWage,
    path: datosGlobalesUrls.EditMinwage,
}, {
    component: EditLevel,
    path: datosGlobalesUrls.EditLevel,
}, {
    component: EditActivity,
    path: datosGlobalesUrls.EditActivity,
}, {
    component: EditDeviation,
    path: datosGlobalesUrls.EditDeviation,
}, {
    component: EditExtinguisher,
    path: datosGlobalesUrls.EditExtinguisher,
}];

export default datosGlobalesRouting;