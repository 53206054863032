import { call, put, takeLatest } from 'redux-saga/effects';

import { 
    getMaintenances,
} from '../services/MT/maintenancesService';

import { 
    getMaintenancesRequest,
    getMaintenancesSuccess,
    getMaintenancesFailure,
} from '../store/slices/maintenancesSlice';

function* getMaintenancesEffect(action) {
    try {
        const page = action.payload.page;
        const page_limit = action.payload.page_limit;
        const filters = action.payload.filters;
        const { data } = yield call(getMaintenances, page, page_limit, filters);
        yield put(getMaintenancesSuccess({ 
            maintenances: data.results,
            page: data.page,
            pages: data.pages,
            total: data.total,
            page_limit,
        }));
    } catch(e) {
        const { response } = e;
        const error = {
            code: response.status,
            message: response.data.message,
        };
        yield put(getMaintenancesFailure({ error }));
    }
}

function* maintenancesEffects() {
    yield takeLatest(getMaintenancesRequest.type, getMaintenancesEffect);
}

export default maintenancesEffects;