import { useState, useEffect } from 'react';
import { getVehiclePowers } from '../../services/MT/vehiclePowersService';

const useVehiclePowers = () => {
    const [powers, setPowers] = useState([]);

    useEffect(() => {
        async function fetchVehiclePowers() {
            try {
                const { data } = await getVehiclePowers();
                setPowers(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchVehiclePowers();
    }, []);

    return { powers };
}

export default useVehiclePowers;