import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import MuiPhoneNumber from 'material-ui-phone-number';

import useVehicles from '../../../../../../hooks/MT/useVehicles';
import useEquipments from '../../../../../../hooks/MT/useEquipments';

import useUsersByPosition from '../../../../../../hooks/useUsersByPosition';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { Form, Field, ErrorMessage } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const DatosMaintenancesForm = (props) => {
    const classes = useStyles();
    // El 1000 es porque necesitamos traernos todos los vehículos y todos los equipos.
    // Entonces para reutilizar los mismos servicios y el mismo endpoint, se solicita una sola página
    // con mil registros. En caso de ser más, el 1000 debe incrementarse.
    const vehicles = useVehicles(1, 1000);
    const equipments = useEquipments(1, 1000);
    const users = useUsersByPosition("Operario");

    const hasError = (field) => {
        console.log(props.errors);
        return props.errors[field] && props.touched[field] ? true : false;
    };

    const handleTypeButtonClicked = (value) => () => {
        props.setFieldValue('type', value);
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Form>
                <Grid container direction="column" justify="center" alignItems="flex-start" spacing={2}>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="report" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Reporte"
                                    className={classes.formControl} 
                                    error={hasError('report')}
                                    helperText={<ErrorMessage name="report" />}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="sheet" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Folio"
                                    className={classes.formControl} 
                                    error={hasError('sheet')}
                                    helperText={<ErrorMessage name="sheet" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="date" 
                                    as={KeyboardDatePicker} 
                                    label="Fecha"
                                    className={classes.formControl} 
                                    inputVariant="outlined"
                                    variant="inline"
                                    InputAdornmentProps={{ position: "end" }}
                                    format="dd/MM/yyyy"
                                    onChange={(date) => props.setFieldValue("date", date)}
                                    error={hasError('date')}
                                    helperText={<ErrorMessage name="date" />}
                                    disableFuture
                                    autoOk
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="value" 
                                    type="text" 
                                    as={CurrencyTextField} 
                                    variant="outlined" 
                                    label="Valor"
                                    className={classes.formControl} 
                                    error={hasError('value')}
                                    helperText={<ErrorMessage name="value" />}
                                    onChange={(event, value) => props.setFieldValue('value', value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="workshop" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Taller"
                                    className={classes.formControl} 
                                    error={hasError('workshop')}
                                    helperText={<ErrorMessage name="workshop" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="description" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Descripción"
                                    className={classes.formControl} 
                                    error={hasError('description')}
                                    helperText={<ErrorMessage name="description" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl className={classes.formControl} variant="outlined" error={hasError('in_charge_id')}>
                                    <InputLabel>Responsable</InputLabel>
                                    <Field 
                                        name="in_charge_id" 
                                        type="text"
                                        as={Select} 
                                        label="Responsable"
                                        className={classes.formControl} 
                                    >
                                        {users.map((user, key) => (
                                            <MenuItem key={`in-charge-key-${key}`} value={user.id}>{user.firstname + " " + user.lastname}</MenuItem>
                                        ))}
                                    </Field>
                                    <FormHelperText>
                                        <ErrorMessage name="in_charge_id" />
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ButtonGroup style={{height: '50px'}} fullWidth color="primary" aria-label="type-button-group">
                                    <Button onClick={handleTypeButtonClicked("Equipo")} variant={props.values.type === "Equipo" ? "contained": "outlined"}>Equipo</Button>
                                    <Button onClick={handleTypeButtonClicked("Máquina")} variant={props.values.type === "Máquina" ? "contained": "outlined"}>Máquina</Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="phone" 
                                    type="text" 
                                    as={MuiPhoneNumber}
                                    variant="outlined" 
                                    label="Teléfono"
                                    className={classes.formControl} 
                                    error={hasError('phone')}
                                    helperText={<ErrorMessage name="phone" />}
                                    onChange={(value) => props.setFieldValue('phone', value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {props.values.type === "Equipo" ? (
                                    <FormControl className={classes.formControl} variant="outlined" error={hasError('equipment_id')}>
                                        <InputLabel>Código de equipo</InputLabel>
                                        <Field 
                                            name="equipment_id" 
                                            type="text"
                                            as={Select} 
                                            label="Código de equipo"
                                            className={classes.formControl} 
                                        >
                                            {equipments.map((equipment, key) => (
                                                <MenuItem key={`equipment-key-${key}`} value={equipment.id}>{equipment.code}</MenuItem>
                                            ))}
                                        </Field>
                                        <FormHelperText>
                                            <ErrorMessage name="equipment_id" />
                                        </FormHelperText>
                                    </FormControl>
                                ) : (
                                    <FormControl className={classes.formControl} variant="outlined" error={hasError('vehicle_id')}>
                                        <InputLabel>Código de vehículo</InputLabel>
                                        <Field 
                                            name="vehicle_id" 
                                            type="text"
                                            as={Select} 
                                            label="Código de vehículo"
                                            className={classes.formControl} 
                                        >
                                            {vehicles.map((vehicle, key) => (
                                                <MenuItem key={`vehicle-key-${key}`} value={vehicle.id}>{vehicle.code}</MenuItem>
                                            ))}
                                        </Field>
                                        <FormHelperText>
                                            <ErrorMessage name="vehicle_id" />
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Button
                    className={classes.saveButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disableElevation
                >
                    Guardar
                </Button>
            </Form>
        </MuiPickersUtilsProvider>
    );
}

export default DatosMaintenancesForm;