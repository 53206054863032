import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';

import useVehicleById from '../../../../hooks/MT/useVehicleById';

import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';
import { updateVehicle } from '../../../../services/vehiclesService';

import { Formik, validateYupSchema, yupToFormErrors } from 'formik';

import DatosIngresoErrorSchema from '../Forms/Vehicles/DatosIngresoForm/schema';
import DatosVehiculoErrorSchema from '../Forms/Vehicles/DatosVehiculoForm/schema';
import DatosImpuestoErrorSchema from '../Forms/Vehicles/DatosImpuestoForm/schema';

import { MotorUrls } from '../../routing';

import { 
    DatosImpuestoForm,
    DatosIngresoForm,
    DatosVehiculoForm,
} from '../Forms/Vehicles';

const useStyles = makeStyles(styles);

const EditVehicle = (props) => {
    const classes = useStyles();
    const { vehicle_id } = useParams();
    const { vehicle } = useVehicleById(vehicle_id);
    const dispatch = useDispatch();
    const [completed, setCompleted] = useState(new Set());
    const [activeStep, setActiveStep] = useState(0);
    const { 
        backendResponse: updateVehicleResponse,
        executeService: updateVehicleService,
    } = useBackendMessageResponse(updateVehicle);
    const [datosIngreso, setDatosIngreso] = useState({
        image_url: '',
        code: '', 
        owner_name: '',
        class: '',
        registration_number: '',
        enter_date: new Date(),
        registration_class: '',
        provider_name: '',
        plate_number: '',
        cost: '',
        description: '',
        enter_state: '',
        owning_method: '',
    });
    const [datosVehiculo, setDatosVehiculo] = useState({
        traction: '',
        brand_id: '',
        model_id: '',
        power_id: '',
        engine_brand_id: '',
        engine_fuel: '',
        oil_tank: '',
        transmission: '',
        engine_name: '',
        chasis: '',
        color: '',
        type: '',
        doors: '',
        passengers: '',
        capacity: '',
    });

    const [datosImpuesto, setDatosImpuesto] = useState({
        tax_date: new Date(),
        tax_form_number: '',
        tax_value: '',
        soat_expedition_date: new Date(),
        soat_due_date: new Date(),
        tm_expedition_date: new Date(),
        tm_due_date: new Date(),
    });

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleStep = (step) => () => {
        if (completed.has(step)) {
            setActiveStep(step);
        }
    }

    const handleComplete = () => {
        const newCompleted = new Set(completed);
        newCompleted.add(activeStep);
        setCompleted(newCompleted);
        handleNext();
    }

    const isStepCompleted = (index) => {
        return completed.has(index);
    }

    useEffect(() => {
        if (vehicle === null) {
            return;
        }

        setDatosIngreso({
            image_url: vehicle.image_url,
            code: vehicle.code, 
            owner_name: vehicle.owner_name,
            class: vehicle.class,
            registration_number: vehicle.registration_number,
            enter_date: new Date(vehicle.enter_date),
            registration_class: vehicle.registration_class,
            provider_name: vehicle.provider_name,
            plate_number: vehicle.plate_number,
            cost: vehicle.cost,
            description: vehicle.description,
            enter_state: vehicle.enter_state,
            owning_method: vehicle.owning_method,
        });

        setDatosVehiculo({
            traction: vehicle.traction,
            brand_id: vehicle.brand_id,
            model_id: vehicle.model_id,
            power_id: vehicle.power_id,
            engine_brand_id: vehicle.engine_brand_id,
            engine_fuel: vehicle.engine_fuel,
            oil_tank: vehicle.oil_tank,
            transmission: vehicle.transmission,
            engine_name: vehicle.engine_name,
            chasis: vehicle.chasis,
            color: vehicle.color,
            type: vehicle.type,
            doors: vehicle.doors,
            passengers: vehicle.passengers,
            capacity: vehicle.capacity,
        });

        setDatosImpuesto({
            tax_date: new Date(vehicle.tax_date),
            tax_form_number: vehicle.tax_form_number,
            tax_value: vehicle.tax_value,
            soat_expedition_date: new Date(vehicle.soat_expedition_date),
            soat_due_date: new Date(vehicle.soat_due_date),
            tm_expedition_date: new Date(vehicle.tm_expedition_date),
            tm_due_date: new Date(vehicle.tm_due_date),
        });

        completed.add(0);
        completed.add(1);
        completed.add(2);
    
    // eslint-disable-next-line
    }, [vehicle]);

    useEffect(() => {
        if (updateVehicleResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: updateVehicleResponse.message,
            severity: 'success',
        }));

        props.history.push(MotorUrls.Listado);
    }, [dispatch, updateVehicleResponse.message, props.history]);

    useEffect(() => {
        if (updateVehicleResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: updateVehicleResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, updateVehicleResponse.error])

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Editar vehículo</Box>
            </Typography>
            <div className={classes.formSteps}>
                <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                    <Step key="datos-ingreso">
                        <StepButton onClick={handleStep(0)} completed={isStepCompleted(0)} className={classes.stepButton}>Datos de ingreso</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <Formik 
                                    enableReinitialize
                                    validate={async (values) => {
                                        try {
                                            await validateYupSchema(values, DatosIngresoErrorSchema, false, {id: parseInt(vehicle_id)});
                                        } catch(err) {
                                            return yupToFormErrors(err);
                                        }

                                        return {};
                                    }}
                                    initialValues={datosIngreso} 
                                    component={DatosIngresoForm} 
                                    onSubmit={(values) => {
                                        setDatosIngreso(values);
                                        handleComplete();
                                    }}
                                />
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="datos-vehiculos">
                        <StepButton onClick={handleStep(1)} completed={isStepCompleted(1)} className={classes.stepButton}>Datos de vehículo</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <Formik
                                    enableReinitialize
                                    validationSchema={DatosVehiculoErrorSchema}
                                    component={DatosVehiculoForm}
                                    initialValues={datosVehiculo}
                                    onSubmit={(values) => {
                                        setDatosVehiculo(values);
                                        handleComplete();
                                    }}
                                />
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="datos-impuesto">
                        <StepButton onClick={handleStep(2)} completed={isStepCompleted(2)} className={classes.stepButton}>Datos de Impuesto, Seguro y Revisiones</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <Formik
                                    enableReinitialize
                                    validationSchema={DatosImpuestoErrorSchema}
                                    component={DatosImpuestoForm}
                                    initialValues={datosImpuesto}
                                    onSubmit={async (datosImpuestoValues) => {
                                        setDatosImpuesto(datosImpuestoValues);
                                        const newVehicleData = {
                                            ...datosIngreso,
                                            ...datosVehiculo,
                                            ...datosImpuestoValues,
                                        };

                                        await updateVehicleService(vehicle_id, newVehicleData);
                                    }}
                                />
                            </div>
                        </StepContent>
                    </Step>
                </Stepper>
            </div>
        </div>
    );
};

export default EditVehicle;