export default (theme) => ({
    root: {
        marginLeft: '20px',
    },
    pageTitle: {
        marginTop: '25px',
    },
    goBackIcon: {
        marginLeft: '-15px',
    },
    disableUserText: {
        marginTop: '15px',
        color: '#5D5D5D',
    },
    formControlUserType: {
        width: '30%',
        marginTop: '25px',
    },
    formControl: {
        width: '90%',
    },
    row: {
        width: '100%',
    },
    formsContainer: {
        marginTop: '50px',
    },
    button: {
        width: '40%',
        height: '50px',
        marginTop: '30px',
    },
    divider: {
        height: '400px',
        marginTop: '-70px',
        marginLeft: '50px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    modulesSaveButton: {
        width: '150px',
        height: '50px',
        marginTop: '50px',
    },
    modulesContainer: {
        marginTop: '25px',
    },
    authNotificationText: {
        marginTop: '50px',
    },
});