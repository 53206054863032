import {
    EventsList,
    AddEvent,
    EditEvent,
} from './Pages';

import routesUrls from '../../routes/urls';

export const EventsUrls = {
    Listado: `${routesUrls.Eventos}`,
    AddEvent: `${routesUrls.Eventos}/agregar-evento`,
    EditEvent: `${routesUrls.Eventos}/editar-evento/:event_id`,
}

const EventsRouting = [{
    component: EventsList,
    path: EventsUrls.Listado,
}, {
    component: AddEvent,
    path: EventsUrls.AddEvent,
}, {
    component: EditEvent,
    path: EventsUrls.EditEvent,
}];

export default EventsRouting;