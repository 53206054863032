import { call, put, takeLatest } from 'redux-saga/effects';
import {
    fetchNeighborhoodsRequest,
    fetchNeighborhoodsSuccess,
    fetchNeighborhoodsFailure,
} from '../store/slices/neighborhoodsSlice';
import {
    getNeighborhoods,
} from '../services/neighborhoodsService';

function* getNeighborhoodsEffect(action) {
    try {
        const { page, page_limit, filters } = action.payload;
        const { data } = yield call(getNeighborhoods, page, page_limit, filters);
        yield put(fetchNeighborhoodsSuccess({ 
            neighborhoods: data.results,
            page: data.page,
            pages: data.pages,
            total: data.total,
            page_limit,
        }));
    } catch(e) {
        const { response } = e;
        const error = {
            code: response.status,
            message: response.data.message,
        };
        yield put(fetchNeighborhoodsFailure({ error }));
    }
}

function* neighborhoodsEffects() {
    yield takeLatest(fetchNeighborhoodsRequest.type, getNeighborhoodsEffect);
}

export default neighborhoodsEffects;