import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import { Form, Field, ErrorMessage } from 'formik';
import { FormHelperText } from '@material-ui/core';

import { UploadImage } from '../../../../../../components/UploadImage';

const useStyles = makeStyles(styles);

const EnterStates = ['Nuevo', 'Usado', 'Remanufacturado'];
const OwningMethods = ['Donación', 'Compra Directa'];
const RegistrationClasses = ['Pública', 'Particular'];

const DatosIngresoForm = (props) => {
    const classes = useStyles();

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Form>
                <Grid container direction="column" justify="center" alignItems="flex-start" spacing={3}>
                    <Grid item className={classes.row}>
                        <div className={classes.ratioContainer}>
                            <div className={classes.ratioItem}>
                                <Field
                                    name="image_url"
                                    as={UploadImage}
                                    onChange={(image_url) => props.setFieldValue('image_url', image_url)}
                                    imageSrc={props.values.image_url}
                                    ratioMessage={`Ratio 16:9 (1280x720)`}
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="code" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Código"
                                    className={classes.formControl} 
                                    error={hasError('code')}
                                    helperText={<ErrorMessage name="code" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="owner_name" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Propietario"
                                    className={classes.formControl} 
                                    error={hasError('owner_name')}
                                    helperText={<ErrorMessage name="owner_name" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="class" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Clase"
                                    className={classes.formControl} 
                                    error={hasError('class')}
                                    helperText={<ErrorMessage name="class" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="registration_number" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Matrícula"
                                    className={classes.formControl} 
                                    error={hasError('registration_number')}
                                    helperText={<ErrorMessage name="registration_number" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="enter_date" 
                                    as={KeyboardDatePicker} 
                                    label="Fecha de Ingreso"
                                    className={classes.formControl} 
                                    inputVariant="outlined"
                                    variant="inline"
                                    InputAdornmentProps={{ position: "end" }}
                                    format="dd/MM/yyyy"
                                    onChange={(date) => props.setFieldValue("enter_date", date)}
                                    error={hasError('enter_date')}
                                    helperText={<ErrorMessage name="enter_date" />}
                                    autoOk
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl className={classes.formControl} variant="outlined" error={hasError('registration_class')}>
                                    <InputLabel>Clase de matrícula</InputLabel>
                                    <Field 
                                        name="registration_class" 
                                        type="text"
                                        as={Select} 
                                        label="Clase de matrícula"
                                        className={classes.formControl} 
                                    >
                                        {RegistrationClasses.map((item, key) => (
                                            <MenuItem key={`registration-class-key-${key}`} value={item}>{item}</MenuItem>
                                        ))}
                                    </Field>
                                    <FormHelperText>
                                        <ErrorMessage name="registration_class" />
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="provider_name" 
                                    type="text"
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Proveedor"
                                    className={classes.formControl} 
                                    error={hasError('provider_name')}
                                    helperText={<ErrorMessage name="provider_name" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="plate_number" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Placa"
                                    className={classes.formControl} 
                                    error={hasError('plate_number')}
                                    helperText={<ErrorMessage name="plate_number" />}
                                    onChange={(event) => props.setFieldValue('plate_number', event.target.value.toUpperCase())}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="cost" 
                                    type="text"
                                    as={CurrencyTextField} 
                                    variant="outlined" 
                                    label="Costo"
                                    className={classes.formControl} 
                                    error={hasError('cost')}
                                    helperText={<ErrorMessage name="cost" />}
                                    onChange={(event, value) => props.setFieldValue('cost', value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="description" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Descripción"
                                    className={classes.formControl} 
                                    error={hasError('description')}
                                    helperText={<ErrorMessage name="description" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                            <Grid item xs={12} md={6}>
                                <FormControl className={classes.formControl} variant="outlined" error={hasError('enter_state')}>
                                    <InputLabel>Estado de ingreso</InputLabel>
                                    <Field 
                                        name="enter_state" 
                                        type="text"
                                        as={Select} 
                                        label="Estado de ingreso"
                                        className={classes.formControl} 
                                    >
                                        {EnterStates.map((item, key) => (
                                            <MenuItem key={`enter-state-key-${key}`} value={item}>{item}</MenuItem>
                                        ))}
                                    </Field>
                                    <FormHelperText>
                                        <ErrorMessage name="enter_state" />
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl className={classes.formControl} variant="outlined" error={hasError('owning_method')}>
                                    <InputLabel>Modo adquisición</InputLabel>
                                    <Field 
                                        name="owning_method" 
                                        type="text"
                                        as={Select} 
                                        label="Modo adquisición"
                                        className={classes.formControl} 
                                    >
                                        {OwningMethods.map((item, key) => (
                                            <MenuItem key={`owning-method-${key}`} value={item}>{item}</MenuItem>
                                        ))}
                                    </Field>
                                    <FormHelperText>
                                        <ErrorMessage name="owning_method" />
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Button
                    className={classes.nextButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disableElevation
                >
                    Siguiente
                </Button>
            </Form>
        </MuiPickersUtilsProvider>
    );
};

export default DatosIngresoForm;