import * as Yup from 'yup';

export default Yup.object().shape({
    department_id: Yup.number()
        .required('Éste campo es requerido.'),
    city_id: Yup.number()
        .required('Éste campo es requerido.'),
    neighborhood_id: Yup.number()
        .required('Éste campo es requerido.'),
    location_phone: Yup.string()    
        .max(13, 'Máximo 13 dígitos.')
        .required('Éste campo es requerido.'),
    location_area: Yup.string()
        .required('Éste campo es requerido.'),
    location_address: Yup.string()  
        .max(100, 'Máximo 100 caracteres.')
        .required('Éste campo es requerido.'),
    location_observations: Yup.string() 
        .max(500, 'Máximo 500 caracteres.')
        .required('Éste campo es requerido.'),
    location_procedures: Yup.array()
        .of(
            Yup.object().shape({
                procedure: Yup.string()
                    .max(500, 'Máximo 500 caracteres.')
                    .required('Éste campo es requerido.'),
            })
        )
        .required('Éste campo es requerido.'),
});