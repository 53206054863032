import {
    AddRiskAgent,
    EditRiskAgent,
    ListRiskAgents,
} from './Pages';

import routesUrls from '../../routes/urls';

export const riskAgentsUrls = {
    Listado: `${routesUrls.RiesgoUsuarios}`,
    AddRiskAgentUser: `${routesUrls.RiesgoUsuarios}/crear-usuario`,
    EditRiskAgentUser: `${routesUrls.RiesgoUsuarios}/editar-usuario/:user_id`,
}

const riskAgentRouting = [{
    component: ListRiskAgents,
    path: riskAgentsUrls.Listado,
}, {
    component: AddRiskAgent,
    path: riskAgentsUrls.AddRiskAgentUser,
}, {
    component: EditRiskAgent,
    path: riskAgentsUrls.EditRiskAgentUser,
}];

export default riskAgentRouting;