export default (theme) => ({
    input: {
        width: '100%',
        paddingRight: 5,
        paddingLeft: 5,
    },
    searchButton: {
        width: '100%',
        height: '45px',
    },
    searchField: {
        width: '100%',
        height: '45px',
        padding: 3,
        display: 'flex',
        direction: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 7,
        border: '1px solid #DDDDDD',
    },
    iconButton: {
        padding: 10,
    },
});