import { call, put, takeLatest } from 'redux-saga/effects';

import { 
    getRanks,
} from '../services/ranksService';

import { 
    getRanksRequest,
    getRanksSuccess,
    getRanksFailure,
} from '../store/slices/ranksSlice';

function* getRanksEffect(action) {
    try {
        const { data } = yield call(getRanks);
        yield put(getRanksSuccess({ 
            ranks: data,
        }));
    } catch(e) {
        const { response } = e;
        const error = {
            code: response.status,
            message: response.data.message,
        };
        yield put(getRanksFailure({ error }));
    }
}

function* ranksEffects() {
    yield takeLatest(getRanksRequest.type, getRanksEffect);
}

export default ranksEffects;