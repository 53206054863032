export default theme => ({
    saveButton: {
        marginTop: '20px',
        width: '170px',
    },
    formControl: {
        width: '100%',
        '& .Mui-disabled input, & .Mui-disabled div[class*="MuiSelect-"], & .Mui-disabled textarea': {
            color: "rgba(0,0,0,0.6)"
        },
    },
    row: {
        width: '60%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    fullWidth: {
        width: '100%',
    },
    topSpacing: {
        marginTop: '30px',
    },
    addProcedureContainer: {
    },
    addProcedureIcon: {
        fontSize: '40px',
        color: '#53DBB6',
    },
    addProcedureText: {
        color: '#5C5C5C',
    },
    deleteProcedureContainer: {
        float: 'right',
    },
    deleteProcedureIcon: {
        color: '#FF6F6F',
        position: 'absolute',
        right: 5,
        bottom: 5,
    },
});