import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MuiPhoneNumber from 'material-ui-phone-number';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Form, Field, ErrorMessage } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const AfectadoForm = (props) => {
    const classes = useStyles();

    const { readMode } = props;

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    };

    return (
        <div className={classes.root}>
            <Form>
                <Grid container direction="column" spacing={2} justify="flex-start" alignItems="flex-start">
                    <Grid item className={classes.row}>
                        <FormControlLabel
                            control={
                                <Field
                                    name="affected_person_apply"
                                    as={Checkbox}
                                    checked={!props.values.affected_person_apply}
                                    onChange={(event) => props.setFieldValue('affected_person_apply', !event.target.checked)}
                                    color="primary"
                                />
                            }
                            label="No aplica"
                            disabled={readMode}
                        />
                    </Grid>
                    <Grid item className={classes.row}>
                        <Field 
                            name="affected_person" 
                            type="text" 
                            as={TextField} 
                            variant="outlined" 
                            label="Persona afectada"
                            className={classes.formControl} 
                            error={hasError('affected_person')}
                            helperText={<ErrorMessage name="affected_person" />}
                            disabled={!props.values.affected_person_apply || readMode}
                        />
                    </Grid>
                    <Grid item className={classes.row}>
                        <Field 
                            name="affected_person_document" 
                            type="text" 
                            as={TextField} 
                            variant="outlined" 
                            label="Identificación"
                            className={classes.formControl} 
                            error={hasError('affected_person_document')}
                            helperText={<ErrorMessage name="affected_person_document" />}
                            disabled={!props.values.affected_person_apply || readMode}
                        />
                    </Grid>
                    <Grid item className={classes.row}>
                        <Field 
                            name="affected_person_address" 
                            type="text" 
                            as={TextField} 
                            variant="outlined" 
                            label="Dirección"
                            className={classes.formControl} 
                            error={hasError('affected_person_address')}
                            helperText={<ErrorMessage name="affected_person_address" />}
                            disabled={!props.values.affected_person_apply || readMode}
                        />
                    </Grid>
                    <Grid item className={classes.row}>
                        <Field 
                            name="affected_person_phone" 
                            type="text" 
                            as={MuiPhoneNumber} 
                            variant="outlined" 
                            label="Teléfono"
                            className={classes.formControl} 
                            error={hasError('affected_person_phone')}
                            helperText={<ErrorMessage name="affected_person_phone" />}
                            disabled={!props.values.affected_person_apply || readMode}
                            onChange={(value) => props.setFieldValue('affected_person_phone', value)}
                        />
                    </Grid>
                    <Grid item className={classes.row}>
                        <Field 
                            name="affected_person_description" 
                            type="text" 
                            as={TextField} 
                            variant="outlined" 
                            label="Descripción"
                            className={classes.formControl} 
                            error={hasError('affected_person_description')}
                            helperText={<ErrorMessage name="affected_person_description" />}
                            multiline
                            rows={6}
                            disabled={!props.values.affected_person_apply || readMode}
                        />
                    </Grid>
                </Grid>
                <Button
                    className={classes.saveButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disableElevation
                    disabled={readMode}
                >
                    Siguiente
                </Button>
            </Form>
        </div>
    );
}

AfectadoForm.defaultProps = {
    readMode: false,
};

export default AfectadoForm;