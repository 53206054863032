export default theme => ({
    root: {
    },
    fullWidth: {
        width: '100%',
    },
    table: {
        marginBottom: '20px',
    },
    tableCellText: {
        color: '#5C5C5C',
    },
    addCourseContainer: {
        marginRight: '10px',
    },
    addCourse: {
        fontSize: '35px',
        color: '#53DBB6',
    },
    addCourseText: {
        color: '#5C5C5C',
    },
    coursesTitle: {
        marginLeft: '10px',
    },
    deleteCourse: {
        fontSize: '30px',
        color: '#FF6F6F',
    },
});