import * as Yup from 'yup';

export default Yup.object().shape({
    reception_date: Yup.date()
        .required('Éste campo es requerido.'),
    sender_id: Yup.number()
        .required('Éste campo es requerido.'),
    reception_time: Yup.date()
        .required('Éste campo es requerido.'),
    sheet_number: Yup.string()  
        .max(5, 'Máximo 5 dígitos.')
        .required('Éste campo es requerido.'),
    remission_date: Yup.date()
        .required('Éste campo es requerido.'),
    annex_number: Yup.string()
        .max(5, 'Máximo 5 dígitos.')
        .required('Éste campo es requerido.'),
    receiver_id: Yup.number()
        .required('Éste campo es requerido.'),
    reception_observations: Yup.string()
        .max(500, 'Máximo 500 caracteres.')
        .required('Éste campo es requerido.'),
});