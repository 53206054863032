import differenceInSeconds from 'date-fns/differenceInSeconds';

function useTimestamp() {
    const later = new Date(2020, 9, 1, 0, 0, 0);
    const today = new Date();

    const difference = differenceInSeconds(today, later);
    return difference;
}

export default useTimestamp;