import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';

import useTimestamp from '../../../../hooks/useTimestamp';
import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';
import { createStEmergency } from '../../../../services/ST/emergenciesService';

import { SiniestralidadUrls } from '../../routing';

import DatosGeneralesSchema from '../../Forms/Emergencias/DatosGeneralesForm/schema';
import UbicacionSchema from '../../Forms/Emergencias/UbicacionForm/schema';
import AfectadoSchema from '../../Forms/Emergencias/AfectadoForm/schema';
import CostosSchema from '../../Forms/Emergencias/CostosForm/schema';
import ReceptionSchema from '../../Forms/Emergencias/RecepcionForm/schema';

import { Formik } from 'formik';
import { 
    DatosGeneralesForm,
    UnidadesForm,
    UbicacionForm,
    AfectadoForm,
    PrimerosAuxiliosForm,
    TestigosForm,
    EvidenciasForm,
    CostosForm,
    RecepcionForm,
} from '../../Forms/Emergencias';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const AddEmergency = (props) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState(new Set());
    const timestamp = useTimestamp();
    const dispatch = useDispatch();
    const {
        backendResponse: createStEmergencyResponse,
        executeService: createStEmergencyService,
    } = useBackendMessageResponse(createStEmergency);

    const [datosGeneralesForm, setDatosGeneralesForm] = useState({
        report: `ST-${timestamp}`,
        reporter_name: '',
        report_date: new Date(),
        reporter_phone: '',
        report_time: new Date(),
        dispatch_machine: [],
        dispatch_date: new Date(),
        support_machine: [],
        dispatch_time: new Date(),
        report_address: '',
        arriving_time: new Date(),
        report_neighborhood_id: '',
        control_time: new Date(),
        emergency_id: '',
        station_arriving_time: new Date(),
    });

    const [unidadesForm, setUnidadesForm] = useState({
        unities: [],
    });

    const [ubicacionForm, setUbicationForm] = useState({
        department_id: '',
        city_id: '',
        neighborhood_id: '',
        location_phone: '',
        location_area: 'Rural',
        location_address: '',
        location_observations: '',
        location_procedures: [{
            procedure: '',
        }],
    });

    const [afectadoForm, setAfectadoForm] = useState({
        affected_person_apply: true,
        affected_person: '',
        affected_person_document: '',
        affected_person_address: '',
        affected_person_phone: '',
        affected_person_description: '',
    });

    const [primerosAuxiliosForm, setPrimerosAuxiliosForm] = useState({
        first_aid_apply: true,
        first_aids: [],
    });

    const [testigosForm, setTestigosForm] = useState({
        witness_apply: true,
        witnesses: [],
    });

    const [evidenciasForm, setEvidenciasForm] = useState({
        evidence_apply: true,
        evidences: [],
    });

    const [costosForm, setCostosForm] = useState({
        cost_apply: true,
        costs_intervention_hour: '',
    });

    const [recepcionForm, setRecepcionForm] = useState({
        reception_date: new Date(),
        sender_id: '',
        reception_time: new Date(),
        sheet_number: '',
        remission_date: new Date(),
        annex_number: '',
        receiver_id: '',
        reception_observations: '',
    });

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleStep = (step) => () => {
        if (completed.has(step)) {
            setActiveStep(step);
        }
    }

    const handleComplete = () => {
        const newCompleted = new Set(completed);
        newCompleted.add(activeStep);
        setCompleted(newCompleted);
        handleNext();
    }

    const isStepCompleted = (index) => {
        return completed.has(index);
    }

    // Acá se recibe el formulario de reception porque setReception funciona de forma asíncrona
    // Entonces como es el último formulario y para asegurar que se están usando los valores actualizados
    // se usa como el evento para crear una emergencia pero en lugar de usar el estado directamente,
    // se usa los valores retornados por Formik. 
    const onCreateEmergency = async (receptionForm) => {
        setRecepcionForm(receptionForm);

        const emergencyData = {
            ...datosGeneralesForm,
            dispatch_machine: JSON.stringify(datosGeneralesForm.dispatch_machine),
            support_machine: JSON.stringify(datosGeneralesForm.support_machine),
            unities: JSON.stringify(unidadesForm.unities),
            ...ubicacionForm,
            location_procedures: JSON.stringify(ubicacionForm.location_procedures),
            affected_person_info: JSON.stringify(afectadoForm),
            first_aids_info: JSON.stringify(primerosAuxiliosForm),
            witnesses_info: JSON.stringify(testigosForm),
            evidences_info: JSON.stringify(evidenciasForm),
            costs_info: JSON.stringify(costosForm),
            ...receptionForm,
        };

        await createStEmergencyService(emergencyData);
    }

    useEffect(() => {
        if (createStEmergencyResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createStEmergencyResponse.message,
            severity: 'success',
        }));

        props.history.push(SiniestralidadUrls.Tablero);
    }, [dispatch, createStEmergencyResponse.message, props.history]);

    useEffect(() => {
        if (createStEmergencyResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createStEmergencyResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, createStEmergencyResponse.error])

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Añadir emergencia</Box>
            </Typography>
            <div className={classes.formSteps}>
                <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                    <Step key="datos-generales">
                        <StepButton onClick={handleStep(0)} completed={isStepCompleted(0)}>Datos generales</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <Formik 
                                    initialValues={datosGeneralesForm}
                                    component={DatosGeneralesForm} 
                                    validationSchema={DatosGeneralesSchema}
                                    onSubmit={(values) => {
                                        setDatosGeneralesForm(values);
                                        handleComplete();
                                    }}
                                />
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="unidades">
                        <StepButton onClick={handleStep(1)} completed={isStepCompleted(1)}>Unidades</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <UnidadesForm
                                    unitiesData={unidadesForm} 
                                    onChangeUnities={(unities) => setUnidadesForm(current => ({ ...current, unities}))} 
                                    onHandleNext={handleComplete}
                                />
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="ubicacion">
                        <StepButton onClick={handleStep(2)} completed={isStepCompleted(2)}>Ubicación</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <Formik 
                                    initialValues={ubicacionForm}
                                    component={UbicacionForm} 
                                    validationSchema={UbicacionSchema}
                                    onSubmit={(values) => {
                                        setUbicationForm(values);
                                        handleComplete();
                                    }}
                                />
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="afectado">
                        <StepButton onClick={handleStep(3)} completed={isStepCompleted(3)}>Afectado</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <Formik 
                                    initialValues={afectadoForm}
                                    component={AfectadoForm} 
                                    validationSchema={AfectadoSchema}
                                    onSubmit={(values) => {
                                        setAfectadoForm(values);
                                        handleComplete();
                                    }}
                                />
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="primeros-auxilios">
                        <StepButton onClick={handleStep(4)} completed={isStepCompleted(4)}>Primeros Auxilios</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <PrimerosAuxiliosForm 
                                    firstAidsData={primerosAuxiliosForm} 
                                    onChangeFirstAids={(first_aids) => setPrimerosAuxiliosForm(current => ({ ...current, first_aids }))} 
                                    onToggleApply={() => setPrimerosAuxiliosForm(current => ({...current, first_aid_apply: !primerosAuxiliosForm.first_aid_apply}))}
                                    onHandleNext={handleComplete}
                                />
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="testigos">
                        <StepButton onClick={handleStep(5)} completed={isStepCompleted(5)}>Testigos</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <TestigosForm
                                    witnessesData={testigosForm} 
                                    onChangeWitnesses={(witnesses) => setTestigosForm(current => ({ ...current, witnesses }))} 
                                    onToggleApply={() => setTestigosForm(current => ({...current, witness_apply: !testigosForm.witness_apply}))}
                                    onHandleNext={handleComplete}
                                />
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="evidencias">
                        <StepButton onClick={handleStep(6)} completed={isStepCompleted(6)}>Evidencias</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <EvidenciasForm
                                    evidencesData={evidenciasForm} 
                                    onChangeEvidences={(evidences) => setEvidenciasForm(current => ({ ...current, evidences }))} 
                                    onToggleApply={() => setEvidenciasForm(current => ({...current, evidence_apply: !evidenciasForm.evidence_apply}))}
                                    onHandleNext={handleComplete}
                                />
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="costos">
                        <StepButton onClick={handleStep(7)} completed={isStepCompleted(7)}>Costos</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <Formik
                                    initialValues={costosForm}
                                    onSubmit={(values) => {
                                        setCostosForm(values);
                                        handleComplete();
                                    }}
                                    validationSchema={CostosSchema}
                                >
                                    {formik => (
                                        <CostosForm {...formik} datosGenerales={{...datosGeneralesForm}}/>
                                    )}
                                </Formik>
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="recepcion">
                        <StepButton onClick={handleStep(8)} completed={isStepCompleted(8)}>Recepción</StepButton>
                        <StepContent>
                            <div className={classes.form}>
                                <Formik 
                                    initialValues={recepcionForm}
                                    component={RecepcionForm} 
                                    onSubmit={onCreateEmergency}
                                    validationSchema={ReceptionSchema}
                                />
                            </div>
                        </StepContent>
                    </Step>
                </Stepper>
            </div>
        </div>
    );
}

export default AddEmergency;