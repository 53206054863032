import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../../../store/slices/alertsSlice';

import { 
    selectDamageReports,
    getDamageReportsRequest,
} from '../../../../../../store/slices/damageReportsSlice';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Can, Only } from '../../../../../../components/Can';
import { AllowOnlyIf } from '../../../../../../components/AllowOnlyIf';
import { ROLES_KEYS } from '../../../../../../constants/roles';
import { PERMISSION_KEYS } from '../../../../../../constants/permissions';

import { FilterButton } from '../../../../../../components/FilterButton';
import { Formik } from 'formik';
import { DamageReportFilterForm } from './DamageReportFilterForm';

import useBackendMessageResponse from '../../../../../../hooks/useBackendMessageResponse';
import { deleteDamageReport } from '../../../../../../services/MT/damageReportsService';

import format from 'date-fns/format';

import { SearchField } from '../../../../../../components/SearchField';

import { MotorUrls } from '../../../../routing';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const ListaDamageReport = (props) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const damageReports = useSelector(selectDamageReports);
    const dispatch = useDispatch();
    const {
        backendResponse: deleteDamageReportResponse,
        executeService: deleteDamageReportService,
    } = useBackendMessageResponse(deleteDamageReport);
    const [damageReportClickedData, setDamageReportClickedData] = useState({
        openDialog: false,
        report: null,
    });
    const [damageReportFilterForm, setDamageReportFilterForm] = useState({
        report: '',
        date: '',
        shift: '',
        operator: '',
        costs: '',
        sheet: '',

        // Éste campo aparecerá únicamente para aquellos usuarios
        // que no sean ni estación ni usuarios de una estación.
        station_name: '',
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onGetSearchTerm = (term) => {
        setSearchTerm(term);
        setPage(0);
    }

    const onClickAddDamageReport = () => {
        const { history } = props;
        history.push(MotorUrls.AddDamageReport);
    }

    const onClickEditReport = (report) => () => {
        const { history } = props;
        history.push(MotorUrls.EditDamageReport.replace(':damage_report_id', report.id));
    }

    const onClickDeleteReport = (report) => () => {
        setDamageReportClickedData({
            openDialog: true,
            report,
        });
    }

    const handleCloseDeleteDialog = () => {
        setDamageReportClickedData({
            openDialog: false,
            report: null,
        });
    }

    const handleDamageReportDelete = async () => {
        try {
            const reportId = damageReportClickedData.report.id;
            await deleteDamageReportService(reportId);
            setDamageReportClickedData({
                openDialog: false,
                report: null,
            });
            dispatch(getDamageReportsRequest({
                page: page + 1,
                page_limit: rowsPerPage,
                filters: {
                    searchTerm: searchTerm !== '' ? searchTerm : undefined,
                    ...cleanFilter(),
                },
            }));
        } catch (e) {
            const { response } = e;
            console.log(response);
        }
    }

    const cleanFilter = useCallback(() => {
        const cleanFilteredData = damageReportFilterForm;
        Object.keys(cleanFilteredData).forEach((key) => {
            if (cleanFilteredData[key] === '') {
                delete cleanFilteredData[key];
            }
        });
        return cleanFilteredData;
    }, [damageReportFilterForm]);

    useEffect(() => {
        dispatch(getDamageReportsRequest({
            page: page + 1,
            page_limit: rowsPerPage,
            filters: {
                searchTerm: searchTerm !== '' ? searchTerm : undefined,
                ...cleanFilter(),
            },
        }));
    }, [dispatch, page, rowsPerPage, searchTerm, cleanFilter]);

    useEffect(() => {
        if (deleteDamageReportResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: deleteDamageReportResponse.message,
            severity: 'success',
        }));
    }, [dispatch, deleteDamageReportResponse.message]);

    useEffect(() => {
        if (deleteDamageReportResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: deleteDamageReportResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, deleteDamageReportResponse.error])

    return (
        <div className={classes.root}>
            <Dialog
                open={damageReportClickedData.openDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-damage-report-delete-title"
                aria-describedby="alert-dialog-damage-report-delete-description"
            >
                <DialogTitle id="alert-dialog-damage-report-delete-title">{"Confirmación"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-damage-report-delete-description">
                        ¿Estás seguro de querer continuar con la eliminación del registro seleccionado?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDamageReportDelete} color="primary" autoFocus>
                        Continuar
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.fullWidth} spacing={2}>
                <Grid item md={6} xs={12} className={classes.searchInput}>
                    <SearchField onClickSearch={onGetSearchTerm} />
                </Grid>
                <Grid item>
                    <FilterButton>
                        {filterActions => (
                            <div className={classes.filterForm}>
                                <Formik 
                                    enableReinitialize
                                    component={DamageReportFilterForm}
                                    initialValues={damageReportFilterForm}
                                    onSubmit={(values) => { 
                                        const submitter = window.event.submitter.name;
                                        setPage(0);
                                        if (submitter === "clean") {
                                            // Acá se tiene que vaciar el estado de esta forma en lugar de:
                                            // action.resetForm(); ya que FilterButton usa un Popper internamente y se está usando
                                            // filterFormData como valores iniciales. Por lo que al montar y desmontar
                                            // a través del Popper se van a tomar los valores del estado.
                                            setDamageReportFilterForm({
                                                report: '',
                                                date: '',
                                                shift: '',
                                                operator: '',
                                                costs: '',
                                                sheet: '',
                                            });
                                            filterActions.hideFilterForm();
                                            return;
                                        }
                                        setDamageReportFilterForm(values);
                                        filterActions.hideFilterForm();
                                    }}
                                />
                            </div>
                        )}
                    </FilterButton>
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" justify="space-between" className={classes.fullWidth}>
                <Grid item>
                    <Typography component={"span"}>
                        <Box fontWeight="fontWeightBold" fontSize={25}>Reporte de daños</Box>
                    </Typography>
                </Grid>
                <Grid item>
                    <AllowOnlyIf permission={PERMISSION_KEYS.MT_WRITE}>
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            className={classes.addReportButton} 
                            disableElevation
                            onClick={onClickAddDamageReport}
                        >
                            Añadir reporte 
                        </Button>
                    </AllowOnlyIf>
                </Grid>
            </Grid>
            <Paper elevation={0}>
                <TableContainer>
                    <Table className={classes.table} aria-label="damage-reports-table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Reporte</TableCell>
                                <TableCell align="left">Fecha</TableCell>
                                <TableCell align="left">Turno</TableCell>
                                <TableCell align="left">Operario</TableCell>
                                <TableCell align="left">Costos</TableCell>
                                <TableCell align="left">Folio</TableCell>
                                <Can>
                                    <Only roles={[ROLES_KEYS.admin, ROLES_KEYS.risk_agent]}>
                                        <TableCell align="left">Estación</TableCell>
                                    </Only>
                                </Can>
                                <AllowOnlyIf permission={PERMISSION_KEYS.MT_WRITE}>
                                    <TableCell align="right">Acciones</TableCell>
                                </AllowOnlyIf>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {damageReports.damageReports.map((damageReport, key) => (
                                <TableRow key={`list-damage-reports-item-key-${key}`}>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {damageReport.report}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {format(new Date(damageReport.date), "dd/MM/yyyy")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {damageReport.shift}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {damageReport.operator.firstname + " " + damageReport.operator.lastname}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {damageReport.costs}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {damageReport.sheet}
                                        </Typography>
                                    </TableCell>
                                    <Can>
                                        <Only roles={[ROLES_KEYS.admin, ROLES_KEYS.risk_agent]}>
                                            <TableCell align="left">
                                                <Typography component={"span"} className={classes.tableCellText}>
                                                    {`${damageReport.station_name}`}
                                                </Typography>
                                            </TableCell>
                                        </Only>
                                    </Can>
                                    <AllowOnlyIf permission={PERMISSION_KEYS.MT_WRITE}>
                                        <TableCell align="right">
                                            <Grid container direction="row" alignItems="center" justify="flex-end" spacing={1}>
                                                <Grid item>
                                                    <IconButton aria-label="edit" className={classes.iconButton} onClick={onClickEditReport(damageReport)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton aria-label="delete" className={classes.iconButton} onClick={onClickDeleteReport(damageReport)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </AllowOnlyIf>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={damageReports.total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage="Filas por página:"
                        labelDisplayedRows={() => {
                            return `Página: ${page + 1} de ${Math.ceil(damageReports.total / rowsPerPage)}`;
                        }}
                    />
                </TableContainer>
            </Paper>
        </div>
    );
};

export default ListaDamageReport;