import { authSuccess, signOutSuccess } from '../slices/authSlice';
import { resetCurrentUser } from '../slices/currentUserSlice';
import { clientPrepared, clientUnprepared } from '../slices/httpClientSlice';
import httpClient from '../../http';

const handleAuthTokenMiddleware = store => next => action => {
    if (action.type === authSuccess.type) {
        httpClient.defaults.headers.common['Authorization'] = 
            `Bearer ${action.payload.token}`;
        store.dispatch(clientPrepared());
    } else if (action.type === signOutSuccess.type) {
        delete httpClient.defaults.headers.common['Authorization'];
        store.dispatch(resetCurrentUser());
        store.dispatch(clientUnprepared());
    }
    return next(action);
};

export default handleAuthTokenMiddleware;