export default theme => ({
    root: {
        padding: '25px',
    },
    goBackIcon: {
        marginLeft: '-15px',
    },
    form: {
        width: '100%',
    },
    formControl: {
        marginTop: '15px',
        width: '100%',
    },
    saveButton: {
        width: '150px',
        marginTop: theme.spacing(5),
    },
    authNotificationText: {
        marginTop: '25px',
    },
    formContainer: {
        width: '100%',
    },
    formItem: {
        width: '20%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    fireTypes: {
        width: '100%',
    },
    fireTypesContainer: {
        width: '100%',
    },
    deleteIcon: {
        marginTop: '20px',
        color: '#FF6F6F',
    },
    addFireTypeIcon: {
        marginTop: '20px',
    },
    addFireTypeText: {
        color: '#5C5C5C',
        marginTop: '20px',
    },
});