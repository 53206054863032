export default theme => ({
    root: {
    },
    fullWidth: {
        width: '100%',
    },
    table: {
        marginBottom: '20px',
    },
    tableCellText: {
        color: '#5C5C5C',
    },
    addExperienceContainer: {
        marginRight: '10px',
    },
    addExperience: {
        fontSize: '35px',
        color: '#53DBB6',
    },
    addExperienceText: {
        color: '#5C5C5C',
    },
    experiencesTitle: {
        marginLeft: '10px',
    },
    deleteExperience: {
        fontSize: '30px',
        color: '#FF6F6F',
    },
});