import { useState } from 'react';

// Éste hook se utiliza porque hay eventos que no necesitan hacer uso de los effects de redux-saga.
// Ya que se vuelve innecesario almacenar tales acciones en el estado.
const useBackendMessageResponse = (service) => {
    const [backendResponse, setBackendResponse] = useState({
        isLoading: false,
        error: null,
        message: null,
    });

    async function executeService(...parameters) {
        try {
            setBackendResponse(currentBackendResponse => ({
                ...currentBackendResponse,
                isLoading: true,
            }));
            const { data } = await service(...parameters);
            setBackendResponse({
                message: data.message,
                error: null,
                isLoading: false,
            });
        } catch(e) {
            const { response } = e;
            let message = '';
            if (typeof response.data.message === 'object') {
                for(var key in response.data.message) {
                    message = response.data.message[key];
                    break;
                }
            } else {
                message = response.data.message;
            }
            const error = {
                code: response.status,
                message,
            };
            setBackendResponse({
                message: null,
                isLoading: false,
                error,
            });
        }
    }

    // Ésta función existe porque algunas vistas son necesarias que repitan sus acciones
    // (i.e. crear recursos) múltiples veces sin salir de la ruta actual y es necesario
    // resetear todos los estados incluyendo el de respuesta del backend ya que 
    // evaluan ciertas condiciones como por ejemplo backend.message !== null.
    function resetBackendResponseState() {
        setBackendResponse({
            isLoading: false,
            error: null,
            message: null,
        });
    }

    return { backendResponse, executeService, resetBackendResponseState };
}

export default useBackendMessageResponse;