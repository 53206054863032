export default (theme) => ({
    root: {
        width: '300px',
        height: '400px',
    },
    paper: {
        width: '100%',
        height: '100%',
        borderRadius: 5,
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: '0.3s',
        "&:hover": {
            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        height: '100%',
        width: '100%',
    },
    item: {
        width: '100%',
        height: '100%',
    },
    backgroundImage: {
        position: 'absolute',
        height: '200px',
        width: '300px',
        borderRadius: 5,
        zIndex: -1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },
    checkbox: {
        position: 'absolute',
        zIndex: 2,
    },
    moduleTitle: {
        color: '#FFF',
        marginTop: '10px',
        zIndex: 2,
    },
    moduleIcon: {
        width: '80px',
        height: '80px',
        zIndex: 2,
    },
    gradient: {
        position: 'absolute',
        height: '200px',
        width: '300px',
        borderRadius: 5,
        backgroundColor: 'rgba(1, 153, 174, 0.5)',
    },
});