import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGrInspectionsRequest, selectGrInspections } from '../../../../../../store/slices/grInspectionsSlice';
import { setGlobalAlert } from '../../../../../../store/slices/alertsSlice';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VerifiedUserRoundedIcon from '@material-ui/icons/VerifiedUserRounded';

import { Can, Only } from '../../../../../../components/Can';
import { ROLES_KEYS } from '../../../../../../constants/roles';

import useUsers from '../../../../../../hooks/useUsers';
import useBackendMessageResponse from '../../../../../../hooks/useBackendMessageResponse';
import { deleteInspection } from '../../../../../../services/GR/inspectionService';

import { SearchField } from '../../../../../../components/SearchField';
import { FilterButton } from '../../../../../../components/FilterButton';
import { InspectionFilterForm } from './InspectionFilterForm';

import format from 'date-fns/format';

import { Formik } from 'formik';

import { GestionRiesgoUrls } from '../../../../routing';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';
import { AllowOnlyIf } from '../../../../../../components/AllowOnlyIf';
import { PERMISSION_KEYS } from '../../../../../../constants/permissions';

const useStyles = makeStyles(styles);

// Ésto se coloca directamente acá para evitar un re-render infinito.
// Ya que si se colocara por ejemplo:
// const { ... } = useUsers(..., { position_name: "Inspector" });
// Se dispara la misma petición infinitas veces.
// Asumo que tiene que ver con el hecho de que se está creando un objecto nuevo cada vez que 
// se renderiza, ocasionando que el useEffect dentro del useUsers se dispare.
// Así que colocando fuera del componente, evitamos eso.
const inspector_filter = {
    position_name: "Inspector",
};

const Inspeccion = (props) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const dispatch = useDispatch();
    const grInspections = useSelector(selectGrInspections);
    const { findUserById } = useUsers(1, 1000, inspector_filter);
    const [inspectionClickedData, setInspectionClickedData] = useState({
        openDialog: false,
        inspection: null,
    });
    const {
        backendResponse: deleteInspectionResponse,
        executeService: deleteInspectionService,
    } = useBackendMessageResponse(deleteInspection);
    const [inspectionFilterForm, setInspectionFilterForm] = useState({
        inspection_number: '',
        owner_name: '',
        inspection_date: '',
        inspector_name: '',

        // Éste campo aparecerá únicamente para aquellos usuarios
        // que no sean ni estación ni usuarios de una estación.
        station_name: '',
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onGetSearchTerm = (term) => {
        setSearchTerm(term);
        setPage(0);
    }

    const onAddInspection = () => {
        const { history } = props;
        history.push(GestionRiesgoUrls.AddInspection);
    }

    const onClickEditInspection = (inspection) => () => {
        const { history } = props;
        history.push(GestionRiesgoUrls.EditInspection.replace(':inspection_id', inspection.id));
    }

    const onClickDetailsInspection = (inspection) => () => {
        const { history } = props;
        history.push(GestionRiesgoUrls.DetailsInspection.replace(':inspection_id', inspection.id));
    }

    const onClickCertificate = (inspection) => () => {
        window.open(inspection.cert_url, "_blank");
    }

    const onClickDeleteInspection = (inspection) => () => {
        setInspectionClickedData({
            openDialog: true,
            inspection,
        });
    } 

    const handleCloseDeleteDialog = () => {
        setInspectionClickedData({
            openDialog: false,
            inspection: null,
        });
    }

    const handleInspectionDelete = async () => {
        try {
            const inspectionId = inspectionClickedData.inspection.id;
            await deleteInspectionService(inspectionId);
            setInspectionClickedData({
                openDialog: false,
                inspection: null,
            });
            dispatch(getGrInspectionsRequest({
                page: page + 1,
                page_limit: rowsPerPage,
                filters: {
                    searchTerm: searchTerm !== '' ? searchTerm : undefined,
                    ...cleanFilter(),
                },
            }));
        } catch (e) {
            const { response } = e;
            console.log(response);
        }
    }

    const cleanFilter = useCallback(() => {
        const cleanFilteredData = inspectionFilterForm;
        Object.keys(cleanFilteredData).forEach((key) => {
            if (cleanFilteredData[key] === '') {
                delete cleanFilteredData[key];
            }
        });
        return cleanFilteredData;
    }, [inspectionFilterForm]);

    useEffect(() => {
        dispatch(getGrInspectionsRequest({
            page: page + 1,
            page_limit: rowsPerPage,
            filters: {
                searchTerm: searchTerm !== '' ? searchTerm : undefined,
                ...cleanFilter(),
            },
        }));
    }, [page, rowsPerPage, dispatch, searchTerm, cleanFilter]);

    useEffect(() => {
        if (deleteInspectionResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: deleteInspectionResponse.message,
            severity: 'success',
        }));
    }, [dispatch, deleteInspectionResponse.message]);

    useEffect(() => {
        if (deleteInspectionResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: deleteInspectionResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, deleteInspectionResponse.error])

    return (
        <div className={classes.root}>
            <Dialog
                open={inspectionClickedData.openDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-inspection-delete-title"
                aria-describedby="alert-dialog-inspection-delete-description"
            >
                <DialogTitle id="alert-dialog-inspection-delete-title">{"Confirmación"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-inspection-delete-description">
                        ¿Estás seguro de querer continuar con la eliminación del registro seleccionado?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleInspectionDelete} color="primary" autoFocus>
                        Continuar
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.fullWidth} spacing={2}>
                <Grid item md={6} xs={12} className={classes.searchInput}>
                    <SearchField onClickSearch={onGetSearchTerm} />
                </Grid>
                <Grid item>
                    <FilterButton>
                        {filterActions => (
                            <div className={classes.filterForm}>
                                <Formik
                                    enableReinitialize
                                    initialValues={inspectionFilterForm}
                                    component={InspectionFilterForm}
                                    onSubmit={(values) => {
                                        const submitter = window.event.submitter.name;
                                        setPage(0);
                                        if (submitter === "clean") {
                                            setInspectionFilterForm({
                                                inspection_number: '',
                                                owner_name: '',
                                                inspection_date: '',
                                                inspector_name: '',
                                            });
                                            return;
                                        }
                                        setInspectionFilterForm(values);
                                        filterActions.hideFilterForm();
                                    }}
                                />
                            </div>
                        )}
                    </FilterButton>
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" justify="space-between" className={classes.fullWidth}>
                <Grid item>
                    <Typography component={"span"}>
                        <Box fontWeight="fontWeightBold" fontSize={25}>Inspecciones</Box>
                    </Typography>
                </Grid>
                <Grid item>
                    <AllowOnlyIf permission={PERMISSION_KEYS.GR_WRITE}>
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            className={classes.addInspectionButton} 
                            disableElevation
                            onClick={onAddInspection}
                        >
                            Añadir inspección 
                        </Button>
                    </AllowOnlyIf>
                </Grid>
            </Grid>
            <Paper elevation={0}>
                <TableContainer>
                    <Table className={classes.table} aria-label="activities-table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Número de inspección</TableCell>
                                <TableCell align="left">Propietario</TableCell>
                                <TableCell align="left">Fecha de inspección</TableCell>
                                <TableCell align="left">Inspector</TableCell>
                                <Can>
                                    <Only roles={[ROLES_KEYS.admin, ROLES_KEYS.risk_agent]}>
                                        <TableCell align="left">Estación</TableCell>
                                    </Only>
                                </Can>
                                <TableCell align="right">Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {grInspections.grInspections.map((inspection, key) => {
                                const user = findUserById(inspection.inspector_id);
                                if (user === undefined || user === null) {
                                    return null;
                                }
                                return (
                                    <TableRow key={`list-gr-inspection-item-key-${key}`}>
                                        <TableCell align="left">
                                            <Typography component={"span"} className={classes.tableCellText}>
                                                {inspection.inspection_number}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography component={"span"} className={classes.tableCellText}>
                                                {inspection.owner_name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography component={"span"} className={classes.tableCellText}>
                                                {format(new Date(inspection.inspection_date), "dd/MM/yyyy")}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography component={"span"} className={classes.tableCellText}>
                                                {`${user.firstname} ${user.lastname}`}
                                            </Typography>
                                        </TableCell>
                                        <Can>
                                            <Only roles={[ROLES_KEYS.admin, ROLES_KEYS.risk_agent]}>
                                                <TableCell align="left">
                                                    <Typography component={"span"} className={classes.tableCellText}>
                                                        {`${inspection.station_name}`}
                                                    </Typography>
                                                </TableCell>
                                            </Only>
                                        </Can>
                                        <AllowOnlyIf permission={PERMISSION_KEYS.GR_WRITE}>
                                            {(hasPermission) => {
                                                return hasPermission ? (
                                                    <TableCell align="right">
                                                        <Grid container direction="row" alignItems="center" justify="flex-end" spacing={1}>
                                                            { inspection.certified && (
                                                            <Grid item>
                                                                <IconButton aria-label="edit" className={classes.iconButton} onClick={onClickCertificate(inspection)}>
                                                                    <VerifiedUserRoundedIcon />
                                                                </IconButton>
                                                            </Grid>
                                                            )}
                                                            <Grid item>
                                                                <IconButton aria-label="edit" className={classes.iconButton} onClick={onClickEditInspection(inspection)}>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton aria-label="delete" className={classes.iconButton} onClick={onClickDeleteInspection(inspection)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                ) : (
                                                    <TableCell align="right">
                                                        <Grid container direction="row" alignItems="center" justify="flex-end" spacing={1}>
                                                            { inspection.certified && (
                                                            <Grid item>
                                                                <IconButton aria-label="edit" className={classes.iconButton} onClick={onClickCertificate(inspection)}>
                                                                    <VerifiedUserRoundedIcon />
                                                                </IconButton>
                                                            </Grid>
                                                            )}
                                                            <Grid item>
                                                                <IconButton aria-label="edit" className={classes.iconButton} onClick={onClickDetailsInspection(inspection)}>
                                                                    <VisibilityIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                )
                                            }}
                                        </AllowOnlyIf>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={grInspections.total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage="Filas por página:"
                        labelDisplayedRows={() => {
                            return `Página: ${page + 1} de ${Math.ceil(grInspections.total / rowsPerPage)}`;
                        }}
                    />
                </TableContainer>
            </Paper>
        </div>
    );
}

export default Inspeccion;