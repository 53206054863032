
import validate from 'validate.js';
import isAfter from 'date-fns/isAfter';
import isToday from 'date-fns/isToday';

validate.validators.isTodayOrLater= function(value, options, key, attributes) {
    const dateThatShouldBeTodayOrLater = value;
    const today = new Date();
    if (isToday(dateThatShouldBeTodayOrLater) || 
        isAfter(dateThatShouldBeTodayOrLater, today)) {
        return null;
    }
    return options['message'] || 'is not today or later';
}