import { createSlice } from '@reduxjs/toolkit';

export const modulesSlice = createSlice({
    name: 'modules',
    initialState: {
        availableModules: [],
    },
    reducers: {
        setAvailableModules: (state, action) => {
            state.availableModules = action.payload.availableModules;
        },
    },
});

export const { 
    setAvailableModules,
} = modulesSlice.actions;

export const selectModules = (state) => state.modules;

export default modulesSlice.reducer;