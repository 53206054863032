import React from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import useRanks from '../../../../../hooks/useRanks';
import usePositions from '../../../../../hooks/usePositions';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import { Form, Field } from 'formik';

const useStyles = makeStyles(styles);

const StationUserFilterForm = (props) => {
    const classes = useStyles();
    const { ranks } = useRanks();
    const { positions } = usePositions();

    return (
        <Form>
            <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                <Grid item className={classes.fullWidth}>
                    <Field
                        name="document"
                        as={TextField}
                        className={classes.formControl}
                        variant="outlined"
                        label="N. de identificación"
                        size="small"
                    />
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <Field
                        name="name"
                        as={TextField}
                        className={classes.formControl}
                        variant="outlined"
                        label="Nombre y apellido"
                        size="small"
                    />
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <Field
                        name="email"
                        as={TextField}
                        className={classes.formControl}
                        variant="outlined"
                        label="Correo electrónico"
                        size="small"
                    />
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <FormControl variant="outlined" className={classes.formControl} size="small">
                        <InputLabel id="rank-level-station-user-label">Rango</InputLabel>
                        <Field
                            name="rank_id"
                            as={Select}
                            labelId="rank-level-station-user-label"
                            id="rank-level-station-user-select"
                            label="Rango"
                        >
                        {ranks.ranks.map((rank, index) => (
                            <MenuItem value={rank.id} key={`rank-level-station-user-key-${index}`}>{rank.name}</MenuItem>
                        ))}
                        </Field>
                    </FormControl>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <FormControl variant="outlined" className={classes.formControl} size="small">
                        <InputLabel id="position-level-station-user-label">Posición</InputLabel>
                        <Field
                            name="position_id"
                            as={Select}
                            labelId="position-level-station-user-label"
                            id="position-level-station-user-select"
                            label="Posición"
                        >
                        {positions.positions.map((position, index) => (
                            <MenuItem value={position.id} key={`position-level-station-user-key-${index}`}>{position.name}</MenuItem>
                        ))}
                        </Field>
                    </FormControl>
                </Grid>
                <Grid item>
                    <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                        <Grid item>
                            <Button
                                name="apply"
                                variant="contained" 
                                color="primary" 
                                className={classes.filterButton} 
                                size="small" 
                                disableElevation
                                type="submit"
                            >
                                Aplicar
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button 
                                name="clean"
                                color="primary" 
                                size="small"
                                disableElevation
                                type="submit"
                            >
                                Limpiar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Form>
    );
}

export default StationUserFilterForm;