import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getUsersRequest, selectUsers } from '../../../../store/slices/usersSlice';
import { 
    enableUser,
    disableUser,
} from '../../../../services/usersService';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Formik } from 'formik';
import { UsersListFilterForm } from './UsersListFilterForm';

import { ADMIN_USER_LIST_ROLES as roles } from '../../../../constants/roles';
 
import { SearchField } from '../../../../components/SearchField';
import { AntSwitch } from '../../../../components/AntSwitch';
import { FilterButton } from '../../../../components/FilterButton';

import { adminUsersUrls } from '../../routing';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

import clsx from 'clsx';

const useStyles = makeStyles(styles);

const UsersList = (props) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [pageLimit, setPageLimit] = useState(10);
    const [userClickedData, setUserClickedData] = useState({
        openDialog: false,
        user: null,
    });
    const dispatch = useDispatch();
    const users = useSelector(selectUsers);

    const [filterForm, setFilterForm] = useState({
        station_name: '',
        document: '',
        role: '',
        name: '',
        email: '',
        disabled: '',
    });

    const [searchTerm, setSearchTerm] = useState('');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageLimit(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onAddNewUser = () => {
        const { history } = props;
        history.push(adminUsersUrls.AddUser);
    };

    const onUserSwitchChange = (user) => (event) => {
        setUserClickedData({
            openDialog: true,
            user
        });
    };

    const onUserEdit = (user) => (event) => {
        const { history } = props;
        history.push(adminUsersUrls.EditUser.replace(':user_id', user.id));
    };

    const onGetSearchTerm = (newSearchTerm) => {
        setSearchTerm(newSearchTerm);
        setPage(0);
    };

    const handleDialogClose = () => {
        setUserClickedData({
            openDialog: false,
            user: null,
        });
    };

    const onEnableDisableUser = async () => {
        let userAction = null;
        const user = userClickedData.user;
        // Tanto enableUser como disableUser reciben exactamente los mismos parámetros.
        // Es por ello que podemos hacer esto.
        if (user.disabled) {
            userAction = enableUser;
        } else {
            userAction = disableUser;
        }

        try {
            const { data } = await userAction(user.id);
            console.log(data);
        } catch(e) {
            const { response } = e;
            console.log(response);
        }

        dispatch(getUsersRequest({
            page: page + 1,
            page_limit: pageLimit,
            filters: {
                search_term: searchTerm !== '' ? searchTerm : undefined,
                station_name: filterForm.station_name !== '' ? filterForm.station_name : undefined,
                document: filterForm.document !== '' ? filterForm.document : undefined,
                role: filterForm.role !== '' ? filterForm.role : undefined,
                name: filterForm.name !== '' ? filterForm.name : undefined,
                email: filterForm.email !== '' ? filterForm.email : undefined,
                disabled: filterForm.disabled !== '' ? filterForm.disabled : undefined,
            },
        }));
        setUserClickedData({
            openDialog: false,
            user: null,
        });
    };

    useEffect(() => {
        dispatch(getUsersRequest({
            page: page + 1,
            page_limit: pageLimit,
            filters: {
                search_term: searchTerm !== '' ? searchTerm : undefined,
                station_name: filterForm.station_name !== '' ? filterForm.station_name : undefined,
                document: filterForm.document !== '' ? filterForm.document : undefined,
                role: filterForm.role !== '' ? filterForm.role : undefined,
                name: filterForm.name !== '' ? filterForm.name : undefined,
                email: filterForm.email !== '' ? filterForm.email : undefined,
                disabled: filterForm.disabled !== '' ? filterForm.disabled : undefined,
            },
        }));
    // eslint-disable-next-line
    }, [page, pageLimit, dispatch, searchTerm, filterForm]);

    return (
        <div className={classes.root}>
            <Dialog
                open={userClickedData.openDialog}
                onClose={handleDialogClose}
                aria-labelledby="enable-disable-dialog-title"
                aria-describedby="enable-disable-dialog-description"
            >
                <DialogTitle id="enable-disable-dialog-title">{"Confirmación"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="enable-disable-dialog-description">
                        ¿Está seguro que desea inactivar al usuario seleccionado?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        No
                    </Button>
                    <Button onClick={onEnableDisableUser} color="primary" autoFocus>
                        Sí
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container direction="column" justify="flex-start" alignItems="center">
                <Grid item className={classes.fullWidth}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.fullWidth} spacing={2}>
                        <Grid item md={6} xs={12} className={classes.searchInput}>
                            <SearchField onClickSearch={onGetSearchTerm} />
                        </Grid>
                        <Grid item>
                            <FilterButton>
                                {(filterActions) => (
                                    <div className={classes.filterForm}>
                                        <Formik
                                            enableReinitialize
                                            initialValues={filterForm}
                                            component={UsersListFilterForm}
                                            onSubmit={(values) => {
                                                const submitter = window.event.submitter.name;
                                                setPage(0);
                                                if (submitter === "clean") {
                                                    // Acá se tiene que vaciar el estado de esta forma en lugar de:
                                                    // action.resetForm(); ya que FilterButton usa un Popper internamente y se está usando
                                                    // filterForm como valores iniciales. Por lo que al montar y desmontar
                                                    // a través del Popper se van a tomar los valores del estado.
                                                    setFilterForm({
                                                        station_name: '',
                                                        document: '',
                                                        role: '',
                                                        name: '',
                                                        email: '',
                                                        disabled: '',
                                                    });
                                                    filterActions.hideFilterForm();
                                                    return;
                                                }
                                                setFilterForm(values);
                                                filterActions.hideFilterForm();
                                            }}
                                        />
                                    </div>
                                )}
                            </FilterButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <Grid container direction="row" alignItems="center" justify="space-between">
                        <Grid item>
                            <Typography component={"span"}>
                                <Box fontWeight="fontWeightBold" fontSize={25}>Usuarios</Box>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button 
                                variant="contained" 
                                color="secondary" 
                                className={classes.addUserButton} 
                                disableElevation
                                onClick={onAddNewUser}
                            >
                                Añadir usuarios
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={clsx(classes.fullWidth, classes.dataTable)}>
                    <Paper elevation={0}>
                        <TableContainer>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <TableCell align="left">Estación</TableCell>
                                    <TableCell align="left">Tipo de usuario</TableCell>
                                    <TableCell align="left">Identificación</TableCell>
                                    <TableCell align="left">Nombre y apellido</TableCell>
                                    <TableCell align="left">Correo electrónico</TableCell>
                                    <TableCell align="left">Estado</TableCell>
                                    <TableCell align="left">Acciones</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {users.users.map((user) => (
                                    <TableRow key={`user-rowid-${user.id}`}>
                                        <TableCell align="left">
                                            <Typography className={classes.tableCellText} component={"span"}>
                                                {user.station_details === null ? 'No definida' : user.station_details.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography className={classes.tableCellText} component={"span"}>
                                                {roles[user.role]}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography className={classes.tableCellText} component={"span"}>
                                                {user.document}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography className={classes.tableCellText} component={"span"}>
                                                {user.firstname + " " + user.lastname}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography className={classes.tableCellText} component={"span"}>
                                                {user.email}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography className={classes.tableCellText} component={"span"}>
                                                {user.disabled ? 'Desactivo' : 'Activo'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Grid component="label" container alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <IconButton aria-label="edit" className={classes.editIcon} onClick={onUserEdit(user)}>
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <AntSwitch checked={!user.disabled} name="checked" onChange={onUserSwitchChange(user)} />
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={users.total}
                            rowsPerPage={pageLimit}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage="Filas por página:"
                            labelDisplayedRows={() => {
                                return `Página: ${page + 1} de ${Math.floor(users.total / pageLimit) + 1}`;
                            }}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}

export default UsersList;