import { useState, useEffect } from 'react';
import { getEquipmentById } from '../../services/equipmentsService';

const useEquipmentById = (equipment_id) => {
    const [equipment, setEquipment] = useState(null);

    useEffect(() => {
        async function fetchEquipment() {
            try {
                const { data } = await getEquipmentById(equipment_id);
                setEquipment(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchEquipment();
    }, [equipment_id]);

    return { equipment };
}

export default useEquipmentById;