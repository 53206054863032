export default theme => ({
    emergencyDataContainer: {
        marginTop: '20px',
    },
    emergencyQuantity: {
        color: '#0199AE',
    },
    divider: {
        marginTop: '15px',
    },
});