import * as Yup from 'yup';

export default Yup.object().shape({
    // Ident. Form
    report: Yup.string()
        .required('Éste campo es requerido.'),
    identification: Yup.string()
        .max(15, "Máximo 15 carácteres.")
        .required('Éste campo es requerido.'),
    date: Yup.date()
        .required('Éste campo es requerido.'),
    cause: Yup.string()
        .max(500, "Máximo 500 carácteres.")
        .required('Éste campo es requerido.'),
    time: Yup.date()
        .required('Éste campo es requerido.'),
    action: Yup.string()
        .max(500, "Máximo 500 carácteres.")
        .required('Éste campo es requerido.'),
    shift: Yup.string()
        .required('Éste campo es requerido.'),
    novelty: Yup.string()
        .max(500, "Máximo 500 carácteres.")
        .required('Éste campo es requerido.'),
    operator_id: Yup.number()
        .required('Éste campo es requerido.'),
    sheet: Yup.string()
        .max(20, "Máximo 20 dígitos.")
        .required('Éste campo es requerido.'),

    // Costs form
    business_name: Yup.string()
        .max(100, "Máximo 100 caracteres.")
        .required('Éste campo es requerido.'),
    iva: Yup.string()
        .max(16, "Máximo 16 dígitos.")
        .required('Éste campo es requerido.'),
    facturing_res: Yup.string()
        .max(20, "Máximo 20 carácteres.")
        .required('Éste campo es requerido.'),
    costs: Yup.string()
        .max(16, "Máximo 16 dígitos.")
        .required('Éste campo es requerido.'),
    receipt_number: Yup.string()
        .max(20, "Máximo 20 dígitos.")
        .required('Éste campo es requerido.'),
    ivaless_cost: Yup.string()
        .max(16, "Máximo 16 dígitos.")
        .required('Éste campo es requerido.'),
    type: Yup.string()
        .required('Éste campo es requerido.'),
    equipment_id: Yup.number()
        .when("type", (type, yup) => {
            return type && type === "Equipo" 
                ? yup.required('Éste campo es requerido.') 
                : yup;
        }),
    vehicle_id: Yup.number()
        .when("type", (type, yup) => {
            return type && type === "Máquina" 
                ? yup.required('Éste campo es requerido.')
                : yup;
        }),
});