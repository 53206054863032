import { call, put, takeLatest } from 'redux-saga/effects';

import { 
    getEquipments,
} from '../services/equipmentsService';

import { 
    getEquipmentsRequest,
    getEquipmentsSuccess,
    getEquipmentsFailure,
} from '../store/slices/equipmentsSlice';

function* getEquipmentsEffect(action) {
    try {
        const page = action.payload.page;
        const page_limit = action.payload.page_limit;
        const filters = action.payload.filters;
        const { data } = yield call(getEquipments, page, page_limit, filters);
        yield put(getEquipmentsSuccess({ 
            equipments: data.results,
            page: data.page,
            pages: data.pages,
            total: data.total,
            page_limit,
        }));
    } catch(e) {
        const { response } = e;
        const error = {
            code: response.status,
            message: response.data.message,
        };
        yield put(getEquipmentsFailure({ error }));
    }
}

function* equipmentsEffects() {
    yield takeLatest(getEquipmentsRequest.type, getEquipmentsEffect);
}

export default equipmentsEffects;