import { createSlice } from '@reduxjs/toolkit';

export const configurationListsSlice = createSlice({
    name: 'configurationLists',
    initialState: {
        selectedTab: 0,
    },
    reducers: {
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload.selectedTab;
        },
        resetListState: (state) => {
            state.selectedTab = 0;
        },
    },
});

export const {
    setSelectedTab,
    resetListState,
} = configurationListsSlice.actions;

export const selectConfigurationLists = (state) => state.configurationLists;

export default configurationListsSlice.reducer;