import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import { ADMIN_USER_LIST_ROLES as roles } from '../../../../../constants/roles';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import { Form, Field } from 'formik';

const useStyles = makeStyles(styles);

// Ésto hace referencia a la propiedad 'disabled'.
// Es por ello que Activo = 'false'. 
// Porque si se selecciona 'Activo', se tiene que mandar: disabled=false.
const status_types = {
    'false': 'Activo',
    'true': 'Desactivo',
};

const UsersListFilterForm = (props) => {
    const classes = useStyles();

    return (
        <Form>
            <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                <Grid item className={classes.fullWidth}>
                    <Field
                        id="estacion-filter" 
                        label="Estación" 
                        as={TextField}
                        variant="outlined" 
                        className={classes.formControl} 
                        size="small"
                        name="station_name"
                    />
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <Field
                        id="id-filter" 
                        as={TextField}
                        label="Identificación" 
                        variant="outlined" 
                        className={classes.formControl} 
                        name="document"
                        size="small"
                    />
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <FormControl 
                        variant="outlined" 
                        className={classes.formControl} 
                        size="small"
                    >
                        <InputLabel id="user-type-filter-text">Tipo de usuario</InputLabel>
                        <Field
                            labelId="user-type-filter-text"
                            as={Select}
                            id="user-type-select"
                            label="Tipo de usuario"
                            name="role"
                        >
                            <MenuItem value="">
                                <em>Todos</em>
                            </MenuItem>
                            {Object.keys(roles).map(key => (
                                <MenuItem value={key} key={`menu-roles-${key}`}>{roles[key]}</MenuItem>
                            ))}
                        </Field>
                    </FormControl>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <Field
                        id="name-filter" 
                        as={TextField}
                        label="Nombre" 
                        variant="outlined" 
                        className={classes.formControl} 
                        size="small"
                        name="name"
                    />
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <Field
                        id="email-filter" 
                        as={TextField}
                        label="Correo" 
                        variant="outlined" 
                        className={classes.formControl} 
                        size="small"
                        name="email"
                    />
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <FormControl 
                        variant="outlined" 
                        className={classes.formControl}
                        size="small"
                    >
                        <InputLabel id="status-filter-text">Estado</InputLabel>
                        <Field
                            labelId="status-filter-text"
                            id="status-select"
                            as={Select}
                            label="Estado"
                            name="disabled"
                        >
                            <MenuItem value="">
                                <em>Todos</em>
                            </MenuItem>
                            {Object.keys(status_types).map(key => (
                                <MenuItem value={key} key={`menu-status-${key}`}>{status_types[key]}</MenuItem>
                            ))}
                        </Field>
                    </FormControl>
                </Grid>
                <Grid item>
                    <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                        <Grid item>
                            <Button
                                name="apply"
                                variant="contained" 
                                color="primary" 
                                className={classes.filterButton} 
                                size="small" 
                                disableElevation
                                type="submit"
                            >
                                Aplicar
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button 
                                name="clean"
                                color="primary" 
                                size="small"
                                disableElevation
                                type="submit"
                            >
                                Limpiar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Form>
    );
}

export default UsersListFilterForm;