import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

import { Formik } from 'formik';
import { DatosAvailabilityForm } from '../Forms/Availabilities/DatosAvailabilityForm';
import schema from '../Forms/Availabilities/DatosAvailabilityForm/schema';

import useTimestamp from '../../../../hooks/useTimestamp';
import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';
import { createAvailability } from '../../../../services/MT/availabilitiesService';

import { MotorUrls } from '../../routing';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const AddAvailability = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const timestamp = useTimestamp();
    const {
        backendResponse: createAvailabilityResponse,
        executeService: createAvailabilityService,
    } = useBackendMessageResponse(createAvailability);
    const [availabilityForm, setAvailabilityForm] = useState({
        report: `DP-${timestamp}`,
        damage_report_id: '',
        novelties: '',
        vehicle_id: '',
        maintenance_id: '',
        date: new Date(),
        note: '',
        location: '',
        time: new Date(),
        emergency: '',
    });

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    const handleSubmit = async (values) => {
        setAvailabilityForm(values);

        await createAvailabilityService(values);
    }

    useEffect(() => {
        if (createAvailabilityResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createAvailabilityResponse.message,
            severity: 'success',
        }));

        props.history.push(MotorUrls.Listado);
    }, [dispatch, createAvailabilityResponse.message, props.history]);

    useEffect(() => {
        if (createAvailabilityResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: createAvailabilityResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, createAvailabilityResponse.error])

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Añadir disponibilidad</Box>
            </Typography>
            <div className={classes.form}>
                <Formik
                    validationSchema={schema}
                    initialValues={availabilityForm}
                    component={DatosAvailabilityForm}
                    onSubmit={handleSubmit}
                />
            </div>
        </div>
    );
};

export default AddAvailability;