import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getRanksRequest , selectRanks } from '../store/slices/ranksSlice';

const useRanks = () => {
    const dispatch = useDispatch();
    const ranks = useSelector(selectRanks);

    useEffect(() => {
        dispatch(getRanksRequest());
    }, [dispatch]);

    const findRankNameById = useCallback((id) => {
        const rank = ranks.ranks.find(rank => rank.id === id);
        if (rank === undefined) {
            return '';
        }
        return rank.name;
    }, [ranks]);

    return { ranks, findRankNameById };
}

export default useRanks;