export default theme => ({
    columnWidth: {
        width: '100%',
    },
    stationFormTitle: {
        marginTop: '10px',
        marginBottom: '25px',
        marginLeft: '8px',
    },
    saveProfileButton: {
        marginTop: '50px',
        width: '170px',
    },
    avatarRootContainer: {
        padding: '20px',
        marginBottom: '20px',
    },
    avatarContainer: {
        width: '270px',
    },
    ratioContainer: {
        paddingTop: '100%', // 1:1 ratio
        position: 'relative',
    },
    ratioItem: {
        position: 'absolute',
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
    },
});