import validate from 'validate.js';

validate.validators.fulfillPasswordStandards = function(value, options, key, attributes) {
    let errors = [];
    if (!value.match(/[a-z]/g)) {
        errors.push("Al menos un caracter en minúscula")
    }
    if (!value.match(/[A-Z]/g)) {
        errors.push("Al menos un caracter en mayúscula")
    }
    if (!value.match(/\d/g)) {
        errors.push("Al menos un dígito")
    }
    // Removemos el eslint porque el linter se queja de caracteres innecesarios 
    // cuando sí son necesarios ¯\_(ツ)_/¯
    // eslint-disable-next-line 
    if (!value.match(/[*@!#%&()^~{}$"\/=?¿¡'\[\]^]/g)) {
        errors.push("Al menos un caracter especial")
    }

    if (value.match(/(\s)/g)) {
        errors.push("No debe contener espacios.");
    }

    // Las validaciones de longitud mínima de 8 caracteres 
    // y máxima de 60 lo hacen los validators integrados en validate.js
    return errors.length !== 0 ? errors : null;
}