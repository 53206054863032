import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectAuth } from '../../store/slices/authSlice';

import validate from 'validate.js';
import schema from './schema';

import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// import httpStatusCodes from 'http-status-codes';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

import { 
    checkRecoveryTokenRequest, 
    changePasswordRequest,
    authResetState,
} from '../../store/slices/authSlice';

const useStyles = makeStyles(styles);

const ChangePassword = (props) => {
    const [formState, setFormState] = useState({
        isValid: false,
        isSubmitted: false,
        openBackdrop: false,
        values: {
            password: '',
            confirmPassword: '',
        },
        showPassword: {
            password: false,
            confirmPassword: false,
        },
        touched: {
            password: false,
            confirmPassword: false,
        },
        errors: {
            password: [],
            confirmPassword: [],
        },
    });

    const { token } = useParams();
    const classes = useStyles();
    const dispatch = useDispatch();
    const authState = useSelector(selectAuth);

    const { history } = props;

    const handleSubmit = (event) => {
        event.preventDefault();
        if(!formState.isSubmitted) {
            setFormState(currentState => ({
                ...currentState,
                isSubmitted: true,
            }));
        }
        if(!formState.isValid) {
            return;
        }
        const data = {
            token,
            password: formState.values.password
        };
        dispatch(changePasswordRequest(data));
    }

    const handleOnCancel = () => {
        history.replace('/auth/signin');
    }

    const handleChange = event => {
        event.persist();

        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]: event.target.value,
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            }
        }));
    };

    const handleClickShowPassword = (field) => () => {
        setFormState(currentState => ({ 
            ...currentState, 
            showPassword: {
                ...currentState.showPassword,
                [field]: !currentState.showPassword[field]
            },
        }));
    };

    const hasError = field =>
        (formState.isSubmitted || formState.touched[field]) && formState.errors[field] ? true : false;

    useEffect(() => {
        dispatch(authResetState());
        dispatch(checkRecoveryTokenRequest({ token }));
    }, [token, dispatch]);

    useEffect(() => {
        const errors = validate(formState.values, schema);

        setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [formState.values]);

    useEffect(() => {
        if (authState.message === 'Contraseña actualizada') {
            setFormState(currentState => ({
                ...currentState,
                openBackdrop: true,
            }));
            setTimeout(() => {
                // Éste dispatch se coloca para limpiar el estado inmediatamente luego
                // de haber usado el enlace y actualizado la contraseña correctamente.
                // De ésta forma si se vuelve inmediatamente a abrir el enlace, no redireccione.
                dispatch(authResetState());
                history.push('/auth/signin');
            }, 2500);
        }
    }, [authState, history, dispatch]);

    return (
        <div className={classes.root}>
            <Backdrop open={formState.openBackdrop} className={classes.backdrop}>
                <Grid container direction="column" justity="center" alignItems="center">
                    <Grid item>
                        <CircularProgress color="inherit" />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.title}>
                            <Box fontWeight="fontWeightBold">
                                Redireccionando...
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
            </Backdrop>
            <Grid container className={classes.grid}>
                <Grid item xs={12}>
                    <Typography className={classes.title}>
                        <Box fontWeight="fontWeightBold">
                            Cambiar contraseña
                        </Box>
                    </Typography>
                </Grid>
                <Grid item xs={12} direction="column" justify="center" alignItems="center" className={classes.gridForm}>
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <FormControl className={clsx(classes.margin, classes.textField)} error={hasError('password')} fullWidth>
                            <InputLabel htmlFor="standard-adornment-password">Ingresar nueva contraseña</InputLabel>
                            <Input
                                className={classes.textField}
                                id="standard-adornment-password"
                                name="password"
                                type={formState.showPassword.password ? 'text' : 'password'}
                                value={formState.values.password}
                                onChange={handleChange}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword('password')}
                                    >
                                    {formState.showPassword.password ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                            {hasError('password') && 
                                <FormHelperText id="password-error-text">
                                    {formState.errors.password[0]}
                                </FormHelperText> 
                            }
                        </FormControl>
                        <FormControl className={clsx(classes.margin, classes.textField)} error={hasError('confirmPassword')} fullWidth>
                            <InputLabel htmlFor="standard-adornment-password">Repetir contraseña</InputLabel>
                            <Input
                                className={classes.textField}
                                id="standard-adornment-password"
                                name="confirmPassword"
                                type={formState.showPassword.confirmPassword ? 'text' : 'password'}
                                value={formState.values.confirmPassword}
                                onChange={handleChange}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword('confirmPassword')}
                                    >
                                    {formState.showPassword.confirmPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                            {hasError('confirmPassword') && 
                                <FormHelperText id="password-error-text">
                                    {formState.errors.confirmPassword[0]}
                                </FormHelperText> 
                            }
                        </FormControl>
                        { authState.error.message !== null && (
                            <Typography color="error" className={classes.authErrorText}>
                                <Box fontWeight="fontWeightBold">
                                    { authState.error.message }
                                </Box>
                            </Typography>
                        )}
                        { authState.message !== null && authState.message !== 'Token válido' && (
                            <Typography color="primary" className={classes.authNotificationText}>
                                <Box fontWeight="fontWeightBold">
                                    { authState.message }
                                </Box>
                            </Typography>
                        )}
                        <Grid 
                            container 
                            direction="row"
                            justify="space-around"
                            alignItems="center"
                        >
                            <Grid item>
                                <Button
                                    fullWidth
                                    className={classes.button}
                                    color="primary"
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    disabled={ authState.error.message !== null || authState.isLoading }
                                >
                                    CAMBIAR
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    className={classes.button}
                                    size="large"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleOnCancel}
                                >
                                    CANCELAR
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </div>
    );
};

export default ChangePassword;