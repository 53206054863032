import { useState, useEffect } from 'react';
import { getLatestCertificateFormat } from '../services/certificatesService';

const useLatestCertificateFormat = () => {
    const [certificateFormat, setCertificateFormat] = useState(null);

    useEffect(() => {
        async function fetchLatestCertificateFormat() {
            try {
                const { data } = await getLatestCertificateFormat();
                setCertificateFormat(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchLatestCertificateFormat();
    }, []);

    return certificateFormat;
}

export default useLatestCertificateFormat;