import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { selectExtinguishers, getExtinguishersRequest } from '../../../../../../store/slices/extinguishersSlice';

import { deleteExtinguisher } from '../../../../../../services/extinguishersService';

import { SearchField } from '../../../../../../components/SearchField';

import { Can, Only } from '../../../../../../components/Can';

import { ROLES_KEYS } from '../../../../../../constants/roles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import TablePagination from '@material-ui/core/TablePagination';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import { datosGlobalesUrls } from '../../../../routing';

const useStyles = makeStyles(styles);

const ListaExtintores = (props) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [extinguisherClickedData, setExtinguisherClickedData] = useState({
        openDialog: false,
        extinguisher: null,
    });
    const dispatch = useDispatch();
    const extinguishers = useSelector(selectExtinguishers);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onGetSearchTerm = (term) => {
        setSearchTerm(term);
        setPage(0);
    }

    const onClickAddExtinguisher = () => {
        const { history } = props;
        history.push(datosGlobalesUrls.AddExtinguisher);
    }

    const onEditExtinguisher = (extinguisher) => () => {
        const { history } = props;
        history.push(datosGlobalesUrls.EditExtinguisher.replace(':extinguisher_id', extinguisher.id));
    }

    const onDeleteExtinguisher = (extinguisher) => () => {
        setExtinguisherClickedData({
            openDialog: true,
            extinguisher: extinguisher,
        });
    }

    const handleCloseDeleteDialog = () => {
        setExtinguisherClickedData({
            openDialog: false,
            extinguisher: null,
        });
    }

    const handleExtinguisherDelete = async () => {
        try {
            const extinguisherId = extinguisherClickedData.extinguisher.id;
            await deleteExtinguisher(extinguisherId);
            setExtinguisherClickedData({
                openDialog: false,
                extinguisher: null,
            });
            dispatch(getExtinguishersRequest({
                page: page + 1,
                page_limit: rowsPerPage,
                filters: {
                    searchTerm: searchTerm !== '' ? searchTerm : undefined,
                },
            }));
        } catch (e) {
            const { response } = e;
            console.log(response);
        }
    }

    useEffect(() => {
        dispatch(getExtinguishersRequest({
            page: page + 1,
            page_limit: rowsPerPage,
            filters: {
                searchTerm: searchTerm !== '' ? searchTerm : undefined,
            },
        }));
    }, [dispatch, page, rowsPerPage, searchTerm]);

    return (
        <div className={classes.root}>
            <Dialog
                open={extinguisherClickedData.openDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-activity-delete-title"
                aria-describedby="alert-dialog-activity-delete-description"
            >
                <DialogTitle id="alert-dialog-activity-delete-title">{"Confirmación"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-activity-delete-description">
                        ¿Estás seguro de querer continuar con la eliminación del registro seleccionado?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleExtinguisherDelete} color="primary" autoFocus>
                        Continuar
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.fullWidth} spacing={2}>
                <Grid item md={6} xs={12} className={classes.searchInput}>
                    <SearchField onClickSearch={onGetSearchTerm} />
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" justify="space-between" className={classes.fullWidth}>
                <Grid item>
                    <Typography component={"span"}>
                        <Box fontWeight="fontWeightBold" fontSize={25}>Extintores</Box>
                    </Typography>
                </Grid>
                <Grid item>
                    <Can>
                        <Only roles={[ROLES_KEYS.admin]}>
                            <Button 
                                variant="contained" 
                                color="secondary" 
                                className={classes.addDeviationButton} 
                                disableElevation
                                onClick={onClickAddExtinguisher}
                            >
                                Añadir extintor 
                            </Button>
                        </Only>
                    </Can>
                </Grid>
            </Grid>
            <Paper elevation={0}>
                <TableContainer>
                    <Table className={classes.table} aria-label="min-wages-table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Nombre del extintor</TableCell>
                                <TableCell align="left">Capacidad</TableCell>
                                <TableCell align="left">Tipo de fuego</TableCell>
                                <Can>
                                    <Only roles={[ROLES_KEYS.admin]}>
                                        <TableCell align="right">Acciones</TableCell>
                                    </Only>
                                </Can>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {extinguishers.extinguishers.map((item, key) => (
                                <TableRow key={`extinguishers-list-key-${key}`}>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {item.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {item.capacity}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {item.fire_type.split(',').sort().join(' - ')}
                                        </Typography>
                                    </TableCell>
                                    <Can>
                                        <Only roles={[ROLES_KEYS.admin]}>
                                            <TableCell align="right">
                                                <Grid container direction="row" alignItems="center" justify="flex-end" spacing={1}>
                                                    <Grid item>
                                                        <IconButton aria-label="edit" className={classes.iconButton} onClick={onEditExtinguisher(item)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton aria-label="delete" className={classes.iconButton} onClick={onDeleteExtinguisher(item)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </Only>
                                    </Can>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={1}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage="Filas por página:"
                        labelDisplayedRows={() => {
                            return `Página: ${page + 1} de ${Math.ceil(1 / rowsPerPage)}`;
                        }}
                    />
                </TableContainer>
            </Paper>
        </div>
    );
}

export default ListaExtintores;