import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setSelectedTab, selectGlobalDataLists } from '../../../../store/slices/globalDataListsSlice';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

import { StyledTab } from '../../../../components/StyledTab';
import { StyledTabs } from '../../../../components/StyledTabs';
import { TabPanel } from '../../../../components/TabPanel';

import { 
    ListaActividades,
    ListaCertificados,
    ListaDesviaciones,
    ListaExtintores,
    ListaNivel,
    ListaSalarioMinimo,
} from './Listas';

const useStyles = makeStyles(styles);

const ListadosDatosGlobales = (props) => {
    const globalDataLists = useSelector(selectGlobalDataLists);
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleTabChange = (event, newValue) => {
        dispatch(setSelectedTab({ selectedTab: newValue }));
    }

    return (
        <div>
            <StyledTabs value={globalDataLists.selectedTab} onChange={handleTabChange} aria-label="global-data-lists-tabs" variant="scrollable">
                <StyledTab label="Salario mínimo"/>
                <StyledTab label="Nivel"/>
                <StyledTab label="Actividades"/>
                <StyledTab label="Desviaciones"/>
                <StyledTab label="Formato de certificado"/>
                <StyledTab label="Extintores"/>
            </StyledTabs>
            <div className={classes.panelsContainer}>
                <TabPanel value={globalDataLists.selectedTab} index={0}>
                    <ListaSalarioMinimo {...props} />
                </TabPanel>
                <TabPanel value={globalDataLists.selectedTab} index={1}>
                    <ListaNivel {...props} />
                </TabPanel>
                <TabPanel value={globalDataLists.selectedTab} index={2}>
                    <ListaActividades {...props} />
                </TabPanel>
                <TabPanel value={globalDataLists.selectedTab} index={3}>
                    <ListaDesviaciones {...props} />
                </TabPanel>
                <TabPanel value={globalDataLists.selectedTab} index={4}>
                    <ListaCertificados {...props} />
                </TabPanel>
                <TabPanel value={globalDataLists.selectedTab} index={5}>
                    <ListaExtintores {...props} />
                </TabPanel>
            </div>
        </div>
    );
}

export default ListadosDatosGlobales;