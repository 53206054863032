import { useEffect, useState } from 'react';
import { getNeighborhoods } from '../services/neighborhoodsService';

const useNeighborhoods = (page, page_limit, filters) => {
    const [neighborhoods, setNeighborhoods] = useState([]);

    useEffect(() => {
        async function fetchNeighborhoods() {
            try {
                const { data: { results } } = await getNeighborhoods(page, page_limit, filters);
                setNeighborhoods(results);
            } catch(e) {
                const{ response } = e;
                console.log(response);
            }
        }
        fetchNeighborhoods();
    }, [page, page_limit, filters]);

    return neighborhoods;
};

export default useNeighborhoods;