import httpClient from '../http';

export const uploadImage = (image) => {
    const formData = new FormData();
    formData.append("files", image);
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    };
    return httpClient.post('/users/image', formData, config);
}