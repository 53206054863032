export default ({
  password: {
    presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    length: {
      maximum: 60,
      tooLong: 'La contraseña debe contener máximo %{count} caracteres',
      minimum: 8,
      tooShort: 'La contraseña debe tener mínimo %{count} caracteres',
    },
    fulfillPasswordStandards: true,
  },
  confirmPassword: {
    presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    equality: {
      attribute: 'password',
      message: 'Las contraseñas no coinciden.',
    },
    length: {
      maximum: 60,
      tooLong: 'La contraseña debe contener máximo %{count} caracteres',
      minimum: 8,
      tooShort: 'La contraseña debe tener mínimo %{count} caracteres',
    },
    fulfillPasswordStandards: true,
  },
});