export default theme => ({
    fullWidth: {
        width: '100%',
    },
    formControl: {
        width: '100%'
    },
    formControlStation: {
        marginTop: '2px',
        width: '96%',
    },
    filterButton: {
        width: '100px',
        marginTop: '5px',
    },
})