export default theme => ({
    root: {
        width: '100%',
        height: '100%',
        border: '1px solid rgba(150, 194, 201, 0.5)',
        borderRadius: '3%',
        boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)',
    },
    container: {
        height: '100%',
    },
    cardText: {
        color: '#5C5C5C',
        padding: '25px',
    },
    button: {
        width: '75%',
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
});