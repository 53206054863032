import React from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import { RISK_TYPES } from '../../../../../constants/risk_types';

import { Form, Field } from 'formik';

const useStyles = makeStyles(styles);

const ListRiskAgentsFilterForm = (props) => {
    const classes = useStyles();

    return (
        <Form>
            <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                <Grid item className={classes.fullWidth}>
                    <Field
                        name="document"
                        as={TextField}
                        className={classes.formControl}
                        variant="outlined"
                        label="N. de identificación"
                        size="small"
                    />
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <Field
                        name="name"
                        as={TextField}
                        className={classes.formControl}
                        variant="outlined"
                        label="Nombre y apellido"
                        size="small"
                    />
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <Field
                        name="email"
                        as={TextField}
                        className={classes.formControl}
                        variant="outlined"
                        label="Correo electrónico"
                        size="small"
                    />
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <FormControl variant="outlined" className={classes.formControl} size="small">
                        <InputLabel id="risk-user-type-label">Tipo de ubicación</InputLabel>
                        <Field
                            name="risk_user_type"
                            as={Select}
                            labelId="risk-user-type-label"
                            id="risk-user-type-select"
                            label="Tipo de ubicación"
                        >
                            {Object.entries(RISK_TYPES).map(([risk_user_key, risk_user_name], index) => (
                                <MenuItem value={risk_user_key} key={`risk-user-key-${index}`}>{risk_user_name}</MenuItem>
                            ))}
                        </Field>
                    </FormControl>
                </Grid>
                <Grid item>
                    <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                        <Grid item>
                            <Button
                                name="apply"
                                variant="contained" 
                                color="primary" 
                                className={classes.filterButton} 
                                size="small" 
                                disableElevation
                                type="submit"
                            >
                                Aplicar
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button 
                                name="clean"
                                color="primary" 
                                size="small"
                                disableElevation
                                type="submit"
                            >
                                Limpiar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Form>
    );
} 

export default ListRiskAgentsFilterForm;