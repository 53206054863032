import React, { useState } from 'react';

import QrReader from 'react-qr-reader'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import clsx from 'clsx';
import isBefore from 'date-fns/isBefore';

import { Formik } from 'formik';
import schema from './ValidarCertificadoForm/schema';
import { ValidarCertificadoForm } from './ValidarCertificadoForm';

import { getAllInspections } from '../../../../../../services/GR/inspectionService';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const LoadingCertificate = (props) => {
    return (
        <Grid container direction="column" justify="center" alignItems="center">
            <Grid item>
                <CircularProgress />
            </Grid>
            <Grid item>
                <Typography component="span">
                    <Box fontWeight="fontWeightLight" m={1}>
                        {props.loadingText}
                    </Box>
                </Typography>
            </Grid>
        </Grid>
    );
}

const CertificateCheckComponent = (props) => {
    const classes = useStyles();
    const { isCertificateValid, certificateMessage, certificateUrl } = props;

    const onClickSeeCertificate = () => {
        window.open(certificateUrl, '_blank');
    }

    return (
        <Grid container direction="column" spacing={1} justify="center" alignItems="center">
            <Grid item>
                { isCertificateValid ? (
                    <CheckCircleOutlineRoundedIcon className={clsx(classes.icon, classes.sucessColor)} />
                ) : (
                    <ErrorOutlineRoundedIcon className={clsx(classes.icon, classes.errorColor)} />
                )}
            </Grid>
            <Grid item>
                <Typography component="span" className={classes.certificateCheckText}>
                    <Box fontWeight="fontWeightLight" m={1} fontSize={20}>
                        { certificateMessage }
                    </Box>
                </Typography>
            </Grid>
            { certificateUrl !== null && certificateUrl !== undefined && (
            <Grid item>
                <Button color="primary" onClick={onClickSeeCertificate}>Ver certificado</Button>
            </Grid>
            )}
        </Grid>
    );
}

const ValidarCertificado = (props) => {
    const classes = useStyles();
    const [dialogData, setDialogData] = useState({
        open: false,
        inspection: null,
        isCertificateValid: false,
        certificateDialogMessage: '',
    });
    const [readerError, setReaderError] = useState('');

    const isCertificateValid = (inspection) => {
        return inspection && inspection.certified && isBefore(new Date(inspection.inspection_date), new Date());
    }

    const determineCertificateMessage = (inspection) => {
        if (!inspection || !inspection.certified) {
            return 'Certificado inválido';
        } else if (!isBefore(new Date(inspection.inspection_date), new Date())) {
            return 'Certificado expirado';
        }
        return 'Certificado válido';
    }

    const handleScan = async data => {
        if (!data) {
            return;
        }

        setDialogData(currentData => ({
            ...currentData,
            inspection: null,
            open: true,
        }));
        window.navigator.vibrate(200);
        const inspections = await getAllInspections({
            inspection_number: data,
        });
        const inspection = inspections.data[0];
        // TODO: Aplicar validaciones acá.
        setDialogData(currentData => ({
            ...currentData,
            inspection,
            isCertificateValid: isCertificateValid(inspection),
            certificateDialogMessage: determineCertificateMessage(inspection),
        }));
    }

    const handleSubmit = async (values) => {
        setDialogData(currentData => ({
            ...currentData,
            inspection: null,
            open: true,
        }));
        const { cert_number } = values;
        const inspections = await getAllInspections({
          cert_number,
        });
        const inspection = inspections.data[0];
        // TODO: Aplicar validaciones acá.
        setDialogData(currentData => ({
            ...currentData,
            inspection,
            isCertificateValid: isCertificateValid(inspection),
            certificateDialogMessage: determineCertificateMessage(inspection),
        }));
    }

    const resetDialog = () => {

        // La razón por la que sólo cambiamos open es porque al volver a escanear 
        // inspection cambia a null, de esta forma al cerrar el dialog sucede de una forma más
        // suavemente con respecto a la ventana actual (no se muestra ventana de verificación por 1 segundo al cerrar).
        setDialogData(currentData => ({
            ...currentData,
            open: false,
        }));
    }

    const handleScanError = (error) => {
        console.log(error.code + " - " + error.message + " - " + error.name);
        switch(error.name) {
            case 'NotFoundError':
                setReaderError('No se encontró ninguna cámara.');
                break;
            case 'NotAllowedError':
                setReaderError('Acceso a cámara negado.');
                break;
            default:
                setReaderError('');
                break;
        }
    }

    return (
        <div className={classes.root}>
            <Dialog
                open={dialogData.open}
                onClose={resetDialog}
            >
                <DialogContent>
                    {dialogData.inspection === null ? (
                        <LoadingCertificate loadingText="Verificando certificado..." />
                    ) : (
                        <CertificateCheckComponent 
                            isCertificateValid={dialogData.isCertificateValid} 
                            certificateMessage={dialogData.certificateDialogMessage} 
                            certificateUrl={dialogData.inspection !== undefined ? dialogData.inspection.cert_url : null}
                        />
                    )}
                </DialogContent>
            </Dialog>
            <QrReader
                delay={!dialogData.open ? 300 : false}
                onError={handleScanError}
                onScan={handleScan}
                className={classes.scanner}
            />
            <Typography component="span">
                <Box fontWeight="fontWeightLight" m={1}>
                    {readerError}
                </Box>
            </Typography>
            <Divider />
            <Typography component="span">
                <Box fontWeight="fontWeightLight" m={1}>
                    {'O puede verificar introduciendo el número de certificado: '}
                </Box>
            </Typography>
            <Formik
                validationSchema={schema}
                initialValues={{
                  cert_number: '',
                }}
                component={ValidarCertificadoForm}
                onSubmit={handleSubmit}
            />
        </div>
    );
}

export default ValidarCertificado;