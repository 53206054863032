import {
    StationUserList,
    AddStationUser,
    EditStationUser,
} from './Pages';

import routesUrls from '../../routes/urls';

export const stationUserUrls = {
    Listado: `${routesUrls.EstacionUsuarios}`,
    AddStationUser: `${routesUrls.EstacionUsuarios}/crear-usuario`,
    EditStationUser: `${routesUrls.EstacionUsuarios}/editar-usuario/:user_id`,
}

const stationUsersRouting = [{
    component: StationUserList,
    path: stationUserUrls.Listado,
}, {
    component: AddStationUser,
    path: stationUserUrls.AddStationUser,
}, {
    component: EditStationUser,
    path: stationUserUrls.EditStationUser,
}];

export default stationUsersRouting;