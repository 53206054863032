export default (theme) => ({
    avatarLarge: {
        width: theme.spacing(6),
        height: theme.spacing(6),
    },
    dropdownArrowIconContainer: {
        color: "#5C5C5C",
    },
    userNameText: {
        color: "#5C5C5C",
        marginLeft: "5px",
    },
    userRoleText: {
        color: "#5C5C5C",
        marginLeft: "5px",
        marginTop: "-5px",
    },
    dropdownItem: {
        fontSize: "13px",
        padding: "10px 20px",
        margin: "0 5px",
        borderRadius: "2px",
        WebkitTransition: "all 150ms linear",
        MozTransition: "all 150ms linear",
        OTransition: "all 150ms linear",
        MsTransition: "all 150ms linear",
        transition: "all 150ms linear",
        display: "block",
        clear: "both",
        fontWeight: "400",
        lineHeight: "1.42857143",
        color: "#5C5C5C",
        whiteSpace: "nowrap",
        height: "unset",
        width: "100%",
        minHeight: "unset",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "#FFF",
        }
    },
    popper: {
        width: "200px",
    },
});