import httpClient from '../http';
import qs from 'qs';

export const getAllCities = async () => {
    return await httpClient.get('/cities/all');
}

export const getCityById = async (city_id) => {
    return await httpClient.get(`/cities/${city_id}`);
}

export const deleteCityById = async (city_id) => {
    return await httpClient.delete(`/cities/${city_id}`);
}

export const getCities = async (page, page_limit, filters) => {
    const filterParams = {
        page,
        page_limit,
        ...filters
    };
    const queryParams = qs.stringify(filterParams);
    return await httpClient.get(`/cities?${queryParams}`);
}

export const createCity = async (city) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedCity = qs.stringify(city);
    return await httpClient.post('/cities', serializedCity, config);
}

export const updateCity = async (city_id, city) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedCity = qs.stringify(city);
    return await httpClient.put(`/cities/${city_id}`, serializedCity, config);
}