import { createSlice } from '@reduxjs/toolkit';

export const motorListsSlice = createSlice({
    name: 'motorLists',
    initialState: {
        selectedTab: 0,
    },
    reducers: {
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload.selectedTab;
        },
        resetListState: (state) => {
            state.selectedTab = 0;
        },
    },
});

export const {
    setSelectedTab,
    resetListState,
} = motorListsSlice.actions;

export const selectMotorLists = (state) => state.motorLists;

export default motorListsSlice.reducer;