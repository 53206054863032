import { useState, useEffect } from 'react';
import { getEventById } from '../services/eventsService';

const useEventById = (event_id) => {
    const [event, setEvent] = useState(null);

    useEffect(() => {
        async function fetchEvent() {
            try {
                const { data } = await getEventById(event_id);
                setEvent(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchEvent();
    }, [event_id]);

    return { event };
}

export default useEventById;