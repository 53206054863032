import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { resetListState } from '../../store/slices/globalDataListsSlice';

import datosGlobalesRouting, { datosGlobalesUrls } from './routing';
import routesUrls from '../../routes/urls';

const DatosGlobales = props => {
    const dispatch = useDispatch();

    // Ésto se coloca porque el listado guarda el estado del tab seleccionado para que 
    // al cambiar a cualquier de las páginas se vuelva a seleccionar el tab que estaba 
    // anteriormente. Pero no queremos que se persista al salir de datos globales.
    useEffect(() => {
        return () => {
            dispatch(resetListState());
        };
    }, [dispatch]);

    return (
        <div>
            <Switch>
                {datosGlobalesRouting.map((item, key) => (
                    <Route key={`datos-globales-${key}`} path={item.path} exact render={routeProps => (
                        <item.component {...routeProps} {...props} />
                    )}/>
                ))}
                <Redirect exact from={routesUrls.DatosGlobales} to={datosGlobalesUrls.Listado} />
            </Switch>
        </div>
    );
};

export default DatosGlobales;