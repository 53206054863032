export default (theme) => ({
    root: {
        marginLeft: "30px",
    },
    avatarLarge: {
        marginTop: theme.spacing(5),
        width: theme.spacing(25),
        height: theme.spacing(25),
    },
    editProfileFormContainer: {
        marginTop: theme.spacing(5),
    },
    form: {
        width: '40%',
    },
    textField: {
        width: '90%',
    },
    fields: {
        width: '50%',
    },
    row: {
        marginTop: '30px',
        width: '100%',
    },
    editButton: {
        width: '50%',
    },
});