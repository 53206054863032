import React, { useState } from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

import { Bar, Pie, Line } from "react-chartjs-2";

import useAllEmergencies from "../../../../../../hooks/ST/useAllEmergencies";
import useAllByEmergencies from "../../../../../../hooks/ST/useAllByEmergencies";

import { ChartEmergencyForm } from "../../../../Forms/Graficos/ChartEmergencyForm";
import ChartEmergencySchema from "../../../../Forms/Graficos/ChartEmergencyForm/schema";

import { Formik } from "formik";

import { makeStyles } from "@material-ui/styles";
import styles from "./styles";

const useStyles = makeStyles(styles);

const Graficos = () => {
  const classes = useStyles();
  const [emergenciesDateRange, setEmergenciesDateRange] = useState({
    start_date: new Date(),
    end_date: new Date(),
  });
  const [percentageOccurrence, setPercentageOccurrence] = useState({
    start_date: new Date(),
    end_date: new Date(),
  });
  const [percentageOccurrenceYear, setPercentageOccurrenceYear] = useState({
    start_date: new Date(),
    end_date: new Date(),
  });
  const [emergenciesDateRangeYear, setEmergenciesDateRangeYear] = useState({
    start_date: new Date(),
    end_date: new Date(),
  });
  const [countEmergenciesDate, setCountEmergenciesDate] = useState({
    start_date: new Date(),
    end_date: new Date(),
  });


  // Numero de emergencias por mes 
  const emergencies = useAllEmergencies(emergenciesDateRange);
  
  let months = {};
  emergencies.forEach((emergency) => {
    let date = new Date(emergency.report_date);
    let key =
    date.toLocaleString("es-CO", { month: "long" }).toUpperCase() +
    "-" +
    date.getFullYear();
    console.log(date.getFullYear());
    if (months[key] === undefined) {
      months[key] = 1;
    } else {
      months[key] += 1;
    }
  });
  
  const data = {
    labels: Object.keys(months),
    datasets: [
      {
        label: "# de Emergencias",
        data: Object.values(months),
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };


  const percentageOccurrenceEmer = useAllByEmergencies(percentageOccurrence);

  let namesEmer = Object.keys(percentageOccurrenceEmer);
  let numbersEmer = [];

  for(let name of namesEmer){
    numbersEmer.push(percentageOccurrenceEmer[name]?.length);
  }

  if(numbersEmer.length){
    const total = numbersEmer?.reduce((a, b) => a + b);
    numbersEmer = numbersEmer.map(val => {
      return val * 100 / total;
    })
  }

  const dataPie = {
    labels: namesEmer,
    datasets: [
      {
        label: "Ocurrencia porcentual de los eventos",
        data: numbersEmer,
        backgroundColor: [
          "rgba(255, 99, 132)",
          "rgba(255, 159, 64)",
          "rgba(255, 205, 86)",
          "rgba(75, 192, 192)",
        ],
        hoverOffset: 4,
      },
    ],
  };

  const percentageOccurrenceY = useAllEmergencies(percentageOccurrenceYear);
  let yearsPer = {};
  percentageOccurrenceY.forEach((emergency) => {
    let date = new Date(emergency.report_date);
    let key = date.getFullYear();
    if (yearsPer[key] === undefined) {
      yearsPer[key] = 1;
    } else {
      yearsPer[key] += 1;
    }
  });

  let valuesY = Object.values(yearsPer);
  if(valuesY.length){
    const total = valuesY?.reduce((a, b) => a + b);
    valuesY = valuesY.map(val => {
      return val * 100 / total;
    })
  }

  const dataPie2 = {
    labels: Object.keys(yearsPer),
    datasets: [
      {
        label: "Ocurrencia porcentual de eventos por año",
        data: valuesY,
        backgroundColor: [
          "rgba(255, 99, 132)",
          "rgba(255, 159, 64)",
          "rgba(255, 205, 86)",
          "rgb(54, 162, 235)",
        ],
        hoverOffset: 4,
      },
    ],
  };

  // Numero de emergencias por año
  const emergenciesYear = useAllEmergencies(emergenciesDateRangeYear);
  
  let years = {};
  emergenciesYear.forEach((emergency) => {
    let date = new Date(emergency.report_date);
    let key = date.getFullYear();
    if (years[key] === undefined) {
      years[key] = 1;
    } else {
      years[key] += 1;
    }
  });
  
  const line = {
    labels: Object.keys(years),
    datasets: [
      {
        label: "# de Emergencias",
        data: Object.values(years),
        fill: false,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
        ],
        tension: 0.1,
        borderWidth: 1,
      },
    ],
  };
  
  // Clases de eventos en cantidad
  const byEmergency = useAllByEmergencies(countEmergenciesDate);

  let namesEm = Object.keys(byEmergency);
  let numbersEm = [];
  
  for(let name of namesEm){
    numbersEm.push(byEmergency[name]?.length);
  }

  const dataLine = {
    labels: Object.keys(byEmergency),
    datasets: [{
      label: '# Eventos',
      data: numbersEm,
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      pointBackgroundColor: 'rgb(75, 192, 192)',
      pointRadius: 5,
      tension: 0.1
    }]
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        className={classes.emergencyDataContainer}
      >
        <Grid item xs={12} md={10}>
          <Typography component={"span"}>
            <Box fontWeight="fontWeightBold" fontSize={20}>
              Numero de emergencias por mes
            </Box>
          </Typography>
          <br />
          <Formik
            initialValues={emergenciesDateRange}
            component={ChartEmergencyForm}
            validationSchema={ChartEmergencySchema}
            onSubmit={(values) => {
              setEmergenciesDateRange(values);
            }}
          />
          <Bar data={data} options={options} />
          <br />
          <Divider className={classes.divider} />
          <br />
          <Typography component={"span"}>
            <Box fontWeight="fontWeightBold" fontSize={20}>
              Ocurrencia porcentual de los eventos
            </Box>
          </Typography>
          <br />
          <Formik
            initialValues={percentageOccurrence}
            component={ChartEmergencyForm}
            validationSchema={ChartEmergencySchema}
            onSubmit={(values) => {
              setPercentageOccurrence(values);
            }}
          />
          <Pie data={dataPie} />
          <br />
          <Divider className={classes.divider} />
          <br />
          <Typography component={"span"}>
            <Box fontWeight="fontWeightBold" fontSize={20}>
              Ocurrencia porcentual de eventos por año
            </Box>
          </Typography>
          <br />
          <Formik
            initialValues={percentageOccurrenceYear}
            component={ChartEmergencyForm}
            validationSchema={ChartEmergencySchema}
            onSubmit={(values) => {
              setPercentageOccurrenceYear(values);
            }}
          />
          <Pie data={dataPie2} />
          <br />
          <Divider className={classes.divider} />
          <br />
          <Typography component={"span"}>
            <Box fontWeight="fontWeightBold" fontSize={20}>
              Numero de emergencias por año
            </Box>
          </Typography>
          <br />
          <Formik
            initialValues={emergenciesDateRangeYear}
            component={ChartEmergencyForm}
            validationSchema={ChartEmergencySchema}
            onSubmit={(values) => {
              setEmergenciesDateRangeYear(values);
            }}
          />
          <Bar data={line} options={options} />
          <br />
          <Divider className={classes.divider} />
          <br />
          <Typography component={"span"}>
            <Box fontWeight="fontWeightBold" fontSize={20}>
              Clases de eventos en cantidad
            </Box>
          </Typography>
          <br />
          <Formik
            initialValues={countEmergenciesDate}
            component={ChartEmergencyForm}
            validationSchema={ChartEmergencySchema}
            onSubmit={(values) => {
              setCountEmergenciesDate(values);
            }}
          />
          <Line data={dataLine} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Graficos;
