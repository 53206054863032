import httpClient from '../http';
import qs from 'qs';

export const getAreas = async (page, page_limit, filters) => {
    const filterParams = {
        page,
        page_limit,
        ...filters
    };
    const queryParams = qs.stringify(filterParams);
    return await httpClient.get(`/areas?${queryParams}`);
}

export const createArea = async (area) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedArea = qs.stringify(area);
    return await httpClient.post('/areas', serializedArea, config);
}

export const updateArea = async (area_id, area) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedArea = qs.stringify(area);
    return await httpClient.put(`/areas/${area_id}`, serializedArea, config);
}

export const deleteAreasGroup = async (minwage_id, level_id) => {
    return await httpClient.delete(`/areas?minwage_id=${minwage_id}&level_id=${level_id}`);
}