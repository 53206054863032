import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import { ThemeProvider } from '@material-ui/styles';
import darkTheme from '../../themes/dark';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import {
    selectAuth,
} from '../../store/slices/authSlice';

import { useSelector } from 'react-redux';

import styles from './styles';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../assets/logos/newLogoHor.png';

import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import routesUrls from '../../routes/urls';

import './slide.css';

const useStyles = makeStyles(styles);

const backgrounds = [{
    src: 'https://images.unsplash.com/photo-1577468845449-fe85c4725017?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80',
}, {
    src: 'https://images.unsplash.com/photo-1592235905030-74b3fd573cca?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
}, {
    src: 'https://images.unsplash.com/photo-1503714964235-8954a5249c00?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
}];

const Auth = props => {
    const { children, history } = props;
    const [backgroundIndex, setBackgroundIndex] = useState(0);
    const auth = useSelector(selectAuth);

    const classes = useStyles();
    
    useEffect(() => {
        if (auth.token !== null) {
            history.replace(routesUrls.SignInPostConfig);
        }
    }, [auth, history]);

    const onBackgroundChange = (event) => {
        // La razón por la que tenemos que asignarlo como string es porque si lo hacemos como
        // enteros directamente, no se marca el radiobutton seleccionado.
        setBackgroundIndex(parseInt(event.target.value));
    }

    useEffect(() => {
        let interval = setInterval(() => {
            setBackgroundIndex((backgroundIndex + 1) % backgrounds.length);
        }, 3000);
        
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    });

    return (
        <ThemeProvider theme={darkTheme}>
            <Helmet>
                <title>Sipgerd - Admin Auth</title>
            </Helmet>
            <div className={classes.root}>
                <TransitionGroup>
                    <CSSTransition
                        classNames="slide"
                        timeout={{ enter: 5000, exit: 5000 }}
                        key={`background-caroulsel-index-${backgroundIndex}`}
                    >
                        <img src={backgrounds[backgroundIndex].src} className={classes.backgroundImage} alt="BackgroundImage" />
                    </CSSTransition>
                </TransitionGroup>
                <div className={classes.backgroundGradient}></div>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-end"
                    className={classes.containerGrid}
                >
                    <Grid item className={ history.location.pathname === '/auth/relief-corps' ? classes.logoRelief : classes.logo }>
                        <img src={logo} alt="WhiteLogo" width="330px"/>
                    </Grid>
                    <Grid item className={classes.forms}>
                        <main>{children}</main>
                    </Grid>
                    <Grid item className={ history.location.pathname === '/auth/relief-corps' 
                        ? classes.backgroundRadioButtonsRelief 
                        : classes.backgroundRadioButtons }
                    >
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="gender" name="gender1" value={backgroundIndex.toString()} onChange={onBackgroundChange}>
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <FormControlLabel value={"0"} control={<Radio color="default" />} />
                                    <FormControlLabel value={"1"} control={<Radio color="default" />} />
                                    <FormControlLabel value={"2"} control={<Radio color="default" />} />
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        </ThemeProvider>
    );
}

Auth.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
}

export default Auth;