import * as Yup from 'yup';

export default Yup.object().shape({
    cost_apply: Yup.boolean()
        .required('Éste campo es requerido.'),
    costs_intervention_hour: Yup.string()
        .when('cost_apply', (cost_apply, yup) => {
            return cost_apply 
                ? yup.required('Éste campo es requerido') 
                     .max(18, 'Máximo 18 dígitos.')
                : yup;
        }),
});