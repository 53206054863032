import { ALL_REPORT_TYPES } from '../../../../../constants/GR/reports';

export const requiredDateRange = (selectedType) => {
    const DateRangeRequiredTypes = [
        ALL_REPORT_TYPES.companies_with_deviations,
        ALL_REPORT_TYPES.companies_without_deviations,
        ALL_REPORT_TYPES.certified_companies,
        ALL_REPORT_TYPES.non_certified_companies,
        ALL_REPORT_TYPES.inspector_summary,
        ALL_REPORT_TYPES.general_report_inspectors,
        ALL_REPORT_TYPES.detailed_report,
        ALL_REPORT_TYPES.inspector_report,
    ];
    return DateRangeRequiredTypes.includes(ALL_REPORT_TYPES[selectedType]);
}

export const requiredInspection = (selectedType) => {
    const inspectionRequiredTypes = [
        ALL_REPORT_TYPES.deviations_report,
    ];
    return inspectionRequiredTypes.includes(ALL_REPORT_TYPES[selectedType]);
}

export  const requiredInspector = (selectedType) => {
    const inspectorRequiredTypes = [
        ALL_REPORT_TYPES.inspector_summary,
        ALL_REPORT_TYPES.inspector_report,
    ];
    return inspectorRequiredTypes.includes(ALL_REPORT_TYPES[selectedType]);
}

export const requiredMinwageYear = (selectedType) => {
    const minwageYearRequiredTypes = [
        ALL_REPORT_TYPES.general_table_year,
    ];
    return minwageYearRequiredTypes.includes(ALL_REPORT_TYPES[selectedType]);
}

export const requiredRequestDate = (selectedType) => {
    const requiredRequestDateTypes = [
        ALL_REPORT_TYPES.schedule_day,
    ];
    return requiredRequestDateTypes.includes(ALL_REPORT_TYPES[selectedType]);
}