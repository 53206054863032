export default theme => ({
    saveButton: {
        marginTop: '20px',
        width: '170px',
    },
    formControl: {
        width: '100%',
        '& .Mui-disabled input, & .Mui-disabled div[class*="MuiSelect-"], & .Mui-disabled textarea': {
            color: "rgba(0,0,0,0.6)"
        },
    },
    row: {
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    fullWidth: {
        width: '103%',
    },
    topSpacing: {
        marginTop: '30px',
    },
});