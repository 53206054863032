import React from 'react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { Form, Field, ErrorMessage } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const EmergencyAttendanceReportForm = (props) => {
    const classes = useStyles();

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    };

    return (
        <Form>
            <Grid container direction="row" spacing={1} justify="flex-start" alignItems="center">
                <Grid item>
                    <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={props.goBack}>
                        <ArrowBackRoundedIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <Typography component={"span"}>
                        <Box fontWeight="fontWeightBold" fontSize={25}>Informe de atención de Emergencia</Box>
                    </Typography>
                </Grid>
            </Grid>
            <br />
            <Grid container direction="column" spacing={2} justify="flex-start" alignItems="flex-start">
                <Grid item className={classes.row}>
                    <Grid container direction="row" spacing={2} justify="flex-start" alignItems="flex-start">
                        <Grid item xs={12} md={6}>
                            <Field
                                name="report"
                                as={TextField}
                                className={classes.formControl}
                                variant="outlined"
                                label="Identificación"
                                type="text"
                                error={hasError("report")}
                                helperText={<ErrorMessage name="report" />}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Button
                        className={classes.downloadButton}
                        color="primary"
                        size="large"
                        type="submit"
                        variant="contained"
                        disableElevation
                    >
                        Consultar
                    </Button>
                </Grid>
            </Grid>
        </Form>
    );
}

export default EmergencyAttendanceReportForm;