import * as Yup from 'yup';
import emailValidator from 'email-validator';

export default Yup.object().shape({
    name: Yup.string()
        .max(100, 'Máximo 100 caracteres.')
        .required('Éste campo es requerido.'),
    document: Yup.string()
        .max(16, 'Máximo 16 caracteres.')
        .required('Éste campo es requerido.')
        .test(
            'document-already-loaded',
            'La identificación ya se encuentra cargada.',
            function (document) {
                const witnesses = this.options.context.witnesses;
                const witness_document_found = witnesses.find(witness => witness.document === document);
                return witness_document_found === undefined;
            },
        ),
    phone: Yup.string()
        .max(13, 'Máximo 13 dígitos.')
        .required('Éste campo es requerido.'),
    address: Yup.string()
        .max(60, 'Máximo 60 caracteres.')
        .required('Éste campo es requerido.'),
    email: Yup.string()
        .required('Éste campo es requerido.')
        .max(60, 'Máximo 60 caracteres.')
        .test(
            'invalid-email-format',
            'Correo inválido.',
            function (email) {
                return emailValidator.validate(email);
            }
        ),
});