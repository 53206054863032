import * as Yup from 'yup';

export default Yup.object({
    tax_date: Yup.date()
        .required('Éste campo es requerido.'),
    tax_form_number: Yup.string()
        .max(20, 'Máximo 20 dígitos.')
        .required('Éste campo es requerido'),
    tax_value: Yup.string()
        .max(16, 'Máximo 16 dígitos.')
        .required('Éste campo es requerido.'),
    soat_expedition_date: Yup.date()
        .required('Éste campo es requerido.') ,
    soat_due_date: Yup.date()
        .required('Éste campo es requerido.'),
    tm_expedition_date: Yup.date()
        .required('Éste campo es requerido.') ,
    tm_due_date: Yup.date()
        .required('Éste campo es requerido.'),
});