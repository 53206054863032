import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';
import useDepartmentById from '../../../../hooks/useDepartmentById';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import validate from 'validate.js';
import schema from './schema';

import { configurationUrls } from '../../routing';

import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';
import { updateDepartment } from '../../../../services/departmentService';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const EditDepartment = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { department_id } = useParams();
    const { department } = useDepartmentById(department_id);
    const {
        backendResponse: updateDepartmentResponse,
        executeService: updateDepartmentService,
    } = useBackendMessageResponse(updateDepartment);
    const [departmentForm, setDepartmentForm] = useState({
        isValid: false,
        isSubmitted: false,
        values: {
            name: '',
        },
        touched: {
            name: false,
        }, 
        errors: {},
    });

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    const hasError = field =>
        (departmentForm.isSubmitted || departmentForm.touched[field]) && departmentForm.errors[field] ? true : false;

    const handleChange = event => {
        event.persist();

        setDepartmentForm(currentDepartmentForm => ({
            ...currentDepartmentForm,
            values: {
                ...currentDepartmentForm.values,
                [event.target.name]: event.target.value,
            },
            touched: {
                ...currentDepartmentForm.touched,
                [event.target.name]: true
            },
        }));
    };

    const onSubmitEditDepartment = async (event) => {
        event.preventDefault();

        if (!departmentForm.isSubmitted) {
            setDepartmentForm(currentDepartmentForm => ({
                ...currentDepartmentForm,
                isSubmitted: true,
            }));
        }

        if (!departmentForm.isValid) {
            return;
        }

        const department = departmentForm.values;
        await updateDepartmentService(department_id, department);
    }

    useEffect(() => {
        if (updateDepartmentResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            severity: 'success',
            message: updateDepartmentResponse.message,
        }));
        props.history.push(configurationUrls.Listado);
    }, [dispatch, props.history, updateDepartmentResponse.message]);

    useEffect(() => {
        if (updateDepartmentResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            severity: 'error',
            message: updateDepartmentResponse.error.message,
        }));
    }, [dispatch, updateDepartmentResponse.error]);

    useEffect(() => {
        if(department === null) {
            return;
        }

        setDepartmentForm(currentDepartmentForm => ({
            ...currentDepartmentForm,
            isValid: true,
            values: {
                name: department.name,
            },
        }));
    }, [department]);

    useEffect(() => {
        const errors = validate(departmentForm.values, schema);

        setDepartmentForm(currentDepartmentForm => ({
            ...currentDepartmentForm,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [departmentForm.values, departmentForm.isSubmitted]);

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Editar departamento</Box>
            </Typography>
            <form onSubmit={onSubmitEditDepartment} className={classes.form}>
                <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
                    <Grid item>
                        <TextField 
                            id="department-name-input" 
                            label="Nombre del departamento" 
                            variant="outlined" 
                            className={classes.formControl} 
                            error={hasError('name')}
                            helperText={
                                hasError('name') ? departmentForm.errors.name[0] : null
                            }
                            name="name"
                            value={departmentForm.values.name}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Button
                    className={classes.saveButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disableElevation
                >
                    Guardar
                </Button>
            </form>
        </div>
    );
}

export default EditDepartment;