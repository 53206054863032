import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HttpStatus from 'http-status-codes';

import { authRequest, authResetState } from '../../store/slices/authSlice';

import clsx from 'clsx';
import validate from 'validate.js';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

import routesUrls from '../../routes/urls';

import schema from './schema';

import { selectAuth } from '../../store/slices/authSlice';

const useStyles = makeStyles(styles);

const SignIn = (props) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const authState = useSelector(selectAuth);

    const { history } = props;

    const [formState, setFormState] = useState({
        isValid: false,
        showPassword: false,
        isSubmitted: false,
        values: {
            email: '',
            password: '',
        },
        touched: {
            email: false,
            password: false,
        }, 
        errors: {
            email: [],
            password: [],
        }
    });

    const hasError = field =>
        (formState.isSubmitted || formState.touched[field]) && formState.errors[field] ? true : false;

    useEffect(() => {
        const errors = validate(formState.values, schema);

        setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [formState.values, formState.isSubmitted]);

    useEffect(() => {
        if(authState.token !== null) {
            history.push(routesUrls.SignInPostConfig);
        }
    }, [authState, history]);

    useEffect(() => {
      if (authState.token === null && authState.isLoading) {
        dispatch(authResetState());
      }
    // eslint-disable-next-line
    }, []);

    const handleClickShowPassword = () => {
        setFormState(currentState => ({ ...currentState, showPassword: !currentState.showPassword }));
    };

    const handleChange = event => {
        event.persist();

        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]: event.target.value,
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            }
        }));
  };

    const handleSignIn = async event => {
        event.preventDefault();
        if(!formState.isSubmitted) {
            setFormState(formState => ({
                ...formState,
                isSubmitted: true,
            }));
        }
        if(!formState.isValid) {
            return;
        }
        const data = {
            email: formState.values.email.toLocaleLowerCase(),
            password: formState.values.password,
        };
        dispatch(authRequest(data))
    };

    const [blockedUserState, setBlockedUserState] = useState({
        isBlocked: false,
        remainingTime: 0,
    });

    const handleBlockedUserCounter = (authError) => {
        const messageSplitted = authError.split(" ");
        // const blockedTimeSeconds = messageSplitted[3];
        messageSplitted.splice(3, 1); // Delete time from message.
        messageSplitted.splice(3, 0, blockedUserState.remainingTime); // Append formatted element.
        return messageSplitted.join(" ");
    };

    const extractBlockedTime = (authError) => {
        const messageSplitted = authError.split(" ");
        return messageSplitted[3];
    };

    const handleOnReliefCorps = () => {
        history.push(routesUrls.ReliefCorps);
    };

    useEffect(() => {
        let interval = null;
        if (authState.error.code === HttpStatus.TOO_MANY_REQUESTS) {
            if (blockedUserState.isBlocked && blockedUserState.remainingTime !== 0) {
                interval = setInterval(() => {
                    setBlockedUserState((blockedState) => ({
                        isBlocked: true,
                        remainingTime:  blockedState.remainingTime - 1,
                    }));
                }, 1000);
            } else if (blockedUserState.isBlocked && blockedUserState.remainingTime === 0) {
                setBlockedUserState(() => ({
                    isBlocked: false,
                    remainingTime: 0,
                }));
                dispatch(authResetState());
            } else if(authState.error.message !== null) {
                setBlockedUserState(() => ({
                    isBlocked: true,
                    remainingTime: extractBlockedTime(authState.error.message),
                }));
            }
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [authState, blockedUserState, dispatch]);

    return (
        <div className={classes.root}>
            <Grid className={classes.grid} container>
                <Grid className={classes.content} item xs={12}>
                    <div className={classes.contentBody}>
                        <form onSubmit={handleSignIn} className={classes.form}>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                error={hasError('email')}
                                helperText={
                                    hasError('email') ? formState.errors.email[0] : null
                                }
                                label="Correo electrónico"
                                name="email"
                                onChange={handleChange}
                                type="text"
                                value={formState.values.email}
                            />
                            <FormControl className={clsx(classes.margin, classes.textField)} error={hasError('password')} fullWidth>
                                <InputLabel htmlFor="standard-adornment-password">Contraseña</InputLabel>
                                <Input
                                    className={classes.textField}
                                    id="standard-adornment-password"
                                    name="password"
                                    type={formState.showPassword ? 'text' : 'password'}
                                    value={formState.values.password}
                                    onChange={handleChange}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                        >
                                        {formState.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                />
                                {hasError('password') && 
                                    <FormHelperText id="password-error-text">
                                        {formState.errors.password[0]}
                                    </FormHelperText> 
                                }
                            </FormControl>
                            { formState.isSubmitted && authState.error.message !== null && (
                                <Typography color="error" className={classes.authErrorText}>
                                    <Box fontWeight="fontWeightBold">
                                        { authState.error.code !== HttpStatus.TOO_MANY_REQUESTS 
                                            ? authState.error.message
                                            : handleBlockedUserCounter(authState.error.message)
                                        }
                                    </Box>
                                </Typography>
                            )}
                            <Button
                                fullWidth
                                className={classes.signInButton}
                                color="primary"
                                size="large"
                                type="submit"
                                variant="contained"
                                disabled={authState.isLoading}
                            >
                                Ingresar
                            </Button>
                            <Button
                                fullWidth
                                className={classes.signInButton}
                                style={{backgroundColor: '#ffe250'}}
                                size="large"
                                type="button"
                                variant="contained"
                                onClick={handleOnReliefCorps}
                            >
                                Verficaciones
                            </Button>
                            <Typography className={classes.recoverPasswordText}>
                                <Box fontWeight="fontWeightBold" fontSize={14}>
                                    <Link href="/auth/password-recovery" style={{ color: '#FFFFFF' }}>
                                        ¿Olvidaste tu contraseña?
                                    </Link>
                                </Box>
                            </Typography>
                        </form>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default SignIn;