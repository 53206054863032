import { useState, useEffect } from 'react';
import { getMaintenances } from '../../services/MT/maintenancesService';

const useMaintenances = (page, page_limit) => {
    const [maintenances, setMaintenances] = useState([]);

    useEffect(() => {
        async function fetchMaintenances() {
            try {
                const { data: { results } } = await getMaintenances(page, page_limit);
                setMaintenances(results);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchMaintenances();
    }, [page, page_limit]);

    return maintenances;
}

export default useMaintenances;