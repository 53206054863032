import React from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import useDamageReports from '../../../../../../hooks/MT/useDamageReports';
import useVehicles from '../../../../../../hooks/MT/useVehicles';
import useMaintenances from '../../../../../../hooks/MT/useMaintenances';

import { Form, Field, ErrorMessage } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const DatosAvailabilityForm = (props) => {
    const classes = useStyles();

    // El 1000 viene porque necesitamos traernos todos los registros y se intenta reutilizar el mismo endpoint.
    // En caso de que existan más, habría que aumentar el número 1000 o crear un endpoint separado y consumirlo desde los hooks.
    const vehicles = useVehicles(1, 1000);
    const damageReports = useDamageReports(1, 1000);
    const maintenances = useMaintenances(1, 1000);

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Form>
                <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="report" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Reporte"
                                    className={classes.formControl} 
                                    error={hasError('report')}
                                    helperText={<ErrorMessage name="report" />}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl className={classes.formControl} variant="outlined" error={hasError('damage_report_id')}>
                                    <InputLabel>Reporte accidente</InputLabel>
                                    <Field 
                                        name="damage_report_id" 
                                        type="text"
                                        as={Select} 
                                        label="Reporte accidente"
                                        className={classes.formControl} 
                                    >
                                        {damageReports.map((report, key) => (
                                            <MenuItem key={`damage-report-key-${key}`} value={report.id}>{report.report}</MenuItem>
                                        ))}
                                    </Field>
                                    <FormHelperText>
                                        <ErrorMessage name="damage_report_id" />
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="novelties" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Novedades"
                                    className={classes.formControl} 
                                    error={hasError('novelties')}
                                    helperText={<ErrorMessage name="novelties" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl className={classes.formControl} variant="outlined" error={hasError('vehicle_id')}>
                                    <InputLabel>Código de vehículo</InputLabel>
                                    <Field 
                                        name="vehicle_id" 
                                        type="text"
                                        as={Select} 
                                        label="Código de vehículo"
                                        className={classes.formControl} 
                                    >
                                        {vehicles.map((vehicle, key) => (
                                            <MenuItem key={`vehicle-key-${key}`} value={vehicle.id}>{vehicle.code}</MenuItem>
                                        ))}
                                    </Field>
                                    <FormHelperText>
                                        <ErrorMessage name="vehicle_id" />
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                            <Grid item xs={12} md={6}>
                                <FormControl className={classes.formControl} variant="outlined" error={hasError('maintenance_id')}>
                                    <InputLabel>Reporte mantenimiento</InputLabel>
                                    <Field 
                                        name="maintenance_id" 
                                        type="text"
                                        as={Select}
                                        label="Reporte mantenimiento"
                                        className={classes.formControl} 
                                    >
                                        {maintenances.map((maintenance, key) => (
                                            <MenuItem key={`maintenance-key-${key}`} value={maintenance.id}>{maintenance.report}</MenuItem>
                                        ))}
                                    </Field>
                                    <FormHelperText>
                                        <ErrorMessage name="maintenance_id" />
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="date" 
                                    as={KeyboardDatePicker} 
                                    label="Fecha"
                                    className={classes.formControl} 
                                    inputVariant="outlined"
                                    variant="inline"
                                    InputAdornmentProps={{ position: "end" }}
                                    format="dd/MM/yyyy"
                                    onChange={(date) => props.setFieldValue("date", date)}
                                    error={hasError('date')}
                                    helperText={<ErrorMessage name="date" />}
                                    autoOk
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="note" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Nota"
                                    className={classes.formControl} 
                                    error={hasError('note')}
                                    helperText={<ErrorMessage name="note" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="location" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Ubicación"
                                    className={classes.formControl} 
                                    error={hasError('location')}
                                    helperText={<ErrorMessage name="location" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="time" 
                                    as={KeyboardTimePicker} 
                                    ampm={false}
                                    label="Hora" 
                                    className={classes.formControl} 
                                    inputVariant="outlined"
                                    variant="inline"
                                    InputAdornmentProps={{ position: "end" }}
                                    keyboardIcon={<AccessTimeIcon />}
                                    onChange={(date) => props.setFieldValue("time", date)}
                                    error={hasError('time')}
                                    helperText={<ErrorMessage name="time" />}
                                    autoOk
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="emergency" 
                                    type="number" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Emergencias atendidas"
                                    className={classes.formControl} 
                                    error={hasError('emergency')}
                                    helperText={<ErrorMessage name="emergency" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Button
                    className={classes.saveButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disableElevation
                >
                    Guardar
                </Button>
            </Form>
        </MuiPickersUtilsProvider>
    );
}

export default DatosAvailabilityForm;