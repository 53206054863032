import { useEffect, useState } from 'react';
import { getAllEducationLevels } from '../services/educacionLevelsService';

const useEducationLevels = () => {
    const [educationLevels, setEducationLevels] = useState([]);

    useEffect(() => {
        async function fetchAllEducationLevels() {
            try {
                const { data } = await getAllEducationLevels();
                setEducationLevels(data);
            } catch(e) {
                const{ response } = e;
                console.log(response);
            }
        }
        fetchAllEducationLevels();
    }, []);

    return educationLevels;
};

export default useEducationLevels;