import { createSlice } from '@reduxjs/toolkit';

export const usersSlice = createSlice({
    name: 'users',
    initialState: {
        isLoading: false,
        users: [],
        total: null,
        pages: null,
        page: 0,
        page_limit: 10,
        error: {
            code: null,
            message: null,
        },
        filters: {},
    },
    reducers: {
        getUsersRequest: (state) => {
            state.isLoading = true;
        },
        getUsersSuccess: (state, action) => {
            state.isLoading = false;
            state.users = action.payload.users;
            state.total = action.payload.total;
            state.page = action.payload.page;
            state.pages = action.payload.pages;
            state.page_limit = action.payload.page_limit;
            state.filters = action.payload.filters;
            state.error = {
                code: null,
                message: null,
            };
        },
        getUsersFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
    },
});

export const {
    getUsersRequest,
    getUsersSuccess,
    getUsersFailure,
} = usersSlice.actions;

export const selectUsers = (state) => state.users;

export default usersSlice.reducer;