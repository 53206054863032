export default theme => ({
    root: {
        padding: '25px',
    },
    goBackIcon: {
        marginLeft: '-15px',
    },
    form: {
        width: '100%',
    },
    formControl: {
        width: '100%',
    },
    row: {
        width: '70%',
        marginTop: '25px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    saveButton: {
        width: '150px',
    },
    authNotificationText: {
        marginTop: '25px',
    },
});