import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';

import useAllDeviationTypes from '../../../../../hooks/useAllDeviationTypes';

import { Form, Field } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const NoDeviationsComponent = () => {
    const classes = useStyles();

    return (
        <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
            <Grid item>
                <InfoIcon className={classes.noDeviationIcon} />
            </Grid>
            <Grid item>
                <Typography component="span" className={classes.noDeviationText}>
                    <Box fontWeight="fontWeightRegular">
                        {`No hay desviaciones cargadas acá.`}
                    </Box>
                </Typography>
            </Grid>
        </Grid>
    );
}

const ChecklistForm = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(0);
    const { deviationTypes } = useAllDeviationTypes();
    const { readMode } = props;

    const handleExpandChange = (index) => () => {
        setExpanded(index);
    }

    return (
        <div className={classes.root}>
            <Form>
                <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                    <Grid item className={classes.row}>
                        {deviationTypes.map((deviationType, index) => (
                            <Accordion expanded={expanded === index} onChange={handleExpandChange(index)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography className={classes.heading}>{deviationType.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {(() => {
                                        const deviations = props.values.checklist.filter(deviation => deviation.deviation_type_id === deviationType.id);
                                        if (deviations.length === 0) {
                                            return (
                                                <NoDeviationsComponent />
                                            );
                                        }

                                        const selectAllCheckBoxes = () => {
                                            return (
                                                <Grid
                                                    container 
                                                    direction="row" 
                                                    justify="center" 
                                                    alignItems="center" 
                                                    spacing={1}
                                                >
                                                    <Grid item xs={6} style={{textAlign: 'center'}}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={!deviations.some(deviation => !deviation.checked)}
                                                                    color="primary"
                                                                    onChange={(event) => {
                                                                        const newDeviations = deviations.map((deviation) => {
                                                                            deviation.checked = event.target.checked;
                                                                            if (event.target.checked && deviation.NA) {
                                                                                deviation.NA = false;
                                                                            }
                                                                            return deviation;
                                                                        });
                                                                        const currentDeviations = props.values.checklist;
                                                                        const modifiedDeviations = currentDeviations.map(currentDeviation => {
                                                                            const modifiedDeviation = newDeviations.find(d => d.id === currentDeviation.id);
                                                                            return modifiedDeviation !== undefined ? modifiedDeviation : currentDeviation;
                                                                        });
                                                                        props.setFieldValue('checklist', modifiedDeviations);
                                                                    }}
                                                                    disabled={readMode}
                                                                />
                                                            }
                                                            label={
                                                                <Typography>
                                                                    <Box fontWeight="fontWeightBold">
                                                                        Seleccionar todo
                                                                    </Box>
                                                                </Typography>
                                                            }
                                                            labelPlacement="top"
                                                            style={{
                                                                marginLeft: '-25px',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} style={{textAlign: 'center'}}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={!deviations.some(deviation => !deviation.NA)}
                                                                    color="primary"
                                                                    onChange={(event) => {
                                                                        const newDeviations = deviations.map((deviation) => {
                                                                            deviation.NA = event.target.checked;
                                                                            if (event.target.checked && deviation.checked) {
                                                                                deviation.checked = false;
                                                                            }
                                                                            return deviation;
                                                                        });
                                                                        const currentDeviations = props.values.checklist;
                                                                        const modifiedDeviations = currentDeviations.map(currentDeviation => {
                                                                            const modifiedDeviation = newDeviations.find(d => d.id === currentDeviation.id);
                                                                            return modifiedDeviation !== undefined ? modifiedDeviation : currentDeviation;
                                                                        });
                                                                        props.setFieldValue('checklist', modifiedDeviations);
                                                                    }}
                                                                    disabled={readMode}
                                                                />
                                                            }
                                                            label={
                                                                <Typography>
                                                                    <Box fontWeight="fontWeightBold">
                                                                        Seleccionar todo
                                                                    </Box>
                                                                </Typography>
                                                            }
                                                            labelPlacement="top"
                                                            style={{
                                                                marginLeft: '-40px',
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            );
                                        }

                                        return (
                                            <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                                                <Grid item xs={12}>
                                                    {deviations.length > 0 && (
                                                        selectAllCheckBoxes()
                                                    )}
                                                    {deviations.map((deviation, key) => {
                                                        const deviationWholeSetIndex = props.values.checklist.findIndex(c => c.id === deviation.id);
                                                        return (
                                                            <Grid 
                                                                key={`deviation-key-${deviation.deviaton_type_id}-${deviation.id}`} 
                                                                container 
                                                                direction="row" 
                                                                justify="center" 
                                                                alignItems="center" 
                                                                spacing={1}
                                                            >
                                                                <Grid item xs={6} style={{textAlign: 'center'}}>
                                                                    <Tooltip title={props.values.checklist[deviationWholeSetIndex].norm} placement="right" arrow>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Field 
                                                                                    as={Checkbox} 
                                                                                    checked={props.values.checklist[deviationWholeSetIndex].checked} 
                                                                                    name={`checklist[${deviationWholeSetIndex}].checked`} 
                                                                                    color="primary"
                                                                                    onChange={(event) => {
                                                                                        props.setFieldValue(`checklist[${deviationWholeSetIndex}].checked`, event.target.checked);
                                                                                        if (event.target.checked && props.values.checklist[deviationWholeSetIndex].NA) {
                                                                                            props.setFieldValue(`checklist[${deviationWholeSetIndex}].NA`, false);
                                                                                        }
                                                                                        if (event.target.checked) {
                                                                                            props.setFieldValue('certified', false);
                                                                                            props.setFieldValue('remedied', false);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label={`${deviation.code}`}
                                                                            disabled={readMode}
                                                                        />
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item xs={6} style={{textAlign: 'center'}}>
                                                                    <Tooltip title={props.values.checklist[deviationWholeSetIndex].norm} placement="right" arrow>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Field 
                                                                                    as={Checkbox} 
                                                                                    checked={props.values.checklist[deviationWholeSetIndex].NA} 
                                                                                    name={`checklist[${deviationWholeSetIndex}].NA`} 
                                                                                    color="primary"
                                                                                    onChange={(event) => {
                                                                                        props.setFieldValue(`checklist[${deviationWholeSetIndex}].NA`, event.target.checked);
                                                                                        if (event.target.checked && props.values.checklist[deviationWholeSetIndex].checked) {
                                                                                            props.setFieldValue(`checklist[${deviationWholeSetIndex}].checked`, false);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            }
                                                                            disabled={readMode}
                                                                            label={`N/A`}
                                                                        />
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </Grid>
                                        );
                                    })()}
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>
                    <Grid item className={classes.row} style={{marginTop: '20px'}}>
                        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={1}>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Field 
                                            as={Checkbox} 
                                            checked={props.values.remedied} 
                                            name={`remedied`} 
                                            color="primary"
                                            onChange={(event) => {
                                                if (!props.values.certified) {
                                                    return;
                                                }
                                                props.setFieldValue('remedied', event.target.checked);
                                            }}
                                        />
                                    }
                                    label={`Subsanado`}
                                    disabled={readMode}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Field 
                                            as={Checkbox} 
                                            checked={props.values.certified} 
                                            name="certified"
                                            color="primary"
                                            onChange={(event) => {
                                                const isAnyDeviationChecked = props.values.checklist.some(deviation => deviation.checked === true);
                                                if (!isAnyDeviationChecked) {
                                                    props.setFieldValue('certified', event.target.checked);
                                                    props.setFieldValue('remedied', event.target.checked);
                                                }
                                            }}
                                        />
                                    }
                                    disabled={readMode}
                                    label={`Certificado`}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <div className={classes.wrapper}>
                    <Button
                        className={classes.saveButton}
                        color="primary"
                        size="large"
                        type="submit"
                        variant="contained"
                        disableElevation
                        disabled={readMode || props.isSubmitting}
                    >
                        Guardar
                    </Button>
                    {props.isSubmitting && ( 
                        <CircularProgress size={24} className={classes.buttonProgress} />
                    )}
                </div>
            </Form>
        </div>
    );
}

ChecklistForm.defaultProps = {
    readMode: false,
};

export default ChecklistForm;