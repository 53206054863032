import React from 'react';

import { Field, ErrorMessage } from 'formik';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MuiPhoneNumber from 'material-ui-phone-number';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

// Éste componente no contiene el 'Form' porque es necesario delegar eso a un componente padre
// en caso de que se quiera combinar con otros formularios en otras vistas y necesitan
// enviarse todos bajo el mismo submit. 
const StationForm = (props) => {
    const classes = useStyles();

    const { blockedForm } = props;

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    }

    return (
        <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
            <Grid item className={classes.fullWidth}>
                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                    <Grid item md={6} xs={12}>
                        <Field
                            name="station_name"
                            as={TextField}
                            className={classes.formControl}
                            variant="outlined"
                            label="Estación"
                            type="text"
                            error={hasError("station_name")}
                            helperText={<ErrorMessage name="station_name" />}
                            disabled={blockedForm}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Field
                            name="station_address"
                            as={TextField}
                            className={classes.formControl}
                            variant="outlined"
                            label="Dirección de la estación"
                            type="text"
                            error={hasError("station_address")}
                            helperText={<ErrorMessage name="station_address" />}
                            disabled={blockedForm}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.fullWidth}>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item md={6} xs={12}>
                        <Field
                            name="station_phone"
                            as={MuiPhoneNumber}
                            className={classes.formControl}
                            variant="outlined"
                            label="Teléfono de la estación"
                            type="text"
                            error={hasError("station_phone")}
                            helperText={<ErrorMessage name="station_phone" />}
                            onChange={(value) => props.setFieldValue('station_phone', value)}
                            disabled={blockedForm}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

StationForm.defaultProps = {
    blockedForm: false,
};

export default StationForm;