import * as Yup from 'yup';

import { requireOperator } from './helpers';

export default Yup.object().shape({
    type: Yup.string()
        .required('Éste campo es requerido.'),
    operator_id: Yup.number()
        .when('type', (type, yup) => {
            return requireOperator(type) 
                ? yup.required('Éste campo es requerido.')
                : yup;
        }),
});