import httpClient from '../http';
import qs from 'qs';

export const getDeviations = async (page, page_limit, filters) => {
    const filterParams = {
        page,
        page_limit,
        ...filters,
    };
    const queryParams = qs.stringify(filterParams);
    return await httpClient.get(`/deviations?${queryParams}`);
}

export const getAllDeviations = async () => {
    return await httpClient.get(`/deviations/all`);
}

export const getDeviationById = async (deviation_id) => {
    return await httpClient.get(`/deviations/${deviation_id}`);
}

export const createDeviation = async (deviation) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedDeviation = qs.stringify(deviation);
    return await httpClient.post('/deviations', serializedDeviation, config);
}

export const updateDeviation = async (deviation_id, deviation) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedDeviation = qs.stringify(deviation);
    return await httpClient.put(`/deviations/${deviation_id}`, serializedDeviation, config);
}

export const deleteDeviation = async (deviation_id) => {
    return await httpClient.delete(`/deviations/${deviation_id}`);
}