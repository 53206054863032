import httpClient from '../../http';
import qs from 'qs';

import { report_urls, report_modules } from '../../constants/MT/reports';

export const downloadMTReport = async (reportName, reportData) => {
    const reportModule = report_modules[reportName];
    if (reportModule === undefined) {
        throw new Error(`Nombre de reporte '${reportName}' inválido.`);
    }

    const reportUrl = report_urls[reportModule];

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        responseType: 'blob',
    };
    const serializedReportData = qs.stringify(reportData);
    return await httpClient.post(reportUrl, serializedReportData, config);
}