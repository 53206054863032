import React from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import useUsersByPosition from '../../../../../../../hooks/useUsersByPosition';

import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import { Field, ErrorMessage } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const SHIFTS = ["Matinal", "Vespertino", "Nocturno"];

const DamageReportIdForm = (props) => {
    const classes = useStyles();

    const users = useUsersByPosition("Operario");

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container direction="column" justify="flex-start" alignItems="center" spacing={2}>
                <Grid item className={classes.fullWidth}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Field 
                                name="report" 
                                type="text" 
                                as={TextField} 
                                variant="outlined" 
                                label="Reporte"
                                className={classes.formControl} 
                                error={hasError('report')}
                                helperText={<ErrorMessage name="report" />}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field 
                                name="identification" 
                                type="text" 
                                as={TextField} 
                                variant="outlined" 
                                label="Identificación"
                                className={classes.formControl} 
                                error={hasError('identification')}
                                helperText={<ErrorMessage name="identification" />}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Field 
                                name="date" 
                                as={KeyboardDatePicker} 
                                label="Fecha" 
                                className={classes.formControl} 
                                inputVariant="outlined"
                                variant="inline"
                                InputAdornmentProps={{ position: "end" }}
                                format="dd/MM/yyyy"
                                onChange={(date) => props.setFieldValue("date", date)}
                                error={hasError('date')}
                                helperText={<ErrorMessage name="date" />}
                                autoOk
                                disableFuture
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field 
                                name="cause" 
                                type="text" 
                                as={TextField} 
                                variant="outlined" 
                                label="Causa"
                                className={classes.formControl} 
                                error={hasError('cause')}
                                helperText={<ErrorMessage name="cause" />}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Field 
                                name="time" 
                                as={KeyboardTimePicker} 
                                ampm={false}
                                label="Hora" 
                                className={classes.formControl} 
                                inputVariant="outlined"
                                variant="inline"
                                InputAdornmentProps={{ position: "end" }}
                                keyboardIcon={<AccessTimeIcon />}
                                onChange={(date) => props.setFieldValue("time", date)}
                                error={hasError('time')}
                                helperText={<ErrorMessage name="time" />}
                                autoOk
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field 
                                name="action" 
                                type="text" 
                                as={TextField} 
                                variant="outlined" 
                                label="Acción"
                                className={classes.formControl} 
                                error={hasError('action')}
                                helperText={<ErrorMessage name="action" />}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FormControl variant="outlined" className={classes.formControl} error={hasError("shift")}>
                                <InputLabel id="shift-label">Turno</InputLabel>
                                <Field
                                    name="shift"
                                    as={Select}
                                    labelId="shift-label"
                                    id="shift-select"
                                    label="Turno"
                                >
                                {SHIFTS.map((shift, index) => (
                                    <MenuItem value={shift} key={`shift-key-${index}`}>{shift}</MenuItem>
                                ))}
                                </Field>
                                <FormHelperText>
                                    <ErrorMessage name="shift" />
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field 
                                name="novelty" 
                                type="text" 
                                as={TextField} 
                                variant="outlined" 
                                label="Novedad"
                                className={classes.formControl} 
                                error={hasError('novelty')}
                                helperText={<ErrorMessage name="novelty" />}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FormControl variant="outlined" className={classes.formControl} error={hasError("operator_id")}>
                                <InputLabel id="operator-label">Operador</InputLabel>
                                <Field
                                    name="operator_id"
                                    as={Select}
                                    labelId="operator-label"
                                    id="operator-select"
                                    label="Operador"
                                >
                                {users.map((user, index) => (
                                    <MenuItem value={user.id} key={`operator-key-${index}`}>{user.firstname + " " + user.lastname}</MenuItem>
                                ))}
                                </Field>
                                <FormHelperText>
                                    <ErrorMessage name="operator_id" />
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field 
                                name="sheet" 
                                type="number" 
                                as={TextField} 
                                variant="outlined" 
                                label="Folio"
                                className={classes.formControl} 
                                error={hasError('sheet')}
                                helperText={<ErrorMessage name="sheet" />}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    );
}

export default DamageReportIdForm;