import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import MuiPhoneNumber from 'material-ui-phone-number';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import useAllNeighborhoods from '../../../../../hooks/useAllNeighborhoods';

import { Form, Field, ErrorMessage } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const INSPECTION_STATES = ['Nuevo', 'Renovación', 'Cerrado'];

const DatosGeneralesForm = (props) => {
    const classes = useStyles();
    const neighborhoods = useAllNeighborhoods();
    const { readMode } = props;

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    };

    return (
        <div className={classes.root}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Form>
                    <Grid container direction="column" spacing={1} justify="flex-start" alignItems="flex-start">
                        <Grid item className={classes.row}>
                            <Grid container direction="row" spacing={1} justify="flex-start" alignItems="flex-start">
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="inspection_number" 
                                        type="text" 
                                        as={TextField} 
                                        variant="outlined" 
                                        label="Número de inspección"
                                        className={classes.formControl} 
                                        error={hasError('inspection_number')}
                                        helperText={<ErrorMessage name="inspection_number" />}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="address" 
                                        type="text" 
                                        as={TextField} 
                                        variant="outlined" 
                                        label="Dirección"
                                        className={classes.formControl} 
                                        error={hasError('address')}
                                        helperText={<ErrorMessage name="address" />}
                                        disabled={readMode}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.row}>
                            <Grid container direction="row" spacing={1} justify="flex-start" alignItems="flex-start">
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="social_reason" 
                                        type="text" 
                                        as={TextField} 
                                        variant="outlined" 
                                        label="Razón social"
                                        className={classes.formControl} 
                                        error={hasError('social_reason')}
                                        helperText={<ErrorMessage name="social_reason" />}
                                        disabled={readMode}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl disabled={readMode} className={classes.formControl} variant="outlined" error={hasError('neighborhood_id')}>
                                        <InputLabel>Barrio</InputLabel>
                                        <Field 
                                            name="neighborhood_id" 
                                            type="text"
                                            as={Select} 
                                            label="Barrio"
                                            className={classes.formControl} 
                                        >
                                            {neighborhoods.map((item, key) => (
                                                <MenuItem key={`neighborhood-key-${key}`} value={item.id}>{`${item.name}`}</MenuItem>
                                            ))}
                                        </Field>
                                        <FormHelperText>
                                            <ErrorMessage name="neighborhood_id" />
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.row}>
                            <Grid container direction="row" spacing={1} justify="flex-start" alignItems="flex-start">
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="commercial_reason" 
                                        type="text" 
                                        as={TextField} 
                                        variant="outlined" 
                                        label="Razón comercial"
                                        className={classes.formControl} 
                                        error={hasError('commercial_reason')}
                                        helperText={<ErrorMessage name="commercial_reason" />}
                                        disabled={readMode}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="phone_number" 
                                        type="text" 
                                        as={MuiPhoneNumber} 
                                        variant="outlined" 
                                        label="Teléfono"
                                        className={classes.formControl} 
                                        error={hasError('phone_number')}
                                        helperText={<ErrorMessage name="phone_number" />}
                                        onChange={(value) => props.setFieldValue('phone_number', value)}
                                        disabled={readMode}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.row}>
                            <Grid container direction="row" spacing={1} justify="flex-start" alignItems="flex-start">
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="cert_number" 
                                        as={TextField} 
                                        variant="outlined" 
                                        label="Número de certificado"
                                        className={classes.formControl} 
                                        error={hasError('cert_number')}
                                        helperText={<ErrorMessage name="cert_number" />}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="email" 
                                        type="text" 
                                        as={TextField} 
                                        variant="outlined" 
                                        label="Email"
                                        className={classes.formControl} 
                                        error={hasError('email')}
                                        helperText={<ErrorMessage name="email" />}
                                        disabled={readMode}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.row}>
                            <Grid container direction="row" spacing={1} justify="flex-start" alignItems="flex-start">
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="inspection_date" 
                                        as={KeyboardDatePicker} 
                                        label="Fecha de inspección"
                                        className={classes.formControl} 
                                        inputVariant="outlined"
                                        variant="inline"
                                        InputAdornmentProps={{ position: "end" }}
                                        format="dd/MM/yyyy"
                                        onChange={(date) => props.setFieldValue("inspection_date", date)}
                                        error={hasError('inspection_date')}
                                        helperText={<ErrorMessage name="inspection_date" />}
                                        autoOk
                                        disabled={readMode}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="owner_name" 
                                        type="text" 
                                        as={TextField} 
                                        variant="outlined" 
                                        label="Propietario"
                                        className={classes.formControl} 
                                        error={hasError('owner_name')}
                                        helperText={<ErrorMessage name="owner_name" />}
                                        disabled={readMode}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.row}>
                            <Grid container direction="row" spacing={1} justify="flex-start" alignItems="flex-start">
                                <Grid item xs={12} md={6}>
                                    <FormControl disabled={readMode} className={classes.formControl} variant="outlined" error={hasError('state')}>
                                        <InputLabel>Estado</InputLabel>
                                        <Field 
                                            name="state" 
                                            type="text"
                                            as={Select} 
                                            label="Estado"
                                            className={classes.formControl} 
                                        >
                                            {INSPECTION_STATES.map((item, key) => (
                                                <MenuItem key={`state-key-${key}`} value={item}>{`${item}`}</MenuItem>
                                            ))}
                                        </Field>
                                        <FormHelperText>
                                            <ErrorMessage name="state" />
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="admin_name" 
                                        type="text" 
                                        as={TextField} 
                                        variant="outlined" 
                                        label="Administrador"
                                        className={classes.formControl} 
                                        error={hasError('admin_name')}
                                        helperText={<ErrorMessage name="admin_name" />}
                                        disabled={readMode}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.row}>
                            <Grid container direction="row" spacing={1} justify="flex-start" alignItems="flex-start">
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="nit" 
                                        type="number" 
                                        as={TextField} 
                                        variant="outlined" 
                                        label="NIT"
                                        className={classes.formControl} 
                                        error={hasError('nit')}
                                        helperText={<ErrorMessage name="nit" />}
                                        disabled={readMode}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Button
                        className={classes.saveButton}
                        color="primary"
                        size="large"
                        type="submit"
                        variant="contained"
                        disableElevation
                        disabled={readMode}
                    >
                        Siguiente
                    </Button>
                </Form>
            </MuiPickersUtilsProvider>
        </div>
    );
}

DatosGeneralesForm.defaultProps = {
    readMode: false,
};

export default DatosGeneralesForm;