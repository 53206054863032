import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { Form, Field, ErrorMessage } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const DatosEventForm = (props) => {
    const classes = useStyles();
    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Form>
                <Grid container direction="column" justify="center" alignItems="flex-start" spacing={2}>
                    <Grid item className={classes.row}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                            <Grid item xs={12} md={12}>
                                <Field 
                                    name="name" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Evento"
                                    className={classes.formControl} 
                                    error={hasError('name')}
                                    helperText={<ErrorMessage name="name" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="date" 
                                    as={KeyboardDateTimePicker} 
                                    label="Fecha Inicio"
                                    className={classes.formControl} 
                                    inputVariant="outlined"
                                    variant="inline"
                                    InputAdornmentProps={{ position: "end" }}
                                    format="dd/MM/yyyy, hh:mm a"
                                    onChange={(date) => props.setFieldValue("date", date)}
                                    error={hasError('date')}
                                    helperText={<ErrorMessage name="date" />}
                                    autoOk
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="date_end" 
                                    as={KeyboardDateTimePicker} 
                                    label="Fecha Fin"
                                    className={classes.formControl}
                                    minDate= {props.values.date} 
                                    inputVariant="outlined"
                                    variant="inline"
                                    InputAdornmentProps={{ position: "end" }}
                                    format="dd/MM/yyyy, hh:mm a"
                                    onChange={(date) => props.setFieldValue("date_end", date)}
                                    error={hasError('date_end')}
                                    helperText={<ErrorMessage name="date_end" />}
                                    autoOk
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.row}>
                        <Field 
                            name="motif" 
                            type="text" 
                            as={TextField} 
                            variant="outlined" 
                            label="Motivo"
                            className={classes.formControl} 
                            error={hasError('motif')}
                            helperText={<ErrorMessage name="motif" />}
                            multiline={true}
                            rows={4}
                        />
                    </Grid>
                </Grid>
                <Button
                    className={classes.saveButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disableElevation
                >
                    Guardar
                </Button>
            </Form>
        </MuiPickersUtilsProvider>
    );
}

export default DatosEventForm;