import { call, put, takeLatest } from 'redux-saga/effects';

import {
    getAreas,
} from '../services/areasService';

import {
    getAreasRequest,
    getAreasSuccess,
    getAreasFailure,
} from '../store/slices/areasSlice';

function* getAreasEffect(action) {
    try {
        const page = action.payload.page;
        const page_limit = action.payload.page_limit;
        const filters = action.payload.filters;
        const { data } = yield call(getAreas, page, page_limit, filters);
        yield put(getAreasSuccess({ 
            areas: data.results,
            page: data.page,
            pages: data.pages,
            total: data.total,
            page_limit,
        }));
    } catch(e) {
        const { response } = e;
        const error = {
            code: response.status,
            message: response.data.message,
        };
        yield put(getAreasFailure({ error }));
    }
}

function* areasEffects() {
    yield takeLatest(getAreasRequest.type, getAreasEffect);
}

export default areasEffects;