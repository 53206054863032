export default theme => ({
    saveButton: {
        marginTop: '10px',
        width: '170px',
    },
    formControl: {
        width: '100%',
    },
    row: {
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    avatarContainer: {
        width: '250px',
        height: '250px',
    },
    ratioContainer: {
        paddingTop: '100%', // 1:1 ratio
        position: 'relative',
    },
    ratioItem: {
        position: 'absolute',
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
    },
});