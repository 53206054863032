import { createSlice } from '@reduxjs/toolkit';

export const vehiclesSlice = createSlice({
    name: 'vehicles',
    initialState: {
        isLoading: false,
        vehicles: [],
        total: null,
        pages: null,
        page: 1,
        page_limit: 10,
        error: {
            code: null,
            message: null,
        },
    },
    reducers: {
        getVehiclesRequest: (state) => {
            state.isLoading = true;
        },
        getVehiclesSuccess: (state, action) => {
            state.isLoading = false;
            state.error = {
                code: null,
                message: null,
            };
            state.vehicles = action.payload.vehicles;
            state.total = action.payload.total;
            state.pages = action.payload.pages;
            state.page = action.payload.page;
            state.page_limit = action.payload.page_limit;
        },
        getVehiclesFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
    },
});

export const {
    getVehiclesRequest,
    getVehiclesSuccess,
    getVehiclesFailure,
} = vehiclesSlice.actions;

export const selectVehicles = (state) => state.vehicles;

export default vehiclesSlice.reducer;