import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import{ useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';
import useUserById from '../../../../hooks/useUserById';
import { updateUser } from '../../../../services/usersService';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';

import { Formik } from 'formik';
import { RiskAgentForm } from '../Forms/RiskAgentForm';
import RiskAgentFormSchema from '../Forms/RiskAgentForm/schema';
import { ROLES_KEYS } from '../../../../constants/roles';

import { riskAgentsUrls } from '../../routing';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const EditRiskAgent = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { user_id } = useParams();
    const { user } = useUserById(user_id);
    const {
        backendResponse: updateUserResponse,
        executeService: updateUserService,
    } = useBackendMessageResponse(updateUser);
    const [riskUserData, setRiskUserData] = useState({
        firstname: '',
        lastname: '',
        document_type: '',
        document: '',
        phone: '',
        email: '',
        risk_user_type: '',
        department_id: '',
        city_id: '',
    });

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    useEffect(() => {
        if (user === null) {
             return;
        }

        setRiskUserData({
            firstname: user.firstname,
            lastname: user.lastname,
            document_type: user.document_type,
            document: user.document,
            phone: user.phone,
            email: user.email,
            risk_user_type: user.risk_user_type,
            department_id: user.department_id,
            city_id: user.city_id,
        });
    }, [user]);

    useEffect(() => {
        if (updateUserResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: updateUserResponse.message,
            severity: 'success',
        }));

        props.history.push(riskAgentsUrls.Listado);
    }, [dispatch, updateUserResponse.message, props.history]);

    useEffect(() => {
        if (updateUserResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: updateUserResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, updateUserResponse.error])

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Editar usuario</Box>
            </Typography>
            <div className={classes.form}>
                <Formik
                    enableReinitialize
                    initialValues={riskUserData}
                    component={RiskAgentForm}
                    validationSchema={RiskAgentFormSchema}
                    onSubmit={async (values) => {
                        setRiskUserData(values);
                        const riskUserData = {
                            ...values,
                            role: ROLES_KEYS.risk_agent,
                            id: user_id,
                        };
                        await updateUserService(user_id, riskUserData);
                    }}
                />
            </div>
        </div>
    );
}

export default EditRiskAgent;