import { createSlice } from '@reduxjs/toolkit';

export const damageReportsSlice = createSlice({
    name: 'damageReports',
    initialState: {
        isLoading: false,
        damageReports: [],
        total: null,
        pages: null,
        page: 1,
        page_limit: 10,
        error: {
            code: null,
            message: null,
        },
    },
    reducers: {
        getDamageReportsRequest: (state) => {
            state.isLoading = true;
        },
        getDamageReportsSuccess: (state, action) => {
            state.isLoading = false;
            state.error = {
                code: null,
                message: null,
            };
            state.damageReports = action.payload.damageReports;
            state.total = action.payload.total;
            state.pages = action.payload.pages;
            state.page = action.payload.page;
            state.page_limit = action.payload.page_limit;
        },
        getDamageReportsFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
    },
});

export const {
    getDamageReportsRequest,
    getDamageReportsSuccess,
    getDamageReportsFailure,
} = damageReportsSlice.actions;

export const selectDamageReports = (state) => state.damageReports;

export default damageReportsSlice.reducer;