import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';

import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import { updateExtinguisher } from '../../../../services/extinguishersService';
import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';

import { datosGlobalesUrls } from '../../routing';

import useExtinguisherByID from '../../../../hooks/useExtinguisherById';

import validate from 'validate.js';
import schema from './schema';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

let FIRE_TYPES_VALUES = ['A', 'B', 'C', 'D'];

const EditExtinguisher = (props) => {
    const classes = useStyles();
    const { extinguisher_id } = useParams();
    const { extinguisher } = useExtinguisherByID(extinguisher_id);
    const dispatch = useDispatch();
    const {
        backendResponse: updateExtinguisherResponse,
        executeService: updateExtinguisherService,
    } = useBackendMessageResponse(updateExtinguisher);
    const [extinguisherForm, setExtinguisherForm] = useState({
        isSubmitted: false,
        isValid: false,
        values: {
            name: '',
            capacity: '',
        },
        touched: {
            name: false,
            capacity: false,
        },
        errors: {},
    });
    const [fireTypeError, setFireTypeError] = useState(null);
    const [fireTypes, setFireTypes] = useState([{ fire_type: '' }]);

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    const handleChange = event => {
        event.persist();

        setExtinguisherForm(currentExtinguisherForm => ({
            ...currentExtinguisherForm,
            values: {
                ...currentExtinguisherForm.values,
                [event.target.name]: event.target.value,
            },
            touched: {
                ...currentExtinguisherForm.touched,
                [event.target.name]: true
            },
        }));
    };

    const hasError = field =>
        (extinguisherForm.isSubmitted || extinguisherForm.touched[field]) && extinguisherForm.errors[field] ? true : false;

    const FireTypeHasError = () => fireTypeError !== null && extinguisherForm.isSubmitted;

    const handleRemoveFireType = (index) => {
        const fireTypesCopy = [...fireTypes];
        appendPreviousValue(index);
        fireTypesCopy.splice(index, 1);
        setFireTypes(fireTypesCopy);
    }

    const handleAddFireType = () => {
        setFireTypes([...fireTypes, { fire_type: '' }]);
    }

    const removeAvailableType = (value) => {
        const index = FIRE_TYPES_VALUES.indexOf(value);
        if (index > -1) {
            FIRE_TYPES_VALUES.splice(index, 1);
        }
    }

    const appendPreviousValue = (inputIndex) => {
        const selectedFireType = fireTypes[inputIndex].fire_type;
        if(selectedFireType !== '') {
            FIRE_TYPES_VALUES.push(selectedFireType);
            FIRE_TYPES_VALUES = FIRE_TYPES_VALUES.sort();
        }
    }

    const handleFireTypeChange = (event, index) => {
        const { name, value } = event.target;
        removeAvailableType(value);
        appendPreviousValue(index);
        const fireTypesCopy = [...fireTypes];
        fireTypesCopy[index][name] = value;
        setFireTypes(fireTypesCopy);
    }

    const onSubmitEditExtinguisher = async (event) => {
        event.preventDefault();

        if(!extinguisherForm.isSubmitted) {
            setExtinguisherForm(currentExtinguisherForm => ({
                ...currentExtinguisherForm,
                isSubmitted: true,
            }));
        }

        if (!extinguisherForm.isValid || fireTypeError !== null) {
            return;
        }

        const fireTypesValues = fireTypes
            .filter(item => item.fire_type !== '')
            .map(item => item.fire_type);
        let extinguisher = {
            ...extinguisherForm.values,
            fire_type: JSON.stringify(fireTypesValues),
        };

        await updateExtinguisherService(extinguisher_id, extinguisher);
    }

    useEffect(() => {
        if (updateExtinguisherResponse.message === null) {
            return;
        }

        // Si llegamos acá es porque ya se ejecutó una actualización y fue éxitosa.
        dispatch(setGlobalAlert({
            show: true,
            message: updateExtinguisherResponse.message,
            duration: 5000,
            severity: 'success',
        }));
        props.history.push(datosGlobalesUrls.Listado);
    }, [dispatch, updateExtinguisherResponse.message, props.history]);

    useEffect(() => {
        if (updateExtinguisherResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            message: updateExtinguisherResponse.error.message,
            duration: 5000,
            severity: 'error',
        }));
    }, [dispatch, updateExtinguisherResponse.error]);

    useEffect(() => {
        const errors = validate(extinguisherForm.values, schema);

        setExtinguisherForm(currentExtinguisherForm => ({
            ...currentExtinguisherForm,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [extinguisherForm.values, extinguisherForm.isSubmitted]);

    useEffect(() => {
        const fireTypesValues = fireTypes
            .filter(item => item.fire_type !== '')
        if (fireTypesValues.length === 0) {
            setFireTypeError('Debe elegir al menos un tipo de fuego.');
            return;
        }
        setFireTypeError(null);
    }, [fireTypes, extinguisherForm.isSubmitted]);

    useEffect(() => {
        if (extinguisher === null) {
            return;
        }

        setExtinguisherForm(currentExtinguisherForm => ({
            ...currentExtinguisherForm,
            isValid: true,
            values: {
                name: extinguisher.name,
                // El .toString() es porque el TextField trabaja mejor con el campo siendo texto.
                capacity: parseFloat(extinguisher.capacity).toString(),
            },
        }));

        // Ésto se pone acá para que al editar un extintor y salir y volver entrar a la página
        // los valores se reinicien. Ya que al no ser parte del componente, 
        // el arreglo permanece de la forma en la que quedó anteriormente.
        FIRE_TYPES_VALUES = ['A', 'B', 'C', 'D'];

        const extinguisherFireTypes = extinguisher.fire_type.split(',').sort();
        extinguisherFireTypes.forEach(item => {
            removeAvailableType(item);
        });
        const fireTypesInputs = extinguisherFireTypes.map(item => ({ fire_type: item }));
        setFireTypes(fireTypesInputs);
    }, [extinguisher]);

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Editar extintor</Box>
            </Typography>
            <form onSubmit={onSubmitEditExtinguisher} className={classes.form}>
                <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1} className={classes.formContainer}>
                    <Grid item className={classes.formItem}>
                        <TextField 
                            id="extinguisher-name-input" 
                            label="Nombre del extintor" 
                            variant="outlined" 
                            className={classes.formControl} 
                            name="name"
                            onChange={handleChange}
                            value={extinguisherForm.values.name}
                            error={hasError('name')}
                            helperText={
                                hasError('name') ? extinguisherForm.errors.name[0] : null
                            }
                        />
                    </Grid>
                    <Grid item className={classes.formItem}>
                        <TextField 
                            id="extinguisher-capacity-input" 
                            label="Capacidad" 
                            variant="outlined" 
                            className={classes.formControl} 
                            name="capacity"
                            type="number"
                            onChange={handleChange}
                            value={extinguisherForm.values.capacity}
                            error={hasError('capacity')}
                            helperText={
                                hasError('capacity') ? extinguisherForm.errors.capacity[0] : null
                            }
                        />
                    </Grid>
                    <Grid item className={classes.fireTypes}>
                        {fireTypes.map((item, index) => (
                            <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.fireTypesContainer} spacing={1}>
                                <Grid item className={classes.formItem}>
                                    <FormControl variant="outlined" className={classes.formControl} error={FireTypeHasError()}>
                                        <InputLabel id="firetype-select-outlined-label">Tipo de fuego</InputLabel>
                                        <Select
                                            labelId="firetype-select-outlined-label"
                                            id="firetype-select-outlined"
                                            label="Tipo de fuego"
                                            name="fire_type"
                                            value={item.fire_type}
                                            onChange={(event) => handleFireTypeChange(event, index)}
                                        >
                                            {item.fire_type !== '' && (
                                                <MenuItem value={item.fire_type}>{item.fire_type}</MenuItem>
                                            )}
                                            {FIRE_TYPES_VALUES.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                            ))}
                                        </Select>
                                        {FireTypeHasError() && (
                                            <FormHelperText>{fireTypeError}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                {fireTypes.length !== 1 && (
                                    <Grid item>
                                        <IconButton aria-label="delete-icon" color="primary" className={classes.deleteIcon} onClick={() => handleRemoveFireType(index)}>
                                            <DeleteIcon style={{fontSize: '25px'}}/>
                                        </IconButton>
                                    </Grid>
                                )}
                                {(fireTypes.length - 1) === index && fireTypes.length < 4 && (
                                    <Grid item>
                                        <Grid container direction="row" justify="center" alignItems="center">
                                            <Grid item>
                                                <Typography component={"span"}>
                                                    <Box fontWeight="fontWeightLight" fontSize={12} className={classes.addFireTypeText}>Agregar tipo de fuego</Box>
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <IconButton aria-label="add-fire-type-icon" color="primary" className={classes.addFireTypeIcon} onClick={handleAddFireType}>
                                                    <AddCircleRoundedIcon style={{fontSize: '30px'}}/>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                { updateExtinguisherResponse.error !== null && (
                    <Typography color="error" className={classes.authNotificationText}>
                        <Box fontWeight="fontWeightBold">
                            { updateExtinguisherResponse.error.message }
                        </Box>
                    </Typography>
                )}
                <Button
                    className={classes.saveButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disableElevation
                >
                    Guardar
                </Button>
            </form>
        </div>
    );
}

export default EditExtinguisher;