export default theme => ({
    fullWidth: {
        width: '100%',
    },
    formControl: {
        width: '100%'
    },
    filterButton: {
        width: '100px',
        marginTop: '5px',
    },
});