import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import roles, { ROLES_KEYS } from '../../constants/roles';

import { 
    // Selectors
    selectCurrentUser, 
    // Actions
    currentUserRequest
} from '../../store/slices/currentUserSlice';

import {
    selectLinearProgress,
} from '../../store/slices/linearProgressSlice';

import {
    selectAlerts,
    hideGlobalAlert,
} from  '../../store/slices/alertsSlice';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';

import {
    signOutRequest,
} from '../../store/slices/authSlice';

import Helmet from 'react-helmet';

import { useSelector, useDispatch } from 'react-redux';

import { ThemeProvider } from '@material-ui/styles';
import lightTheme from '../../themes/light';

import { Sidebar } from '../../components/Sidebar';
import { Navbar } from '../../components/Navbar';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

import { mainRoutesPath, dropdownRoutes, modulesRoutesPath } from '../../routes/Routes';
import routesUrls from '../../routes/urls';

import Modules from '../../constants/modules';
import { setAvailableModules } from '../../store/slices/modulesSlice';

import { PERMISSION_KEYS } from '../../constants/permissions';

const useStyles = makeStyles(styles);

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
    
const Main = props => {
    const { children, history } = props;
    const dispatch = useDispatch();
    const [sidebarRoutes, setSidebarRoutes] = useState([]);
    const [sidebarMobileOpen, setSidebarMobileOpen] = useState(false);

    const { user: currentUser } = useSelector(selectCurrentUser);
    const alerts = useSelector(selectAlerts);
    const linearProgress = useSelector(selectLinearProgress);

    const classes = useStyles();

    const dropdownActions = [{
        title: 'Ver perfil',
        onClick: () => {
            history.push(routesUrls.MyProfile);
        }
    }, {
        title: 'Cerrar sesión',
        onClick: () => { 
            dispatch(signOutRequest({ userId: currentUser.id }));
        }
    }];

    useEffect(() => {
        dispatch(currentUserRequest());
    }, [dispatch]);

    const handleDrawerToggle = () => {
        setSidebarMobileOpen(!sidebarMobileOpen);
    }

    const dispatchAvailableModules = useCallback((selectedModulesPath) => {
        const modulesShortNames = selectedModulesPath.map(({ shortName }) => shortName);
        dispatch(setAvailableModules({
            availableModules: modulesShortNames,
        }));
    }, [dispatch]);

    const determineAvailableModules = useCallback((sidebarRoutes) => {
        let moduleRoutes = [];

        if (currentUser.permissions.includes(PERMISSION_KEYS.ST_READ)) {
            const STModulePath = modulesRoutesPath.find(item => item.shortName === Modules.ST);
            moduleRoutes.push(STModulePath);
        }
        if (currentUser.permissions.includes(PERMISSION_KEYS.MT_READ)) {
            const MTModulePath = modulesRoutesPath.find(item => item.shortName === Modules.MT);
            moduleRoutes.push(MTModulePath);
        }
        if (currentUser.permissions.includes(PERMISSION_KEYS.GR_READ)) {
            const GRModulePath = modulesRoutesPath.find(item => item.shortName === Modules.GR);
            moduleRoutes.push(GRModulePath);
        }

        sidebarRoutes.splice(1, 0, ...moduleRoutes);

        const eventsIndex = sidebarRoutes.findIndex(route => route.title === "Eventos");
        if (!currentUser.permissions.includes(PERMISSION_KEYS.EVENTS_READ)) {
            sidebarRoutes.splice(eventsIndex, 1);
        }

        const usersIndex = sidebarRoutes.findIndex(route => route.title === "Usuarios");
        if (!currentUser.permissions.includes(PERMISSION_KEYS.USERS_READ)) {
            sidebarRoutes.splice(usersIndex, 1);
        }

        dispatchAvailableModules(moduleRoutes);
    }, [currentUser, dispatchAvailableModules]);

    useEffect(() => {
        if (currentUser === null || currentUser.role === null) {
            return;
        }

        const filteredRoutes = mainRoutesPath.filter(route => route.allowedRoles.indexOf(currentUser.role) > -1);
        if (currentUser.role === ROLES_KEYS.admin) {
            filteredRoutes.splice(1, 0, ...modulesRoutesPath);
            dispatchAvailableModules(modulesRoutesPath);
        } else if (currentUser.role === ROLES_KEYS.station || currentUser.role === ROLES_KEYS.station_user) {
            determineAvailableModules(filteredRoutes);
        } else if (currentUser.role === ROLES_KEYS.risk_agent) {
            const riskAgentModuleRoutes = modulesRoutesPath.filter(route => route.allowedRoles.includes(ROLES_KEYS.risk_agent));
            filteredRoutes.splice(1, 0, ...riskAgentModuleRoutes);
            dispatchAvailableModules(riskAgentModuleRoutes);
        }
        setSidebarRoutes(filteredRoutes);
    }, [currentUser, determineAvailableModules, dispatchAvailableModules]);

    const getFullName = () => {
        if (currentUser === null) {
            return "Cargando...";
        }

        if (currentUser.firstname === null && currentUser.lastname === null) {
            return "Cargando...";
        }
        return currentUser.firstname + " " + currentUser.lastname;
    }

    const getFullRole = (shortRole) => {
        return roles[shortRole];
    };

    const handleAlertClose = () => {
        dispatch(hideGlobalAlert());
    }

    return (
        <ThemeProvider theme={lightTheme}>
            <Helmet>
                <title>Sipgerd - Admin Panel</title>
            </Helmet>
            <LinearProgress 
                className={classes.linear}
                variant={linearProgress.variant} 
                value={linearProgress.value}
            />
            <div className={classes.wrapper}>
                <Sidebar 
                    routes={sidebarRoutes} 
                    handleDrawerToggle={handleDrawerToggle} 
                    open={sidebarMobileOpen} 
                    dropdownActions={dropdownActions}
                />
                <div className={classes.mainPanel}>
                    <Navbar 
                        routes={sidebarRoutes.concat(dropdownRoutes)}
                        userName={getFullName()}
                        userRole={currentUser !== null && currentUser.role !== null ? getFullRole(currentUser.role) : "Cargando..."} 
                        avatarUrl={currentUser !== null && currentUser.image_url !== null ? currentUser.image_url : ""}
                        dropdownActions={dropdownActions}
                        handleDrawerToggle={handleDrawerToggle}
                    />
                    <div className={classes.content}>
                        <div className={classes.container}>
                            <main>{children}</main>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar open={alerts.show} autoHideDuration={alerts.duration} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={alerts.severity}>
                    {alerts.message}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
}

Main.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
}

export default Main;