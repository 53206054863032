import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../../store/slices/alertsSlice';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import MuiPhoneNumber from 'material-ui-phone-number';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { Formik, Form, Field, ErrorMessage, validateYupSchema, yupToFormErrors } from 'formik';

import schema from './schema';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const TestigosForm = (props) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const dispatch = useDispatch();
    const { readMode } = props;

    const hasError = (field, formik) => {
        return formik.errors[field] && formik.touched[field] ? true : false;
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onClickDeleteWitness = (witness_index) => () => {
        const currentWitnesses = props.witnessesData.witnesses;
        currentWitnesses.splice(witness_index, 1);
        props.onChangeWitnesses(currentWitnesses);
    }

    const handleNext = () => {
        if (props.witnessesData.witness_apply && props.witnessesData.witnesses.length === 0) {
            dispatch(setGlobalAlert({
                show: true,
                duration: 5000,
                message: 'Debe cargar al menos un registro.',
                severity: 'error',
            }));
            return;
        }
        props.onHandleNext();
    }

    return (
        <div className={classes.root}>
            <Formik
                initialValues={{
                    name: '',
                    document: '',
                    phone: '+57',
                    address: '',
                    email: '',
                }}
                onSubmit={(values, actions) => {
                    console.log(values);
                    const witnesses = props.witnessesData.witnesses;
                    witnesses.push(values);
                    props.onChangeWitnesses(witnesses);
                    actions.resetForm();
                }}
                validate={async (values) => {
                    try {
                        await validateYupSchema(values, schema, false, { witnesses: props.witnessesData.witnesses });
                    } catch(err) {
                        return yupToFormErrors(err);
                    }

                    return {};
                }}
            >
                {formik => (
                    <Form>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item className={classes.row}>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="witness_apply"
                                                    checked={!props.witnessesData.witness_apply}
                                                    onChange={(event) => props.onToggleApply()}
                                                    color="primary"
                                                />
                                            }
                                            label="No aplica"
                                            disabled={readMode}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.row}>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <Field 
                                            name="name" 
                                            type="text" 
                                            as={TextField} 
                                            variant="outlined" 
                                            label="Nombre del testigo"
                                            className={classes.formControl} 
                                            error={hasError('name', formik)}
                                            helperText={<ErrorMessage name="name" />}
                                            disabled={!props.witnessesData.witness_apply || readMode}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field 
                                            name="document" 
                                            type="text" 
                                            as={TextField} 
                                            variant="outlined" 
                                            label="Identificación del testigo"
                                            className={classes.formControl} 
                                            error={hasError('document', formik)}
                                            helperText={<ErrorMessage name="document" />}
                                            disabled={!props.witnessesData.witness_apply || readMode}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.row}>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <Field 
                                            name="phone" 
                                            type="text" 
                                            as={MuiPhoneNumber} 
                                            variant="outlined" 
                                            label="Teléfono del testigo"
                                            className={classes.formControl} 
                                            error={hasError('phone', formik)}
                                            helperText={<ErrorMessage name="phone" />}
                                            disabled={!props.witnessesData.witness_apply || readMode}
                                            onChange={(value) => formik.setFieldValue('phone', value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field 
                                            name="address" 
                                            type="text" 
                                            as={TextField} 
                                            variant="outlined" 
                                            label="Dirección del testigo"
                                            className={classes.formControl} 
                                            error={hasError('address', formik)}
                                            helperText={<ErrorMessage name="address" />}
                                            disabled={!props.witnessesData.witness_apply || readMode}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.row}>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <Field 
                                            name="email" 
                                            type="text" 
                                            as={TextField} 
                                            variant="outlined" 
                                            label="Correo electrónico"
                                            className={classes.formControl} 
                                            error={hasError('email', formik)}
                                            helperText={<ErrorMessage name="email" />}
                                            disabled={!props.witnessesData.witness_apply || readMode}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.row}>
                                <Paper elevation={0}>
                                    <TableContainer>
                                        <Table className={classes.table} aria-label="activities-table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Nombre</TableCell>
                                                    <TableCell align="left">Identificación</TableCell>
                                                    <TableCell align="left">Teléfono</TableCell>
                                                    <TableCell align="left">Dirección</TableCell>
                                                    <TableCell align="left">Correo</TableCell>
                                                    <TableCell align="right"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {props.witnessesData.witnesses.map((witness, key) => {
                                                    return (
                                                        <TableRow key={`list-first-aids-item-key-${key}`}>
                                                            <TableCell align="left">
                                                                <Typography component={"span"} className={classes.tableCellText}>
                                                                    {`${witness.name}`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography component={"span"} className={classes.tableCellText}>
                                                                    {`${witness.document}`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography component={"span"} className={classes.tableCellText}>
                                                                    {`${witness.phone}`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography component={"span"} className={classes.tableCellText}>
                                                                    {`${witness.address}`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography component={"span"} className={classes.tableCellText}>
                                                                    {`${witness.email}`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <IconButton aria-label="delete" disabled={readMode} className={classes.iconButton} onClick={onClickDeleteWitness(witness)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={1 /*vehicles.total*/}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            labelRowsPerPage="Filas por página:"
                                            labelDisplayedRows={() => {
                                                return `Página: ${page + 1} de ${Math.ceil(1 /*vehicles.total*/ / rowsPerPage)}`;
                                            }}
                                        />
                                    </TableContainer>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Button
                            className={classes.saveButton}
                            style={{marginRight: '10px'}}
                            color="primary"
                            size="large"
                            type="submit"
                            variant="outlined"
                            disableElevation
                            disabled={!props.witnessesData.witness_apply || readMode}
                        >
                            Añadir testigo 
                        </Button>
                        <Button
                            className={classes.saveButton}
                            color="primary"
                            size="large"
                            variant="contained"
                            disableElevation
                            onClick={handleNext}
                            disabled={readMode}
                        >
                            Siguiente
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

TestigosForm.defaultProps = {
    readMode: false,
};

export default TestigosForm;