import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { Form } from 'formik';
import { DamageReportIdForm } from './DamageReportIdForm';
import { DamageReportCostsForm } from './DamageReportCostsForm';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const DatosDamageReportForm = (props) => {
    const classes = useStyles();

    return (
        <Form>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                <Grid item md={6} xs={12} style={{borderRight: '2px solid #e5e5e5'}}>
                    <DamageReportIdForm {...props} />
                </Grid>
                <Grid item md={6} xs={12}>
                    <DamageReportCostsForm {...props} />
                </Grid>
            </Grid>
            <Button
                className={classes.saveButton}
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                disableElevation
            >
                Guardar
            </Button>
        </Form>
    );
}

export default DatosDamageReportForm;