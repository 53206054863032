export default (theme) => ({
    root: {
        height: '100%',
    },
    grid: {
        height: '100%',
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBototm: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    logoImage: {
        marginLeft: theme.spacing(4)
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        },
        width: '400px',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    textField: {
        marginTop: theme.spacing(5)
    },
    checkButton: {
        marginTop: theme.spacing(3),
        borderRadius: 50,
        width: '300px',
        height: '50px',
    },
    titleReliefCorp: {
        marginTop: theme.spacing(2),
        zIndex: 5,
    },
    authErrorText: {
        marginTop: theme.spacing(2),
        zIndex: 5,
    },
    signInText: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(10),
        zIndex: 5,
        textAlign: 'center',
    }
});