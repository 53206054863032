import { createSlice } from '@reduxjs/toolkit';

export const activitiesSlice = createSlice({
    name: 'activities',
    initialState: {
        isLoading: false,
        activities: [],
        total: null,
        pages: null,
        page: 1,
        page_limit: 10,
        error: {
            code: null,
            message: null,
        },
    },
    reducers: {
        getActivitiesRequest: (state) => {
            state.isLoading = true;
        },
        getActivitiesSuccess: (state, action) => {
            state.isLoading = false;
            state.error = {
                code: null,
                message: null,
            };
            state.activities = action.payload.activities;
            state.total = action.payload.total;
            state.pages = action.payload.pages;
            state.page = action.payload.page;
            state.page_limit = action.payload.page_limit;
        },
        getActivitiesFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
    },
});

export const {
    getActivitiesRequest,
    getActivitiesSuccess,
    getActivitiesFailure,
} = activitiesSlice.actions;

export const selectActivities = (state) => state.activities;

export default activitiesSlice.reducer;