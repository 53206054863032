import httpClient from '../http';
import qs from 'qs';

export const login = async (email, password) => {
    const credentials = { email, password };
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedCredentials = qs.stringify(credentials);
    return await httpClient.post('/auth/login', serializedCredentials, config);
}

export const getReliefCorp = async (document) => {
    const data = { document };
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedDocument = qs.stringify(data);
    return await httpClient.post('/users/relief_corps', serializedDocument, config);
}

export const passwordRecovery = async (email) => {
    const data = { email };
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedEmail = qs.stringify(data);
    return await httpClient.post('/auth/password-recovery', serializedEmail, config);
}

export const checkRecoveryToken = async (token) => {
    const data = { token };
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedToken = qs.stringify(data);
    return await httpClient.post('/auth/check-recovery-token', serializedToken, config);
}

export const changePassword = async (token, password) => {
    const data = { token, password };
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedData = qs.stringify(data);
    return await httpClient.post('/auth/change-password', serializedData, config);
}

export const signOutUser = async (userId) => {
    const data = { userId };
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedUserId = qs.stringify(data);
    return await httpClient.post('/auth/logout', serializedUserId, config);
}