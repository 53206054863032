import { report_types, report_modules } from '../../../../../../constants/MT/reports';

const AVAILABILITY_TYPES = report_types[report_modules.availabilities];

export const requireVehicle = (selectedType) => {
    const vehicleRequiredTypes = [
        AVAILABILITY_TYPES.availability_summary_vehicle, 
        AVAILABILITY_TYPES.vehicle_availability
    ];
    return vehicleRequiredTypes.includes(AVAILABILITY_TYPES[selectedType]);
}