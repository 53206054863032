export default theme => ({
    saveButton: {
        marginTop: '20px',
        width: '170px',
    },
    formControl: {
        width: '100%',
    },
    row: {
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    ratioContainer: {
        paddingTop: '56.25%', // 16:9 ratio
        position: 'relative',
    },
    ratioItem: {
        position: 'absolute',
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
    },
});