import React, { Fragment } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MuiPhoneNumber from 'material-ui-phone-number';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import { Form, Field, ErrorMessage } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';
import useEmergencies from '../../../../../hooks/useEmergencies';
import useNeighborhoods from '../../../../../hooks/useNeighborhoods';
import useVehicles from '../../../../../hooks/MT/useVehicles';

const useStyles = makeStyles(styles);


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DatosGeneralesForm = (props) => {
    const classes = useStyles();

    const emergencies = useEmergencies(1, 1000);
    const neighborhoods = useNeighborhoods(1, 1000);
    const vehicles = useVehicles(1, 1000);

    const { readMode } = props;

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    };

    return (
        <div className={classes.root}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Form>
                    <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
                        <Grid item className={classes.row}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="report" 
                                        type="text" 
                                        as={TextField} 
                                        variant="outlined" 
                                        label="Reporte"
                                        className={classes.formControl} 
                                        error={hasError('report')}
                                        helperText={<ErrorMessage name="report" />}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="reporter_name" 
                                        type="text" 
                                        as={TextField} 
                                        variant="outlined" 
                                        label="Nombre de quien reporta"
                                        className={classes.formControl} 
                                        error={hasError('reporter_name')}
                                        helperText={<ErrorMessage name="reporter_name" />}
                                        disabled={readMode}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.row}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="report_date" 
                                        as={KeyboardDatePicker} 
                                        label="Fecha"
                                        className={classes.formControl} 
                                        inputVariant="outlined"
                                        variant="inline"
                                        InputAdornmentProps={{ position: "end" }}
                                        format="dd/MM/yyyy"
                                        onChange={(date) => props.setFieldValue("report_date", date)}
                                        error={hasError('report_date')}
                                        helperText={<ErrorMessage name="report_date" />}
                                        disableFuture
                                        autoOk
                                        disabled={readMode}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="reporter_phone" 
                                        type="text" 
                                        as={MuiPhoneNumber} 
                                        variant="outlined" 
                                        label="Teléfono del testigo"
                                        className={classes.formControl} 
                                        error={hasError('reporter_phone')}
                                        helperText={<ErrorMessage name="reporter_phone" />}
                                        onChange={(value) => props.setFieldValue('reporter_phone', value)}
                                        disabled={readMode}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.row}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="report_time" 
                                        as={KeyboardTimePicker} 
                                        ampm={false}
                                        label="Hora de reporte de emergencia" 
                                        className={classes.formControl} 
                                        inputVariant="outlined"
                                        variant="inline"
                                        InputAdornmentProps={{ position: "end" }}
                                        keyboardIcon={<AccessTimeIcon />}
                                        onChange={(date) => props.setFieldValue("report_time", date)}
                                        error={hasError('report_time')}
                                        helperText={<ErrorMessage name="report_time" />}
                                        autoOk
                                        disabled={readMode}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        as={Autocomplete}
                                        multiple
                                        id="dispatch-machine-select"
                                        options={vehicles}
                                        disableCloseOnSelect
                                        disabled={readMode}
                                        onChange={(event, selectedMachines) => {
                                            const machines_id = selectedMachines.map(machine => machine.id);
                                            props.setFieldValue('dispatch_machine', machines_id);
                                        }}
                                        getOptionLabel={(option) => option.code}
                                        value={(() => {
                                            const dispatch_machines = props.values.dispatch_machine;
                                            const dispatch_machines_mapped = dispatch_machines.map(id => {
                                                const vehicle = vehicles.find(vehicle => vehicle.id === id);
                                                return vehicle;
                                            });
                                            // Ésto se coloca en caso de que se haya eliminado un vehículo y no se encuentre en 
                                            // el arreglo de vehículos. Ya que el '.map' retornataría undefined y hay que limpiarlo.
                                            // Para que no genere errores en el Autocomplete.
                                            const machines = dispatch_machines_mapped.filter(machine => machine !== undefined);
                                            return machines;
                                        })()}
                                        renderOption={(option, { selected }) => (
                                            <Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    checked={selected}
                                                    color="primary"
                                                />
                                                {option.code}
                                            </Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField 
                                                {...params} 
                                                variant="outlined" 
                                                label="Máquinas despachadas" 
                                                placeholder="Máquinas despachadas" 
                                                error={hasError('dispatch_machine')}
                                                helperText={<ErrorMessage name="dispatch_machine" />}
                                                className={classes.formControl}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.row}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="dispatch_date" 
                                        as={KeyboardDatePicker} 
                                        label="Fecha de despacho"
                                        className={classes.formControl} 
                                        inputVariant="outlined"
                                        disabled={readMode}
                                        variant="inline"
                                        InputAdornmentProps={{ position: "end" }}
                                        format="dd/MM/yyyy"
                                        onChange={(date) => props.setFieldValue("dispatch_date", date)}
                                        error={hasError('dispatch_date')}
                                        helperText={<ErrorMessage name="dispatch_date" />}
                                        autoOk
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        as={Autocomplete}
                                        multiple
                                        id="support-machine-select"
                                        options={vehicles}
                                        disableCloseOnSelect
                                        disabled={readMode}
                                        onChange={(event, selectedMachines) => {
                                            const machines_id = selectedMachines.map(machine => machine.id);
                                            props.setFieldValue('support_machine', machines_id);
                                        }}
                                        getOptionLabel={(option) => option.code}
                                        value={(() => {
                                            const support_machines = props.values.support_machine;
                                            const support_machines_mapped = support_machines.map(id => {
                                                const vehicle = vehicles.find(vehicle => vehicle.id === id);
                                                return vehicle;
                                            });
                                            // Ésto se coloca en caso de que se haya eliminado un vehículo y no se encuentre en 
                                            // el arreglo de vehículos. Ya que el '.map' retornataría undefined y hay que limpiarlo.
                                            // Para que no genere errores en el Autocomplete.
                                            const machines = support_machines_mapped.filter(machine => machine !== undefined);
                                            return machines;
                                        })()}
                                        renderOption={(option, { selected }) => (
                                            <Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    checked={selected}
                                                    color="primary"
                                                />
                                                {option.code}
                                            </Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField 
                                                {...params} 
                                                variant="outlined" 
                                                label="Máquinas de apoyo" 
                                                placeholder="Máquinas de apoyo" 
                                                error={hasError('support_machine')}
                                                helperText={<ErrorMessage name="support_machine" />}
                                                className={classes.formControl}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.row}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="dispatch_time" 
                                        as={KeyboardTimePicker} 
                                        ampm={false}
                                        label="Hora de despacho" 
                                        className={classes.formControl} 
                                        inputVariant="outlined"
                                        variant="inline"
                                        InputAdornmentProps={{ position: "end" }}
                                        keyboardIcon={<AccessTimeIcon />}
                                        onChange={(date) => props.setFieldValue("dispatch_time", date)}
                                        error={hasError('dispatch_time')}
                                        helperText={<ErrorMessage name="dispatch_time" />}
                                        autoOk
                                        disabled={readMode}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="report_address" 
                                        type="text" 
                                        as={TextField} 
                                        variant="outlined" 
                                        label="Dirección del reporte"
                                        className={classes.formControl} 
                                        error={hasError('report_address')}
                                        helperText={<ErrorMessage name="report_address" />}
                                        disabled={readMode}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.row}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="arriving_time" 
                                        as={KeyboardTimePicker} 
                                        ampm={false}
                                        label="Hora de llegada" 
                                        className={classes.formControl} 
                                        inputVariant="outlined"
                                        variant="inline"
                                        InputAdornmentProps={{ position: "end" }}
                                        keyboardIcon={<AccessTimeIcon />}
                                        onChange={(date) => props.setFieldValue("arriving_time", date)}
                                        error={hasError('arriving_time')}
                                        helperText={<ErrorMessage name="arriving_time" />}
                                        autoOk
                                        disabled={readMode}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl className={classes.formControl} disabled={readMode} variant="outlined" error={hasError('report_neighborhood_id')}>
                                        <InputLabel>Barrio</InputLabel>
                                        <Field 
                                            name="report_neighborhood_id" 
                                            type="text"
                                            as={Select} 
                                            label="Barrio"
                                            className={classes.formControl} 
                                        >
                                            {neighborhoods.map((item, key) => (
                                                <MenuItem key={`neighborhood-key-${key}`} value={item.id}>{item.name}</MenuItem>
                                            ))}
                                        </Field>
                                        <FormHelperText>
                                            <ErrorMessage name="report_neighborhood_id" />
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.row}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="control_time" 
                                        as={KeyboardTimePicker} 
                                        ampm={false}
                                        label="Hora de control" 
                                        className={classes.formControl} 
                                        inputVariant="outlined"
                                        variant="inline"
                                        InputAdornmentProps={{ position: "end" }}
                                        keyboardIcon={<AccessTimeIcon />}
                                        onChange={(date) => props.setFieldValue("control_time", date)}
                                        error={hasError('control_time')}
                                        helperText={<ErrorMessage name="control_time" />}
                                        autoOk
                                        disabled={readMode}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl className={classes.formControl} disabled={readMode} variant="outlined" error={hasError('emergency_id')}>
                                        <InputLabel>Tipo de emergencia</InputLabel>
                                        <Field 
                                            name="emergency_id" 
                                            type="text"
                                            as={Select} 
                                            label="Tipo de emergencia"
                                            className={classes.formControl} 
                                        >
                                            {emergencies.map((item, key) => (
                                                <MenuItem key={`emergency-key-${key}`} value={item.id}>{`${item.code} - ${item.name}`}</MenuItem>
                                            ))}
                                        </Field>
                                        <FormHelperText>
                                            <ErrorMessage name="emergency_id" />
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.row}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <Field 
                                        name="station_arriving_time" 
                                        as={KeyboardTimePicker} 
                                        ampm={false}
                                        label="Hora de llegada a la estación" 
                                        className={classes.formControl} 
                                        inputVariant="outlined"
                                        variant="inline"
                                        InputAdornmentProps={{ position: "end" }}
                                        keyboardIcon={<AccessTimeIcon />}
                                        onChange={(date) => props.setFieldValue("station_arriving_time", date)}
                                        error={hasError('station_arriving_time')}
                                        helperText={<ErrorMessage name="station_arriving_time" />}
                                        autoOk
                                        disabled={readMode}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Button
                        className={classes.saveButton}
                        color="primary"
                        size="large"
                        type="submit"
                        variant="contained"
                        disableElevation
                        disabled={readMode}
                    >
                        Siguiente
                    </Button>
                </Form>
            </MuiPickersUtilsProvider>
        </div>
    );
}

DatosGeneralesForm.defaultProps = {
    readMode: false,
};

export default DatosGeneralesForm;