import * as Yup from 'yup';

export default Yup.object().shape({
    report: Yup.string()
        .max(15, 'Máximo 15 carácteres.')
        .required('Éste campo es requerido.'),
    reporter_name: Yup.string()
        .max(100, 'Máximo 100 caracteres.')
        .required('Éste campo es requerido.'),
    report_date: Yup.date()
        .required('Éste campo es requerido.'),
    reporter_phone: Yup.string()
        .max(13, 'Máximo 13 dígitos.')
        .required('Éste campo es requerido.'),
    report_time: Yup.date()
        .required('Éste campo es requerido.'),
    dispatch_machine: Yup.array()
        .min(1, 'Debe seleccionar al menos un elemento.')
        .required('Éste campo es requerido.'),
    dispatch_date: Yup.date()
        .required('Éste campo es requerido.'),
    support_machine: Yup.array()
        .min(1, 'Debe seleccionar al menos un elemento.')
        .required('Éste campo es requerido.'),
    dispatch_time: Yup.date()
        .required('Éste campo es requerido.'),
    report_address: Yup.string()
        .max(100, 'Máximo 100 caracteres.')
        .required('Éste campo es requerido.'),
    arriving_time: Yup.date()
        .required('Éste campo es requerido.')
        .when("dispatch_time", (dispatch_time, yup) => {
            return dispatch_time ? yup.min(dispatch_time, "Debe ser mayor a la hora de despacho.")  : yup;
        }),
    report_neighborhood_id: Yup.number()
        .required('Éste campo es requerido.'),
    control_time: Yup.date()
        .required('Éste campo es requerido.')
        .when("dispatch_time", (dispatch_time, yup) => {
            return dispatch_time ? yup.min(dispatch_time, "Debe ser mayor a la hora de despacho.")  : yup;
        }),
    emergency_id: Yup.number()
        .required('Éste campo es requerido.'),
    station_arriving_time: Yup.date()
        .required('Éste campo es requerido.')
        .when("arriving_time", (arriving_time, yup) => {
            return arriving_time ? yup.min(arriving_time, "Debe ser mayor a la hora de llegada.")  : yup;
        }),
});