export default theme => ({
    root: {
        padding: '25px',
    },
    goBackIcon: {
        marginLeft: '-15px',
    },
    form: {
        padding: '20px',
    },
    stepButton: {
        width: '300px',
    },
});