import httpClient from '../http';
import qs from 'qs';

export const getUsers = async (page, page_limit, filters) => {
    // Se hace para no incluir parámetros vacíos en la url.
    for (var property in filters) {
        if(filters[property] === '') {
            delete filters[property];
        }
    }
    const filterParams = {
        page,
        page_limit,
        ...filters,
    };
    const queryParams = qs.stringify(filterParams);
    return await httpClient.get(`/users?${queryParams}`);
}

export const createUser = async (user) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedUser = qs.stringify(user);
    return await httpClient.post('/users', serializedUser, config);
}

export const updateUser = async (user_id, user) => {
    return await httpClient.put(`/users/${user_id}`, user);
}

export const changePassword = async (user_id, oldPassword, newPassword) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const changePasswordData = {
        oldPassword,
        newPassword,
    };
    const serializedPasswordData = qs.stringify(changePasswordData);
    return await httpClient.put(`/users/${user_id}/password`, serializedPasswordData, config);
}

export const getUserById = async (user_id) => {
    return await httpClient.get(`/users/${user_id}`);
}

export const enableUser = async (user_id) => {
    return await httpClient.put(`/users/enable/${user_id}`);
}

export const disableUser = async (user_id) => {
    return await httpClient.put(`/users/disable/${user_id}`);
}