import { useState, useEffect } from 'react';
import { getDeviationById } from '../services/deviationsService';

const useDeviationById = (deviation_id) => {
    const [deviation, setDeviation] = useState(null);

    useEffect(() => {
        async function fetchDeviation() {
            try {
                const { data } = await getDeviationById(deviation_id);
                setDeviation(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchDeviation();
    }, [deviation_id]);

    return { deviation };
}

export default useDeviationById;