import {
    UsersList,
    AddUser,
    EditUser,
} from './Pages';

import routesUrls from '../../routes/urls';

export const adminUsersUrls = {
    Listado: `${routesUrls.AdminUsuarios}`,
    AddUser: `${routesUrls.AdminUsuarios}/crear-usuario`,
    EditUser: `${routesUrls.AdminUsuarios}/editar-usuario/:user_id`,
}

const adminUsersRouting = [{
    component: UsersList,
    path: adminUsersUrls.Listado,
}, {
    component: AddUser,
    path: adminUsersUrls.AddUser,
}, {
    component: EditUser,
    path: adminUsersUrls.EditUser,
}];

export default adminUsersRouting;