import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import validate from 'validate.js';
import schema from './schema';

import useCities from '../../../../hooks/useCities';
import useNeighborhoodByID from '../../../../hooks/useNeighborhoodById';
import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';

import { configurationUrls } from '../../routing';

import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import { updateNeighborhood } from '../../../../services/neighborhoodsService';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const EditNeighborhood = (props) => {
    const classes = useStyles();
    const cities = useCities();
    const dispatch = useDispatch();
    const { neighborhood_id } = useParams();
    const { neighborhood } = useNeighborhoodByID(neighborhood_id);
    const {
        backendResponse: updateNeighborhoodResponse,
        executeService: updateNeighborhoodService,
    } = useBackendMessageResponse(updateNeighborhood);
    const [neighborhoodForm, setNeighborhoodForm] = useState({
        isValid: false,
        isSubmitted: false,
        values: {
            city_id: '',
            name: '',
        },
        touched: {
            city_id: false,
            name: false,
        }, 
        errors: {},
    });

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    const handleChange = event => {
        event.persist();

        setNeighborhoodForm(currentNeighborhoodForm => ({
            ...currentNeighborhoodForm,
            values: {
                ...currentNeighborhoodForm.values,
                [event.target.name]: event.target.value,
            },
            touched: {
                ...currentNeighborhoodForm.touched,
                [event.target.name]: true
            },
        }));
    };

    const hasError = field =>
        (neighborhoodForm.isSubmitted || neighborhoodForm.touched[field]) && neighborhoodForm.errors[field] ? true : false;

    const onSubmitEditNeighborhood = async (event) => {
        event.preventDefault();

        if (!neighborhoodForm.isSubmitted) {
            setNeighborhoodForm(currentNeighborhoodForm => ({
                ...currentNeighborhoodForm,
                isSubmitted: true,
            }));
        }

        if (!neighborhoodForm.isValid) {
            return;
        }

        const neighborhood = neighborhoodForm.values;
        await updateNeighborhoodService(neighborhood_id, neighborhood);
    }

    useEffect(() => {
        if (updateNeighborhoodResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: updateNeighborhoodResponse.message,
            severity: 'success',
        }));
        props.history.push(configurationUrls.Listado);
    }, [dispatch, updateNeighborhoodResponse.message, props.history]);

    useEffect(() => {
        if (updateNeighborhoodResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: updateNeighborhoodResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, updateNeighborhoodResponse.error])

    useEffect(() => {
        if (neighborhood === null) {
            return;
        }
        
        setNeighborhoodForm(currentNeighborhoodForm => ({
            ...currentNeighborhoodForm,
            isValid: true,
            values: {
                name: neighborhood.name,
                city_id: neighborhood.city_id,
            },
        }));
    }, [neighborhood]);

    useEffect(() => {
        const errors = validate(neighborhoodForm.values, schema);

        setNeighborhoodForm(currentNeighborhoodForm => ({
            ...currentNeighborhoodForm,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [neighborhoodForm.values, neighborhoodForm.isSubmitted]);

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Editar barrio</Box>
            </Typography>
            <form onSubmit={onSubmitEditNeighborhood} className={classes.form}>
                <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
                    <Grid item>
                        <FormControl variant="outlined" className={classes.formControl} error={hasError('city_id')}>
                            <InputLabel id="neighborhood-id-input-label">Nombre del municipio</InputLabel>
                            <Select
                                labelId="neighborhood-id-input-label"
                                id="neighborhood-id-input"
                                value={neighborhoodForm.values.city_id}
                                onChange={handleChange}
                                label="Nombre del municipio"
                                name="city_id"
                            >
                            {cities.map((city, key) => (
                                <MenuItem key={`city-key-${key}`} value={city.id}>{city.name}</MenuItem>
                            ))}
                            </Select>
                            { hasError('city_id') && (
                                <FormHelperText>{neighborhoodForm.errors.city_id[0]}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <TextField 
                            id="name-neighborhood-input" 
                            label="Nombre del barrio" 
                            variant="outlined" 
                            className={classes.formControl} 
                            error={hasError('name')}
                            helperText={
                                hasError('name') ? neighborhoodForm.errors.name[0] : null
                            }
                            name="name"
                            value={neighborhoodForm.values.name}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Button
                    className={classes.saveButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disableElevation
                >
                    Guardar
                </Button>
            </form>
        </div>
    );
}

export default EditNeighborhood;