import React from 'react';

import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import { Form, Field, ErrorMessage } from 'formik';

import { report_types, report_modules } from '../../../../../../constants/MT/reports';
import { requireOperator } from './helpers';

import useUsersByPosition from '../../../../../../hooks/useUsersByPosition';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const TYPES = report_types[report_modules.operators];

const OperatorsReportForm = (props) => {
    const classes = useStyles();

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    };

    const operators = useUsersByPosition("Operario");

    return (
        <Form>
            <Grid container direction="column" spacing={2} justify="flex-start" alignItems="flex-start">
                <Grid item className={classes.row}>
                    <Grid container direction="row" spacing={2} justify="flex-start" alignItems="flex-start">
                        <Grid item xs={12} md={6}>
                            <FormControl className={classes.formControl} variant="outlined" error={hasError('type')}>
                                <InputLabel>Tipo de reporte</InputLabel>
                                <Field 
                                    name="type" 
                                    type="text"
                                    as={Select} 
                                    label="Tipo de reporte"
                                    className={classes.formControl} 
                                >
                                    {Object.entries(TYPES).map(([report_type, report_name], key) => (
                                        <MenuItem key={`report-type-key-${key}`} value={report_type}>{report_name}</MenuItem>
                                    ))}
                                </Field>
                                <FormHelperText>
                                    <ErrorMessage name="type" />
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        {requireOperator(props.values.type) && (
                            <Grid item xs={12} md={6}>
                                <FormControl className={classes.formControl} variant="outlined" error={hasError('operator_id')}>
                                    <InputLabel>Operador</InputLabel>
                                    <Field 
                                        name="operator_id" 
                                        type="text"
                                        as={Select} 
                                        label="Operador"
                                        className={classes.formControl} 
                                    >
                                        {operators.map((operator, key) => (
                                            <MenuItem key={`operator-key-${key}`} value={operator.id}>
                                                {operator.document_type}-{operator.document} {operator.firstname} {operator.lastname}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                    <FormHelperText>
                                        <ErrorMessage name="operator_id" />
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item>
                    <Button
                        className={classes.downloadButton}
                        color="primary"
                        size="large"
                        type="submit"
                        variant="contained"
                        disableElevation
                    >
                        Consultar
                    </Button>
                </Grid>
            </Grid>
        </Form>
    );
}

export default OperatorsReportForm;