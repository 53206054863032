import { useState, useEffect } from 'react';
import { getAllEmergencies } from '../../services/ST/emergenciesService';

const useAllEmergencies = (filters) => {
    const [emergencies, setEmergencies] = useState([]);

    useEffect(() => {
        async function fetchAllEmergencies() {
            try {
                const { data } = await getAllEmergencies(filters);
                setEmergencies(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchAllEmergencies();
    }, [filters]);

    return emergencies;
}

export default useAllEmergencies;