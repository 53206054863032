import * as Yup from 'yup';

import { requireVehicle, requireEquipment } from './helpers';

export default Yup.object().shape({
    type: Yup.string()
        .required('Éste campo es requerido.'),
    vehicle_id: Yup.number()
        .when('type', (type, yup) => {
            return requireVehicle(type) 
                ? yup.required('Éste campo es requerido.') 
                : yup;
        }),
    equipment_id: Yup.number()
        .when('type', (type, yup) => {
            return requireEquipment(type) 
                ? yup.required('Éste campo es requerido.') 
                : yup;
        }),
});