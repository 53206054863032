const USER_ROLES = {
    admin: 'Administrador',
    risk_agent: 'Agente de riesgo',
    station: 'Estación',
    station_user: 'Usuario estación',
    client: 'Cliente',
};

export const ADMIN_USER_LIST_ROLES = {
    admin: 'Administrador',
    risk_agent: 'Agente de riesgo',
    station: 'Estación',
    station_user: 'Usuario de Estación',
};

export const ROLES_KEYS = {
    admin: 'admin',
    risk_agent: 'risk_agent',
    client: 'client',
    station: 'station',
    station_user: 'station_user',
};

export default USER_ROLES;