import httpClient from '../http';
import qs from 'qs';

export const getActivities = async (page, page_limit, filters) => {
    const filterParams = {
        page,
        page_limit,
        ...filters
    };
    const queryParams = qs.stringify(filterParams);
    return await httpClient.get(`/activities?${queryParams}`);
}

export const getAllActivities = async () => {
    return await httpClient.get(`/activities/all`);
}

export const getActivityById = async (activity_id) => {
    return await httpClient.get(`/activities/${activity_id}`);
}

export const createActivity = async (activity) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedActivity = qs.stringify(activity);
    return await httpClient.post('/activities', serializedActivity, config);
}

export const editActivity = async (activity_id, activity) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedActivity = qs.stringify(activity);
    return await httpClient.put(`/activities/${activity_id}`, serializedActivity, config);
}

export const deleteActivity = async (activity_id) => {
    return await httpClient.delete(`/activities/${activity_id}`);
}