const white = '#FFFFFF';
const black = '#000000';

export default {
    black,
    white,
    type: 'dark',
    primary: {
        main: '#0199AE',
    },
    secondary: {
        main: '#FF6F6F',
    },
};