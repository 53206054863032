export default theme => ({
    root: {
        padding: '15px',
    },
    fullWidth: {
        width: '100%',
    },
    savePermissionsButton: {
        marginTop: '30px',
        width: '170px',
    },
});