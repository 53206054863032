import * as Yup from 'yup';

export default Yup.object().shape({
    width: Yup.string()
        .max(10, 'Máximo 10 dígitos.')
        .required('Éste campo es requerido.'),
    length: Yup.string()
        .max(10, 'Máximo 10 dígitos.')
        .required('Éste campo es requerido.'),
    activity_id: Yup.number()
        .required('Éste campo es requerido.'),
    impact: Yup.number()
        .typeError('Impacto no válido.')
        .required('Es necesario un impacto.')
});