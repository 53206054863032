import { useState, useEffect } from 'react';
import { getVehicleBrands } from '../../services/MT/vehicleBrandsService';

const useVehicleBrands = () => {
    const [brands, setBrands] = useState([]);

    useEffect(() => {
        async function fetchVehicleBrands() {
            try {
                const { data } = await getVehicleBrands();
                setBrands(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchVehicleBrands();
    }, []);

    return { brands };
}

export default useVehicleBrands;