import { report_types, report_modules } from '../../../../../../constants/MT/reports';

const RECORDS_TYPES = report_types[report_modules.records];

export const requireVehicle = (selectedType) => {
    const vehicleRequiredTypes = [
        RECORDS_TYPES.damage_report_vehicle,
        RECORDS_TYPES.maintenance_vehicle,
    ];
    return vehicleRequiredTypes.includes(RECORDS_TYPES[selectedType]);
}

export const requireEquipment = (selectedType) => {
    const equipmentRequiredTypes = [
        RECORDS_TYPES.damage_report_equipment,
        RECORDS_TYPES.maintenance_equipment,
    ];
    return equipmentRequiredTypes.includes(RECORDS_TYPES[selectedType]);
}