import { useState, useEffect } from 'react';
import { getAvailabilityById } from '../../services/MT/availabilitiesService';

const useAvailabilityById = (availability_id) => {
    const [availability, setAvailability] = useState(null);

    useEffect(() => {
        async function fetchAvailability() {
            try {
                const { data } = await getAvailabilityById(availability_id);
                setAvailability(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchAvailability();
    }, [availability_id]);

    return { availability };
}

export default useAvailabilityById;