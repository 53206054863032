import httpClient from '../../http';
import qs from 'qs';

export const getMaintenances = async (page, page_limit, filters) => {
    const filterParams = {
        page,
        page_limit,
        ...filters
    };
    const queryParams = qs.stringify(filterParams);
    return await httpClient.get(`/mt/maintenances?${queryParams}`);
}

export const getMaintenanceById = async (maintenance_id) => {
    return await httpClient.get(`/mt/maintenances/${maintenance_id}`);
}

export const createMaintenance = async (maintenance) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedMaintenance = qs.stringify(maintenance);
    return await httpClient.post('/mt/maintenances', serializedMaintenance, config);
}

export const updateMaintenance = async (maintenance_id, maintenance) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const serializedMaintenance = qs.stringify(maintenance);
    return await httpClient.put(`/mt/maintenances/${maintenance_id}`, serializedMaintenance, config);
}

export const deleteMaintenance = async (maintenance_id) => {
    return await httpClient.delete(`/mt/maintenances/${maintenance_id}`);
}