import React, { useState, useCallback } from 'react';
import { selectCurrentUser } from '../../store/slices/currentUserSlice';
import { useSelector } from 'react-redux';

import { PERMISSION_KEYS } from '../../constants/permissions';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import { Form, Field } from 'formik';

const useStyles = makeStyles(styles);

const StationUserPermissionsForm = (props) => {
    const classes = useStyles();
    const [settingClicked, setSettingClicked] = useState({
        index: 0,
        setting: 'mt',
    });
    const { user: currentUser } = useSelector(selectCurrentUser);

    const handleSettingClick = (index, setting) => () => {
        setSettingClicked({
            index,
            setting,
        });
    }

    const canAssignST = useCallback(() => {
        return currentUser.permissions.includes(PERMISSION_KEYS.ST_WRITE);
    }, [currentUser.permissions]);

    const canAssignGR = useCallback(() => {
        return currentUser.permissions.includes(PERMISSION_KEYS.GR_WRITE);
    }, [currentUser.permissions]);

    const canAssignMT = useCallback(() => {
        return currentUser.permissions.includes(PERMISSION_KEYS.MT_WRITE);
    }, [currentUser.permissions]);

    const canAssignEvents = useCallback(() => {
        return currentUser.permissions.includes(PERMISSION_KEYS.EVENTS_WRITE);
    }, [currentUser.permissions]);

    const canAssignUsers = useCallback(() => {
        return currentUser.permissions.includes(PERMISSION_KEYS.USERS_WRITE);
    }, [currentUser.permissions]);

    return (
        <Form>
            <div className={classes.root}>
                <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
                    <Grid item className={classes.fullWidth}>
                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                            <Button 
                                variant={settingClicked.index === 0 ? 'contained':''} 
                                onClick={handleSettingClick(0, "mt")}
                                disabled={!canAssignMT()}
                            >
                                MT
                            </Button>
                            <Button 
                                variant={settingClicked.index === 1 ? 'contained':''} 
                                onClick={handleSettingClick(1, "gr")}
                                disabled={!canAssignGR()}
                            >
                                GR
                            </Button>
                            <Button 
                                variant={settingClicked.index === 2 ? 'contained':''} 
                                onClick={handleSettingClick(2, "st")}
                                disabled={!canAssignST()}
                            >
                                ST
                            </Button>
                            <Button 
                                variant={settingClicked.index === 3 ? 'contained':''} 
                                onClick={handleSettingClick(3, "events")}
                                disabled={!canAssignEvents()}
                            >
                                Eventos
                            </Button>
                            <Button 
                                variant={settingClicked.index === 4 ? 'contained':''} 
                                onClick={handleSettingClick(4, "user")}
                                disabled={!canAssignUsers()}
                            >
                                Usuarios
                            </Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item className={classes.fullWidth}>
                        <Typography component="div">
                            <Box fontSize={20} fontWeight="fontWeightBold">Nivel de permiso</Box>
                        </Typography>
                    </Grid>
                    <Grid item className={classes.fullWidth}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Field
                                        name={`${settingClicked.setting}.write`}
                                        as={Checkbox}
                                        checked={props.values[settingClicked.setting].write}
                                        onChange={(event) => { props.setFieldValue(`${settingClicked.setting}.write`, event.target.checked) }}
                                        color="primary"
                                    />
                                }
                                label="Registrar"
                            />
                            <FormControlLabel
                                control={
                                    <Field
                                        name={`${settingClicked.setting}.read`}
                                        as={Checkbox}
                                        checked={props.values[settingClicked.setting].read}
                                        onChange={(event) => { props.setFieldValue(`${settingClicked.setting}.read`, event.target.checked) }}
                                        color="primary"
                                    />
                                }
                                label="Consultar"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Button
                    className={classes.savePermissionsButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disableElevation
                >
                    Guardar
                </Button>
            </div>
        </Form>
    );
}

export default StationUserPermissionsForm;