import { useEffect, useState } from 'react';
import { getAllCities } from '../services/cityService';

const useCities = () => {
    const [cities, setCities] = useState([]);

    useEffect(() => {
        async function fetchAllCities() {
            try {
                const { data } = await getAllCities();
                const otroCityIndex = data.findIndex(city => city.name === "Otro");
                if (otroCityIndex !== -1) {
                    const otroCity = data[otroCityIndex];
                    data.splice(otroCityIndex, 1);
                    data.unshift(otroCity);
                }
                setCities(data);
            } catch(e) {
                const{ response } = e;
                console.log(response);
            }
        }
        fetchAllCities();
    }, []);

    return cities;
};

export default useCities;