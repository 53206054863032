import { useState, useEffect } from 'react';
import { getUsers } from '../services/usersService';

// Éste hook no es el mismo que se utiliza para el listado. 
// El listado utiliza el estado de Redux el cual se llena mediante el dispatch de una acción.
// Entonces para no interferir con el listado de Redux, se usa éste estado local al componente que lo consume.
// Éste hook se utiliza para formularios que necesitan listar a todos los usuarios, o los usuarios por un filtro en específico.
const useUsers = (page, page_limit, filters) => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        async function fetchUsers() {
            try {
                const { data: { results } } = await getUsers(page, page_limit, filters);
                setUsers(results);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchUsers();
    }, [page, page_limit, filters]);

    const findUserById = (id) => {
        const user = users.find(user => user.id === id);
        return user;
    }

    return { users, findUserById };
}

export default useUsers;