import React, { useState } from 'react';

import { StyledTab } from '../../../../components/StyledTab';
import { StyledTabs } from '../../../../components/StyledTabs';
import { TabPanel } from '../../../../components/TabPanel';

import { 
    Consultas,
    Emergencias,
    Graficos,
} from './Tabs';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const TabsSiniestralidad = (props) => {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    }

    return (
        <div>
            <StyledTabs value={selectedTab} onChange={handleTabChange} aria-label="siniestralidad-dashboard-tabs" variant="scrollable">
                <StyledTab label="Emergencias"/>
                <StyledTab label="Consultas"/>
                <StyledTab label="Gráficos"/>
            </StyledTabs>
            <div className={classes.panelsContainer}>
                <TabPanel value={selectedTab} index={0}>
                    <Emergencias {...props} />
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    <Consultas {...props} />
                </TabPanel>
                <TabPanel value={selectedTab} index={2}>
                    <Graficos {...props} />
                </TabPanel>
            </div>
        </div>
    );
}

export default TabsSiniestralidad;