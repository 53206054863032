import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

import httpClient from '../../http';
import { selectHttpClient } from '../../store/slices/httpClientSlice';
import {
    selectAuth,
    authSuccess,
} from '../../store/slices/authSlice';

const useStyles = makeStyles(styles);

const ProtectedRoute = ({ children, redirectUrl, ...rest}) => {
    const auth = useSelector(selectAuth);
    const httpClientState = useSelector(selectHttpClient);
    const dispatch = useDispatch();

    const classes = useStyles();

    const existsTokenInHttpClient = useCallback(() => {
        const authorization = httpClient.defaults.headers.common['Authorization'];
        return authorization !== undefined;
    }, []);

    const areTokensEqual = useCallback(() => {
        const authorization = httpClient.defaults.headers.common['Authorization'];
        return authorization === `Bearer ${auth.token}`;
    }, [auth]);

    useEffect(() => {
        if (auth.token === null) {
            const { history } = rest;
            history.replace(redirectUrl);
            return;
        }

        if(!existsTokenInHttpClient() || !areTokensEqual()) {
            // Hacemos dispatch de authSuccess para que se ejecute
            // el middleware de saveAuthToken y se guarde el token en el httpClient.
            dispatch(authSuccess({
                token: auth.token,
                message: auth.message,
            }));
            return;
        }
    });

    return (
        <div>
            { httpClientState.isReady 
                ? children 
                : (
                    <Backdrop className={classes.backdrop} open={!httpClientState.isReady}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
        </div>
    );
};

export default ProtectedRoute;