import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAvailableReportTypes } from '../../constants/GR/reports';
import { 
    selectCurrentUser, 
} from '../../store/slices/currentUserSlice';

const useAvailableReportTypes = (role) => {
    const { user: currentUser } = useSelector(selectCurrentUser);
    const [availableReportTypes, setAvailableReportTypes] = useState({});

    useEffect(() => {
      const reportTypes = getAvailableReportTypes(currentUser.role);
      setAvailableReportTypes(reportTypes);
    }, [currentUser.role]);

    return availableReportTypes;
}

export default useAvailableReportTypes;