import React from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../../../store/slices/alertsSlice';
import { startLinearProgress, stopLinearProgress } from '../../../../../../store/slices/linearProgressSlice';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { InspectionReports } from '../../../../Forms/Reports/InspectionReports';
import InspectionReportsSchema from '../../../../Forms/Reports/InspectionReports/schema';

import { ALL_REPORT_TYPES } from '../../../../../../constants/GR/reports';
import useAvailableReportTypes from '../../../../../../hooks/GR/useAvailableReportTypes';

import { SUBMIT_MESSAGE } from '../../../../../../constants/reports';
import useReportNameGenerator from '../../../../../../hooks/useReportNameGenerator';

import { Formik } from 'formik';

import { downloadGRReport } from '../../../../../../services/GR/reportsService';
import FileDownload from 'js-file-download';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const Consultas = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const generateName = useReportNameGenerator();
    const reportTypes = useAvailableReportTypes();

    return (
        <div className={classes.root}>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Consultas</Box>
            </Typography>
            <br />
            <div className={classes.formPanel}>
                <Formik
                    initialValues={{
                        type: Object.keys(reportTypes)[0],
                        start_date: new Date(),
                        end_date: new Date(),
                        inspection_number: '',
                        inspector_id: '',
                        year: '',
                        request_date: new Date(),
                        /* Resto de propiedades */
                    }}
                    component={InspectionReports}
                    validationSchema={InspectionReportsSchema}
                    onSubmit={async (values) => {
                        try {
                            dispatch(setGlobalAlert({
                                show: true,
                                duration: 5000,
                                message: SUBMIT_MESSAGE,
                                severity: 'info',
                            }));
                            dispatch(startLinearProgress());
                            const result = await downloadGRReport(values);
                            const filename = generateName(ALL_REPORT_TYPES, values.type);
                            FileDownload(result.data, filename);
                            dispatch(stopLinearProgress());
                        } catch(err) {
                            const { response } = err;
                            dispatch(setGlobalAlert({
                                show: true,
                                duration: 5000,
                                message: response.data.message,
                                severity: 'error',
                            }));
                            dispatch(stopLinearProgress());
                        }
                    }}
                />
            </div>
        </div>
    );
}

export default Consultas;