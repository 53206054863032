export default theme => ({
    root: {
        padding: '25px',
    },
    goBackIcon: {
        marginLeft: '-15px',
    },
    form: {
        width: '100%',
    },
    formControl: {
        marginTop: '15px',
        width: '260px',
    },
    saveButton: {
        width: '150px',
        marginTop: theme.spacing(5),
    },
    authNotificationText: {
        marginTop: '25px',
    },
    addAnotherNeighborhoodIconContainer: {
        marginTop: '35px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '0px',
        },
    },
    addAnotherNeighborhoodIcon: {
        fontSize: '35px',
        color: '#53DBB6',
    },
    addAnotherNeighborhoodText: {
        color: '#5C5C5C',
        marginTop: '35px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '0px',
        },
    },
});