import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { 
    selectCurrentUser, 
} from '../../../../../../store/slices/currentUserSlice';


import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { VariableDocumentationDialog } from '../../../../../../components/VariableDocumentationDialog';
import { Can, Only } from '../../../../../../components/Can';

import { ROLES_KEYS } from '../../../../../../constants/roles';

import { uploadImage } from '../../../../../../services/imageService';

import { CERT_BODY_VARIABLES } from './editorVariables';

import { Editor } from '@tinymce/tinymce-react'; 

import { Form, Field } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const TINYMCE_CLOUD_KEY = process.env.REACT_APP_TINYMCE_CLOUD_KEY;

const CertificatesFormatForm = (props) => {
    const classes = useStyles();
    const [openCertBodyDialog, setOpenCertBodyDialog] = useState(false);
    const { user: currentUser } = useSelector(selectCurrentUser);

    const onImageUpload = async (blobInfo, success, failure) => {
        try {
            const { data } = await uploadImage(blobInfo.blob());
            const image_url = data.image.Location;
            success(image_url);
        } catch(e) {
            failure(e);
        }
    }

    const onBodyCertDialogOpen = () => {
        setOpenCertBodyDialog(true);
    }

    const onBodyCertDialogClose = () => {
        setOpenCertBodyDialog(false);
    }

    return (
        <Form>
            <VariableDocumentationDialog 
                open={openCertBodyDialog} 
                onClose={onBodyCertDialogClose} 
                variables={CERT_BODY_VARIABLES}
            />
            <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                {/* <Grid item className={classes.row}>
                    <Typography component={"span"}>
                        <Box fontWeight="fontWeightBold" fontSize={15}>Encabezado:</Box>
                    </Typography>
                </Grid>
                <Grid item className={classes.row}>
                    <Field
                        name="header_html"
                        as={Editor}
                        apiKey={TINYMCE_CLOUD_KEY}
                        onEditorChange={(content) => props.setFieldValue('header_html', content)}
                        disabled={currentUser.role !== ROLES_KEYS.admin}
                        init={{
                            height: 300,
                            menubar: false,
                            paste_data_images: true,
                            file_picker_types: 'file image media',
                            branding: false,
                            content_style: "body { line-height: 1; }",
                            plugins: [
                                'advlist autolink lists link image', 
                                'charmap print preview anchor help',
                                'searchreplace visualblocks code',
                                'insertdatetime media table paste wordcount',
                                'image paste table',
                            ],
                            toolbar:
                                // eslint-disable-next-line 
                                'undo redo | formatselect | bold italic forecolor backcolor | \
                                alignleft aligncenter alignright | \
                                bullist numlist outdent indent | help \
                                image | paste | table',
                            images_upload_handler: onImageUpload,
                        }}
                    />
                </Grid> */}
                <Grid item className={classes.row} style={{ marginTop: '20px' }}>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography component={"span"}>
                                <Box fontWeight="fontWeightBold" fontSize={15}>Cuerpo de Certificado:</Box>
                            </Typography>
                        </Grid>
                        <Can>
                            <Only roles={[ROLES_KEYS.admin]}>
                                <Grid item>
                                    <Button color="primary" onClick={onBodyCertDialogOpen}>Variables</Button>
                                </Grid>
                            </Only>
                        </Can>
                    </Grid>
                </Grid>
                <Grid item className={classes.row}>
                    <Field
                        name="body_html"
                        as={Editor}
                        apiKey={TINYMCE_CLOUD_KEY}
                        onEditorChange={(content) => props.setFieldValue('body_html', content)}
                        disabled={currentUser.role !== ROLES_KEYS.admin}
                        init={{
                            height: 630,
                            menubar: false,
                            paste_data_images: true,
                            content_style: "body { line-height: 1; }",
                            file_picker_types: 'file image media',
                            branding: false,
                            plugins: [
                                'advlist autolink lists link image', 
                                'charmap print preview anchor help',
                                'searchreplace visualblocks code',
                                'insertdatetime media table paste wordcount',
                                'image paste table',
                            ],
                            toolbar:
                                // eslint-disable-next-line 
                                'undo redo | formatselect | bold italic forecolor backcolor | \
                                alignleft aligncenter alignright | \
                                bullist numlist outdent indent | help \
                                image | paste | table',
                            images_upload_handler: onImageUpload,
                        }}
                    />
                </Grid>
                <Grid item className={classes.row} style={{ marginTop: '20px' }}>
                    <Typography component={"span"}>
                        <Box fontWeight="fontWeightBold" fontSize={15}>Pie de página:</Box>
                    </Typography>
                </Grid>
                <Grid item className={classes.row}>
                    <Field
                        name="footer_html"
                        as={Editor}
                        apiKey={TINYMCE_CLOUD_KEY}
                        onEditorChange={(content) => props.setFieldValue('footer_html', content)}
                        disabled={currentUser.role !== ROLES_KEYS.admin}
                        init={{
                            height: 300,
                            menubar: false,
                            paste_data_images: true,
                            file_picker_types: 'file image media',
                            branding: false,
                            content_style: "body { line-height: 1; }",
                            plugins: [
                                'advlist autolink lists link image', 
                                'charmap print preview anchor help',
                                'searchreplace visualblocks code',
                                'insertdatetime media table paste wordcount',
                                'image paste table',
                            ],
                            toolbar:
                                // eslint-disable-next-line 
                                'undo redo | formatselect | bold italic forecolor backcolor | \
                                alignleft aligncenter alignright | \
                                bullist numlist outdent indent | help \
                                image | paste | table',
                            images_upload_handler: onImageUpload,
                        }}
                    />
                </Grid>
                <Grid item>
                    <Button
                        className={classes.saveButton}
                        color="primary"
                        size="large"
                        type="submit"
                        variant="contained"
                        disableElevation
                        disabled={currentUser.role !== ROLES_KEYS.admin}
                    >
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </Form>
    );
};

export default CertificatesFormatForm;