import React, { useState } from 'react';

import { StyledTab } from '../../../../components/StyledTab';
import { StyledTabs } from '../../../../components/StyledTabs';
import { TabPanel } from '../../../../components/TabPanel';

import { 
    Consultas,
    Graficos,
    Inspeccion,
    ValidarCertificado,
} from './Tabs';

import styles from './styles';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(styles);

const TabsGestionRiesgo = (props) => {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    }

    return (
        <div>
            <StyledTabs value={selectedTab} onChange={handleTabChange} aria-label="gestion-riesgo-dashboard-tabs" variant="scrollable">
                <StyledTab label="Inspección"/>
                <StyledTab label="Validar certificado"/>
                <StyledTab label="Consultas"/>
                <StyledTab label="Gráficos"/>
            </StyledTabs>
            <div className={classes.panelsContainer}>
                <TabPanel value={selectedTab} index={0}>
                    <Inspeccion {...props} />
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    <ValidarCertificado {...props} />
                </TabPanel>
                <TabPanel value={selectedTab} index={2}>
                    <Consultas {...props} />
                </TabPanel>
                <TabPanel value={selectedTab} index={3}>
                    <Graficos {...props} />
                </TabPanel>
            </div>
        </div>
    );
}

export default TabsGestionRiesgo;