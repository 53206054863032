export default theme => ({
    downloadButton: {
        width: '150px',
        height: '54px',
    },
    formControl: {
        width: '100%',
    },
    row: {
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    rowItem: {
        width: '300px',
    },
});