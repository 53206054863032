import { useState, useEffect } from 'react';
import { getAllActivities } from '../services/activitiesService';

const useAllActivities = () => {
    const [activities, setActivities] = useState([]);

    useEffect(() => {
        async function fetchAllActivities() {
            try {
                const { data } = await getAllActivities();
                setActivities(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchAllActivities();
    }, []);

    function findActivityById(activity_id) {
        const activity = activities.find(activity => activity.id === activity_id);
        return activity;
    }

    return { activities, findActivityById };
}

export default useAllActivities;