import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalAlert } from '../../../../../../store/slices/alertsSlice';
import { 
    selectAvailabilities,
    getAvailabilitiesRequest,
} from '../../../../../../store/slices/availabilitiesSlice';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Can, Only } from '../../../../../../components/Can';
import { ROLES_KEYS } from '../../../../../../constants/roles';

import { SearchField } from '../../../../../../components/SearchField';
import { AllowOnlyIf } from '../../../../../../components/AllowOnlyIf';

import { MotorUrls } from '../../../../routing';

import format from 'date-fns/format';

import useBackendMessageResponse from '../../../../../../hooks/useBackendMessageResponse';
import { deleteAvailability } from '../../../../../../services/MT/availabilitiesService';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';
import { PERMISSION_KEYS } from '../../../../../../constants/permissions';

const useStyles = makeStyles(styles);

const ListaDisponibilidad = (props) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const dispatch = useDispatch();
    const availabilities = useSelector(selectAvailabilities);
    const {
        backendResponse: deleteAvailabilityResponse,
        executeService: deleteAvailabilityService,
    } = useBackendMessageResponse(deleteAvailability);
    const [availabilityClickedData, setAvailabilityClickedData] = useState({
        openDialog: false,
        availability: null,
    });

    const onClickAddAvailability = () => {
        const { history } = props;
        history.push(MotorUrls.AddAvailability);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onGetSearchTerm = (term) => {
        setSearchTerm(term);
        setPage(0);
    }

    const onClickEditAvailability = (availability) => () => {
        const { history } = props;
        history.push(MotorUrls.EditAvailability.replace(':availability_id', availability.id));
    }

    const onClickDeleteAvailability = (availability) => () => {
        setAvailabilityClickedData({
            openDialog: true,
            availability,
        });
    }

    const handleCloseDeleteDialog = () => {
        setAvailabilityClickedData({
            openDialog: false,
            availability: null,
        });
    }

    const handleAvailabilityDelete = async () => {
        try {
            const availabilityId = availabilityClickedData.availability.id;
            await deleteAvailabilityService(availabilityId);
            setAvailabilityClickedData({
                openDialog: false,
                availability: null,
            });
            dispatch(getAvailabilitiesRequest({
                page: page + 1,
                page_limit: rowsPerPage,
                filters: {
                    searchTerm: searchTerm !== '' ? searchTerm : undefined,
                },
            }));
        } catch (e) {
            const { response } = e;
            console.log(response);
        }
    };

    useEffect(() => {
        dispatch(getAvailabilitiesRequest({
            page: page + 1,
            page_limit: rowsPerPage,
            filters: {
                searchTerm: searchTerm !== '' ? searchTerm : undefined,
            },
        }));
    }, [dispatch, page, rowsPerPage, searchTerm]);

    useEffect(() => {
        if (deleteAvailabilityResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: deleteAvailabilityResponse.message,
            severity: 'success',
        }));
    }, [dispatch, deleteAvailabilityResponse.message]);

    useEffect(() => {
        if (deleteAvailabilityResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: deleteAvailabilityResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, deleteAvailabilityResponse.error])

    return (
        <div className={classes.root}>
            <Dialog
                open={availabilityClickedData.openDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-availability-delete-title"
                aria-describedby="alert-dialog-availability-delete-description"
            >
                <DialogTitle id="alert-dialog-availability-delete-title">{"Confirmación"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-availability-delete-description">
                        ¿Estás seguro de querer continuar con la eliminación del registro seleccionado?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleAvailabilityDelete} color="primary" autoFocus>
                        Continuar
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.fullWidth} spacing={2}>
                <Grid item md={6} xs={12} className={classes.searchInput}>
                    <SearchField onClickSearch={onGetSearchTerm} />
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" justify="space-between" className={classes.fullWidth}>
                <Grid item>
                    <Typography component={"span"}>
                        <Box fontWeight="fontWeightBold" fontSize={25}>Disponibilidad</Box>
                    </Typography>
                </Grid>
                <Grid item>
                    <AllowOnlyIf permission={PERMISSION_KEYS.MT_WRITE}>
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            className={classes.addAvailabilityButton} 
                            disableElevation
                            onClick={onClickAddAvailability}
                        >
                            Añadir disponibilidad 
                        </Button>
                    </AllowOnlyIf>
                </Grid>
            </Grid>
            <Paper elevation={0}>
                <TableContainer>
                    <Table className={classes.table} aria-label="damage-reports-table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Reporte</TableCell>
                                <TableCell align="left">Horas disponible</TableCell>
                                <TableCell align="left">Fecha</TableCell>
                                <TableCell align="left">Código de vehículo</TableCell>
                                <TableCell align="left">Reporte accidente</TableCell>
                                <TableCell align="left">Reporte mantenimiento</TableCell>
                                <Can>
                                    <Only roles={[ROLES_KEYS.admin, ROLES_KEYS.risk_agent]}>
                                        <TableCell align="left">Estación</TableCell>
                                    </Only>
                                </Can>
                                <AllowOnlyIf permission={PERMISSION_KEYS.MT_WRITE}>
                                    <TableCell align="right">Acciones</TableCell>
                                </AllowOnlyIf>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {availabilities.availabilities.map((availability, key) => (
                                <TableRow key={`list-availability-item-key-${key}`}>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {availability.report}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {format(new Date(availability.time), "HH:mm")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {format(new Date(availability.date), "dd/MM/yyyy")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {availability.vehicle.code}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {availability.damage_report.report}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {availability.maintenance.report}
                                        </Typography>
                                    </TableCell>
                                    <Can>
                                        <Only roles={[ROLES_KEYS.admin, ROLES_KEYS.risk_agent]}>
                                            <TableCell align="left">
                                                <Typography component={"span"} className={classes.tableCellText}>
                                                    {`${availability.station_name}`}
                                                </Typography>
                                            </TableCell>
                                        </Only>
                                    </Can>
                                    <AllowOnlyIf permission={PERMISSION_KEYS.MT_WRITE}>
                                        <TableCell align="right">
                                            <Grid container direction="row" alignItems="center" justify="flex-end" spacing={1}>
                                                <Grid item>
                                                    <IconButton aria-label="edit" className={classes.iconButton} onClick={onClickEditAvailability(availability)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton aria-label="delete" className={classes.iconButton} onClick={onClickDeleteAvailability(availability)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </AllowOnlyIf>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={availabilities.total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage="Filas por página:"
                        labelDisplayedRows={() => {
                            return `Página: ${page + 1} de ${Math.ceil(availabilities.total / rowsPerPage)}`;
                        }}
                    />
                </TableContainer>
            </Paper>
        </div>
    );
};

export default ListaDisponibilidad;