import { createSlice } from '@reduxjs/toolkit';

export const areasSlice = createSlice({
    name: 'areas',
    initialState: {
        isLoading: false,
        areas: [],
        total: null,
        pages: null,
        page: 1,
        page_limit: 10,
        error: {
            code: null,
            message: null,
        },
    },
    reducers: {
        getAreasRequest: (state) => {
            state.isLoading = true;
        },
        getAreasSuccess: (state, action) => {
            state.isLoading = false;
            state.error = {
                code: null,
                message: null,
            };
            state.areas = action.payload.areas;
            state.total = action.payload.total;
            state.pages = action.payload.pages;
            state.page = action.payload.page;
            state.page_limit = action.payload.page_limit;
        },
        getAreasFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
    },
});

export const {
    getAreasRequest,
    getAreasSuccess,
    getAreasFailure,
} = areasSlice.actions;

export const selectAreas = (state) => state.areas;

export default areasSlice.reducer;