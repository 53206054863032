import { useState, useEffect } from 'react';
import { getInspectionById } from '../../services/GR/inspectionService';

const useInspectionById = (inspection_id) => {
    const [inspection, setInspection] = useState(null);

    useEffect(() => {
        async function fetchInspection() {
            try {
                const { data } = await getInspectionById(inspection_id);
                setInspection(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchInspection();
    }, [inspection_id]);

    return { inspection };
}

export default useInspectionById;