import React from 'react';

import { Form, Field, ErrorMessage } from 'formik';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const CoursesForm = (props) => {
    const classes = useStyles();

    const hasError = (field) => {
        return props.errors[field] && props.touched[field] ? true : false;
    }

    return (
        <Form>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                    <Grid item className={classes.fullWidth}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Field
                                    name="name"
                                    as={TextField}
                                    className={classes.formControl}
                                    variant="outlined"
                                    label="Nombre del curso"
                                    type="text"
                                    error={hasError("name")}
                                    helperText={<ErrorMessage name="name" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field 
                                    name="date" 
                                    as={KeyboardDatePicker} 
                                    label="Fecha"
                                    className={classes.formControl} 
                                    inputVariant="outlined"
                                    variant="inline"
                                    InputAdornmentProps={{ position: "end" }}
                                    format="dd/MM/yyyy"
                                    onChange={(date) => props.setFieldValue("date", date)}
                                    error={hasError("date")}
                                    autoOk
                                    helperText={<ErrorMessage name="date" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.fullWidth}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Field
                                    name="time"
                                    as={TextField}
                                    className={classes.formControl}
                                    variant="outlined"
                                    label="Duración en horas"
                                    type="number"
                                    error={hasError("time")}
                                    helperText={<ErrorMessage name="time" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field
                                    name="emmited_by"
                                    as={TextField}
                                    className={classes.formControl}
                                    variant="outlined"
                                    label="Institución que lo dictó"
                                    type="text"
                                    error={hasError("emmited_by")}
                                    helperText={<ErrorMessage name="emmited_by" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.fullWidth}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Field
                                    name="registry_number"
                                    as={TextField}
                                    className={classes.formControl}
                                    variant="outlined"
                                    label="N. de registro curso"
                                    type="number"
                                    error={hasError("registry_number")}
                                    helperText={<ErrorMessage name="registry_number" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Button
                    className={classes.saveCourseButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disableElevation
                >
                    Agregar
                </Button>
            </MuiPickersUtilsProvider>
        </Form>
    );
}

export default CoursesForm;