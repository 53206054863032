import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../store/slices/alertsSlice';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

import useDamageReportById from '../../../../hooks/MT/useDamageReportById';
import useBackendMessageResponse from '../../../../hooks/useBackendMessageResponse';
import { updateDamageReport } from '../../../../services/MT/damageReportsService';

import { MotorUrls } from '../../routing';


import { Formik } from 'formik';
import { DatosDamageReportForm } from '../Forms/DamageReports/DatosDamageReportForm';
import schema from '../Forms/DamageReports/DatosDamageReportForm/schema';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const EditDamageReport = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { damage_report_id } = useParams();
    const { damageReport } = useDamageReportById(damage_report_id);
    const {
        backendResponse: updateReportResponse,
        executeService: updateReportService,
    } = useBackendMessageResponse(updateDamageReport);
    const [damageReportData, setDamageReportData] = useState({
        // Ident. Form
        report: '',
        identification: '',
        date: new Date(),
        cause: '',
        time: new Date(),
        action: '',
        shift: '',
        novelty: '',
        operator_id: '',
        sheet: '',

        // Costs form
        business_name: '',
        iva: '',
        facturing_res: '',
        costs: '',
        receipt_number: '',
        ivaless_cost: '',
        equipment_id: '',
        vehicle_id: '',
        type: 'Equipo',
    });

    const handleGoBackClick = () => {
        const { history } = props;
        history.goBack();
    }

    const handleSubmit = async (values) => {
        setDamageReportData(values);
        // Se tiene que eliminar uno de los dos por dos motivos.
        // 1. Para que el motor de bases de datos no se queje.
        // 2. No tiene sentido tener ambos si se supone sólo es de un único tipo.
        if (values.type === "Equipo") {
            delete values["vehicle_id"];
        } else if (values.type === "Máquina") {
            delete values["equipment_id"];
        }
        await updateReportService(damage_report_id, values);
    }

    useEffect(() => {
        if (updateReportResponse.message === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: updateReportResponse.message,
            severity: 'success',
        }));

        props.history.push(MotorUrls.Listado);
    }, [dispatch, updateReportResponse.message, props.history]);

    useEffect(() => {
        if (updateReportResponse.error === null) {
            return;
        }

        dispatch(setGlobalAlert({
            show: true,
            duration: 5000,
            message: updateReportResponse.error.message,
            severity: 'error',
        }));
    }, [dispatch, updateReportResponse.error])

    useEffect(() => {
        if (damageReport === null) {
            return;
        }

        setDamageReportData({
            // Ident. Form
            report: damageReport.report,
            identification: damageReport.identification,
            date: new Date(damageReport.date),
            cause: damageReport.cause,
            time: new Date(damageReport.time),
            action: damageReport.action,
            shift: damageReport.shift,
            novelty: damageReport.novelty,
            operator_id: damageReport.operator_id,
            sheet: damageReport.sheet,

            // Costs form
            business_name: damageReport.business_name,
            iva: damageReport.iva,
            facturing_res: damageReport.facturing_res,
            costs: damageReport.costs,
            receipt_number: damageReport.receipt_number,
            ivaless_cost: damageReport.ivaless_cost,
            equipment_id: damageReport.equipment_id ?? '',
            vehicle_id: damageReport.vehicle_id ?? '',
            type: damageReport.type,
        });
    }, [damageReport]);

    return (
        <div className={classes.root}>
            <IconButton aria-label="goback" color="primary" className={classes.goBackIcon} onClick={handleGoBackClick}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography component={"span"}>
                <Box fontWeight="fontWeightBold" fontSize={25}>Editar reporte de daños</Box>
            </Typography>
            <div className={classes.form}>
                <Formik
                    enableReinitialize
                    validationSchema={schema}
                    initialValues={damageReportData}
                    component={DatosDamageReportForm}
                    onSubmit={handleSubmit}
                />
            </div>
        </div>
    );
};

export default EditDamageReport;