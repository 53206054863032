import * as Yup from 'yup';

export default Yup.object().shape({
    image_url: Yup.string(),
    firstname: Yup.string()
        .max(100, 'Máximo 100 caracteres.')
        .required('Éste campo es requerido.'),
    lastname: Yup.string()
        .max(100, 'Máximo 100 caracteres.')
        .required('Éste campo es requerido.'),
    document_type: Yup.string()
        .required('Éste campo es requerido.'),
    document: Yup.string()
        .max(16, 'Máximo 16 caracteres.')
        .required('Éste campo es requerido.'),
    phone: Yup.string()
        .max(13, 'Máximo 13 dígitos.')
        .required('Éste campo es requerido.'),
    email: Yup.string()
        .required('Éste campo es requerido.'),
    risk_user_type: Yup.string()
        .required('Éste campo es requerido.'),
    department_id: Yup.number()
        .required('Éste campo es requerido.'),
    city_id: Yup.number()
        .required('Éste campo es requerido.'),
});