export default theme => ({
    saveButton: {
        marginTop: '20px',
        width: '170px',
    },
    formControl: {
        width: '100%',
        '& .Mui-disabled input, & .Mui-disabled div[class*="MuiSelect-"]': {
            color: "rgba(0,0,0,0.6)"
        },
    },
    row: {
        width: '60%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    fullWidth: {
        width: '100%',
    },
    topSpacing: {
        marginTop: '30px',
    },
    ratioContainer: {
        width: '150px',
        paddingTop: '56.25%', // 16:9 ratio
        position: 'relative',
        marginTop: '10px',
    },
    ratioContent: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: '5px',
    },
    inputFile: {
        display: 'none',
    },
    buttonBase: {
        width: '100%',
        height: '100%',
    },
    loadImageIcon: {
        fontSize: 40,
        color: '#96C2C9',
    },
    container: {
        backgroundColor: '#E7F6FC',
        width: '100%',
        height: '100%',
        borderRadius: 5,
        borderStyle: 'dashed',
        borderColor: '#96C2C9',
    },
    imageDeleteButton: {
        color: '#5C5C5C',
    },
    deleteButtonContainer: {
        position: 'absolute',
        top: 0,
        right: -5,
    },
});