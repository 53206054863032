import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { authResetState, checkReliefCorpRequest } from '../../store/slices/authSlice';

import validate from 'validate.js';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Swal from 'sweetalert2';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

import routesUrls from '../../routes/urls';

import schema from './schema';

import { selectAuth } from '../../store/slices/authSlice';

import { getAllInspections } from '../../services/GR/inspectionService';

const useStyles = makeStyles(styles);

const ReliefCorps = (props) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const authState = useSelector(selectAuth);
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 4000,
        title: {
            fontFamily: 'Red Hat Display sans-serif',
        },
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const { history } = props;

    const [formState, setFormState] = useState({
        isValid: false,
        isSubmitted: false,
        values: {
            document: '',
        },
        touched: {
            document: false,
        }, 
        errors: {
            document: [],
        }
    });

    const [certData, setCertData] = useState({
        isSubmitted: false,
        isValid: false,
        certificateMessage: null,
        values: {
            cert_number: '',
        },
        touched: {
            cert_number: false,
        },
        errors: {
            cert_number: [],
        }
    });

    const hasError = field =>
        (formState.isSubmitted || formState.touched[field]) && formState.errors[field] ? true : false;

    const hasErrorCert = field =>
        (certData.isSubmitted || certData.touched[field]) && certData.errors[field] ? true : false;

    useEffect(() => {
        const errors = validate(formState.values, schema);

        setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [formState.values, formState.isSubmitted]);

    useEffect(() => {
        const errors = validate(certData.values, schema);

        setCertData(certData => ({
            ...certData,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [certData.values, certData.isSubmitted]);

    useEffect(() => {
        if(authState.token !== null) {
            history.push(routesUrls.SignInPostConfig);
        }
    }, [authState, history]);

    useEffect(() => {
      if (authState.token === null && authState.isLoading) {
        dispatch(authResetState());
      }
    // eslint-disable-next-line
    }, []);


    const handleChange = event => {
        event.persist();

        setFormState(formState => ({
            ...formState,
            isValid: false,
            isSubmitted: false,
            values: {
                ...formState.values,
                [event.target.name]: event.target.value,
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            },
            errors: {
                ...formState.errors,
                [event.target.name]: []
            }
        }));

        setCertData(certData => ({
            ...certData,
            isValid: false,
            isSubmitted: false,
            certificateMessage: null,
            values: {
                ...certData.values,
                [event.target.name]: event.target.value,
            },
            touched: {
                ...certData.touched,
                [event.target.name]: true
            },
            errors: {
                ...certData.errors,
                [event.target.name]: []
            }
        }));
    };

    const handleCheckReliefCorps = async event => {
        event.preventDefault();

        if(!formState.isSubmitted) {
            setFormState(formState => ({
                ...formState,
                isSubmitted: true,
            }));
        }
        
        const data = {
            document: formState.values.document,
        };

        dispatch(checkReliefCorpRequest(data));
        setCertData(certData => ({
            ...certData,
            isSubmitted: false,
        }));
    };

    const handleCheckCertificate = async event => {
        event.preventDefault();
        if(!certData.isSubmitted) {
            setCertData(certData => ({
                ...certData,
                isSubmitted: true,
            }));
        }
        const { cert_number } = certData.values;
        if(cert_number !== ''){
            const inspection = await getAllInspections({
                cert_number,
            });
    
            setCertData(certData => ({
                ...certData,
                isValid: isCertificateValid(inspection),
                certificateMessage: isCertificateValid(inspection)
                    ? "Certificado válido"
                    : "Certificado inválido",
            }));
                 
        }
        setFormState(formState => ({
            ...formState,
            isSubmitted: false,
        }));
    };

    const isCertificateValid = (inspection) => {
        if(inspection.data.length === 0) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (certData.isSubmitted && certData.isValid && certData.certificateMessage !== null) {
            Toast.fire({
                icon: 'success',
                title: certData.certificateMessage,
                background: 'green',
                color: 'white',
            });
        }else if (certData.isSubmitted && !certData.isValid && certData.certificateMessage !== null) {
            Toast.fire({
                icon: 'error',
                title: certData.certificateMessage,
                background: 'red',
                color: 'white',
            });
        }
    }, [Toast, certData]);
    
    useEffect(() => {
        if (formState.isSubmitted && authState.error.message !== null && formState.values.document !== '') {
            Toast.fire({
                icon: 'error',
                title: authState.error.message,
                background: 'red',
                color: 'white',
                width: '26rem',
            });
        }else if (formState.isSubmitted && authState.message !== null) {
            Toast.fire({
                icon: 'success',
                title: authState.message,
                background: 'green',
                color: 'white',
                width: '26rem',
            });
        }
    }, [Toast, formState, authState]);

    return (
        <div className={classes.root}>
            <Grid className={classes.grid} container>
                <Grid className={classes.content} item xs={6}>
                    <div className={classes.contentBody}>
                        <form onSubmit={handleCheckCertificate} className={classes.form} 
                            style={{ transform: 'translateY(50%)', position: 'absolute'}} 
                        >
                            <Typography className={classes.recoverPasswordText}>
                                <Box fontWeight="fontWeightBold" fontSize={35} style={{ color: '#FFFFFF' }} textAlign={'center'}>
                                    Validar certificado
                                </Box>
                            </Typography>
                            <TextField
                                fullWidth
                                label="Numero del certificado"
                                name="cert_number"
                                onChange={handleChange}
                                error={hasErrorCert('cert_number')}
                                helperText={
                                    hasErrorCert('cert_number') ? certData.errors.cert_number[0] : null
                                }
                                type="text"
                                value={certData.values.cert_number}
                            />
                            <Button
                                fullWidth
                                className={classes.checkButton}
                                style={{backgroundColor: '#ffe250'}}
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                Verficar certificado
                            </Button>
                        </form>
                    </div>
                </Grid>
                <Grid className={classes.content} item xs={6}>
                    <div className={classes.contentBody}>
                        <form onSubmit={handleCheckReliefCorps} className={classes.form}>
                            <Typography className={classes.recoverPasswordText}>
                                <Box fontWeight="fontWeightBold" fontSize={35} style={{ color: '#FFFFFF' }} textAlign={'center'}>
                                    Validar Funcionario
                                </Box>
                            </Typography>
                            <TextField
                                fullWidth
                                label="Ingresar ID"
                                name="document"
                                onChange={handleChange}
                                error={hasError('document')}
                                helperText={
                                    hasError('document') ? formState.errors.document[0] : null
                                }
                                type="number"
                                value={formState.values.document}
                            />
                            <Button
                                fullWidth
                                className={classes.checkButton}
                                style={{backgroundColor: '#ffe250'}}
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                Validar funcionario
                            </Button>
                        </form>
                    </div>
                </Grid>
            </Grid>
            <Typography className={classes.signInText}>
                <Box fontWeight="fontWeightBold" fontSize={14}>
                    <Link href="/auth/signin" style={{ color: '#FFFFFF' }}>
                        Iniciar sesión
                    </Link>
                </Box>
            </Typography>
        </div>
    );
};

export default ReliefCorps;