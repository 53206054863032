import differenceInSeconds from 'date-fns/differenceInSeconds';

function useTimestampWithCustomDate(year, month, day, hours, minutes, seconds) {
    const later = new Date(year, month, day, hours, minutes, seconds);
    const today = new Date();

    const difference = differenceInSeconds(today, later);
    return difference;
}

export default useTimestampWithCustomDate;