import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Form, Field } from 'formik';

import { Can, Only } from '../../../../../../../components/Can';
import { ROLES_KEYS } from '../../../../../../../constants/roles';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const InspectionFilterForm = (props) => {
    const classes = useStyles();

    return (
        <Form>
            <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                <Grid item className={classes.fullWidth}>
                    <Field
                        name="inspection_number"
                        as={TextField}
                        className={classes.formControl}
                        variant="outlined"
                        label="N. de inspección"
                        size="small"
                    />
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <Field
                        name="owner_name"
                        as={TextField}
                        className={classes.formControl}
                        variant="outlined"
                        label="Propietario"
                        size="small"
                    />
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <Field
                        name="inspectio_date"
                        as={TextField}
                        className={classes.formControl}
                        variant="outlined"
                        label="Fecha de inspección"
                        size="small"
                    />
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <Field
                        name="inspector_name"
                        as={TextField}
                        className={classes.formControl}
                        variant="outlined"
                        label="Inspector"
                        size="small"
                    />
                </Grid>
                <Can>
                    <Only roles={[ROLES_KEYS.admin, ROLES_KEYS.risk_agent, ROLES_KEYS.client]}>
                        <Field
                            name="station_name"
                            as={TextField}
                            className={classes.formControlStation}
                            variant="outlined"
                            label="Nombre de Estación"
                            size="small"
                        />
                    </Only>
                </Can>
                <Grid item className={classes.fullWidth}>
                    <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                        <Grid item>
                            <Button
                                name="apply"
                                variant="contained" 
                                color="primary" 
                                className={classes.filterButton} 
                                size="small" 
                                disableElevation
                                type="submit"
                            >
                                Aplicar
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button 
                                name="clean"
                                color="primary" 
                                size="small"
                                disableElevation
                                type="submit"
                            >
                                Limpiar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Form>
    );
}

export default InspectionFilterForm;