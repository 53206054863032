import * as Yup from 'yup';

import emailValidator from 'email-validator';

export default Yup.object().shape({
    inspection_number: Yup.string()
        .required('Éste campo es requerido.'),
    address: Yup.string()
        .max(60, 'Máximo 60 caracteres.')
        .required('Éste campo es requerido.'),
    social_reason: Yup.string()
        .max(100, 'Máximo 100 caracteres.')
        .required('Éste campo es requerido.'),
    neighborhood_id: Yup.number()
        .required('Éste campo es requerido.'),
    commercial_reason: Yup.string()
        .max(100, 'Máximo 100 caracteres.')
        .required('Éste campo es requerido.'),
    phone_number: Yup.string()
        .max(13, 'Máximo 13 dígitos.')
        .required('Éste campo es requerido.'),
    cert_number: Yup.string()
        .min(8, 'Minimo 8 dígitos.')
        .required('Éste campo es requerido.'),
    email: Yup.string()
        .max(60, 'Máximo 60 caracteres.')
        .test(
            'invalid-email-format',
            'Formato de correo inválido.',
            function (email) {
                return emailValidator.validate(email);
            }
        )
        .required('Éste campo es requerido.'),
    inspection_date: Yup.date()
        .required('Éste campo es requerido.'),
    owner_name: Yup.string()
        .max(100, 'Máximo 100 caracteres.')
        .required('Éste campo es requerido.'),
    state: Yup.string()
        .required('Éste campo es requerido.'),
    admin_name: Yup.string()
        .max(100, 'Máximo 100 caracteres.')
        .required('Éste campo es requerido.'),
    nit: Yup.string()
        .max(10, 'Máximo 10 dígitos.')
        .required('Éste campo es requerido.'),
});