import { call, put, takeLatest } from 'redux-saga/effects';

import { 
    getExtinguishers,
} from '../services/extinguishersService';

import { 
    getExtinguishersRequest,
    getExtinguishersSuccess,
    getExtinguishersFailure,
} from '../store/slices/extinguishersSlice';

function* getExtinguishersEffect(action) {
    try {
        const page = action.payload.page;
        const page_limit = action.payload.page_limit;
        const filters = action.payload.filters;
        const { data } = yield call(getExtinguishers, page, page_limit, filters);
        yield put(getExtinguishersSuccess({ 
            extinguishers: data.results,
            page: data.page,
            pages: data.pages,
            total: data.total,
            page_limit,
        }));
    } catch(e) {
        const { response } = e;
        const error = {
            code: response.status,
            message: response.data.message,
        };
        yield put(getExtinguishersFailure({ error }));
    }
}

function* extinguishersEffects() {
    yield takeLatest(getExtinguishersRequest.type, getExtinguishersEffect);
}

export default extinguishersEffects;