import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../../../store/slices/alertsSlice';

import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import schema from './schema';

import { Formik, Form, Field, ErrorMessage, validateYupSchema, yupToFormErrors } from 'formik';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const PrimerosAuxiliosForm = (props) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const dispatch = useDispatch();
    const { readMode } = props;

    const hasError = (field, formik) => {
        return formik.errors[field] && formik.touched[field] ? true : false;
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onClickDeleteFirstAid = (first_aid_index) => () => {
        const currentFirstAids = props.firstAidsData.first_aids;
        currentFirstAids.splice(first_aid_index, 1);
        props.onChangeFirstAids(currentFirstAids);
    }

    const handleNext = () => {
        if (props.firstAidsData.first_aid_apply && props.firstAidsData.first_aids.length === 0) {
            dispatch(setGlobalAlert({
                show: true,
                duration: 5000,
                message: 'Debe cargar al menos un registro.',
                severity: 'error',
            }));
            return;
        }
        props.onHandleNext();
    }

    return (
        <div className={classes.root}>
            <Formik
                initialValues={{
                    name: '',
                    document: '',
                    types: '',
                    observations: '',
                }}
                validate={async (values) => {
                    try {
                        await validateYupSchema(values, schema, false, { first_aids: props.firstAidsData.first_aids });
                    } catch(err) {
                        return yupToFormErrors(err);
                    }

                    return {};
                }}
                onSubmit={(values, actions) => {
                    const firstAids = props.firstAidsData.first_aids;
                    firstAids.push(values);
                    props.onChangeFirstAids(firstAids);
                    actions.resetForm();
                }}
            >
                {formik => (
                    <Form>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item className={classes.row}>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="first_aid_apply"
                                                    checked={!props.firstAidsData.first_aid_apply}
                                                    onChange={() => props.onToggleApply()}
                                                    color="primary"
                                                />
                                            }
                                            label="No aplica"
                                            disabled={readMode}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.row}>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <Field 
                                            name="name" 
                                            type="text" 
                                            as={TextField} 
                                            variant="outlined" 
                                            label="Nombre"
                                            className={classes.formControl} 
                                            error={hasError("name", formik)}
                                            helperText={<ErrorMessage name="name" />}
                                            disabled={!props.firstAidsData.first_aid_apply || readMode}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field 
                                            name="document" 
                                            type="text" 
                                            as={TextField} 
                                            variant="outlined" 
                                            label="Identificación"
                                            className={classes.formControl} 
                                            error={hasError("document", formik)}
                                            helperText={<ErrorMessage name="document" />}
                                            disabled={!props.firstAidsData.first_aid_apply || readMode}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.row}>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <Field 
                                            name="types" 
                                            type="text" 
                                            as={TextField} 
                                            variant="outlined" 
                                            label="Tipo de primeros auxilios"
                                            className={classes.formControl} 
                                            error={hasError("types", formik)}
                                            helperText={<ErrorMessage name="types" />}
                                            disabled={!props.firstAidsData.first_aid_apply || readMode}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.row}>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <Field 
                                            name="observations" 
                                            type="text" 
                                            as={TextField} 
                                            variant="outlined" 
                                            label="Observación especial"
                                            className={classes.formControl} 
                                            error={hasError("observations", formik)}
                                            helperText={<ErrorMessage name="observations" />}
                                            multiline
                                            rows={6}
                                            disabled={!props.firstAidsData.first_aid_apply || readMode}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.row}>
                                <Paper elevation={0}>
                                    <TableContainer>
                                        <Table className={classes.table} aria-label="activities-table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Nombre</TableCell>
                                                    <TableCell align="left">Identificación</TableCell>
                                                    <TableCell align="left">Tipo</TableCell>
                                                    <TableCell align="left">Observación</TableCell>
                                                    <TableCell align="right"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {props.firstAidsData.first_aids.map((first_aid, key) => {
                                                    return (
                                                        <TableRow key={`list-first-aids-item-key-${key}`}>
                                                            <TableCell align="left">
                                                                <Typography component={"span"} className={classes.tableCellText}>
                                                                    {`${first_aid.name}`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography component={"span"} className={classes.tableCellText}>
                                                                    {`${first_aid.document}`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography component={"span"} className={classes.tableCellText}>
                                                                    {`${first_aid.types}`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography component={"span"} className={classes.tableCellText}>
                                                                    {`${first_aid.observations}`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <IconButton aria-label="delete" disabled={readMode} className={classes.iconButton} onClick={onClickDeleteFirstAid(first_aid)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={props.firstAidsData.first_aids.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            labelRowsPerPage="Filas por página:"
                                            labelDisplayedRows={() => {
                                                return `Página: ${page + 1} de ${Math.ceil(props.firstAidsData.first_aids.length / rowsPerPage)}`;
                                            }}
                                        />
                                    </TableContainer>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Button
                            className={classes.saveButton}
                            style={{marginRight: '10px'}}
                            color="primary"
                            size="large"
                            type="submit"
                            variant="outlined"
                            disableElevation
                            disabled={!props.firstAidsData.first_aid_apply || readMode}
                        >
                            Añadir
                        </Button>
                        <Button
                            className={classes.saveButton}
                            color="primary"
                            size="large"
                            variant="contained"
                            disableElevation
                            onClick={handleNext}
                            disabled={readMode}
                        >
                            Siguiente
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

PrimerosAuxiliosForm.defaultProps = {
    readMode: false,
};

export default PrimerosAuxiliosForm;