import { createSlice } from '@reduxjs/toolkit';

export const positionsSlice = createSlice({
    name: 'positions',
    initialState: {
        isLoading: false,
        positions: [],
        error: {
            code: null,
            message: null,
        },
    },
    reducers: {
        getPositionsRequest: (state) => {
            state.isLoading = true;
        },
        getPositionsSuccess: (state, action) => {
            state.isLoading = false;
            state.error = {
                code: null,
                message: null,
            };
            state.positions = action.payload.positions;
        },
        getPositionsFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
    },
});

export const {
    getPositionsRequest,
    getPositionsSuccess,
    getPositionsFailure,
} = positionsSlice.actions;

export const selectPositions = (state) => state.positions;

export default positionsSlice.reducer;