export default (theme) => ({
    root: {
        height: '100vh',
    },
    containerGrid: {
        height: '100%',
        width: '95%',
    },
    forms: {
        // marginRight: theme.spacing(10),
    },
    logo: {
        marginRight: theme.spacing(7),
        marginBottom: theme.spacing(1),
        zIndex: 5,
    },
    logoRelief: {
        marginRight: theme.spacing(35),
        marginBottom: theme.spacing(1),
        zIndex: 5,
    },
    backgroundImage: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: -4,
        top: 0,
        left: 0,
    },
    backgroundGradient: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        background: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(20, 20, 20, 0.73))',
        zIndex: -3,
    },
    backgroundRadioButtons: {
        width: '255px',
        marginTop: '30px',
    },

    backgroundRadioButtonsRelief: {
        width: '255px',
        marginTop: '30px',
        marginRight: theme.spacing(30),
    },
});