export default theme => ({
    root: {
    },
    goBackIcon: {
        marginLeft: '-15px',
    },
    panelsContainer: {
        paddingTop: '25px',
    },
    columnWidth: {
        width: '100%',
    },
    saveProfileButton: {
        marginTop: '50px',
        width: '170px',
    },
});