const routesUrls = {
     // Comunes
     DatosGlobales: '/datos-globales',
     MyProfile: '/my-profile',
     SignInPostConfig: '/signing-in',

     // Modules
     Siniestralidad: '/siniestralidad',
     Motor: '/motor',
     GestionRiesgo: '/gestion-de-riesgo',

     // Admin
     AdminUsuarios: '/admin/usuarios',
     Configuracion: '/configuracion',

     // Estación
     EstacionUsuarios: '/estacion/usuarios',
     Eventos: '/eventos',

     // Agentes de riesgo
     RiesgoUsuarios: '/agentes/usuarios',

     // Auth
     SignIn: '/auth/signin',
     ReliefCorps: '/auth/relief-corps',
     PasswordRecovery: '/auth/password-recovery',
     AuthChangePassword: '/auth/change-password/:token',
};

export default routesUrls;