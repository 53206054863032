import React, { useState } from 'react';

import { Switch, Route } from 'react-router-dom';

import { StyledTab } from '../../components/StyledTab';
import { StyledTabs } from '../../components/StyledTabs';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

import profileRouting from './routing';

const useStyles = makeStyles(styles);

const Perfil = props => {
    // Tiene que ser igual al index del primer elemento de pagesRouting
    // porque se supone que el path de tal elemento es el mismo que el de la vista como tal.
    const [selectedTab, setSelectedTab] = useState(profileRouting[0].index);
    const classes = useStyles();

    const { history } = props;

    const handleTabChange = (event, newValue) => {
        const newPage = profileRouting.find(item => item.index === newValue);
        if (newPage !== undefined) {
            setSelectedTab(newValue);
            history.replace(newPage.path);
        }
    }

    return (
        <div>
            <StyledTabs value={selectedTab} onChange={handleTabChange} aria-label="profile-tabs">
                <StyledTab label="Información de usuario"/>
                <StyledTab label="Cambio de contraseña"/>
            </StyledTabs>
            <div className={classes.content}>
                <Switch>
                    {profileRouting.map((item, key) => (
                        <Route key={`perfil-${key}`} path={item.path} exact component={item.component} />
                    ))}
                </Switch>
            </div>
        </div>
    );
};

export default Perfil;