import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

import format from 'date-fns/format';

import { Formik } from 'formik';
import { CoursesForm } from '../../Forms/CoursesForm';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import schema from './schema';

const useStyles = makeStyles(styles);

const CoursesTable = (props) => {
    const classes = useStyles();
    const [openForm, setOpenForm] = useState(false);
    const [newCourseData, setNewCourseData] = useState({
        name: '',
        date: new Date(),
        time: '',
        emmited_by: '',
        registry_number: '',
    });
    const { courses, disabledAlter } = props;

    const onClickAddCourse = () => {
        setOpenForm(!openForm);
        setNewCourseData({
            name: '',
            date: new Date(),
            time: '',
            emmited_by: '',
            registry_number: '',
        });
    }

    const onClickDeleteCourse = (index) => () => {
        const { courses, onCoursesChange } = props;
        const updatedCourses = [...courses];
        updatedCourses.splice(index, 1);
        onCoursesChange(updatedCourses);
    }

    const handleSubmit = (newCourse) => {
        const { courses, onCoursesChange } = props;
        const coursesWithNewCourse = [...courses, newCourse];
        onCoursesChange(coursesWithNewCourse);
        setOpenForm(!openForm);
    }

    return (
        <div className={classes.root}>
            <Grid container direction="row" justify="space-between" alignItems="center" spacing={1}>
                <Grid item>
                    <Typography component="div">
                        <Box fontWeight="fontWeightBold" fontSize={16} className={classes.coursesTitle}>
                            Cursos 
                        </Box>
                    </Typography>
                </Grid>
                { !disabledAlter ? (
                    <Grid item>
                        <Grid container direction="row" justify="flex-end" alignItems="center">
                            <Grid item>
                                <Typography component={"span"}>
                                    <Box fontWeight="fontWeightLight" fontSize={13} className={classes.addCourseText}>
                                        {!openForm ? `Añadir información` : `Cerrar formulario`}
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton aria-label="add-fire-type-icon" color="primary" className={classes.addCourseContainer} onClick={onClickAddCourse}>
                                    {!openForm ? (
                                        <AddCircleRoundedIcon className={classes.addCourse} />
                                    ) : (
                                        <CancelIcon className={classes.addCourse} />
                                    )}
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
            </Grid>
            <Paper elevation={0}>
                <TableContainer className={classes.table}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Curso</TableCell>
                                <TableCell align="center">Fecha</TableCell>
                                <TableCell align="center">Duración en horas</TableCell>
                                <TableCell align="center">Instituto que lo dictó</TableCell>
                                <TableCell align="center">Número de registro del curso</TableCell>
                                { !disabledAlter ? (
                                    <TableCell align="center"></TableCell>
                                ) : null }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {courses.map((course, index) => (
                                <TableRow key={`course-key-${index}`}>
                                    <TableCell align="center">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {course.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {format(course.date, 'dd/MM/yyyy')}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {course.time}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {course.emmited_by}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography component={"span"} className={classes.tableCellText}>
                                            {course.registry_number}
                                        </Typography>
                                    </TableCell>
                                    { !disabledAlter ? (
                                        <TableCell align="center">
                                            <IconButton aria-label="delete-course-icon" className={classes.deleteCourseIcon} onClick={onClickDeleteCourse(index)}>
                                                <DeleteForeverRoundedIcon className={classes.deleteCourse} />
                                            </IconButton>
                                        </TableCell>
                                    ) : null}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            {openForm && (
                <Formik 
                    validationSchema={schema}
                    enableReinitialize
                    component={CoursesForm}
                    initialValues={newCourseData}
                    onSubmit={(values) => {
                        handleSubmit(values);
                    }}
                />
            )}
        </div>
    );
}

CoursesTable.defaultProps = {
    courses: [],
    onCoursesChange: () => {},
    disabledAlter: false,
};

export default CoursesTable;