import { createSlice } from '@reduxjs/toolkit';

export const deviationsSlice = createSlice({
    name: 'deviations',
    initialState: {
        isLoading: false,
        deviations: [],
        total: null,
        pages: null,
        page: 1,
        page_limit: 10,
        error: {
            code: null,
            message: null,
        },
    },
    reducers: {
        getDeviationsRequest: (state) => {
            state.isLoading = true;
        },
        getDeviationsSuccess: (state, action) => {
            state.isLoading = false;
            state.error = {
                code: null,
                message: null,
            };
            state.deviations= action.payload.deviations;
            state.total = action.payload.total;
            state.pages = action.payload.pages;
            state.page = action.payload.page;
            state.page_limit = action.payload.page_limit;
        },
        getDeviationsFailure: (state, action)  => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
    },
});

export const { 
    getDeviationsRequest,
    getDeviationsSuccess,
    getDeviationsFailure,
} = deviationsSlice.actions;

export const selectDeviations = (state) => state.deviations;

export default deviationsSlice.reducer;