import routesUrls from '../../routes/urls';

import { 
    UserProfile, 
    ChangePassword 
} from './Pages';

export const profileRoutingUrls = {
    MyProfilePage: routesUrls.MyProfile,
    UpdatePassword: `${routesUrls.MyProfile}/update-password`,
};

const profileRouting = [{
    index: 0,
    component: UserProfile,
    path: profileRoutingUrls.MyProfilePage,
}, {
    index: 1,
    component: ChangePassword,
    path: profileRoutingUrls.UpdatePassword,
}];

export default profileRouting;