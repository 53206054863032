export default ({
    name: {
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
        length: {
            maximum: 60,
            tooLong: 'Éste campo sólo permite un máximo de %{count} caracteres.',
        },
    },
    capacity: {
        length: {
            maximum: 10,
            tooLong: 'Éste campo sólo permite un máximo de %{count} caracteres.',
        },
        presence: { allowEmpty: false, message: 'Éste campo es requerido.' },
    },
});