import { call, put, takeLatest } from 'redux-saga/effects';

import { 
    getAllEvents,
} from '../services/eventsService';

import { 
    getEventsRequest,
    getEventsSuccess,
    getEventsFailure,
} from '../store/slices/eventsSlice';

function* getEventsEffect(action) {
    try {
        const filters = action.payload.filters;
        const { data } = yield call(getAllEvents, filters);
        yield put(getEventsSuccess({ 
            events: data,
        }));
    } catch(e) {
        const { response } = e;
        const error = {
            code: response.status,
            message: response.data.message,
        };
        yield put(getEventsFailure({ error }));
    }
}

function* eventsEffects() {
    yield takeLatest(getEventsRequest.type, getEventsEffect);
}

export default eventsEffects;