import { 
    AddCity,
    AddDepartment,
    AddEmergency,
    AddNeighborhood,
    EditCity,
    EditDepartment,
    EditEmergency,
    EditNeighborhood,
    ListadosConfiguracion,
} from './Pages';

import routesUrls from '../../routes/urls';

export const configurationUrls = {
    Listado: `${routesUrls.Configuracion}/listados`,
    AddCity: `${routesUrls.Configuracion}/agregar-ciudad`,
    AddDepartment: `${routesUrls.Configuracion}/agregar-departamento`,
    AddEmergency: `${routesUrls.Configuracion}/agregar-emergencia`,
    AddNeighborhood: `${routesUrls.Configuracion}/agregar-vecindario`,
    EditCity: `${routesUrls.Configuracion}/editar-ciudad/:city_id`,
    EditDepartment: `${routesUrls.Configuracion}/editar-departamento/:department_id`,
    EditEmergency: `${routesUrls.Configuracion}/editar-emergencia/:emergency_id`,
    EditNeighborhood: `${routesUrls.Configuracion}/editar-vecindario/:neighborhood_id`,
}

const configuracionRouting = [{
    component: AddCity,
    path: configurationUrls.AddCity,
}, {
    component: AddDepartment,
    path: configurationUrls.AddDepartment,
}, {
    component: AddEmergency,
    path: configurationUrls.AddEmergency,
}, {
    component: AddNeighborhood,
    path: configurationUrls.AddNeighborhood,
}, {
    component: EditCity,
    path: configurationUrls.EditCity,
}, {
    component: EditDepartment,
    path: configurationUrls.EditDepartment,
}, {
    component: EditEmergency,
    path: configurationUrls.EditEmergency,
}, {
    component: EditNeighborhood,
    path: configurationUrls.EditNeighborhood,
}, {
    component: ListadosConfiguracion,
    path: configurationUrls.Listado,
}];

export default configuracionRouting;