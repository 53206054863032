import axios from 'axios';
import httpStatusCodes from 'http-status-codes';
import { signOutSuccess } from '../store/slices/authSlice';

const httpClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

export const configureInterceptors = (store) => {
    httpClient.interceptors.response.use(function(response) {
        return response;
    }, function(error) {
        const { response } = error;
        const statusCode = response.status;
        if (statusCode === httpStatusCodes.FORBIDDEN || statusCode === httpStatusCodes.UNAUTHORIZED) {
            // Se hace dispatch de signOutSuccess porque esta acción es la que dispara el middleware
            // que limpia el estado persistido y limpia el token del httpClient, lo cual causa
            // un redireccionamiento al login.
            store.dispatch(signOutSuccess());
        }
        return Promise.reject(error);
    });

    httpClient.interceptors.response.use(function(response) {
        return response;
    }, function(error) {
        if (error.request.responseType === 'blob' && 
            error.response.data instanceof Blob && 
            error.response.data.type &&
            error.response.data.type.toLowerCase().indexOf('json') !== -1) {
                return new Promise((resolve, reject) => {
                    let reader = new FileReader();

                    reader.onload = () => {
                        error.response.data = JSON.parse(reader.result);
                        resolve(Promise.reject(error));
                    }

                    reader.onerror = () => {
                        reject(error);
                    }

                    reader.readAsText(error.response.data);
                });
            }
        
        return Promise.reject(error);
    });
};

export default httpClient;
