import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

import { Formik, Form, Field, ErrorMessage } from 'formik';

import ExtintoresSchema, { SelectExtintorSchema } from './schema';

import { makeStyles } from '@material-ui/styles';
import styles from './styles';
import useAllExtinguishers from '../../../../../hooks/useAllExtinguishers';

const useStyles = makeStyles(styles);

const ExtintoresForm = (props) => {
    const classes = useStyles();

    const { extinguishers } = useAllExtinguishers();
    const { readMode } = props;

    const hasError = (field, formik) => {
        return formik.errors[field] && formik.touched[field] ? true : false;
    };

    const onDecreaseQuantity = (index) => () => {
        const selectedExtinguishers = props.extintoresData.extinguishers;
        selectedExtinguishers[index].quantity = selectedExtinguishers[index].quantity - 1;
        props.onChangeExtintores(selectedExtinguishers);
    }

    const onIncrementQuantity = (index) => () => {
        const selectedExtinguishers = props.extintoresData.extinguishers;
        selectedExtinguishers[index].quantity = selectedExtinguishers[index].quantity + 1;
        props.onChangeExtintores(selectedExtinguishers);
    }

    const onDeleteExtinguisher = (index) => () => {
        const selectedExtinguishers = props.extintoresData.extinguishers;
        selectedExtinguishers.splice(index, 1);
        props.onChangeExtintores(selectedExtinguishers);
    }

    return (
        <div className={classes.root}>
            <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
                <Grid item className={classes.row}>
                    <Formik
                        initialValues={{
                            extinguisher: {
                                id: '',
                                name: '',
                            },
                        }}
                        validationSchema={SelectExtintorSchema}
                        onSubmit={(values, actions) => {
                            const extinguisher = {
                                extinguisher_id: values.extinguisher.id,
                                extinguisher_name: values.extinguisher.name,
                                quantity: 1,
                            };
                            const selectedExtinguishers = props.extintoresData.extinguishers;
                            selectedExtinguishers.push(extinguisher);
                            props.onChangeExtintores(selectedExtinguishers);
                            actions.resetForm();
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <Grid container direction="row" spacing={2} alignItems="flex-start" justify="flex-start">
                                    <Grid item xs={10}>
                                        <Field
                                            name="extinguisher"
                                            as={Autocomplete}
                                            onChange={(event, selectedExtinguisher) => {
                                                formik.setFieldValue('extinguisher', selectedExtinguisher);
                                            }}
                                            id="extinguishers-combobox"
                                            options={extinguishers.filter((extinguisher) => 
                                                !props.extintoresData.extinguishers.find(e => e.extinguisher_id === extinguisher.id)
                                            )}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => 
                                                <TextField 
                                                    {...params} 
                                                    label="Extintores" 
                                                    variant="outlined" 
                                                    error={hasError('extinguisher', formik)}
                                                    helperText={<ErrorMessage name="extinguisher.id" />}
                                                />
                                            }
                                            disabled={readMode}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                            className={classes.addExtinguisherButton}
                                            color="primary"
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            disableElevation
                                            disabled={readMode}
                                        >
                                            Añadir
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
                <Grid item className={classes.row}>
                    <Divider variant="middle" />
                </Grid>
                <Grid item className={classes.row}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                        {props.extintoresData.extinguishers.map((extinguisher, key) => (
                            <Grid item xs={12} md={6} key={`extinguisher-key-${key}`}>
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <Grid item xs={2}>
                                        <IconButton 
                                            aria-label="upload picture" 
                                            component="span" 
                                            onClick={onDecreaseQuantity(key)}
                                            disabled={extinguisher.quantity === 1 || readMode}
                                            className={classes.iconButton}
                                        >
                                            <RemoveCircleRoundedIcon className={classes.iconSize} />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography component={"span"}>
                                            {extinguisher.quantity}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton disabled={readMode} color="primary" aria-label="upload picture" component="span" onClick={onIncrementQuantity(key)}>
                                            <AddCircleRoundedIcon className={classes.iconSize} />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography component={"span"}>
                                            {extinguisher.extinguisher_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton disabled={readMode} className={classes.iconButton} aria-label="upload picture" component="span" onClick={onDeleteExtinguisher(key)}>
                                            <DeleteRoundedIcon className={classes.iconSize} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                        <Grid item xs={12} md={6} className={classes.extinguishersAcumulator}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={5}>
                                <Grid item>
                                    <Typography component={"span"}>
                                        <Box fontWeight="fontWeightBold">
                                            {`Total de extintores: `}
                                        </Box>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography component={"span"} className={classes.extinguisherCounter}>
                                        <Box fontWeight="fontWeightBold">
                                            {`${props.extintoresData.extinguishers.reduce((accumulator, extinguisher) => {
                                                return accumulator + extinguisher.quantity;
                                            }, 0)}`}
                                        </Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.row}>
                    <Formik
                        initialValues={{
                            extinguishers_comments: props.extintoresData.extinguishers_comments,
                        }}
                        validationSchema={ExtintoresSchema}
                        onSubmit={(values) => {
                            props.onCommentChange(values.extinguishers_comments);
                            props.onHandleNext();
                        }}
                    >
                        {formik => (
                            <Form>
                                <Field 
                                    name="extinguishers_comments" 
                                    type="text" 
                                    as={TextField} 
                                    variant="outlined" 
                                    label="Comentarios"
                                    className={classes.formControl} 
                                    error={hasError('extinguishers_comments', formik)}
                                    helperText={<ErrorMessage name="extinguishers_comments" />}
                                    multiline
                                    rows={6}
                                    disabled={readMode}
                                />
                                <Button
                                    className={classes.saveButton}
                                    color="primary"
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    disableElevation
                                    disabled={readMode}
                                >
                                    Siguiente
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </div>
    );
}

ExtintoresForm.defaultProps = {
    readMode: false,
};

export default ExtintoresForm;