import { useState, useEffect } from 'react';
import { getVehicleById } from '../../services/vehiclesService';

const useVehicleById = (vehicle_id) => {
    const [vehicle, setVehicle] = useState(null);

    useEffect(() => {
        async function fetchVehicle() {
            try {
                const { data } = await getVehicleById(vehicle_id);
                setVehicle(data);
            } catch(e) {
                const { response } = e;
                console.log(response);
            }
        }
        fetchVehicle();
    }, [vehicle_id]);

    return { vehicle };
}

export default useVehicleById;