import React from 'react';
import { Switch, Route } from 'react-router-dom';

import adminUserRoutes from './routing';

const Usuarios = props => {
    return (
        <div>
            <Switch>
                {adminUserRoutes.map((item, key) => (
                    <Route key={`usuarios-${key}`} path={item.path} exact render={routeProps => (
                        <item.component {...routeProps} {...props} />
                    )}/>
                ))}
            </Switch>
        </div>
    );
};

export default Usuarios;