const white = '#FFFFFF';
const black = '#000000';

export default {
    black,
    white,
    primary: {
        main: '#0199AE',
    },
    secondary: {
        main: '#FF9C50',
    },
};